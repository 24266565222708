import { Helmet } from 'react-helmet';
import TeensBanner from "./TeensBanner";
import TeensCard from "./TeensCard";
import TeensCta from "./TeensCta";
import TalkAstrologer from "../TalkAstrologer";

function Teens() {
  return (
    <>
    <Helmet>
        <title>Navigate the Stars: Teen Horoscope Astrology Services</title>
        <meta name="description" data-rh="true" content="Teen Horoscope Astrology Service helps kids with astrology. Personalise horoscopes for direction, insight, and empowerment during these important years" />
        <meta name="keywords" data-rh="true" content="horoscope for teens, astrology for teens, teenage horoscope, marriage prediction, daily horoscope for teens, horoscope by date of birth, teen horoscope services" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/services/teen-horoscope-astrology-services/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/services/teen-horoscope-astrology-services/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Navigate the Stars: Teen Horoscope Astrology Services" />
        <meta property="og:description" content="Teen Horoscope Astrology Service helps kids with astrology. Personalise horoscopes for direction, insight, and empowerment during these important years" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Navigate the Stars: Teen Horoscope Astrology Services" />
        <meta name="twitter:description" content="Teen Horoscope Astrology Service helps kids with astrology. Personalise horoscopes for direction, insight, and empowerment during these important years" />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

     <TeensBanner />
     <TeensCard />
     <TeensCta />
     <TalkAstrologer />
    </>
  );
}

export default Teens;
