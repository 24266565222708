import '../../../Styles/login.css';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { MakeCallWithAstrologer } from '../../../Redux/Slice';

function NewUserForm({ backNewUser, id, astrologerRate }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Loading, User, ConsultationType } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    useEffect(() => {
        window.scrollTo(0, 0);
        if (ConsultationType == null) {
            navigate(-1);
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            name: '',
            gender: '',
            dateofbirth: '',
            birthplace: '',
            Hour: '',
            Min: '',
            meridiem: '',
            maritalstatus: '',
            duration: ''
        },
        onSubmit: async (values, { resetForm }) => {
            let date = new Date(values.dateofbirth).toString().slice(3, 16);
            let BirthTime = `${values.Hour}:${values.Min} ${values.meridiem}`;
            let astrologerAmount = Number(astrologerRate);
            let WalletBalance = Number(User?.data?.data?.user?.accountbalance);
            let DurationLimit;
            let isFixedDuration;

            if (values.duration === "Unlimited") {
                DurationLimit = WalletBalance / 10;
                isFixedDuration = 0;
            }
            else {
                DurationLimit = values.duration;
                isFixedDuration = 1;
            }

            let CallDuration = DurationLimit ? Number(DurationLimit) * 60 : 5 * 60;
            let CallTotalRate = DurationLimit ? Number(DurationLimit) * astrologerAmount : 5 * 10;

            if (Number(WalletBalance < CallTotalRate) || WalletBalance < (5 * astrologerAmount)) {
                Swal.fire({
                    text: `You have INR ${WalletBalance} in your wallet. To initiate a call, a minimum balance of  INR ${5 * astrologerAmount} (5 minutes) must be paid to start call.`,
                    showCancelButton: true,
                    cancelButtonColor: "#025BFD",
                    confirmButtonColor: "#319F43",
                    confirmButtonText: "Recharge"
                }).then((result) => {
                    if (result?.isConfirmed) {
                        navigate('/add-wallet-money/');
                    }
                });
            }
            else {
                let CalculationRate = (CallDuration / 60) * astrologerAmount;
                if (Number(WalletBalance < CalculationRate)) {
                    Swal.fire({
                        text: `Oops! Something's not right. Please refresh and try again.`,
                        confirmButtonColor: "#025BFD",
                        confirmButtonText: "Refresh"
                    }).then((result) => {
                        if (result?.isConfirmed) {
                            window.location.reload(false);
                        }
                    });
                }
                else {
                    let CounsultationData = {
                        astrologerId: Number(id),
                        name: values?.name,
                        gender: values?.gender,
                        dateofbirth: date,
                        timeofbirth: BirthTime,
                        placeofbirth: values?.birthplace,
                        maritalstatus: values?.maritalstatus,
                        type: ConsultationType == "call" ? "call" : "chat",
                        totalrate: CallTotalRate,
                        duration: Math.floor(CallDuration),
                        duration_isfixed: isFixedDuration,
                        subuserId: null,
                    }
                    localStorage.setItem("userData", JSON.stringify(CounsultationData));
                    localStorage.setItem("astrologerRate", astrologerAmount);
                    dispatch(MakeCallWithAstrologer({ token, CounsultationData }));
                }
            }
        },
        validate: (values) => {
            const errors = {};

            if (!values.name) {
                errors.name = "Required*";
            }
            if (!values.dateofbirth) {
                errors.dateofbirth = "Required*";
            }
            if (!values.gender) {
                errors.gender = "Required*";
            }
            if (!values.birthplace) {
                errors.birthplace = "Required*";
            }
            if (!values.Hour) {
                errors.Hour = "Required*";
            }
            if (!values.Min) {
                errors.Min = "Required*";
            }
            if (!values.duration) {
                errors.duration = "Required*";
            }
            return errors;
        }
    })

    return (
        <>
            <Container fluid className='pt-250 mb-5'>
                <Container>
                    <Row className='d-flex justify-content-center new-user-form new-user-main'>
                        <Col lg={10} className='available-balance mb-4'>
                            <p>Available Balance : <span><b>₹ {User?.data?.data?.user?.accountbalance}</b></span></p>
                            <div className='recharge-btn'>
                                <button onClick={() => { navigate('/add-wallet-money/') }}>Recharge</button>
                            </div>
                        </Col>
                        <Col lg={10} className='bg-blue p-5 radius-5'>
                            <div className='detail-form'>
                                <div className='text-white pt-2 pb-3'>
                                    <h3>Enter Your Basic Details</h3>
                                </div>
                                <Col lg={12} className='d-flex flex-wrap justify-content-between '>
                                    <Col lg={6} className='half-form mb-4'>
                                        <div className='d-flex justify-content-between text-white pb-2'>
                                            <label htmlFor="">Enter Your Name <span className='text-darkOrange'>*</span></label>
                                            <p className='error'>{formik.errors.name}</p>
                                        </div>
                                        <input
                                            type="text"
                                            id='name'
                                            name='name'
                                            placeholder='Enter Your Name'
                                            className={formik.errors.name ? "error-input" : null}
                                            value={formik.values.name} onChange={formik.handleChange}
                                        />
                                    </Col>
                                    <Col lg={6} className="half-form d-flex flex-column mb-4">
                                        <div className='d-flex justify-content-between text-white pb-2'>
                                            <label >Gender<span className='text-darkOrange'>*</span></label>
                                            <p className='error'>{formik.errors.gender}</p>
                                        </div>
                                        <select
                                            id='gender'
                                            name='gender'
                                            value={formik.values.gender}
                                            onChange={formik.handleChange}
                                        >
                                            <option value="">Choose</option>
                                            <option value="male">male</option>
                                            <option value="female">female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </Col>
                                    <Col lg={6} className="half-form d-flex flex-column mb-4">
                                        <div className='d-flex justify-content-between text-white pb-2'>
                                            <label >Date of Birth<span className='text-darkOrange'>*</span></label>
                                            <p className='error'>{formik.errors.dateofbirth}</p>
                                        </div>

                                        <DatePicker
                                            className="px-3"
                                            showIcon
                                            placeholderText="Select Date"
                                            selected={formik.values.dateofbirth}
                                            onChange={(value) => { formik.setFieldValue('dateofbirth', value) }}
                                            monthsShown={1}
                                            maxDate={new Date()}
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                        {/* <input type="date" name='dateofbirth' id='dateofbirth' value={formik.values.dateofbirth} onChange={formik.handleChange} /> */}
                                    </Col>
                                    <Col lg={6} className="half-form d-flex flex-column mb-4">
                                        <div className='d-flex justify-content-between text-white pb-2'>
                                            <label >Birth Place<span className='text-darkOrange'>*</span></label>
                                            <p className='error'>{formik.errors.birthplace}</p>
                                        </div>
                                        <input
                                            type="text"
                                            id='birthplace'
                                            name='birthplace'
                                            value={formik.values.birthplace}
                                            onChange={formik.handleChange}
                                        />

                                    </Col>
                                    <Col lg={6} className="half-form birth-time d-flex flex-column mb-4">
                                        <div className='d-flex justify-content-between text-white pb-2'>
                                            <label >Birth Time<span className='text-darkOrange'>*</span></label>
                                            <p className='error'>{formik.errors.Hour || formik.errors.Min || formik.errors.meridiem}</p>
                                        </div>
                                        <div className="d-flex gap-1">
                                            {/* <input type="number" name='Hour' value={formik.values.Hour} onChange={formik.handleChange} className="col-lg-2" /> */}
                                            <select
                                                name='Hour'
                                                value={formik.values.Hour}
                                                onChange={formik.handleChange}
                                                className="col-lg-2"
                                            >
                                                <option value="">hr</option>
                                                {[...Array(12)].map((x, i) =>
                                                    <option key={i} value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                                                        {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                                                    </option>
                                                )}
                                            </select>
                                            <span>:</span>
                                            <select
                                                name='Min'
                                                value={formik.values.Min}
                                                onChange={formik.handleChange}
                                                className="col-lg-2"
                                            >
                                                <option value="">min</option>
                                                {[...Array(60)].map((x, i) =>
                                                    <option key={i} value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                                                        {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                                                    </option>
                                                )}
                                            </select>
                                            <span>:</span>
                                            <select
                                                name='meridiem'
                                                value={formik.values.meridiem}
                                                onChange={formik.handleChange}
                                                className="col-lg-2 col-sm-3"
                                            >
                                                <option value=""></option>
                                                <option value="am">AM</option>
                                                <option value="pm">PM</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="half-form d-flex flex-column mb-4">
                                        <div className='d-flex justify-content-between text-white pb-2'>
                                            <label >Marital Status</label>
                                        </div>
                                        <select
                                            id='maritalstatus'
                                            name='maritalstatus'
                                            value={formik.values.maritalstatus}
                                            onChange={formik.handleChange}
                                        >
                                            <option value="">Choose</option>
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                            <option value="Divorced">Divorced</option>
                                        </select>
                                    </Col>
                                    <Col lg={6} className="half-form d-flex flex-column mb-4">
                                        <div className='d-flex justify-content-between text-white pb-2'>
                                            <label >Duration<span className='text-darkOrange'>*</span></label>
                                            <p className='error'>{formik.errors.duration}</p>
                                        </div>
                                        <select
                                            id='duration'
                                            name='duration'
                                            value={formik.values.duration}
                                            onChange={formik.handleChange}
                                        >
                                            <option value="" >Choose</option>
                                            <option value="Unlimited">Limitless</option>
                                            <option value="5" >05 min</option>
                                            <option value="10">10 min</option>
                                            <option value="15">15 min</option>
                                            <option value="20">20 min</option>
                                            <option value="25">25 min</option>
                                            <option value="30">30 min</option>
                                            <option value="45">45 min</option>
                                            <option value="60">60 min</option>
                                        </select>
                                    </Col>
                                </Col>
                                <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                                    <Button
                                        className='otp-button bg-lightBlue'
                                        onClick={() => { backNewUser() }}
                                    > Back
                                    </Button>
                                    <Button
                                        className='otp-button bg-orange'
                                        onClick={formik.handleSubmit}
                                    >
                                        {Loading ?
                                            <div className='loader'></div>
                                            : ConsultationType == "call" ? "Call"
                                                : ConsultationType == "chat" ? "Chat"
                                                    : null}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default NewUserForm;