import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function ElderlyCta() {
    return (
      <>
       <Container fluid>
          <Container>
              <Row>
                  <Col lg={12} className='bg-lightOrange p-5 text-center'>
                    <h6 className='text-blue'>It’s important to recognise that individual preferences and beliefs may vary, and not everyone will resonate with or find astrology and psychology helpful in their later years. Seeking support from professionals experienced in working with the elderly, maintaining social connections, and accessing appropriate healthcare and community resources are also crucial aspects of supporting the well-being of the elderly population</h6>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default ElderlyCta;
  