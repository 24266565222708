import { Helmet } from 'react-helmet';
import EndoflifeBanner from "./EndoflifeBanner";
import EndoflifeCard from "./EndoflifeCard";
import EndoflifeCta from "./EndoflifeCta";
import TalkAstrologer from "../TalkAstrologer";

function Endoflife() {
  return (
    <>
    <Helmet>
        <title>Get Insight: End-of-Life Horoscope Astrology Services</title>
        <meta name="description" data-rh="true" content="Our End-of-Life Horoscope Astrology Services provide guidance and celestial insight. Find comfort, advice, and a cosmic perspective for your particular journey." />
        <meta name="keywords" data-rh="true" content="horoscope astrology, astrology services, end of life horoscope astrology" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/services/end-of-life-horoscope-astrology-services/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/services/end-of-life-horoscope-astrology-services/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Get Insight: End-of-Life Horoscope Astrology Services" />
        <meta property="og:description" content="Our End-of-Life Horoscope Astrology Services provide guidance and celestial insight. Find comfort, advice, and a cosmic perspective for your particular journey." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Get Insight: End-of-Life Horoscope Astrology Services" />
        <meta name="twitter:description" content="Our End-of-Life Horoscope Astrology Services provide guidance and celestial insight. Find comfort, advice, and a cosmic perspective for your particular journey." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

     <EndoflifeBanner />
     <EndoflifeCard />
     <EndoflifeCta />
     <TalkAstrologer />
    </>
  );
}

export default Endoflife;
