import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import useInterval from 'use-interval';
import { format, toZonedTime, fromZonedTime, formatInTimeZone, toDate } from 'date-fns-tz';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetChatStatus } from '../../../Redux/Slice';

import moment from 'moment-timezone';

function ChatWaiting() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { IpAddress, ChatStatus, errors } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    const orderId = localStorage.getItem("orderId");
    const chatSessionId = localStorage.getItem("messageSessionId");
    const astrologerName = localStorage.getItem("roomName");

    const [chatWaiting, setChatWaiting] = useState(true);
    const [waiting_sec, setWaiting_sec] = useState(150);

    const handleClose = () => {
        setChatWaiting(false);
        localStorage.removeItem("roomName");
        localStorage.removeItem("messageSessionId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("waitingId");
        localStorage.removeItem("astrologerId");
        localStorage.removeItem("astrologerImage");
        dispatch(GetChatStatus(""));
    }
    useInterval(() => {
        if (waiting_sec > 0) {
            setWaiting_sec(waiting_sec - 1);
        }
    }, 1000);

    useInterval(() => {
        if (chatSessionId) {
            dispatch(GetChatStatus({ token, orderId }));
        }
    }, 5000);

    useEffect(() => {
        if (ChatStatus?.status === 200) {
            if (ChatStatus?.data?.data?.isStarted) {
                navigate('/chat/');
                setChatWaiting(false);
                // let CurrentDate = new Date(ChatStatus?.data?.data?.startTime);
                // console.log("CurrentDate",CurrentDate);
                // let NewDate = moment(ChatStatus?.data?.data?.startTime);
                // const dateNew = NewDate.tz('America/New_York');
                // console.log(dateNew.format("MM Do YYYY, h:mm:ss a"))
                //  let StartTime = new Date().toLocaleString(IpAddress?.country, { timeZone: IpAddress?.timezone });
                //  let StartTime = ChatStatus?.data?.data?.startTime;
                let StartTime = new Date();
                console.log(StartTime);
                localStorage.setItem("StartTime", StartTime);
            }
        }
        else if (errors?.status === 400) {
            setChatWaiting(false);
            localStorage.removeItem("roomName");
            localStorage.removeItem("messageSessionId");
            localStorage.removeItem("orderId");
            localStorage.removeItem("waitingId");
            localStorage.removeItem("astrologerId");
            localStorage.removeItem("astrologerImage");
        }
    }, [ChatStatus, errors])

    return (
        <>
            <Modal
                show={chatWaiting}
                onHide={handleClose}
                shouldCloseOnOverlayClick={false}
                backdrop="static"
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {
                    ChatStatus?.data?.data?.rejected || ChatStatus?.data?.data?.isCompleted ?
                        <Modal.Header closeButton className='chat-waiting-header'>
                        </Modal.Header>
                        : null
                }

                <Modal.Body className='chat-waiting-modal'>
                    {
                        ChatStatus?.data?.data?.rejected || ChatStatus?.data?.data?.isCompleted ?
                            <p className='text-center'>{ChatStatus?.data?.message}</p>
                            :
                            <div>
                                <p className='text-center'>
                                    Connecting to Astrologer ( {astrologerName} ). <br /> Please wait....
                                </p>
                                <h4>{waiting_sec} sec</h4>
                                <div class="loading-dots">
                                    <div className='process'>Processing</div>
                                    <div className="loading-dots--dot"></div>
                                    <div className="loading-dots--dot"></div>
                                    <div className="loading-dots--dot"></div>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChatWaiting;