import { Helmet } from 'react-helmet';

import ReportsBanner from "./ReportsBanner"
import ReportsCards from "./ReportsCards"
import MultiStepForm from "./MultiStepForm/MultiStepForm"
function AstrologyReports() {
  return (
    <>
      <Helmet>
        <title>Personalized Prediction Reports | By Astromind Counsel</title>
        <meta name="description" data-rh="true" content="Find answers to your most common astrology questions with Astromind Counsel's FAQ section. Discover insights on astrological readings, services, and more." />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/get-your-astrology-reports/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/get-your-astrology-reports/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Personalized Prediction Reports | By Astromind Counsel" />
        <meta property="og:description" content="Unlock your future with Astromind Counsel's Personalized Prediction Reports. Get tailored astrological insights and guidance for your life's journey." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Personalized Prediction Reports | By Astromind Counsel" />
        <meta name="twitter:description" content="Unlock your future with Astromind Counsel's Personalized Prediction Reports. Get tailored astrological insights and guidance for your life's journey." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>
      <ReportsBanner />
      <ReportsCards />
    </>
  )
}

export default AstrologyReports