import { Helmet } from 'react-helmet';
import CouplesBanner from "./CouplesBanner";
import CouplesCard from "./CouplesCard";
import CouplesCta from "./CouplesCta";
import TalkAstrologer from "../TalkAstrologer";

function Couples() {
  return (
    <>
    <Helmet>
        <title>Couples Horoscope Astrology: Unlock Cosmic Compatibility</title>
        <meta name="description" data-rh="true" content="Join our Couples Horoscope Astrology. Spiritual relationship advice. Travel and relationships benefit from astrology, compatibility testing, and horoscopes" />
        <meta name="keywords" data-rh="true" content="horoscope for couples, couples horoscope, couples astrology, best zodiac couples, zodiac couples, horoscope by date of birth" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/services/couples-horoscope-astrology/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/services/couples-horoscope-astrology/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Couples Horoscope Astrology: Unlock Cosmic Compatibility" />
        <meta property="og:description" content="Join our Couples Horoscope Astrology. Spiritual relationship advice. Travel and relationships benefit from astrology, compatibility testing, and horoscopes" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Couples Horoscope Astrology: Unlock Cosmic Compatibility" />
        <meta name="twitter:description" content="Join our Couples Horoscope Astrology. Spiritual relationship advice. Travel and relationships benefit from astrology, compatibility testing, and horoscopes" />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>
     <CouplesBanner />
     <CouplesCard />
     <CouplesCta />
     <TalkAstrologer />
    </>
  );
}

export default Couples;
