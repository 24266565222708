import { Row, Col, Button } from 'react-bootstrap';
import React, { useContext, useState, useEffect } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import EmptyProfile from '../../../../Assets/AstrologerImages/empty-profile.png';

import Swal from 'sweetalert2';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AstrologerRegister, successMessage, errorMessage, UpdateUserProfileImg } from '../../../../Redux/Slice';


const Review = () => {
  const dispatch = useDispatch();
  const { Loading, success, errors } = useSelector((state) => state.Astromind);

  const { details, bankdetails, prev } = useContext(MultiStepFormContext);
  let date = new Date(details.dateofbirth).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  const [imageSrc, setImageSrc] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const file = details.profile;

  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const handleRegister = (e) => {
    e.preventDefault();

    let description = JSON.stringify({ "data": details?.expertise });
    let language = JSON.stringify({ "data": details?.language });

    let registerData = {
      name: details?.name,
      email: details?.email,
      phone: details?.phone,
      gender: details?.gender,
      experience: details?.exprience,
      description: description,
      language: language,
      speciallity: details?.about,
      profession: details?.profession,
      qualification: details?.qualification,
      dateofbirth: details?.date,
      maritalstatus: details?.maritalstatus,
      age: details?.age,
      accountholder_name: bankdetails?.holder_name,
      accountnumber: bankdetails?.account_number,
      bank_name: bankdetails?.bank_name?.value,
      branch: bankdetails?.branch_name,
      IFSCcode: bankdetails?.branch_name,
      pancard: bankdetails?.pan_number
    }

    dispatch(AstrologerRegister(registerData));
  }

  useEffect(() => {
    if (success?.status === 200) {
      Swal.fire({
        title: success?.data?.message,
        icon: "success",
        iconColor: "#36AA00",
        confirmButtonColor: "#36AA00",
        confirmButtonText: "OK"
      }).then((result) => {
        if (result?.isConfirmed) {
          dispatch(successMessage(''));
          window.location.reload(false);
        }
      })
    }
    else if (errors?.status === 400) {
      Swal.fire({
        title: errors?.data?.message,
        icon: "error",
        iconColor: "#FF0000",
        confirmButtonColor: "#FF0000",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result?.isConfirmed) {
          dispatch(errorMessage(''));
        }
      })
    }

    if (success?.data?.token) {
      let token = success?.data?.token;
      let ExistFile = null;

      if (details?.profile) {
        let formData = new FormData()
        formData.append('filefor', 'profile');
        formData.append('file', details?.profile);

        dispatch(UpdateUserProfileImg({ formData, token, ExistFile }));
        console.log("profile");
      }

      if (bankdetails?.id_proof_image) {
        let formData = new FormData()
        formData.append('filefor', 'idproof');
        formData.append('file', bankdetails?.id_proof_image);

        dispatch(UpdateUserProfileImg({ formData, token, ExistFile }));
        console.log("idproof");
      }

      if (bankdetails?.passbook_image) {
        let formData = new FormData()
        formData.append('filefor', 'passbook');
        formData.append('file', bankdetails?.passbook_image);

        dispatch(UpdateUserProfileImg({ formData, token, ExistFile }));
        console.log("passbook");

      }
    }
  }, [dispatch, success, errors, bankdetails, details]);

  return (
    <Row className='my-5'>
      <Col lg={12} className='register-review bg-lightOrange p-5'>
        <Col lg={12} className='pb-3'>
          <h4 className='mb-3'>Personal Details</h4>
          <div className='d-flex'>
            <Col lg={9} className='d-flex'>
              <Col lg={6} >
                <p >
                  <b>Name :</b>
                  <span style={{ marginLeft: "10px" }} >
                    {details.name ? details.name : "none"}
                  </span>
                </p>
                <p>
                  <b>Phone Number :</b>
                  <span style={{ marginLeft: "10px" }} >
                    {details.phone ? "+" + details.phone : "none"}
                  </span>
                </p>
                <p>
                  <b>Age :</b>
                  <span style={{ marginLeft: "10px" }} >
                    {details.age ? details.age : "none"}
                  </span>
                </p>
                <p>
                  <b>Marital Status :</b>
                  <span style={{ marginLeft: "10px" }} >
                    {details.gender ? details.gender : "none"}
                  </span>
                </p>
              </Col>
              <Col lg={6}>
                <p>
                  <b>Email :</b>
                  <span style={{ marginLeft: "10px" }} >
                    {details.email ? details.email : "none"}
                  </span>
                </p>
                <p>
                  <b>Date Of Birth :</b>
                  <span style={{ marginLeft: "10px" }} >
                    {date ? date : "none"}
                  </span>
                </p>
                <p>
                  <b>Gender :</b>
                  <span style={{ marginLeft: "10px" }} >
                    {details.gender ? details.gender : "none"}
                  </span>
                </p>
              </Col>
            </Col>
            <Col lg={3} className='review-profile-img'>
              <img src={imageSrc ? imageSrc : EmptyProfile} alt="" />
            </Col>
          </div>

        </Col>
        <h4 className='mb-3'>Professional Details</h4>

        <Col lg={12} className='d-flex'>
          <Col lg={6}>
            <p>
              <b>Profession :</b>
              <span style={{ marginLeft: "10px" }} >
                {details.profession ? details.profession : "none"}
              </span>
            </p>
            <p>
              <b>Qualification :</b>
              <span style={{ marginLeft: "10px" }} >
                {details.qualification ? details.qualification : "none"}
              </span>
            </p>
          </Col>
          <Col lg={6}>
            <p>
              <b>Experience :</b>
              <span style={{ marginLeft: "10px" }} >
                {details.exprience ? details.exprience : "none"}
              </span>
            </p>
            <p>
              <b>Languages : </b>
              <span style={{ marginLeft: "10px" }} >
                {details.language ? details.language.map((item, index) => (item.name + ", ")) : "none"}
              </span>
            </p>
          </Col>
        </Col>
        <Col lg={12} className='mb-4'>
          <p>
            <b>Areas of Expertise :</b>
            <span style={{ marginLeft: "10px" }} >
              {details.expertise ? details.expertise.map((item, index) => (item.name + ", ")) : "none"}
            </span>
          </p>
          <p>
            <b>About :</b>
            <span style={{ marginLeft: "10px" }} >
              {details.about ? details.about : "none"}
            </span>
          </p>
        </Col>

        <h4 className='mb-3' >Bank Details</h4>

        <Col lg={12} className='d-flex mb-4' >
          <Col lg={6}>
            <p>
              <b>Holder name  :</b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.holder_name ? bankdetails.holder_name : "none"}
              </span>
            </p>
            <p>
              <b>Account number  :</b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.account_number ? bankdetails.account_number : "none"}
              </span>
            </p>
            <p>
              <b>IFSC Code : </b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.ifsc_code ? bankdetails.ifsc_code : "none"}
              </span>
            </p>
            <p>
              <b>Pan number :</b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.pan_number ? bankdetails.pan_number : "none"}
              </span>
            </p>
          </Col>
          <Col lg={6}>
            <p>
              <b>Bank name  :</b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.bank_name ? bankdetails.bank_name?.value : "none"}
              </span>
            </p>
            <p>
              <b>Branch Name   :</b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.branch_name ? bankdetails.branch_name : "none"}
              </span>
            </p>
            <p>
              <b>Aadhar card   :</b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.id_proof_image ? bankdetails.id_proof_image.name : "none"}
              </span>
            </p>
            <p>
              <b>Bank passbook  :</b>
              <span style={{ marginLeft: "10px" }} >
                {bankdetails.passbook_image ? bankdetails.passbook_image.name : "none"}
              </span>
            </p>
          </Col>
        </Col>
        <Col className='d-flex gap-2 terms-check mb-3'>
          <input
            type="checkbox"
            value={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <p>By signing up, you agree to our <a href='/terms-of-use/' target='_blank' rel="noreferrer" >Terms of Use</a> and <a href='/privacy-policies/' target='_blank' rel="noreferrer">Privacy Policy.</a></p>
        </Col>
        <Col >
          <div
            className="d-flex justify-content-center gap-3"
          >
            <Button
              type={"default"}
              onClick={prev}
            >
              Back
            </Button>
            <Button
              className={Loading ? "verify-btn bg-orange disabled" : "verify-btn bg-orange"}
              disabled={!isChecked}
              onClick={handleRegister}
            >
              {Loading ? <div className='loader'></div> : "Register"}
            </Button>
          </div>
        </Col>
      </Col>

    </Row>
  );
};
export default Review;
