import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';

import ChartModal from '../BirthChart/ChartModal'


function HomeKundli() {
  const [chart, setChart] = useState();
  const [user, setUser] = useState();
  // const [astroDetails, setAstroDetails] = useState();
  const [modalShow, setModalShow] = useState(false);
  // const [latitude, setlatitude] = useState('')
  // const [longitude, setLongitude] = useState('')

  const closeModalShow = (value) => {
    setModalShow(value)
  }

  const formik = useFormik({
    initialValues: {
      Name: '',
      Gender: '',
      Dob: '',
      Hour: '',
      Min: '',
      meridiem: '',
      Place: '',
      Chart: 'south',
    },
    onSubmit: async (values, { resetForm }) => {
      handleChange(values);
      setUser(values);
      resetForm();
    },
    validate: (values) => {
      const errors = {};

      if (!values.Name) {
        errors.Name = "Required*";
      }

      if (!values.Gender) {
        errors.Gender = "Required*";
      }
      if (!values.Dob) {
        errors.Dob = "Required*";
      }
      if (!values.Hour) {
        errors.Hour = "Required*";
      } if (!values.Min) {
        errors.Min = "Required*";
      }
      if (!values.Place) {
        errors.Place = "Required*";
      }

      return errors;
    }

  })

  const handleChange = async (values) => {
    const date = values.Dob.split("-");
    const RailewayHours = (values) => {
      if (values.meridiem === "pm") {
        return values.Hour = Number(values.Hour) + 12;
      }
      else {
        return values.Hour;
      }
    }
    const Hour = RailewayHours(values);

    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(values?.Place)}`;
    const response = await axios.get(url);
    const { data } = response;

    let latitude;
    let longitude;
    if (data && data.length > 0) {
      const { lat, lon } = data[0];
      latitude = parseFloat(lat);
      longitude = parseFloat(lon);
    }

    var api = 'vedic_horoscope';
    var userId = '627376';
    var apiKey = 'bb51d4f2427ec02f8b01bd238c82daed';
    var birthchartData = {
      day: date[2],
      month: date[1],
      year: date[0],
      hour: Hour,
      min: values.Min,
      lat: latitude,
      lon: longitude,
      tzone: 5.5,
      manglik_regional_setting: values.Chart,
    };

    var auth = "Basic " + new Buffer(userId + ":" + apiKey).toString("base64");

    var request = await axios({
      url: "https://json.astrologyapi.com/v1/" + api,
      method: "POST",
      dataType: 'json',
      headers: {
        "authorization": auth,
        "Content-Type": 'application/json',
        "Accept-Language": 'ta'
      },
      data: JSON.stringify(birthchartData)
    }).then(function (resp) {
      setChart(resp.data)
    }, function (err) {
      console.log(err);
    });

    setModalShow(true)
  }

  //set latitude and longitude values
  // const inputRef = useRef()

  // const handlePlace = () => {
  //   console.log("place");

  //   const [place] = inputRef.current.getPlaces();
  //   console.log(place);
  //   if (place) {
  //     setlatitude(place.geometry.location.lat())
  //     setLongitude(place.geometry.location.lng())
  //   }
  // }

  // useEffect(() => {
  //   validateLocation(latitude, longitude)
  // }, [])

  // const validateLocation = (latitude, longitude) => {
  //   if (latitude.length !== '' && longitude.length !== '') {
  //     return true
  //   }
  //   return false
  // }


  // const handleSubmit = async (e) => {

  //   e.preventDefault()


  //   console.log(JSON.stringify({ "lat": latitude, "lng": longitude }));


  //   if (validateLocation(latitude, longitude)) {
  //       setSubmit(true)
  //     console.log("submit");
  //   }


  // }


  return (
    <>
      {
        modalShow ?
          <ChartModal
            modalShow={modalShow}
            closeModalShow={closeModalShow}
            chartData={chart}
            user={user}
          />
          : null
      }

      <Container fluid className="kundliMain">
        <Container >
          <Row className="d-flex justify-content-center ">
            <Col lg={12} className="pt-4 pb-3">
              <h3 className="text-orange text-center">Get Your Birth Chart</h3>
            </Col>
            <Col lg={10} sm={12} className="d-flex flex-column justify-content-center align-items-center bg-blue radius-5 kundli-details text-white">
              <Col lg={9} sm={10} className="birth-detailsLeft birth-banner-bottom radius-5">
                <div>
                  <h4>Birthchart calculator</h4>
                </div>
                <div className="d-flex flex-column gap-4">
                  <form onSubmit={formik.handleSubmit} action="">
                    <div className="d-flex flex-column">
                      <div className='d-flex justify-content-between'>
                        <label >Name</label>
                        <p className='error'>{formik.errors.Name}</p>
                      </div>
                      <input type="text" id='Name' name='Name' value={formik.values.Name} onChange={formik.handleChange} />
                    </div>
                    <div className="d-flex justify-content-between mt-3 kundli-col">
                      <Col lg={6} className="d-flex flex-column ">
                        <div className='d-flex justify-content-between'>
                          <label >Gender</label>
                          <p className='error'>{formik.errors.Gender}</p>
                        </div>
                        <select value={formik.values.Gender} onChange={formik.handleChange} name='Gender'>
                          <option value=""></option>
                          <option value="male">male</option>
                          <option value="female">female</option>
                          <option value="other">Other</option>
                        </select>
                      </Col>
                      <Col lg={6} className="d-flex flex-column">
                        <div className='d-flex justify-content-between'>
                          <label >Date of Birth</label>
                          <p className='error'>{formik.errors.Dob}</p>
                        </div>
                        <input type="date" name='Dob' value={formik.values.Dob} onChange={formik.handleChange} />
                      </Col>
                    </div>


                    <div className="d-flex flex-column mt-3">
                      <div className='d-flex justify-content-between'>
                        <label >Birth Place</label>
                        <p className='error'>{formik.errors.Place}</p>
                      </div>
                      {/* <LoadScript
                        googleMapsApiKey='AIzaSyCrRwQZKpFBc5MeQGViOVq-IU5RhdKX8GQ'
                        libraries={["places"]}
                      >
                        <StandaloneSearchBox
                          onLoad={ref => { inputRef.current = ref }}
                          onPlacesChanged={handlePlace}
                        > */}

                      <input
                        type="text"
                        feedbackValid="Looks good!"
                        placeholder="Enter Your Birth Location"
                        text="Ex:Times Square"
                        id='Place'
                        name='Place'
                        value={formik.values.Place}
                        onChange={formik.handleChange}
                        style={{ width: "100%" }}
                      />
                      {/* </StandaloneSearchBox>
                      </LoadScript> */}
                    </div>
                    <div className="d-flex flex-column mt-3 birth-time">
                      <div className='d-flex justify-content-between'>
                        <label >Birth Time</label>
                        <p className='error'>{formik.errors.Hour}</p>
                      </div>
                      <div className="d-flex gap-1">
                        {/* <input type="number" name='Hour' value={formik.values.Hour} onChange={formik.handleChange} className="col-lg-2" /> */}
                        <select name='Hour' value={formik.values.Hour} onChange={formik.handleChange} className="col-lg-2 col-sm-3">
                          <option value="">hr</option>
                          {[...Array(12)].map((x, i) =>
                            <option key={i} value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                              {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                            </option>
                          )}
                        </select>
                        <span>:</span>
                        <select name='Min' value={formik.values.Min} onChange={formik.handleChange} className="col-lg-2 col-sm-3">
                          <option value="">min</option>
                          {[...Array(60)].map((x, i) =>
                            <option key={i} value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                              {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                            </option>
                          )}
                        </select>
                        <span>:</span>
                        <select name='meridiem' value={formik.values.meridiem} onChange={formik.handleChange} className="col-lg-2 col-sm-3" >
                          <option value=""></option>
                          <option value="am">AM</option>
                          <option value="pm">PM</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="d-flex flex-column mt-3">
                      <label for="">Chart Type</label>
                      <select value={formik.values.Chart} onChange={formik.handleChange} name='Chart'>
                        <option value=""></option>
                        <option value="north">North Indian Chart</option>
                        <option value="south">South Indian Chart</option>
                      </select>
                    </div> */}

                    <Col lg={12} className="d-flex justify-content-center mt-5">
                      <input type='submit' name='View Details' className="bg-orange text-white kundli-btn" />
                    </Col>
                  </form>

                </div>
              </Col>

            </Col>

          </Row>
        </Container >
      </Container >
    </>
  );
}

export default HomeKundli;