import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';

import CardtopImg from '../../Assets/HomeImages/card-top-img.svg'
import SelfRelectionIcon from '../../Assets/HomeImages/self-reflection-icon.svg'
import ArchetpalIcon from '../../Assets/HomeImages/archetypal-icon.svg'
import BrainIcon from '../../Assets/HomeImages/brain-icon.svg'
import PsychologyIcon from '../../Assets/HomeImages/psychology-icon.svg'



function HomeCard() {
  return (
    <>
      <Container fluid className="mt-5 mb-5 yellow-card">
        <div className="card-left-img">
          <img src={CardtopImg} alt="" />
        </div>
        <Container>
          <Col lg={12}>
            <Row className="d-flex gap-4 ">
              <Col lg={6} sm={12} className="d-flex gap-3 p-3 bg-orange text-white radius-5">
                <div className="bg-white white-card">
                  <img src={SelfRelectionIcon} alt="" />
                </div>
                <div className="card-description">
                  <h5>Self-Reflection</h5>
                  <p>By examining the positions of planets in your birth chart and their corresponding astrological meanings, you can gain insight into your personality traits, strengths, weaknesses, and potential challenges. This self-reflection can help you better understand yourself and make conscious choices in various areas of your life.</p>
                </div>
              </Col>
              <Col lg={6} className="d-flex gap-3 p-3 bg-orange text-white radius-5">
                <div className="bg-white white-card">
                  <img src={ArchetpalIcon} alt="" />
                </div>
                <div className="card-description">
                  <h5>Archetypal Exploration</h5>
                  <p>Astrology offers a wide range of archetypal symbols that can be explored and related to psychological patterns. By understanding the archetypes associated with different planets and signs, you can gain insights into the deeper layers of your psyche and the universal themes they represent.</p>
                </div>
              </Col>
              <Col lg={6} className="d-flex gap-3 p-3 bg-orange text-white radius-5">
                <div className="bg-white white-card">
                  <img src={BrainIcon} alt="" />
                </div>
                <div className="card-description">
                  <h5>Psychological Astrology</h5>
                  <p>Uses astrological concepts as a way to understand clients’ personalities, motivations, and life experiences. By incorporating astrology into therapy, individuals can explore their unconscious patterns, unresolved issues, and potential areas of growth.</p>
                </div>
              </Col>
              <Col lg={6} className="d-flex gap-3 p-3 bg-orange text-white radius-5">
                <div className="bg-white white-card">
                  <img src={PsychologyIcon} alt="" />
                </div>
                <div className="card-description">
                  <h5>Transpersonal Psychology</h5>
                  <p>Transpersonal psychology focuses on the integration of spiritual and transcendent aspects of human experience. Astrology can be seen as a tool for exploring the spiritual dimension of the self and connecting with the larger cosmic order. It can help individuals find meaning and purpose in their lives beyond the purely personal.</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
        <div className="card-right-img">
          <img src={CardtopImg} alt="" />
        </div>

      </Container>
    </>
  );
}

export default HomeCard;