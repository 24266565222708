import '../../Styles/contact.css'
import { Container, Row, Col } from 'react-bootstrap';

function ContactBanner() {
    return (
      <>
       <Container fluid className='contact-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>Contact Information</h1>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default ContactBanner;
  