import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function ChildrenBanner() {
    return (
      <>
       <Container fluid className='children-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>Children</h1>
                     <p>Combining astrology and psychology can also provide benefits for children. Here are some ways in which this field can help kids</p>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default ChildrenBanner;
  