import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { Steps } from "antd";
import { Provider } from "./MultiStepFormContext";
import PersonalDetails from "./PersonalDetails";
import BankDetails from '../MultiStepForm/BankDetails';
import Review from "./Review";

const { Step } = Steps;

const detailsInitialState = {
  name: "",
  email: "",
  phone: "",
  gender: "",
  dateofbirth: "",
  maritalstatus: "",
  age:"",
  profile:"",
  expertise: null,
  language: null,
  exprience: "",
  qualification:"",
  profession:"",
  about: "",
};

const bankInitialState = {
   holder_name:"",
   bank_name:"",
   account_number:"",
   branch_name:"",
   ifsc_code:"",
   pan_number:"",
   id_proof_image:"",
   passbook_image:""
};

const renderStep = (step) => {
  switch (step) {
    case 0:
      return <PersonalDetails />;
    case 1:
      return <BankDetails />;
    case 2:
      return <Review />;

    default:
      return null;
  }
};

const MultiStepForm = () => {
  const [details, setDetails] = useState(detailsInitialState);
  const [bankdetails, setBankdetails] = useState(bankInitialState);
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    if (currentStep === 2) {
      setCurrentStep(0);
      setDetails(detailsInitialState);
      setBankdetails(bankInitialState);
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const prev = () => setCurrentStep(currentStep - 1);
  
  return (
    <Provider value={{ details, setDetails, next, prev, bankdetails, setBankdetails }}>
      <Container fluid>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={10}>
              <Steps current={currentStep}>
                <Step title={"Fill in your details"} />
                <Step title={"Bank details "} />
                <Step title={"Preview "} />
              </Steps>
              <main>{renderStep(currentStep)}</main>
            </Col>
          </Row>
        </Container>
      </Container>

    </Provider>
  );
};
export default MultiStepForm;
