import { useState } from 'react';
import { Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ReactAudioPlayer from 'react-audio-player';

import { FaArrowRight } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";

import AddReview from '../../Astrologer/ChatPage/AddReview';
import ChatHistory from './ChatHistory';

const OrderHistoryDetails = ({ data, show, handleClose }) => {

    const [reviewShow, setReviewShow] = useState(false);
    const [chatShow, setChatShow] = useState(false);

    let indianTime = new Date(data?.order?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });

    let StartTime = data?.order?.type === "call" ?
        new Date(data?.order?.calls[0]?.StartTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
        : data?.order?.type === "chat" ?
            new Date(data?.chat[0]?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
            : null;

    let EndTime = data?.order?.type === "call" ?
        new Date(data?.order?.calls[0]?.EndTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
        : data?.order?.type === "chat" ?
            new Date(data?.chat[0]?.endTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
            : null;

    let Minute = data?.order?.type === "call" ?
        Math.floor(data?.order?.calls[0]?.ConversationDuration / 60)
        : data?.order?.type === "chat" ?
            Math.floor(data?.chat[0]?.duration / 60)
            : 0;

    let seconds = data?.order?.type === "call" ?
        data?.order?.calls[0]?.ConversationDuration % 60
        : data?.order?.type === "chat" ?
            Math.floor(data?.chat[0]?.duration % 60)
            : 0;

    const reviewShowClose = (value) => {
        setReviewShow(value)
    }

    const chatShowClose = () => {
        setChatShow(false)
    }

    return (
        <>
            {
                reviewShow ?
                    <AddReview
                        show={reviewShow}
                        reviewClose={reviewShowClose}
                        astrologer_name={data?.order?.astrologer_name}
                        astrologer_image={data?.profile?.file}
                        astrologerId={data?.order?.astrologerId}
                        orderId={data?.order?.id}
                    />
                    : null
            }
            {
                chatShow ?
                    <ChatHistory
                        show={chatShow}
                        chatClose={chatShowClose}
                        reviewShow={reviewShowClose}
                        data={data}
                    />
                    : null
            }

            <Offcanvas
                className="call-canvas"
                show={show}
                onHide={handleClose}
                placement={window.screen.width < 600 ? 'bottom' : 'end'}
                name={window.screen.width < 600 ? 'bottom' : 'end'}
                backdrop={false}
                scroll={true}
            >
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col className='preview-card'>
                        <div className='d-flex justify-content-between'>
                            <Col lg={8}>
                                <p className='preview-data'><span>Name :</span>{data?.order?.subuser?.name}</p>

                                <p className='preview-data'><span>Order ID :</span>{data?.order?.id}</p>

                                <p className='preview-data'><span>Total Rate :</span>₹ {data?.order?.user_consumed_cost}</p>

                                <p className='preview-data'><span>Date :</span>{indianTime}</p>

                                <p className='preview-data'>
                                    <span>Start Time :</span>
                                    {
                                        data?.order?.calls?.length > 0 ?
                                            StartTime
                                            : (data?.chat?.length > 0 ? data?.chat[0]?.status === "completed" : false) ?
                                                StartTime
                                                : "none"
                                    }
                                </p>

                                <p className='preview-data'>
                                    <span>End Time :</span>
                                    {
                                        data?.order?.calls?.length > 0 ?
                                            EndTime
                                            : (data?.chat?.length > 0 ? data?.chat[0]?.status === "completed" : false) ?
                                                EndTime
                                                : "none"
                                    }
                                </p>

                                <p className='preview-data'>
                                    <span>Duration :</span>
                                    {
                                        data?.order?.calls?.length > 0 ?
                                            <>{`${Minute} min ${seconds} sec`}</>
                                            : (data?.chat?.length > 0 ? data?.chat[0]?.status === "completed" : false) ?
                                                <>{`${Minute} min ${seconds} sec`}</>
                                                : "none"
                                    }
                                </p>

                                <p className='preview-data'><span>Status :</span>
                                    <span className={
                                        data?.order?.calls?.length > 0 ?
                                            data?.order?.calls[0]?.Status === "no-answer" || data?.order?.calls[0]?.Status === "busy" ?
                                                "text-orange" :
                                                "payment-success" :
                                            (data?.chat?.length > 0) && (data?.chat[0]?.status === 'completed') ?
                                                "payment-success" :
                                                "payment-failed"
                                    }>
                                        {
                                            data?.order?.calls?.length > 0 ?
                                                data?.order?.calls[0]?.Status
                                                : data?.chat?.length > 0 ?
                                                    data?.chat[0]?.status
                                                    : "Canceled"
                                        }
                                    </span>
                                </p>
                            </Col>
                            <Col lg={4} >
                                <div className='call-profile-img'>
                                    <img
                                        src={data?.profile?.file}
                                        alt="img"
                                        className='img-fluid'
                                    />
                                    <p>{data?.order?.astrologer_name}</p>
                                </div>
                            </Col>
                        </div>
                        {data?.order?.calls?.length > 0 ?
                            <>
                                <div className='call-audio'>
                                    <ReactAudioPlayer
                                        src={data?.order?.calls[0] ? data?.order?.calls[0]?.RecordingUrl : null}
                                        controls
                                    />
                                </div>
                            </>
                            : null
                        }
                        {
                            data?.review?.comment ?
                                <>
                                    <div>
                                        <p className='preview-data'><span>Your Review</span></p>
                                    </div>
                                    <div className='bg-lightOrange p-2 px-3'>
                                        <div className='profileStars'>
                                            <div className="profileStar">
                                                <span>
                                                    {[...new Array(5)].map((arr, index) => {
                                                        return index < `${data?.review?.rating}` ?
                                                            <AiFillStar className="colorStar" key={index} />
                                                            : <AiFillStar key={index} className="NoColorStar" />
                                                    })}
                                                </span>

                                            </div>
                                        </div>
                                        <div>
                                            <p className='preview-data'>{data?.review?.comment}</p>
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        <div className='d-flex justify-content-between mt-3'>
                            {
                                data?.order?.type === "chat" && (data?.chat?.length > 0 ? data?.chat[0]?.status === "completed" : false) ?
                                    <button
                                        className='chat-history-btn'
                                        onClick={() => setChatShow(true)}
                                    >
                                        View Chat History Details
                                    </button>
                                    : null
                            }
                            {
                                data?.review?.comment ?
                                    null
                                    : data?.order?.calls?.length > 0 || (data?.chat?.length > 0 ? data?.chat[0]?.status === "completed" : false) ?
                                        <button
                                            className='review-btn'
                                            onClick={() => setReviewShow(true)}
                                        >
                                            Give your Review Here <FaArrowRight />
                                        </button>
                                        : null
                            }
                        </div>
                    </Col>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OrderHistoryDetails;