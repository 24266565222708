import '../../../Styles/blogdetail.css'
import { Container, Row, Col } from 'react-bootstrap';

import UnlockYourFutureImg1 from '../../../Assets/ServiceImages/Unlock-Your-Future-img-1.png';
import UnlockYourFutureImg2 from '../../../Assets/ServiceImages/Unlock-Your-Future-img-2.png'


function UnlockYourFutureContent() {
    return (
        <>
            <Container fluid className='blogContent-main mt-5'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div>
                                <h4 className='text-blue'>Exploring the depths of Nadi Astrology</h4>
                            </div>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between'>
                            <Col lg={6}>
                                <div className='blogContent-img pt-3'>
                                    <img src={UnlockYourFutureImg1} alt="" />
                                </div>
                            </Col>
                            <Col lg={5} className='d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <p className='text-black pt-2'>Deep insights into personal destiny may be gained via the heavenly science of Nadi Astrology, which has been handed down through the years by ancient Rishis. Nadi forecasts, which have been developed by holy sages like Agasthiya, Kousika, and Vasishta, are carefully written on palm leaves and preserve centuries' worth of knowledge. These leaves are treasure troves of ageless wisdom, kept in the old libraries of Tamil rulers such as <a href='https://www.artisera.com/blogs/expressions/tanjore-painting'>Tanjore's patron</a> of the arts and sciences.</p>
                                </div>
                            </Col>
                        </Col>
                        <Col lg={12} className='pt-2' >
                            <div>
                                <p className='text-black pt-2'>Our family of Nadi astrologers at Astromind Counsel is steeped in the hallowed traditions of the Vaitheeswaran Temple. Since our founding in 1802, we have guided seekers from all walks of life, maintaining the authenticity and integrity of this age-old practice. We are the cornerstones of <a href='https://timesofindia.indiatimes.com/astrology/others/nadi-astrology/articleshow/68205593.cms'>authentic Nadi astrology</a>, trusted by thousands of happy customers, in contrast to less reputable younger institutes.</p>
                                <p className='text-black '>Nadi Astrology explores each person's past and future to provide unique answers to a variety of problems. Through the interpretation of thumb imprints and <a href='https://www.astromindcounsel.com/blogs/tamil-jathagam-raja-nadi-conjunctions-part-one/'>palm leaf manuscripts</a>, we solve the past, present, and future riddles. Our goal is to enable people to find their genuine self and face life's obstacles with confidence by educating and enlightening them.</p>
                                <p className='text-black '>As stewards of this holy heritage, we respect the history of our ancestors by offering searchers of all hues and races precise and perceptive prophecies. Set off on a path of self-actualization and satisfaction with Astromind Counsel, guided by the age-old knowledge of <a href='https://www.astromindcounsel.com/blogs/rajanadi-personal-horoscope-astrology-guide/'>Nadi astrology</a>.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='blogContent-main bg-blue text-white text-center mt-4 py-4'>
                <Row className='d-flex justify-content-center'>
                    <Col lg={10}>
                        <div>
                            <h4>How can Nadi astrology be beneficial?</h4>
                            <p>Countless people face questions, disbelief, and an array of barriers in different facets of their lives, whether at jobs, in personal relationships, or in general. Each problem is unique, necessitating a customized solution adapted to specific circumstances rather than a one-size-fits-all approach. This is where Nadi astrology comes in, delivering essential support by providing tailored remedies to particular difficulties.</p>
                            <a href="/astrologers/"><button className="bg-orange radius-5 text-white px-3 py-2">Talk to A Counselor</button></a>
                        </div>
                    </Col>

                </Row>
            </Container>
            <Container fluid className='blogContent-main mt-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between'>

                            <Col lg={5} className='d-flex flex-column justify-content-center align-items-center'>
                                <div>
                                    <h4 className='text-blue'>Why opt for Astromind Counsel for Nadi astrology?</h4>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <ul>
                                        <li>Astromind Counsel excels in providing stunningly accurate predictions through the ancient art of Nadi Astrology, offering clarity and insight into life's mysteries.</li>
                                        <li>We are committed to advancing astrology as a science for future generations, blending traditional wisdom with modern insights to offer practical guidance in today's world.</li>
                                        <li>Through our elemental approach, Astromind Counsel helps individuals identify and nurture their spirituality, fostering a deeper connection with themselves and the universe.</li>
                                        <li>Our mission is to educate as many people as possible about Nadi Astrology, enabling them to discover their true selves and unlock their highest potential.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='pt-3'>
                                    <img src={UnlockYourFutureImg2} alt="" className='img-fluid' />
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>
            
        </>
    )
}

export default UnlockYourFutureContent