import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function TeensCta() {
    return (
      <>
       <Container fluid>
          <Container>
              <Row>
                  <Col lg={12} className='bg-lightOrange p-5 text-center'>
                    <h6 className='text-blue'>It’s important to note that while astrology and psychology can offer valuable insights and support, teenagers should be encouraged to approach them critically and responsibly. Guidance from trusted adults, such as parents, mentors, or professionals with expertise in astrology and psychology, can ensure that teenagers receive accurate information and have a safe space to explore their thoughts and feelings</h6>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default TeensCta;
  