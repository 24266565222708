import { Container, Row } from 'react-bootstrap';


const PrivacyPolicies = () => {
    return (
        <>
            <Container fluid  >
                <Container>
                    <Row className='privacy-policy'>
                        <div>
                            <h1>PRIVACY POLICY</h1>
                            <hr />
                        </div>
                        <div>
                            <p>
                                www.astromindcounsel.com (“we”,"Astromind Counsel Private Limited" (online and application), hereinafter referred to as the "website") is dedicated to safeguarding the privacy of website users, which includes those who are astrologers and buyers/customers, whether or not they are registered. To understand how the website will use the information you provide, please carefully read our privacy statement. This privacy statement is released in compliance with Information Technology (Intermediaries Guidelines) Rules, 2011 Rule 3(1) and Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 which mandate that a privacy statement be published before any sensitive personal data or information is collected, used, stored, or transferred.
                            </p>

                        </div>
                        <div>
                            <h4>USER’S CONSENT</h4>
                            <p>
                                This Privacy Policy, which is subject to periodic updates and amendments, addresses the data that the website collects from its users, including personal identification, contact information, date of birth, and any forecasts generated using the data they provide. It also describes how the website uses this data for other purposes. You acknowledge that you have read the terms and specifically agree to the privacy policy of this website by using it to access and use it. Please do not use this website if you disagree with the conditions of this privacy statement. Your continuing use of this website will serve as confirmation that you have given your unqualified consent and that you agree to the provisions of this privacy statement regarding the collection, storage, use, processing, and disclosure of your personal data in compliance with it.
                            </p>
                            <p>
                                You should read this privacy statement in conjunction with any applicable terms of use or other terms and conditions that are listed on the website.
                            </p>
                        </div>
                        <div>
                            <h4>Collection of Personal Information</h4>
                            <p>
                                Creating a user profile with Astromind Counsel Private Limited involves providing specific information. Your phone number is one of the required fields for OTP (One-Time Password) verification, which is required to guarantee the security and legitimacy of the registration procedure. Furthermore, as an optional field, you must enter your date of birth (DOB), first and last names. It is not required to enter the date of birth while registering, as it can be regarded as an optional element.
                            </p>
                        </div>
                        <div>
                            <h4>PURPOSE AND USE OF DATA/INFORMATION COLLECTION</h4>
                            <p>
                            Astromind Counsel Private Limited hopes to build a customized user profile that may meet your unique requirements and interests by gathering these data. It won't, however, interfere with the registration procedure if you decide not to enter your date of birth; instead, you can use your confirmed phone number to access AstroMind Counsel's services.
                            </p>
                        </div>
                        <div>
                            <h4>Data Deletion</h4>
                            <p>
                                Delete Profile: If you wish to delete your entire AstroMind Counsel profile, including personal information associated with it, you might find an option to "Delete your account" Click on this setting option inside the menu and follow the instructions and complete the account deletion process.
                            </p>
                        </div>
                        <div>
                            <h4>Voice Recording and Microphone Permission</h4>
                            <p>
                                We've included a special and practical method for you to communicate with us via chat on our app: you can record your voice as audio to ask us questions. You can also just talk into your device's microphone, record your voice as audio, and then send it over chat instead of typing out your questions. The goal of this audio interaction feature is to improve the intuitiveness and alignment of our software with natural communication.
                            </p>
                            <p>
                                We must obtain access to your device's microphone in order to allow you to record your queries and opinions as audio. By giving us this permission, you enable our app to record your voice as audio and transform it into digital data that we can handle.
                            </p>
                        </div>
                        <div>
                            <h4>COMMITMENT</h4>
                            <p>
                                The goal of the website is to safeguard the privacy of all users who use the platform, regardless of whether they are registered users or just guests. It is advised that each user be aware of the categories of personally identifiable data that are gathered. The Website uses personally identifiable information for some predictions, but it guarantees that no direct or indirect use of the information disclosed in a member's prediction will occur unless specifically needed to provide the member with the horoscope charts and predictions, disclosing such information. It is also made clear that the information provided to the website is never sold or rented by the website.
                            </p>
                            <p>
                                The website does not promise to help individuals with poor mental health, including those who have thoughts of suicide, self-destruction, or other negative outcomes. Such users are encouraged to discontinue using the current website immediately. Should they choose to do so, any further use of the website will be at their own risk, and the website will not be held responsible for any negative outcomes. The Website states that, should the need arise, law enforcement officials may receive access to the information that this type of user provides. No confidentiality or non-disclosure agreements of any type exist between the Website and the third parties engaged in this information.
                            </p>
                            <p>
                                The accuracy of the astrologers' predictions is not guaranteed by the website in any way to any user. Regarding the authenticity and dependability of the diamonds and other relevant products advertised and sold on the website, the Website makes no guarantees, accepts no responsibility, and assumes no liability. The Website additionally states that it makes no representations or warranties of any kind regarding such services.
                            </p>
                        </div>
                        <div>
                            <h4>INFORMATION COLLECTED BY WEBSITE</h4>
                            <p>
                                <b>PERSONAL IDENTIFIABLE INFORMATION:</b> When data is gathered and used to identify a particular end user, it is considered personally identifiable information. The website would gather this kind of data while doing the following:
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        <b>Account creation and registration information:</b> The user of the website may need to register an account in order to access it. A user's full name, address, phone number, email address, date of birth, gender, location, photograph, and any other information required on the website during registration are examples of the personal information that may be requested when creating an account. This definition of "sensitive personal data or information" is based on the Information Technology (Reasonable Security Practices And Procedures And Sensitive Personal Data Of Information) Rules, 2011, which were enacted under the Information Technology Act, 2000.
                                    </p>
                                    <p>
                                        All users are now notified that their phone number or email address, along with a password or one-time password (OTP), is used to secure their user profile and to enable the website's tailored SMS and email services. If the user does not register, the website might not be able to offer any services since the user's personally identifiable information might not be available.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Booking a paid service:</b> When using the Order Form to book a service, personal information may be requested. This information may include, but not be limited to, the data mentioned in Column 1(a), financial data such as bank account details, credit card or debit card details, or details of other payment instruments via a secure third-party gateway, IP (Internet protocol) address, and any other data that a User may choose to submit when booking a Paid Service on the Website. Such data is held in strict confidence.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Log Files, IP Address and Cookies:</b> The website gathers data that is saved on your computer's hard drive by your browser, i.e., through cookies. Additionally, it automatically logs session data—generic information about the user's computer connection to the Internet. The user's PC may be permanently or temporarily stored with "cookies" by the website. Cookies would enable the web server to identify the user's machine each time the user visits the website, allowing it to record information about the visit, such as the time and date, the page viewed, how long it took, and if the user entered a password or not. The user has the option to block these cookies on their computers, and they are often only viewed by the server where they are set. Please be aware that the user might not be able to utilize some website features if cookies are disabled. The website utilizes cookies to display advertisements based on the user's selections and to personalize the user's experience. A few of the services offered by the website could send the user to a third-party platform. Users may provide information on these platforms, and the third-party platforms may handle it in accordance with their privacy policies. In this regard, the Website expressly disclaims any liability or claim that may result from the use or misuse of the information that the User shares, to any third party or to any party that the Website is not aware of. The website disclaims any liability for any misuse of the user's or any third party's shared information.
                                    </p>

                                    <p>
                                        We also gather information, such as user comments and feedback, among other things that may be revealed, discussed, or referenced on any blog post, article, group, forum, or other page that the user may access while on the website. The User is recommended to use caution before disclosing any information that is in the public domain and available to all Users and visitors of the Website, as it may be misused.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Other Activities:</b> The Website may gather any additional information that may be required to be disclosed. It may also obtain additional information by email or through other means, including contracts, about particular services or products purchased from the Website. This information may not be included in the User-Member's Profile and may only be used to address the particular need or concern of the User.
                                    </p>
                                </li>
                            </ul>
                            <p>
                                <b>NON - PERSONAL IDENTIFIABLE INFORMATION:</b> When the data gathered cannot be used to identify a particular end user, it is considered non-personal data. This kind of data is gathered by cookies, user visits to the website, and would comprise, but not be restricted to:
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        Uniform Resource Locator (URL) of the website the user viewed just before or after this website, depending on which one they visited first.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        IP address, telecom service provider, and internet service provider.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        kind of browser that was utilized to view the webpage.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Location in Space
                                    </p>
                                </li>
                            </ul>
                            <p>
                                The Website uses this kind of non-personally identifiable information for a number of purposes, such as troubleshooting connection issues, managing the website, analyzing trends, collecting demographic data, tracking the frequency and average duration of website visits, the pages viewed during a visit, adhering to applicable legal requirements, assisting with law enforcement efforts, etc.
                            </p>
                            <p>
                                The data is used to enhance the functionality and content of the website. If necessary, the website may also share this data with third-party advertisers and service providers to gauge the overall success of the website's online programming, advertising, and content.
                            </p>
                            <p>
                                By using this form, the user represents and certifies that the data they have provided to the website is true, accurate, up to date, and authentic. The authenticity of any information a user may supply shall not be the responsibility of the website or its entities. If any provision is broken, the user will be held personally liable and will have to compensate the website.
                            </p>
                            <p>
                                <b>SECURITY MEASURES:</b> The website places a high priority on the security of the personal information users provide. To that end, the website takes a number of reasonable precautions, including physical and electronic security measures, to prevent unauthorized access to the information. A protected server is where user personal data is gathered. On the page of the bank or payment gateway, the payment details are input over a secure SSL connection. Data is encrypted as it moves between the Bank's page and the payment gateways. It should be noted, nevertheless, that no data transmission can be guaranteed to be 100% safe. Therefore, it is recommended that users exercise caution and refrain from disclosing any information they have provided on the website, including their log-in credentials that they generated upon registration. The website does not guarantee the security or privacy of any communications that users send via email or other internet-based means.
                            </p>
                            <p>
                                <b>USAGE OF THE INFORMATION:</b> The data that the website collects may be put to any use that is allowed by the relevant laws, including but not restricted to the following:
                            </p>
                            <p>
                                - To offer a customized browsing experience. While ensuring user anonymity, the personal data gathered under Clause "Personal Identifiable Information" may be utilized for research, to enhance marketing and promotional initiatives, to analyze website usage, to enhance the website's content and product offerings, and to modify the website's layout to better meet the needs of its users.
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        IP tracking information and cookie data are solely used by the website to make it easier for users to navigate and to personalize their experience. Sensitive information is never shared with third parties without the user's permission.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        All information (and copies thereof) that the website collects, including without limitation Personal Information, User Data, and other information pertaining to your use of the services it offers, may be kept on file by the website for as long as is required, including—but not limited to—the fulfillment of legal or statutory requirements, tax laws, potential evidentiary purposes, and other justifiable purposes like managing your use of the services, implementing, administering, and administering them, or resolving disputes.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The Website may use information gathered through cookies, log files, device identifiers, location data, and clear gifs to: (a) remember information so you won't have to enter it again during your visit or the next time you visit the site; (b) provide tailored content and information, including advertising; (c) provide and monitor the effectiveness of the Services offered by the Website; (d) monitor aggregate metrics like total number of visitors, traffic, usage, and demographic patterns on the Website and its Services; (e) diagnose or fix technological issues; and (f) in other ways to plan for and improve the service.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Website tracks traffic and usage trends for the Services using a few third-party analytics technologies. These technologies gather data from the user's device, such as the pages they visit, add-ons, and other information that helps the website provide better services, but it is not sensitive or personal data. Anonymized logs are used to gather this kind of data from users, making it impossible to reasonably utilize it to identify any one specific user.
                                    </p>
                                </li>
                            </ul>
                            <p>
                                <b>CONFIDENTIAL:</b> The website strives to protect any information that users send it that could be deemed confidential. The term "personal information" expressly excludes such private information, which is not needed to be provided to the website and should not be gathered or utilized. The following situations will prevent the Websites, its staff, agents, or any other third-party contractors, including experts, from verbally or in writing disclosing or sharing the User's private information:
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        If the Website determines that there is a substantial, genuine, and immediate harm or risk to the health, safety, or life of the user, as well as to the health, safety, or life of any other individual or member of the public.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        If sharing such private information is required by law, including in the event of a court summons, investigation, or other legal action, etc.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        To safeguard and uphold the Website's property rights.
                                    </p>
                                </li>
                            </ul>
                            <p>
                                <b>CHILDREN'S PRIVACY POLICY:</b> Although users of the website must be at least 18 years old to access certain services, children under the age of 18 can also access certain service information. It is important to note, though, that no personally identifiable information about children under the age of 13 is willfully collected, and that the website is not made to be appealing to children under that age. PLEASE AVOID USING ANY OF THE WEBSITE'S SERVICES AT ANY TIME OR IN ANY MANNER IF YOU ARE UNDER 13 YEARS OF AGE. Please get in touch with the website right away if a parent has any concerns about the sharing of any information pertaining to a child under the age of 13. We will remove the data from the Website's systems and take the necessary action.
                            </p>
                            <p>
                                <b>Safety and Security:</b> Astromind Counsel Private Limited has a 100% refund policy for all genuine cases as laid out in the Refund Policy, as determined solely by Astromind Counsel. The refund has to be sought within one week from the date on which the transaction has occurred. The refund request will be evaluated by an audit team, who will be the final authority on behalf of Astromind Counsel to adjudicate on the validity of your refund claims. The evaluation of the audit team will be final. Any customer attempting to fraudulently request a refund may be blocked from using the portal in future.Note: The refund of the wallet balance back to the source of the payment is not allowed under any circumstances, regardless of whether such balance has been consumed towards a consultation or not.
                            </p>
                        </div>
                        <div>
                            <h4>DISCLAIMER</h4>
                            <p>ANY COMMUNICATION BETWEEN THE USER AND THE THIRD-PARTY WEBSITE IS NOT RELATED TO THIS WEBSITE. THIS WEBSITE SHALL NOT BE HELD LIABLE FOR SUCH USAGE MADE ONLY BECAUSE A LINK TO THE THIRD-PARTY WEBSITE WAS PROVIDED ON THE PAGE OF THIS WEBSITE. THE USER IS ADVISED TO READ THE PRIVACY POLICY AND OTHER POLICIES OF THE THIRD PARTY ON THEIR WEBSITES.</p>
                        </div>
                        <div>
                            {/* <h4>GRIEVANCE OFFICER</h4>
                            <p>
                                <b>Grievance Officer Name:</b> Ankush Mohan
                            </p> */}
                            <p>
                                <b>Email: astromindcounsel@gmail.com</b>
                            </p>
                            <p>
                                In compliance with the Information Technology Act 2000 and its implementing regulations, the aforementioned Officer has been appointed. If there are any inconsistencies on the website, if the Terms of Use, Privacy Policy, or other policies are broken, or if you have any other complaints or concerns, you can get in touch with the Officer.
                            </p>
                        </div>
                    </Row>
                </Container>

            </Container>
        </>
    )

}

export default PrivacyPolicies;