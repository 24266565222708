import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import EmptyProfile from '../../../Assets/AstrologerImages/empty-profile.png';
import { FaArrowRight } from "react-icons/fa";
import { FaFileDownload } from "react-icons/fa";
import { MdDownload } from "react-icons/md";

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetChatSession } from '../../../Redux/Slice';

function ChatHistory({ show, chatClose, reviewShow, data }) {
    const dispatch = useDispatch();
    const { ChatHistory } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    let messageSessionId = data?.chat[0].id;
    let StartTime = new Date(data?.chat[0]?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    let EndTime = new Date(data?.chat[0]?.endTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    let Minute = Math.floor(data?.chat[0]?.duration / 60);
    let seconds = Math.floor(data?.chat[0]?.duration % 60);

    useEffect(() => {
        dispatch(GetChatSession({ token, messageSessionId }));
    }, [dispatch, token, messageSessionId]);

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={chatClose}
                className='chat-history-modal'
            >
                <Modal.Header closeButton className='chat-history-header bg-blue'>
                    <Col lg={12} sm={12} className='d-flex justify-content-between'>
                        <Col lg={6} sm={7} className='d-flex gap-3'>
                            <div className='chat-history-profile'>
                                <img src={data?.profile ? data?.profile?.file : EmptyProfile} alt="" />
                            </div>
                            <div className='text-white chat-history-detail'>
                                <h5>{data?.order?.astrologer_name} </h5>
                                <p>Start Time : {StartTime} </p>
                                <p>End Time : {EndTime} </p>
                            </div>
                        </Col>
                        <Col lg={6} sm={5} className='text-white d-flex flex-column align-items-end chat-history-detail'>
                            <p className='mb-3'>Duration : {Minute} min {seconds} sec</p>
                            <button
                                className='review-btn'
                                onClick={() => reviewShow(true)}
                            >
                                Give your Review Here <FaArrowRight />
                            </button>
                        </Col>
                    </Col>
                </Modal.Header>
                <Modal.Body className='bg-skyBlue'>
                    <Col lg={12} id="chat-feed" className="chat-view-screen bg-skyBlue p-3">
                        {
                            ChatHistory?.data?.data?.map((item, index) => {
                                const url = item.message;
                                const imageExtension = url?.split('.').pop().toLowerCase();
                                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(imageExtension);
                                const isFile = ['pdf', 'csv', 'xlsx', 'txt', 'pptx', 'docx', 'doc', 'zip'].includes(imageExtension);

                                const urlFile = isFile ? new URL(url) : null;
                                const pathname = isFile ? urlFile.pathname : null;

                                let messageTime = new Date(item?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

                                return (
                                    <Col
                                        lg={12}
                                        className={`d-flex ${item.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}
                                        key={index}
                                    >
                                        {isImage ?
                                            <div className="chat-image" >
                                                <img src={item.message} alt="" className="img-fluid" />
                                                <div className="image-download">
                                                    <p><a href={item.message}>Download <MdDownload className="icon" /></a></p>
                                                    <span>{messageTime}</span>
                                                </div>
                                            </div>
                                            : isFile ?
                                                <div className="chat-file">
                                                    <div className="file">
                                                        <a href={item.message}>
                                                            <FaFileDownload className="icon" />
                                                        </a>
                                                    </div>
                                                    <div className="file-name">
                                                        <p>{pathname?.slice(15)}</p>
                                                        <span>{messageTime}</span>
                                                    </div>
                                                </div>
                                                :
                                                <div className="chat-message">
                                                    <p>{item.message}</p>
                                                    <span>{messageTime}</span>
                                                </div>
                                        }
                                    </Col>
                                );
                            })
                        }
                        <Col lg={12} className="d-flex justify-content-center chat-connect">
                            <p>Your Chat is Ended</p>
                        </Col>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChatHistory;