import { Helmet } from 'react-helmet';

import MidlifecrisisBanner from "./MidlifecrisisBanner";
import MidlifecrisisCard from "./MidlifecrisisCard";
import MidlifecrisisCta from "./MidlifecrisisCta";
import TalkAstrologer from "../TalkAstrologer";

function Midlifecrisis() {
  return (
    <>
    <Helmet>
        <title>Tamil Jathagam : Astrological Guidance for Midlife crisis</title>
        <meta name="description" data-rh="true" content="Tamil Jathagam is your astrological guide for midlife. Explore cosmic knowledge to guide you through midlife's transformation with astromind counsel." />
        <meta name="keywords" data-rh="true" content="astrological guide, astrological guide for midlife, an astrological guide" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/services/tamil-jathagam-for-Midlife-crisis/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/services/tamil-jathagam-for-Midlife-crisis/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Tamil Jathagam : Astrological Guidance for Midlife crisis" />
        <meta property="og:description" content="Tamil Jathagam is your astrological guide for midlife. Explore cosmic knowledge to guide you through midlife's transformation with astromind counsel." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Tamil Jathagam : Astrological Guidance for Midlife crisis" />
        <meta name="twitter:description" content="Tamil Jathagam is your astrological guide for midlife. Explore cosmic knowledge to guide you through midlife's transformation with astromind counsel." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

     <MidlifecrisisBanner />
     <MidlifecrisisCard />
     <MidlifecrisisCta />
     <TalkAstrologer />
    </>
  );
}

export default Midlifecrisis;
