import '../../../Styles/astrologer.css'
import { Container, Row, Col } from 'react-bootstrap';

function AstrologerBanner() {
    return (
      <>
       <Container fluid className='astrologer-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>Get your Consultation</h1>
                     <p>We provide exceptional astrology online prediction services through its pool of highly qualified and experienced astrologers.</p>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default AstrologerBanner;
  