import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { AiFillStar } from "react-icons/ai";

import FindAstrologer from '../../Assets/HomeImages/find-astrologer-icon.svg'
import Filter from '../../Assets/HomeImages/filter-icon.svg'
import SortBy from '../../Assets/HomeImages/sortby-icon.svg'
import Search from '../../Assets/HomeImages/search-icon.svg'
import EmptyProfile from '../../Assets/AstrologerImages/empty-profile.png'
import Layer1 from '../../Assets/HomeImages/talk-to-astrologer-Layer1.svg'
import Layer2 from '../../Assets/HomeImages/talk-to-astrologer-Layer2.svg'
import Layer3 from '../../Assets/HomeImages/talk-to-astrologer-Layer3.svg'

// import { Astrologers } from '../AstrologersData/data';
import GetRemainingTime from '../Astrologer/AstrologerListPage/GetRemainingTime';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetDedicatedAstrologers, GetAstrologerStatus } from '../../Redux/Slice';

function TopAstrologers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { DedicatedAstrologers, AstrologerStatus } = useSelector((state) => state.Astromind);

  const waitingId = localStorage.getItem("waitingId");

  useEffect(() => {
    dispatch(GetDedicatedAstrologers());
    dispatch(GetAstrologerStatus());
  }, [dispatch]);


  useEffect(() => {
    if (DedicatedAstrologers?.data) {
        localStorage.setItem("currentPage", 1);
    }
}, [DedicatedAstrologers]);

  const handleClick = (item) => {
    navigate(`/talk-to-astrologer/${item?.profileDetail?.slug}/${item?.profileDetail?.id}`);
  }

  var settings = {
    dots: false,
    arrows: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,

        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,

        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,

        },
      },
    ],

  };
  return (
    <>
      <Container fluid className="d-flex mt-5 mb-5">
        <div className="talk-left-img">
          <img src={Layer1} alt="" />
        </div>
        <Container >
          <Col lg={12}>
            <Row className="d-flex justify-content-center">
              <Col lg={8} className="d-flex flex-column text-center justify-content-center">
                <h3 className="text-blue">Meet Our Top Astrologers</h3>
                <p className="text-black">We provide exceptional astrology online prediction services through its pool of highly qualified and experienced astrologers. Get your Consultation now.</p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              {/* <Col lg={10} className="d-flex justify-content-center gap-3 astrologer-nav">
                <div className="d-flex gap-2 align-items-center bg-blue text-white py-2 px-4 talk-btn">
                  <img src={FindAstrologer} alt="" />
                  <p>Find the Astrologer</p>
                </div>
                <div className="d-flex gap-2 align-items-center bg-blue text-white py-2 px-4 talk-btn">
                  <img src={Filter} alt="" />
                  <p>Filter</p>
                </div>
                <div className="d-flex gap-2 align-items-center bg-blue text-white py-2 px-4 talk-btn">
                  <img src={SortBy} alt="" />
                  <p>Sort by</p>
                </div>
                <div className="d-flex bg-orange text-white talk-btn">
                  <input type="text" />
                  <div className="d-flex justify-content-center p-2 search-btn">
                    <img src={Search} alt="" />
                  </div>
                </div>
              </Col> */}
              <Col lg={12} className="d-flex justify-content-center gap-5 mt-5 astrologer-list">
                <Slider {...settings}>
                  {/* {
                   Astrologers?.data?.astrologer?.map((astro, index) => {
                    let description = astro?.profileDetail?.description?.data.map(obj => obj.name);

                    return(
                      <Col lg={2} className="astrologer-card pt-3">
                        <div className="astrologer-content">
                          <div className="astrologer-img ">
                            <img src={astro?.profileImage?.file ? astro?.profileImage?.file : EmptyProfile} alt="" />
                          </div>
                          <div className="mt-3 mb-2 text-black astrologer-info ">
                            <p className="name">{astro?.profileDetail?.name}</p>
                            <p className="position">{description.join(", ")}</p>
                          </div>
                        </div>
                        <div className="consult-btn">
                          <button  onClick={() => { handleClick(astro) }} className="px-3 py-2">Get Consultation</button>
                        </div>
                      </Col>
                    )})
                  } */}
                  {
                    DedicatedAstrologers?.data?.astrologer?.map((item, index) => {
                      let description = item?.profileDetail?.description?.data.map(obj => obj.name);
                      let language = item?.profileDetail?.language?.data.map(obj => obj.name);

                      let OnlineStatus = AstrologerStatus?.data?.astrologer?.find((status) => status.id === item?.profileDetail?.id);
                      let Call_active_status = OnlineStatus?.call_active_status;

                      let remainingWaitingTime = OnlineStatus?.call_waiting_time > 0 ? GetRemainingTime(OnlineStatus?.call_waiting_time, OnlineStatus?.call_start_time) : null;

                      return (
                        <>
                          <Col lg={4} sm={6} key={index} className='astrologer-Card home-astrologer-Card'>
                                <div className='top-choice-badge'>
                                  <p>Best choice</p>
                                </div>
                            <Col lg={12}
                              className={`d-flex 
                                                                ${OnlineStatus?.call_next_activedate ||
                                  OnlineStatus?.chat_next_activedate ||
                                  item?.profileDetail?.discountpercentage ?
                                  "justify-content-between" : "justify-content-end"} 
                                                                gap-2 pb-1 `}>
                              {
                                item?.profileDetail?.discountpercentage ?
                                  <div className='offer-badge'>
                                    <p className='mb-0'><span>{item?.profileDetail?.discountpercentage}% off</span></p>
                                  </div>
                                  : null
                              }

                              {
                                (OnlineStatus?.call_next_activedate || OnlineStatus?.call_next_activetime) ?
                                  <div className='next-active'>
                                    <p> Next Online - {OnlineStatus?.call_next_activedate + " " + OnlineStatus?.call_next_activetime}</p>
                                  </div>
                                  : null
                              }
                              {/* {
                              //   ConsultationType == "chat" && (OnlineStatus?.chat_next_activedate || OnlineStatus?.chat_next_activetime) ?
                              //     <div className='next-active'>
                              //       <p> Next Online - {OnlineStatus?.chat_next_activedate + " " + OnlineStatus?.chat_next_activetime}</p>
                              //     </div>
                              //     : null
                              // } */}
                              <div className={Call_active_status ? 'online-btn' : 'ofline-btn'}>
                                {Call_active_status ? "Online" : "Offline"}
                              </div>
                            </Col>
                            <Col
                              lg={12}
                              sm={12}
                              className='d-flex gap-2 astrologer-CardCol'
                              onClick={() => { handleClick(item) }}
                            >
                              <Col lg={4} sm={4} className='astrologerCard-img'>
                                <div className='d-flex justify-content-center astro-person-img'>
                                  <img src={item?.profileImage?.file ? item?.profileImage?.file : EmptyProfile} alt="" />
                                </div>

                                <div className='free-btn mt-2'>
                                  <p>
                                    <span>Rs. {item?.profileDetail?.userpayspermin ? item?.profileDetail?.userpayspermin : item?.profileDetail?.myrate} </span>
                                    {item?.profileDetail?.userpayspermin ? <del>Rs. {item?.profileDetail?.myrate}</del> : null} / min
                                  </p>
                                  <p>100 sessions</p>
                                </div>
                                <div className='profileStars'>
                                  <div className="profileStar">
                                    <span>
                                      {[...new Array(5)].map((arr, index) => {
                                        return index < `${item?.profileDetail?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                      })}
                                    </span>

                                  </div>
                                </div>
                              </Col>

                              <Col lg={8} sm={8} className='astrologerCard-content'>
                                <div >
                                  <h6 className='font-700'>{item?.profileDetail?.name}</h6>
                                  <p className='description'>{description.join(", ")}</p>
                                </div>
                                <div className='d-flex gap-3 exprience'>
                                  {item?.profileDetail?.experience ? <p>Exp:<span className='text-black font-700 px-2'>{item?.profileDetail?.experience} years</span></p> : null}
                                  {/* {item.qualification ? <p className='qualification-para'>Qual:<span className='text-black font-700 px-2'>{item.qualification}</span></p> : null} */}
                                </div>
                                <div className='language'>
                                  {language ? <p className='lang-para'>Lang:<span className='text-black font-700 px-2'>
                                    {language.join(", ")}
                                  </span></p> : null}

                                </div>
                                <div className='more-details' onClick={() => { handleClick(item) }}>
                                  More Details
                                </div>

                              </Col>
                            </Col>
                            <Col lg={4} sm={4} className='btn-web'>
                              <p>{
                                remainingWaitingTime?.minutes > 0 && OnlineStatus?.call_waiting_time > 0 ?
                                  "Wait " + remainingWaitingTime?.minutes + 'm'
                                  : null
                              }</p>
                              {/* {
                                ConsultationType == "call" ? */}
                              <div>
                                <button
                                  onClick={() => { handleClick(item) }}
                                  className={OnlineStatus?.call_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null ? 'yellow-btn' : 'yellow-disable-btn'}
                                  disabled={!(OnlineStatus?.call_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null)}
                                >
                                  Call
                                </button>
                              </div>
                              {/* : null
                              } */}
                              {/* {
                                ConsultationType == "chat" ?
                                  <div>
                                    <button
                                      onClick={() => { handleClick(item) }}
                                      className={OnlineStatus?.chat_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null ? 'yellow-btn' : 'yellow-disable-btn'}
                                      disabled={!(OnlineStatus?.chat_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null)}
                                    >
                                      Chat
                                    </button>
                                  </div>
                                  : null
                              } */}
                            </Col>
                          </Col>
                        </>
                      )
                    })
                  }
                </Slider>

              </Col>
            </Row>
          </Col>
        </Container>
        <div className="talk-right-img">
          <img className="img-1" src={Layer2} alt="" />
          <img className="img-2" src={Layer3} alt="" />
        </div>

      </Container>

    </>
  );
}

export default TopAstrologers;