import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function TopAstrologersInCoimbatoreBanner() {
  return (
    <>
      <Container fluid className='children-background'>
        <Container>
          <Row className='d-flex justify-content-center align-items-center'>
            <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
              <h1 className='text-darkOrange'>Top Astrologers in Coimbatore Are Here</h1>
              <p>Get accurate predictions and tailored advice to guide your journey.</p>
              <div className="banner-btn py-2">
                <a href="/talk-to-astrologer/"><button className="bg-lightBlue radius-5 text-white p-2 mx-2">Talk to An Astrologer</button></a>
                <a href="/chat-with-astrologer/"><button className="bg-lightBlue radius-5 text-white p-2 mx-2">Chat With Astrologer</button></a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

    </>
  );
}

export default TopAstrologersInCoimbatoreBanner;
