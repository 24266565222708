import MultiStepForm from "./MultiStepForm/MultiStepForm";

function AstrologerResgister() {
  return (
    <>
      <div className="pt-250">
        <MultiStepForm />
      </div>
    </>
  )
}

export default AstrologerResgister;