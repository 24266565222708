import { Container, Row, Col } from 'react-bootstrap';


function HomeGuideVideo() {
    return (
        <>
            <Container fluid className='my-5'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={8} className="d-flex flex-column text-center justify-content-center mb-3">
                            <h3 className="text-orange">How It Works</h3>
                            {/* <p className="text-black">We provide exceptional astrology online prediction services through its pool of highly qualified and experienced astrologers. Get your Consultation now.</p> */}
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center guide-video'>
                        <video
                            src="https://astromindcounsel.s3.ap-south-1.amazonaws.com/ASTRO+MIND+FINAL+OUT.mp4"
                            controls
                        >
                        </video>
                    </Row>

                </Container>
            </Container>
        </>
    )
}

export default HomeGuideVideo;