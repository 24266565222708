import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

import Card1 from '../../../Assets/ServiceImages/children-card-icon-1.svg'
import Card2 from '../../../Assets/ServiceImages/children-card-icon-2.svg'
import Card3 from '../../../Assets/ServiceImages/children-card-icon-3.svg'
import Card4 from '../../../Assets/ServiceImages/children-card-icon-4.svg'
import Card5 from '../../../Assets/ServiceImages/children-card-icon-5.svg'
import Card6 from '../../../Assets/ServiceImages/children-card-icon-6.svg'

import serviceLeftImg from '../../../Assets/ServiceImages/service-card-left-img.svg'
import serviceRightImg from '../../../Assets/ServiceImages/service-card-right-img.svg'

function TeensCard() {
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <div className="service-left-img">
                    <img src={serviceLeftImg} alt="" />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap gap-4 service-cards'>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card1} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Self-Identity and Self-Acceptance</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist teenagers in developing a better understanding of themselves and accepting their unique qualities. By exploring their astrological birth charts and understanding their personality traits, strengths, and challenges, teenagers can gain insights into their identity. This knowledge can help them build self-esteem and foster a sense of self-acceptance.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card3} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Career Guidance</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can offer guidance and insights into potential career paths and areas of interest for teenagers. By exploring the astrological placements related to vocation and ambition, teenagers can gain insights into their natural inclinations and talents. Integrating psychological approaches can further support career exploration by considering their values, skills, and personal fulfillment.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card4} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Coping with Challenges</h5>
                                    </div>
                                    <div>
                                        <p>Teenagers face various challenges, such as academic pressures, social relationships, and emotional changes. Astrology and psychology can provide tools and perspectives for coping with these challenges. Astrological insights can help teenagers understand the energies at play during specific periods, while psychological approaches can provide strategies for stress management, emotional regulation, and building resilience.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Relationships and Communication</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can support teenagers in navigating relationships and improving communication skills. By exploring astrological compatibility and relationship dynamics, teenagers can gain insights into their own needs and those of others. This understanding, combined with psychological frameworks, can foster healthy communication, empathy, and the development of meaningful connections.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Goal Setting and Personal Development</h5>
                                    </div>
                                    <div>
                                        <p> Integrating astrology and psychology can assist teenagers in setting goals and working towards personal development. Astrology can provide insights into timing and life themes, helping teenagers align their aspirations with the energies at play. Psychological approaches can support goal-setting, motivation, and the development of strategies to overcome obstacles and achieve personal growth.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card2} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Decision Making</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can provide teenagers with additional perspectives when making important life decisions. By exploring astrological transits and progressions, teenagers can gain insights into potential opportunities and challenges that may arise during different periods. Integrating psychological approaches can assist in decision-making processes by considering values, interests, and long-term goals.</p>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <div className="service-right-img">
                    <img src={serviceRightImg} alt="" />
                </div>
            </Container>

        </>
    );
}

export default TeensCard;
