import { Row, Col, Button } from 'react-bootstrap';
import React, { useContext } from "react";
import MultiStepFormContext from "./MultiStepFormContext";

const Review = () => {
  const { details, payment, next, prev } = useContext(MultiStepFormContext);
  console.log(payment.file?.name);
  const date = new Date(details.dateofbirth).toString().slice(3,16);
  const time = details.birthhour + ":" +details.birthminute + details.meridiem;
  console.log(time);

  return (
    <Row className='mt-5'>
      <Col lg={12} className='pb-3'>
        <h1>Review Your Personal Details</h1>
      </Col>
      <Col lg={12} className='d-flex' >
        <Col lg={6}>
          <p ><b>Name :</b> <span style={{marginLeft: "10px"}} >{details.name ? details.name : "none"}</span></p>
          <p><b>Email :</b> <span style={{marginLeft: "10px"}} >{details.email ? details.email : "none"} </span> </p>
          <p><b>Phone Number :</b> <span style={{marginLeft: "10px"}} >{details.phone ? details.phone : "none"} </span></p>
          <p><b>Gender :</b> <span style={{marginLeft: "10px"}} > {details.gender ? details.gender : "none"} </span></p>
          <p><b>Date Of Birth :</b> <span style={{marginLeft: "10px"}} >{date ? date : "none"} </span></p>
        </Col>
        <Col lg={6}>
          <p><b>Time Of Birth :</b> <span style={{marginLeft: "10px"}} >{ details.birthhour || details.birthminute || details.meridiem ? time : "none"}</span></p>
          <p><b>Place Of Birth :</b> <span style={{marginLeft: "10px"}} > {details.placeofbirth ? details.placeofbirth : "none"}</span></p>
          <p><b>Marital Status :</b> <span style={{marginLeft: "10px"}} > {details.maritalstatus ? details.maritalstatus : "none"} </span></p>
          <p><b>Preferred Language : </b> <span style={{marginLeft: "10px"}} >{details.preferredlanguage ? details.preferredlanguage : "none"} </span></p>
          <p><b>How did you hear about us :</b> <span style={{marginLeft: "10px"}} > {details.reference ? details.reference : "none"} </span></p>
        </Col>
      </Col>
      <Col lg={12} className='mb-5'>
        <p><b>Message :</b> <span style={{marginLeft: "10px"}} > {details.message ? details.message : "none"} </span></p>
      </Col>
      <Col >
        <div
          className="d-flex justify-content-center gap-3"
        >
          <Button type={"default"} onClick={prev}>
            Back
          </Button>
          <Button type={"primary"} onClick={next}>
            Make a Payment
          </Button>
        </div>
      </Col>
    </Row>
  );
};
export default Review;
