import { Container, Row } from 'react-bootstrap';


const RefundPolicies = () => {
    return (
        <>
            <Container fluid  >
                <Container>
                    <Row className='privacy-policy'>
                        <div>
                            <h1>REFUND & CANCELLATION POLICY</h1>
                            <hr />
                        </div>
                        <div>
                            <p>
                                www.astromindcounsel.com unit of Astromind Counsel Private Limited (“we”,"AstroMind Counsel" (online and application), hereinafter referred to as the "website") is dedicated to safeguarding the privacy of website users, which includes those who are astrologers and buyers/customers, whether or not they are registered. To understand how the website will use the information you provide, please carefully read our privacy statement. This privacy statement is released in compliance with Information Technology (Intermediaries Guidelines) Rules, 2011 Rule 3(1) and Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 which mandate that a privacy statement be published before any sensitive personal data or information is collected, used, stored, or transferred.
                            </p>

                        </div>
                        <div>
                            <h4>Refund Policy</h4>
                            <p>
                            Astromind Counsel Private Limited has a 100% refund policy for all genuine cases as laid out in the Refund Policy, as determined solely by Astromind Counsel. The refund has to be sought within one week from the date on which the transaction has occurred. The refund request will be evaluated by an audit team, who will be the final authority on behalf of Astromind Counsel to adjudicate on the validity of your refund claims. The evaluation of the audit team will be final. Any customer attempting to fraudulently request a refund may be blocked from using the portal in future.Note: The refund of the wallet balance back to the source of the payment is not allowed under any circumstances, regardless of whether such balance has been consumed towards a consultation or not.
                            </p>
                            <p>
                                At Astromind Counsel, we are dedicated to providing you with accurate and insightful astrology services. We understand that circumstances may arise where you may require a refund. Please read our refund policy carefully to understand our procedures:
                            </p>
                        </div>
                        <div>
                            <h5>1. Eligibility:</h5>
                            <ul>
                                <li><p>Refunds are applicable to purchases made directly through our website.</p></li>
                                <li><p>Refunds are only available for unused astrology services.</p></li>
                                <li><p>To be eligible for a refund, you must request it within 3 days of the original purchase date.</p></li>
                            </ul>

                        </div>

                        <div>
                            <h5>2. How to Request a Refund:</h5>
                            <ul>
                                <li><p>To request a refund, please contact our customer support team at [ <a href='mailto:astromindcounsel@gmail.com'>astromindcounsel@gmail.com</a> or phone number : 8111023733 ] with your order details.</p></li>
                                <li><p>Clearly state the reason for your refund request.</p></li>
                            </ul>

                        </div>

                        <div>
                            <h5>3. Processing Time:</h5>
                            <ul>
                                <li><p>Once we receive your refund request, we will review it and notify you of the approval or rejection of your refund.</p></li>
                                <li><p>If approved, your refund will be processed within 3 days.</p></li>
                                <li><p>Refunds will be issued back to the original payment method used for the purchase.</p></li>
                            </ul>

                        </div>

                        <div>
                            <h5>4. Exceptions:</h5>
                            <ul>
                                <li><p>We reserve the right to deny refund requests if the astrology services have been fully or partially utilized.</p></li>
                                <li><p>Refunds will not be provided for dissatisfaction with the astrology predictions or interpretations provided. Our services are based on subjective interpretations and insights, and refunds cannot be issued based on personal disagreement with the content.</p></li>
                            </ul>

                        </div>

                        <div>
                            <h5>5. Contact Us:</h5>
                            <ul>
                                <li><p>If you have any questions about our refund policy, please contact us at [ <a href='mailto:astromindcounsel@gmail.com'>astromindcounsel@gmail.com</a> or phone number : 8111023733 ].</p></li>
                                <li><p>Our Address : Astromind Counsel Private Limited, RSF.NO.55/1, MAIN ROAD, OPP TO PARAMASIVAMPALAYAM, Pallapalayam, Tiruppur, Coimbatore- 641663, Tamil Nadu</p></li>
                                <li><p>We are committed to addressing any concerns or issues you may have regarding your purchase.</p></li>
                            </ul>

                        </div>

                        <div>
                            <h4>Cancellation Policy</h4>
                            <p>
                                If your credit card/ debit card has been charged twice erroneously, you must go to the contact us page or support section in your account and fill out a request for the same. The same will be processed and done within 72 hours. Make sure you mention the order in your mail. Cancellation will result in the payment gateway charges being deducted from the amount being returned. Partial cancellation or refunds are not possible, i.e. if you have already used part of your balance and are then requesting a refund. Cash cards / cheques / drafts cannot be refunded. You may cancel your astrology service order within 72 hours of placing the order, provided that the service has not been initiated or completed. To cancel your order, please contact our customer support team at contact email : <a href='mailto:astromindcounsel@gmail.com'>astromindcounsel@gmail.com</a> or phone number : 8111023733 with your order details. Once the cancellation request is received and verified, we will process the cancellation and issue a refund according to our refund policy. Due to the nature of our astrology services being intangible and based on subjective interpretations, we do not offer returns on completed services. If you are dissatisfied with the astrology service provided, please contact our customer support team to discuss your concerns. We will do our best to address any issues or provide additional support.
                            </p>
                        </div>
                        <div>
                            <h4>Safety and Security</h4>
                            <p>
                            Astromind Counsel Private Limited has a 100% refund policy for all genuine cases as laid out in the Refund Policy, as determined solely by Astromind Counsel. The refund has to be sought within one week from the date on which the transaction has occurred. The refund request will be evaluated by an audit team, who will be the final authority on behalf of Astromind Counsel to adjudicate on the validity of your refund claims. The evaluation of the audit team will be final. Any customer attempting to fraudulently request a refund may be blocked from using the portal in future.Note: The refund of the wallet balance back to the source of the payment is not allowed under any circumstances, regardless of whether such balance has been consumed towards a consultation or not.
                            </p>
                        </div>
                        <div>
                            <h4>DISCLAIMER</h4>
                            <p>ANY COMMUNICATION BETWEEN THE USER AND THE THIRD-PARTY WEBSITE IS NOT RELATED TO THIS WEBSITE. THIS WEBSITE SHALL NOT BE HELD LIABLE FOR SUCH USAGE MADE ONLY BECAUSE A LINK TO THE THIRD-PARTY WEBSITE WAS PROVIDED ON THE PAGE OF THIS WEBSITE. THE USER IS ADVISED TO READ THE PRIVACY POLICY AND OTHER POLICIES OF THE THIRD PARTY ON THEIR WEBSITES.</p>
                        </div>
                    </Row>
                </Container>

            </Container>
        </>
    )

}

export default RefundPolicies;