import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { IoCall } from "react-icons/io5";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

import AdCard from '../../Assets/HomeImages/ad-card-img.svg'

function AstrologerCta() {
    // localStorage.setItem("showPopup", true);

    const [showCta, setShowCta] = useState(true);

    // useEffect(() => {
    //     localStorage.removeItem("showPopup");
    // }, [])

    return (
        <>
            {
                showCta ?
                    <div className="bg-lightOrange astrologer-cta">
                        <div className="cta-adcard" >
                            <img src={AdCard} alt="" />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <IoClose
                                className="close-btn"
                                onClick={() => {
                                    // localStorage.setItem("showPopup", false)
                                    setShowCta(false);
                                }}
                            />
                            <h4 className="mb-3 text-blue">Ready to Transform Your Life?</h4>
                            <p>Personalized Astrology at Your Fingertips</p>
                            <div className='d-flex gap-3'>
                                <Nav.Link href="/chat-with-astrologer/" >
                                    <Button className="header-btn">
                                        <IoChatbubbleEllipsesSharp className='mx-1' />
                                        Chat with Astrologer
                                    </Button>
                                </Nav.Link>
                                <Nav.Link href="/talk-to-astrologer/" className='sub-nav2-link' >
                                    <Button className="bg-orange text-white header-btn">
                                        <IoCall /> Talk to An Astrologer
                                    </Button>
                                </Nav.Link>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default AstrologerCta;