import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function PersonalizedHoroscopeBanner() {
  return (
    <>
      <Container fluid className='children-background'>
        <Container>
          <Row className='d-flex justify-content-center align-items-center'>
            <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
              <h1 className='text-darkOrange'>Discover Your Destiny with Our Online Tamil Jathagam Service</h1>
              <p>Astromind Counsel - One of the Best Online Tamil Jathagam Services by Expert Astrologers.
                Explore with our Astrologers to Know yourself and your Destiny!!</p>
            </Col>
          </Row>
        </Container>
      </Container>

    </>
  );
}

export default PersonalizedHoroscopeBanner;
