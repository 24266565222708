import '../../../Styles/blogdetail.css'
import { Container, Row, Col } from 'react-bootstrap';

import BlogBannerImg from '../../../Assets/BlogdetailImages/jathagam-banner-img.png'

function BlogdetailBanner({ blog }) {
  let indianTime = new Date(blog?.publishedOn).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });

  return (
    <>
      <Container fluid className='blogDetail-background pb-5'>
        <Container>
          <Row >
            <Col lg={6} className='blogDetail-bannerContent'>
              {/* <div>
                  <h3 className='text-blue'>Blog Details</h3>
                </div> */}
              <div className='pt-5'>
                <h1 className='text-darkOrange'>{blog?.bannertitle}</h1>
              </div>
              <div className='pt-2'>
                <p className='text-black'>{blog?.bannerdescription}</p>
              </div>
              <div>
                <p className='d-flex gap-2 text-blue'><span>{indianTime}</span><span className='text-black'>Written by :</span><span><b>{blog?.author}</b></span></p>
              </div>
            </Col>
            <Col lg={6} className='blogDetail-banner'>
              <img src={blog?.bannerimage} alt="" />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default BlogdetailBanner;