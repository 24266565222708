import '../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

import Astro1 from '../../Assets/HomeImages/astrologer-img-1.png'
import Astro2 from '../../Assets/HomeImages/astrologer-img-2.png'
import Astro3 from '../../Assets/HomeImages/astrologer-img-3.png'
import AstroFounder from '../../Assets/HomeImages/astrologer-founder-img.png'

function TalkAstrologer() {
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={8}>
                            <h3 className='text-orange text-center'>Talk to Astrologers</h3>
                            <p className='text-center'>We provide exceptional astrology online prediction services through its pool of highly qualified and experienced astrologers. Get your Consultation now.</p>
                        </Col>
                        <Col lg={12} className='d-flex talk-astrologer'>
                        <Col lg={3} className='d-flex flex-column align-items-center px-3 py-3'>
                                <div className='talk-astro-img'>
                                    <img src={AstroFounder} alt="" />
                                </div>
                                <div className='talk-astro-content pt-3'>
                                    <h6>Ka.Parthiban</h6>
                                    <p>Founder of Raja Nadi, Naama Nadi (Namealogy)</p>
                                </div>
                            </Col>
                            <Col lg={3} className='d-flex flex-column align-items-center px-3 py-3'>
                                <div className='talk-astro-img'>
                                    <img src={Astro1} alt="" />
                                </div>
                                <div className='talk-astro-content pt-3'>
                                    <h6>Santhinidevi</h6>
                                    <p>Psychology and RajaNadi Astrology</p>
                                </div>
                            </Col>
                            <Col lg={3} className='d-flex flex-column align-items-center px-3 py-3'>
                                <div className='talk-astro-img'>
                                    <img src={Astro2} alt="" />
                                </div>
                                <div className='talk-astro-content pt-3'>
                                    <h6>Lavanya Subramanian</h6>
                                    <p>RajaNadi & <br /> Nakshatra Astrology</p>
                                </div>
                            </Col>
                            <Col lg={3} className='d-flex flex-column align-items-center px-3 py-3'>
                                <div className='talk-astro-img'>
                                    <img src={Astro3} alt="" />
                                </div>
                                <div className='talk-astro-content pt-3'>
                                    <h6>K Bala Shanmugam</h6>
                                    <p>RajaNadi <br /> Astrologer</p>
                                </div>
                            </Col>
                           
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    );
}

export default TalkAstrologer;
