import { useState, useEffect } from 'react';
import { Col, Row, Nav, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { GetAllAstrologers } from '../../../Redux/Slice';

function FilterAstrologer({ show, close, pageLimit, sortBy, filter }) {
    const dispatch = useDispatch();

    const [filterData, setFilterData] = useState({
        skills: [],
        language: [],
        gender: [],
        country: [],
        offer: [],
        topastrologers: []
    });
    const [sortbyData, setSortbyData] = useState({
        experience_high_to_low: false,
        experience_low_to_high: false,
        price_high_to_low: false,
        price_low_to_high: false,
        rating_high_to_low: false,
        rating_low_to_high: false,
    })

    useEffect(() => {
        if (sortBy) {
            if ((sortBy?.sort == "experience") && (sortBy?.sortby == "hightolow")) {
                setSortbyData({ experience_high_to_low: true })
            }
            else if ((sortBy?.sort == "experience") && (sortBy?.sortby == "lowtohigh")) {
                setSortbyData({ experience_low_to_high: true })
            }
            else if ((sortBy?.sort == "myrate") && (sortBy?.sortby == "hightolow")) {
                setSortbyData({ price_high_to_low: true })
            }
            else if ((sortBy?.sort == "myrate") && (sortBy?.sortby == "lowtohigh")) {
                setSortbyData({ price_low_to_high: true })
            }
            else if ((sortBy?.sort == "rating") && (sortBy?.sortby == "hightolow")) {
                setSortbyData({ rating_high_to_low: true })
            }
            else if ((sortBy?.sort == "rating") && (sortBy?.sortby == "lowtohigh")) {
                setSortbyData({ rating_low_to_high: true })
            }
        }
        else if (filter) {
            setFilterData(filter);
        }

    }, [sortBy, filter])

    const handleSortby = (value, option) => {
        let sort = value;
        let sortby = option;
        dispatch(GetAllAstrologers({ page: 1, pagelimit: pageLimit, sort, sortby }));
        localStorage.setItem("sortby", JSON.stringify({ sort: sort, sortby: sortby }));
        close(false);
    }

    const updateArray = (array, value) => {
        if (array.includes(value)) {
            return array.filter(item => item !== value);
        } else {
            return [...array, value];
        }
    };

    const handleUpdateData = (value, type) => {
        switch (type) {
            case "skills":
                setFilterData(prevState => ({
                    ...prevState,
                    skills: updateArray(prevState.skills, value)
                }));
                break;
            case "language":
                setFilterData(prevState => ({
                    ...prevState,
                    language: updateArray(prevState.language, value)
                }));
                break;
            case "gender":
                setFilterData(prevState => ({
                    ...prevState,
                    gender: updateArray(prevState.gender, value)
                }));
                break;
            case "country":
                setFilterData(prevState => ({
                    ...prevState,
                    country: updateArray(prevState.country, value)
                }));
                break;
            case "offer":
                setFilterData(prevState => ({
                    ...prevState,
                    offer: updateArray(prevState.offer, value)
                }));
                break;
            case "topastrologers":
                setFilterData(prevState => ({
                    ...prevState,
                    topastrologers: updateArray(prevState.topastrologers, value)
                }));
                break;
            default:
                console.log(`Unknown type: ${type}`);
        }
    }

    const handleFilter = () => {
        // let filter = JSON.stringify({ "filterdata": filterData });

        let filter = {
            filterdata: filterData
        }

        dispatch(GetAllAstrologers({ page: 1, pagelimit: pageLimit, filter }));
        localStorage.setItem("filter", JSON.stringify(filterData));
        close(false);
    }

    const handleReset = () => {
        setFilterData({
            skills: [],
            language: [],
            gender: [],
            country: [],
            offer: [],
            topastrologers: []
        });
        localStorage.removeItem("filter");
        dispatch(GetAllAstrologers({ page: 1, pagelimit: pageLimit }));
        close(false);
        window.location.reload(false);
    }

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show?.status}
                onHide={() => close(false)}
                className={`${show?.type == 'filter' ? 'filter-modal' : 'sortby-modal'}`}
            >
                <Modal.Header closeButton>
                    <h4>{show?.type == 'filter' ? "Filter" : show?.type == 'sort' ? "Sort By" : null}</h4>
                </Modal.Header>
                <Modal.Body>
                    {
                        show?.type == 'sort' ?
                            <div className='d-flex flex-column gap-4'>
                                <div className='d-flex gap-2 sortby'>
                                    <input
                                        type='radio'
                                        defaultChecked={sortbyData?.experience_high_to_low}
                                        onChange={() => { handleSortby("experience", "hightolow") }}
                                    />
                                    <p>Experience : High to Low</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input
                                        type='radio'
                                        defaultChecked={sortbyData?.experience_low_to_high}
                                        onChange={() => { handleSortby("experience", "lowtohigh") }}
                                    />
                                    <p>Experience : Low to High</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input
                                        type='radio'
                                        defaultChecked={sortbyData?.price_high_to_low}
                                        onChange={() => { handleSortby("myrate", "hightolow") }}
                                    />
                                    <p>Price : High to Low</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input
                                        type='radio'
                                        defaultChecked={sortbyData?.price_low_to_high}
                                        onChange={() => { handleSortby("myrate", "lowtohigh") }}
                                    />
                                    <p>Price : Low to High</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input
                                        type='radio'
                                        defaultChecked={sortbyData?.rating_high_to_low}
                                        onChange={() => { handleSortby("rating", "hightolow") }}
                                    />
                                    <p>Rating : High to Low</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input
                                        type='radio'
                                        defaultChecked={sortbyData?.rating_low_to_high}
                                        onChange={() => { handleSortby("rating", "lowtohigh") }}
                                    />
                                    <p>Rating : Low to High</p>
                                </div>
                            </div>
                            : null
                    }
                    {
                        show?.type == 'filter' ?
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={4} className='bg-lightOrange filter-left'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Skills</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Language</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Gender</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="four">Country</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="five">Offer</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="six">Top Astrologers</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </Col>
                                    <Col sm={8} className='filter-right'>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first" >
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Face Reading")}
                                                        onChange={() => { handleUpdateData("Face Reading", "skills") }}
                                                    />
                                                    <p >Face Reading</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Kp")}
                                                        onChange={() => { handleUpdateData("Kp", "skills") }}
                                                    />
                                                    <p>KP</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Life Coach")}
                                                        onChange={() => { handleUpdateData("Life Coach", "skills") }}
                                                    />
                                                    <p>Life Coach</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Raja Nadi")}
                                                        onChange={() => { handleUpdateData("Raja Nadi", "skills") }}
                                                    />
                                                    <p>Raja Nadi</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Nadi")}
                                                        onChange={() => { handleUpdateData("Nadi", "skills") }}
                                                    />
                                                    <p>Nadi</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Numerology")}
                                                        onChange={() => { handleUpdateData("Numerology", "skills") }}
                                                    />
                                                    <p>Numerology</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Palmistry")}
                                                        onChange={() => { handleUpdateData("Palmistry", "skills") }}
                                                    />
                                                    <p>Palmistry</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Prashana")}
                                                        onChange={() => { handleUpdateData("Prashana", "skills") }}
                                                    />
                                                    <p>Prashana</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Psychic")}
                                                        onChange={() => { handleUpdateData("Psychic", "skills") }}
                                                    />
                                                    <p>Psychic</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Psychologist")}
                                                        onChange={() => { handleUpdateData("Psychologist", "skills") }}
                                                    />
                                                    <p>Psychologist</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.skills.includes("Tarot")}
                                                        onChange={() => { handleUpdateData("Tarot", "skills") }}
                                                    />
                                                    <p>Tarot</p>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className='d-flex flex-column gap-3'>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.language.includes("English")}
                                                        onChange={() => { handleUpdateData("English", "language") }}
                                                    />
                                                    <p >English</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.language.includes("Tamil")}
                                                        onChange={() => { handleUpdateData("Tamil", "language") }}
                                                    />
                                                    <p>Tamil</p>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" className='d-flex flex-column gap-3'>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.gender.includes("Male")}
                                                        onChange={() => { handleUpdateData("Male", "gender") }}
                                                    />
                                                    <p >Male</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.gender.includes("Female")}
                                                        onChange={() => { handleUpdateData("Female", "gender") }}
                                                    />
                                                    <p>Female</p>
                                                </div>
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="four" className='d-flex flex-column gap-3'>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input type='checkbox' onChange={() => { handleUpdateData("insideindia", "country") }} />
                                                    <p >Inside India</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input type='checkbox' onChange={() => { handleUpdateData("outsideindia", "country") }} />
                                                    <p>Outside India</p>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="five" className='d-flex flex-column gap-3'>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input type='checkbox' onChange={() => { handleUpdateData("active", "offer") }} />
                                                    <p >Active</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input type='checkbox' onChange={() => { handleUpdateData("notactive", "offer") }} />
                                                    <p>Not active</p>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="six" className='d-flex flex-column gap-3'>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input type='checkbox' onChange={() => { handleUpdateData("mostrecommended", "topastrologers") }} />
                                                    <p >Most Recommended </p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input type='checkbox' onChange={() => { handleUpdateData("topchoice", "topastrologers") }} />
                                                    <p>Top choice</p>
                                                </div>
                                            </Tab.Pane> */}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                            : null
                    }

                </Modal.Body>
                {
                    show?.type == 'filter' ?
                        <Modal.Footer className='d-flex justify-content-between'>
                            <Button
                                variant="warning"
                                className='text-white'
                                onClick={() => { handleReset() }}
                                disabled={!filterData}
                            >
                                Reset All
                            </Button>
                            <Button
                                onClick={() => { handleFilter() }}
                                disabled={!filterData}
                            >
                                Submit
                            </Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>
        </>
    )
}

export default FilterAstrologer;