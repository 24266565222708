import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function EmpowerYourLifeBanner() {
    return (
      <>
       <Container fluid className='children-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>Empower Your Life with Vedic Astrology</h1>
                     <p>Explore the mysteries of Vedic Astrology and get precise astrology predictions. Receive your personalized horoscope for insights into your unique celestial journey.</p>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default EmpowerYourLifeBanner;
  