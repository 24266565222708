import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function ChildrenCta() {
    return (
      <>
       <Container fluid>
          <Container>
              <Row>
                  <Col lg={12} className='bg-lightOrange p-5 text-center'>
                    <h6 className='text-blue'>It’s important to note that when working with children, any exploration of astrology and psychology should be age-appropriate, respectful of their individuality, and guided by responsible adults or professionals who have expertise in child development. The focus should be on creating a positive and supportive environment that encourages self-discovery and growth</h6>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default ChildrenCta;
  