import { Container, Row, Col } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import BlogHighlightImg1 from '../../Assets/BlogdetailImages/blog-detail-highlight-img-1.png'
import BlogHighlightImg2 from '../../Assets/BlogdetailImages/blog-detail-highlight-img-2.png'
import BlogHighlightImg3 from '../../Assets/BlogdetailImages/blog-detail-highlight-img-3.png'
import BlogToppostImg1 from '../../Assets/BlogdetailImages/blog-detail-toppost-img-1.png'
import BlogToppostImg2 from '../../Assets/BlogdetailImages/blog-detail-toppost-img-2.png'
import BlogToppostImg3 from '../../Assets/BlogdetailImages/blog-detail-toppost-img-3.png'
import BlogToppostImg4 from '../../Assets/BlogdetailImages/blog-detail-toppost-img-4.png'

import NewYearHighlight from '../../Assets/BlogdetailImages/new-year-blog-highlight-img.png'


import RajanadiToppost from '../../Assets/BlogdetailImages/rajanadi-toppost-img.png'


import FacebookIcon from '../../Assets/BlogdetailImages/facebook-icon.svg'
import TwitterIcon from '../../Assets/BlogdetailImages/twitter-icon.svg'
import InstaIcon from '../../Assets/BlogdetailImages/instagram-icon.svg'
import PinterestIcon from '../../Assets/BlogdetailImages/pinterest-icon.svg'
import YoutubeIcon from '../../Assets/BlogdetailImages/youtube-icon.svg'


function BlogdetailSidebar() {



   return (
      <>
         {/* <Container fluid className='blogdetail-search'>
            <Row>
                  <div>
                     <input type="text" placeholder='Search the blog....' />
                  </div>
            </Row>
         </Container>
         <Container fluid className='blogdetail-calender d-flex justify-content-center mt-3 mb-3'>
            <Row>
               <Col lg={12} className='d-flex flex-column gap-2 pt-4 pb-4'>
                  <div>
                     <h5 className='text-blue'>Categories</h5>
                  </div>
                  <div>
                     <select name="" id="">
                        <option value=""></option>
                       
                     </select>
                  </div>
                  <div>
                     <h5 className='text-blue'>Calendar</h5>
                  </div>
                  <div>
                     <Calendar />
                  </div>
               </Col>
            </Row>
         </Container> */}
         <Container fluid className='bg-lightOrange blogdetail-highlight p-4'>
            <Row>
               <Col lg={12} className='d-flex flex-column gap-2'>
                  <div>
                     <h5 className='text-black'>Blog Highlights</h5>
                  </div>
                  <Col className='highlight-cards'>
                     <Col lg={12} sm={6} className='highlight-card'>
                        <div className='blogHighlight-img'>
                           <a href="/blogs/tamil-jathagam-consultation-new-year-2024/">
                              <img src={NewYearHighlight} alt="" />
                           </a>
                        </div>
                        <div>
                           <h6 className='pt-2 font-700'>New Year Predictions for 2024 – Part 1</h6>
                           <p className='pt-2'>Transit predictions in Raja Nadi is based on the yearly planets triggering the natal planets in a chart. Saturn, Jupiter, Rahu and Ketu are the four planets that creates major changes in our life.</p>
                        </div>
                     </Col>
                     <Col lg={12} sm={6} className='highlight-card'>
                        <div className='blogHighlight-img'>
                           <a href="/blogs/rajanadi-personal-horoscope-astrology-guide/" target='_blank'>
                              <img src={BlogHighlightImg2} alt="" />
                           </a>
                        </div>
                        <div>
                           <h6 className='pt-2 font-700'>Raja Nadi</h6>
                           <p className=''>Nadi system is an astrological system that uses rasi/signs and the planets for predictions. Conjunction of planets forms the basis of this system. A planet is conjunct with another planet which is in trine (1,5,9) with it.</p>
                        </div>
                     </Col>
                     <Col lg={12} sm={6} className='highlight-card'>
                        <div className='blogHighlight-img'>
                           <a href="/blogs/tamil-jathagam-raja-nadi-conjunctions-part-one/" target='_blank'>
                              <img src={BlogHighlightImg3} alt="" />
                           </a>
                        </div>
                        <div>
                           <h6 className='pt-2 font-700'>Raja Nadi Conjunctions – Part 1</h6>
                           <p className=''>Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA).</p>
                        </div>
                     </Col>
                     <Col lg={12} sm={6} className='highlight-card'>
                        <div className='blogHighlight-img'>
                           <a href="/blogs/complete-guide-to-jadhagam/" target='_blank'>
                              <img src={BlogHighlightImg1} alt="" />
                           </a>
                        </div>
                        <div>
                           <h6 className='pt-2 font-700'>ஜாதகம் என்றால் என்ன ?</h6>
                           <p className=''>ஜாதகம் என்றால் ஒரு மனிதன் பிறக்கும் போது வானில் இருக்கும் கிரக நிலைகளை குறிக்கும் வரைபடம்.</p>
                        </div>
                     </Col>

                  </Col>
                  <div className='d-flex justify-content-center blogHighlight-btn'>
                     <button className='text-blue'><a href='/blogs/' className='text-blue'>See all</a></button>
                  </div>
               </Col>
            </Row>
         </Container>

         <Container fluid className='bg-blue text-white blogdetail-toppost p-4 mt-4'>
            <Row>
               <Col lg={12} className='d-flex flex-column gap-2'>
                  <div>
                     <h5>Top Post</h5>
                  </div>
                  <Col className='toppost-cards'>
                     <Col lg={12} sm={6} href="/blogs/rajanadi-personal-horoscope-astrology-guide/" className='d-flex gap-3 bg-white text-black radius-5 p-2 blogToppost-card'>
                        <Col lg={3} className='blogToppost-img'>
                           <img src={RajanadiToppost} alt="" />
                        </Col>
                        <Col lg={8}>
                           <h6 className='pt-2 font-700'>Raja Nadi</h6>
                           <p className=''>Nadi system is an astrological system that uses rasi/signs and the planets for predictions. Conjunction of planets forms the basis of this system. A planet is conjunct with another planet which is in trine (1,5,9) with it.</p>
                        </Col>
                     </Col>
                     <Col lg={12} sm={6} className='d-flex gap-3 bg-white text-black radius-5 p-2 blogToppost-card'>
                        <Col lg={3} className='blogToppost-img'>
                           <img src={BlogToppostImg2} alt="" />
                        </Col>
                        <Col lg={8}>
                           <h6 className='pt-2 font-700'>Raja Nadi Conjunctions – Part 1</h6>
                           <p className=''>Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA).</p>
                        </Col>
                     </Col>
                     <Col lg={12} sm={6} className='d-flex gap-3 bg-white text-black radius-5 p-2 blogToppost-card'>
                        <Col lg={3} className='blogToppost-img'>
                           <img src={BlogToppostImg4} alt="" />
                        </Col>
                        <Col lg={8}>
                           <h6 className='pt-2 font-700'>Raja Nadi Conjunctions – Part 2</h6>
                           <p>Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA).</p>
                        </Col>
                     </Col>
                     <Col lg={12} sm={6} className='d-flex gap-3 bg-white text-black radius-5 p-2 blogToppost-card'>
                        <Col lg={3} className='blogToppost-img'>
                           <img src={BlogToppostImg1} alt="" />
                        </Col>
                        <Col lg={8}>
                           <h6 className='pt-2 font-700'>ஜாதகம் என்றால் என்ன ?</h6>
                           <p className=''>ஜாதகம் என்றால் ஒரு மனிதன் பிறக்கும் போது வானில் இருக்கும் கிரக நிலைகளை குறிக்கும் வரைபடம்.</p>
                        </Col>
                     </Col>

                  </Col>
                  <div className='d-flex justify-content-center blogToppost-btn'>
                     <button className='text-white'><a href="/blogs/" className='text-white'>See all</a></button>
                  </div>
               </Col>
            </Row>
         </Container>

         <Container fluid className='blog-sticky lightOrange p-4 mt-4'>
            <Row>
               <Col lg={12}>
                  <div>
                     <h5 className='text-blue'>Popular Tags</h5>
                  </div>
                  <div className='d-flex flex-wrap gap-2 popularTag-btn pt-3'>
                     <button># astrology</button>
                     <button># Astro Psychologist</button>
                     <button># children astrology</button>
                     <button># Astromind Counsel</button>

                  </div>
               </Col>
               <Col lg={12}>
                  <div>
                     <h5 className='text-blue'>Follow Us</h5>
                  </div>
                  <div className='d-flex gap-4 mt-4'>
                     <div className='d-flex flex-column align-items-center'>
                        <a href="https://www.facebook.com/profile.php?id=61555195043116" target='_blank'>
                           <img src={FacebookIcon} alt="" />
                        </a>
                        <p className='text-blue'>10</p>
                     </div>
                     <div className='d-flex flex-column align-items-center'>
                        <a href="https://twitter.com/astromind123" target='_blank'>
                           <img src={TwitterIcon} alt="" />
                        </a>
                        <p className='text-blue'>69k</p>
                     </div>
                     <div className='d-flex flex-column align-items-center'>
                        <a href="https://www.instagram.com/astromindcounsel/" target='_blank'>
                           <img src={InstaIcon} alt="" />
                        </a>
                        <p className='text-blue'>45</p>
                     </div>
                     <div className='d-flex flex-column align-items-center'>
                        <a href="https://www.pinterest.com/astromindcounsel/" target='_blank'>
                           <img src={PinterestIcon} alt="" />
                        </a>
                        <p className='text-blue'>69k</p>
                     </div>
                     <div className='d-flex flex-column align-items-center'>
                        <a href="https://www.youtube.com/channel/UCoVOb4A0Jhk0zLlSNv1xI8g" target='_blank'>
                           <img src={YoutubeIcon} alt="" />
                        </a>
                        <p className='text-blue'>69k</p>
                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
      </>
   );
}

export default BlogdetailSidebar;