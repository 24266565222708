import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function MidlifecrisisCta() {
    return (
      <>
       <Container fluid>
          <Container>
              <Row>
                  <Col lg={12} className='bg-lightOrange p-5 text-center'>
                    <h6 className='text-blue'>It’s important to note that a mid-life crisis is a complex and individual experience, and not everyone will resonate with the concept or find astrology and psychology helpful in their particular situation. Seeking professional support, maintaining social connections, and practicing self-care are essential components of navigating this phase of life.</h6>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default MidlifecrisisCta;
  