import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';

import PrivacyIcon from '../../Assets/HomeImages/privacy-icon.svg'
import CompetenceIcon from '../../Assets/HomeImages/competence-icon.svg'
import GuaranteeIcon from '../../Assets/HomeImages/guarantee-icon.svg'


function HomeCard2() {
  return (
    <>
      <Container fluid className="mt-5 mb-5 yellow-card">
        <Container>
          <Row className="d-flex gap-4">
            <Col lg={4} className="d-flex gap-3 p-3 bg-orange text-white radius-5">
              <div className="bg-white white-card">
                <img src={PrivacyIcon} alt="" />
              </div>
              <div className="card-description">
                <h5>Privacy</h5>
                <p>Information obtained during the consultation is private.</p>
              </div>
            </Col>
            <Col lg={4} className="d-flex gap-3 p-3 bg-orange text-white radius-5">
              <div className="bg-white white-card">
                <img src={CompetenceIcon} alt="" />
              </div>
              <div className="card-description">
                <h5>Competence</h5>
                <p>We only take on issues that we can solve</p>
              </div>
            </Col>
            <Col lg={4} className="d-flex gap-3 p-3 bg-orange text-white radius-5">
              <div className="bg-white white-card">
                <img src={GuaranteeIcon} alt="" />
              </div>
              <div className="card-description">
                <h5>Result guarantee</h5>
                <p>We provide a guaranteed 100% result</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default HomeCard2;