import React, { useContext } from "react";
import { useFormik } from 'formik';
import { Button } from "antd";
import MultiStepFormContext from "./MultiStepFormContext";
const Details = () => {
  const { details, setDetails, next } = useContext(MultiStepFormContext);

  const formik = useFormik({
    initialValues: details,
    onSubmit: async (values) => {
      setDetails(values);
      next();

    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Required*";
      }
      if (!values.email) {
        errors.email = "Required*";
      }
      else if (values.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address";
      }
      // if (!values.phone) {
      //   errors.phone = "Required*";
      // } else if (!phoneReg.test(values.phone)) {
      //   errors.phone = "Invalid phone number";
      // }
      if (!values.gender) {
        errors.gender = "Required*";
      }
      if (!values.dateofbirth) {
        errors.dateofbirth = "Required*";
      }
      // if (!values.birthhour) {
      //   errors.birthhour = "Required*";
      // }
      // if (!values.birthminute) {
      //   errors.birthminute = "Required*";
      // }
      // if (!values.meridiem) {
      //   errors.meridiem = "Required*";
      // }
      if (!values.placeofbirth) {
        errors.placeofbirth = "Required*";
      }
      if (!values.maritalstatus) {
        errors.maritalstatus = "Required*";
      }
      if (!values.preferredlanguage) {
        errors.preferredlanguage = "Required*";
      }
      // if (!values.message) {
      //   errors.message = "Required*";
      // }

      console.log(errors);
      return errors;
    }

  })
  return (
    <>
      <form action="" onSubmit={formik.handleSubmit}>
        <div className={"details__wrapper"}>
          <div className={`form__item ${formik.errors.name && "input__error"}`}>
            <label>Name *</label>
            <input name="name" value={formik.values.name} onChange={formik.handleChange} />
            <p className={"error__feedback"}>{formik.errors.name}</p>
          </div>
          <div className={`form__item ${formik.errors.email && "input__error"}`}>
            <label>Email *</label>
            <input name="email" value={formik.values.email} onChange={formik.handleChange} />
            <p className={"error__feedback"}>{formik.errors.email}</p>
          </div>
          <div className={`form__item ${formik.errors.phone && "input__error"}`}>
            <label>Phone Number </label>
            <input name="phone" value={formik.values.phone} onChange={formik.handleChange} />
            <p className={"error__feedback"}>{formik.errors.phone}</p>
          </div>
          <div
            className={`form__item ${formik.errors.gender && "input__error"}`}
          >
            <label>Gender *</label>
            <select name="gender" value={formik.values.gender} onChange={formik.handleChange}>
              <option value="">Choose</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <p className={"error__feedback"}>{formik.errors.gender}</p>
          </div>
          <div className={`form__item ${formik.errors.dateofbirth && "input__error"}`}>
            <label>Date Of Birth *</label>
            <input type="date" name="dateofbirth" className="birthInput-desktop" value={formik.values.dateofbirth} onChange={formik.handleChange} />
            <input type="tel" name="dateofbirth" className="birthInput-mobile" placeholder="dd-mm-yyyy" value={formik.values.dateofbirth} onChange={formik.handleChange} />
            <p className={"error__feedback"}>{formik.errors.dateofbirth}</p>
          </div>
          <div className={`form__item ${formik.errors.birthhour && "input__error"}`}>
            <label>Time Of Birth </label>
            <div className="d-flex gap-1 timeofBirth">
              <select
                name='birthhour'
                className="col-lg-2 col-sm-3 "
                value={formik.values.birthhour}
                onChange={formik.handleChange}
              >
                <option value="">hr</option>
                {[...Array(12)].map((x, i) =>
                  <option value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                    {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                  </option>
                )}
              </select>
              <div className='colon'>:</div>
              <select
                name='birthminute'
                className="col-lg-2 col-sm-3 "
                value={formik.values.birthminute}
                onChange={formik.handleChange}
              >
                <option value="">min</option>
                {[...Array(60)].map((x, i) =>
                  <option value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                    {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                  </option>
                )}
              </select>
              <div className='colon'>:</div>
              <select
                name='meridiem'
                className="col-lg-2 col-sm-3 "
                value={formik.values.meridiem}
                onChange={formik.handleChange}
              >
                <option value=""></option>
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
            <p className={"error__feedback"}>{formik.errors.birthhour || formik.errors.birthminute || formik.errors.meridiem}</p>
          </div>
          <div className={`form__item ${formik.errors.placeofbirth && "input__error"}`}>
            <label>Place Of Birth *</label>
            <input name="placeofbirth" value={formik.values.placeofbirth} onChange={formik.handleChange} />
            <p className={"error__feedback"}>{formik.errors.placeofbirth}</p>
          </div>
          <div className={`form__item ${formik.errors.maritalstatus && "input__error"}`}>
            <label>Marital Status *</label>
            <input name="maritalstatus" value={formik.values.maritalstatus} onChange={formik.handleChange} />
            <p className={"error__feedback"}>{formik.errors.maritalstatus}</p>
          </div>
          <div
            className={`form__item ${formik.errors.preferredlanguage && "input__error"}`}
          >
            <label>Preferred Language *</label>
            {/* <Input name={"profession"} /> */}
            <select name="preferredlanguage" value={formik.values.preferredlanguage} onChange={formik.handleChange}>
              <option value="">Choose</option>
              <option value="Tamil">Tamil</option>
              <option value="English">English</option>
            </select>
            <p className={"error__feedback"}>{formik.errors.preferredlanguage}</p>
          </div>
          <div
            className={`form__item`}
          >
            <label>How did you hear about us *</label>
            {/* <Input name={"profession"} /> */}
            <select name="reference" value={formik.values.reference} onChange={formik.handleChange}>
              <option value="">Choose</option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="Linkedin">Linkedin</option>
              <option value="Twitter">Twitter</option>
              <option value="YouTube">YouTube</option>
              <option value="WhatsApp">WhatsApp</option>
              <option value="Friends or Relatives">Friends or Relatives</option>
            </select>
          </div>
          <div className={`form__item2 ${formik.errors.message && "input__error"}`}>
            <label>Message </label>
            <textarea name="message" value={formik.values.message} onChange={formik.handleChange} />
            <p className={"error__feedback"}>{formik.errors.message}</p>
          </div>
          <div
            className={"form__item2 button__items d-flex justify-content-center"}
          >
            <Button type={"primary"} onClick={formik.handleSubmit}>
              Next
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default Details;
