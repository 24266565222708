import '../../../Styles/contact.css'
import { Container, Row, Col } from 'react-bootstrap';

function CourseContent() {
    return (
        <>
            <Container fluid className='mt-5'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={8} className='course-box p-4'>
                            <div className='d-flex justify-content-center'>
                                <h3 className='text-blue fw-bold'>ராஜநாடி எளிய முறை ஜோதிட பயிற்சி வகுப்பு நேரடியாக சேலம் / Rajanaadi Direct Workshop in Salem  </h3>
                            </div>
                            <ul>
                                <li>அங்கு நேரடியாக வந்து கட்டணம் செலுத்தி கொள்ளவும்</li>
                                <li>கட்டணம் ₹6000 புத்தகம் உட்பட </li>
                            </ul>
                        </Col>
                        <Col lg={10} className='course-content mt-5'>
                            <div className='title'>
                                <p>சேலம் ஜனவரி <b>21</b> ஆம் தேதி ஞாயிற்றுக்கிழமை காலை <b>9.30</b> முதல் மாலை <b>5</b> மணி வரை</p>
                            </div>
                            <ul className='d-flex flex-column gap-3 mt-3'>
                                <li>இந்த ஒரு நாள் பயிற்சி முடிந்த பிறகு 90 நாட்கள் ஆன்லைன் வழியாக பயிற்சி தொடரும்.</li>
                                <li>இந்த வகுப்பு போல தமிழகத்தில் அடுத்த அடுத்ததாக நடக்கும் எந்த வகுப்பில் கூட கலந்து கொள்ள முடியும். அதற்கு அன்றைய உங்களுக்கான செலவு ₹500 செலுத்த வேண்டும்.</li>
                                <li>நீங்கள் வகுப்பில் உறுதியாக கலந்து கொள்ள விரும்பினால். முன்கூட்டியே தெரிவிக்கவும்.</li>
                                <li>ஒருநாள் நேரடி பயிற்சி வகுப்பு முடிந்த பிறகு தொடர்ந்து 90 நாட்கள் வரை ஆன்லைன் வழியாக பயிற்சி தொடரும்.</li>
                            </ul>
                            <div className='d-flex flex-column align-items-end'>
                                {/* <h5 className='text-black font-700'>9941220631</h5> */}
                                <h5 className='text-black font-700'>கா பா</h5>
                            </div>
                        </Col>
                        <Col lg={9} className='course-table mt-5 mb-5'>
                            <table>
                                <tr>
                                    <th></th>
                                    <th>English</th>
                                    <th>Tamil</th>
                                </tr>
                                <tr>
                                    <td>Place</td>
                                    <td>City: Salem</td>
                                    <td>இடம்: சேலம்</td>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <td>21-Jan-2024</td>
                                    <td>21 ஜனவரி 2024</td>
                                </tr>
                                <tr>
                                    <td>Fees</td>
                                    <td>Rs. 6000</td>
                                    <td>Rs. 6000</td>
                                </tr>
                                <tr>
                                    <td>Timings</td>
                                    <td>9:30 AM to 5 PM</td>
                                    <td>9.30 முதல் மாலை 5 மணி</td>
                                </tr>
                                <tr>
                                    <td>Notes</td>
                                    <td>30 days of online education provided after the in-person class (included in the fees).</td>
                                    <td>ஒருநாள் நேரடி பயிற்சி வகுப்பு முடிந்த பிறகு தொடர்ந்து 90 நாட்கள் வரை ஆன்லைன் வழியாக பயிற்சி தொடரும்.</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default CourseContent;
