import '../../Styles/contact.css'
import { Container, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';

import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMailOutline, MdLocalPhone } from "react-icons/md";

function ContactForm() {

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            subject: '',
            message: ''

        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            const Headers = {
                'Accept': 'application/json',
                "Content-type": "application/json; charset=UTF-8"
            }
            try {
                await axios.post(`https://prod-astromind-api.astromindcounsel.com/contact-us`,
                    {
                        "first_name": values.firstname,
                        "last_name": values.lastname,
                        "email": values.email,
                        "subject": values.subject,
                        "message": values.message

                    }, Headers)
                    .then((res) => {
                        toast.success("Thanks! Your contact request has been submitted successfully.")
                    })
            } catch (err) {
                toast.error(err.response.data.error.message, {
                    position: toast.POSITION.CENTER_CENTER
                })
            }

            resetForm();

        },
        validate: (values) => {
            const errors = {};

            if (!values.firstname) {
                errors.firstname = "Required*";
            }
            if (!values.lastname) {
                errors.lastname = "Required*";
            }
            if (!values.email) {
                errors.email = "Required*";
            } else if
                (values.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address";
            }
            if (!values.subject) {
                errors.subject = "Required*";
            }
            if (!values.message) {
                errors.message = "Required*";
            }

            console.log("errors", errors);
            return errors;
        }

    })

    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <Container>
                    <Row className='d=flex justify-content-center'>
                        <Col lg={8} className='bg-lightOrange contactForm-main p-5'>
                            <div className='mb-4'>
                                <h4>Get In Touch</h4>
                            </div>
                            <form onSubmit={formik.handleSubmit} action="">
                                <div className='d-flex justify-content-between mb-4 Form-name'>
                                    <div className='d-flex flex-column gap-3 Form-half'>
                                        <div className='d-flex justify-content-between'>
                                            <label >First Name</label>
                                            <p className='error'>{formik.errors.firstname}</p>
                                        </div>
                                        <input type="text" id='firstname' name='firstname' placeholder='Enter Your First Name' className={formik.errors.firstname ? "error-input" : null} value={formik.values.firstname} onChange={formik.handleChange} />
                                    </div>
                                    <div className='d-flex flex-column gap-3 Form-half'>
                                        <div className='d-flex justify-content-between'>
                                            <label >Last Name</label>
                                            <p className='error'>{formik.errors.lastname}</p>
                                        </div>
                                        <input type="text" id='lastname' name='lastname' placeholder='Enter Your Last Name' className={formik.errors.lastname ? "error-input" : null} value={formik.values.lastname} onChange={formik.handleChange} />
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-3 Form-full mb-4'>
                                    <div className='d-flex justify-content-between'>
                                        <label >Your Email</label>
                                        <p className='error'>{formik.errors.email}</p>
                                    </div>
                                    <input type="email" id='email' name='email' placeholder='Enter Your Email Address' className={formik.errors.email ? "error-input" : null} value={formik.values.email} onChange={formik.handleChange} />
                                </div>
                                <div className='d-flex flex-column gap-3 Form-full mb-4'>
                                    <div className='d-flex justify-content-between'>
                                        <label >Subject</label>
                                        <p className='error'>{formik.errors.subject}</p>
                                    </div>
                                    <input type="text" id='subject' name='subject' className={formik.errors.subject ? "error-input" : null} value={formik.values.subject} onChange={formik.handleChange} />
                                </div>
                                <div className='d-flex flex-column gap-3 Form-full'>
                                    <div className='d-flex justify-content-between'>
                                        <label >Your Message</label>
                                        <p className='error'>{formik.errors.message}</p>
                                    </div>
                                    <textarea id='message' name='message' placeholder='Enter Your Message' className={formik.errors.message ? "error-input" : null} value={formik.values.message} onChange={formik.handleChange} />
                                </div>
                                <div className='d-flex justify-content-center mt-4 submit-btn'>
                                    <button type='submit' name='Send' className='bg-blue text-white'>Send</button>
                                </div>
                            </form>
                        </Col>

                        <Col lg={8} className='bg-lightOrange contactForm-main px-5 py-3 mt-5'>
                            <div className='mb-4'>
                                <h4>Our Office</h4>
                                <div className='office-address mt-3'>
                                    <div className=''>
                                        <IoLocationOutline className='location-icon' />
                                    </div>
                                    <div>
                                        <p>Astromind Counsel Private Limited</p>
                                        <p>RSF.NO.55/1, MAIN ROAD, OPP TO PARAMASIVAMPALAYAM, Pallapalayam, Tiruppur, Coimbatore- 641663, Tamil Nadu</p>
                                    </div>
                                </div>
                                <div className='office-address mt-3'>
                                    <div className=''>
                                        <MdOutlineMailOutline className='location-icon' />
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <p>astromindcounsel@gmail.com</p>
                                    </div>
                                </div>
                                <div className='office-address mt-3'>
                                    <div className=''>
                                        <MdLocalPhone className='location-icon' />
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <p>+91 94459 50005, +91 81110 23733</p>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default ContactForm;
