import React, { useState, useEffect } from "react";
import { Col, Button } from 'react-bootstrap';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { UpdateUserProfile } from '../../../Redux/Slice';

const EditProfile = ({ editShow, editClose }) => {
    const dispatch = useDispatch();
    const { Loading, User } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    const [userData, setUserData] = useState();

    useEffect(() => {
        setUserData({
            name: User?.data?.data?.user?.name,
            email: User?.data?.data?.user?.email,
            gender: User?.data?.data?.user?.gender,
            dateofbirth: User?.data?.data?.user?.dateofbirth,
            birthplace: User?.data?.data?.user?.placeofbirth,
            maritalstatus: User?.data?.data?.user?.maritalstatus,
            timeofbirth: User?.data?.data?.user?.timeofbirth,
            phone: User?.data?.data?.user?.phone,
            country: User?.data?.data?.user?.country,
            state: User?.data?.data?.user?.state,
            city: User?.data?.data?.user?.city,
            pincode: User?.data?.data?.user?.pincode
        })
    }, [User, editShow]);

    const handleUpdateProfile = () => {
        if (token) {
            dispatch(UpdateUserProfile({ userData, token }));
        }
    }

    return (
        <>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={editShow}
                onHide={editClose}
                className=''
            >
                <Modal.Body>
                    <div className='detail-form edit-form'>
                        <div className='text-center pt-2 pb-3'>
                            <h1>Edit Your Basic Details</h1>
                        </div>
                        <Col lg={11} className='d-flex flex-wrap justify-content-between'>
                            <Col lg={4} className='half-form mb-4'>
                                <div className='pb-2'>
                                    <label htmlFor="">Enter Your Name</label>
                                </div>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={userData?.name}
                                    onChange={(e) => { setUserData({ ...userData, name: e.target.value }) }}
                                />
                            </Col>
                            <Col lg={4} className='half-form mb-4'>
                                <div className='pb-2'>
                                    <label htmlFor="">Enter Your Email ID</label>
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={userData?.email}
                                    onChange={(e) => { setUserData({ ...userData, email: e.target.value }) }}
                                />
                            </Col>

                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Gender</label>
                                </div>
                                <select
                                    id='gender'
                                    name='gender'
                                    value={userData?.gender}
                                    onChange={(e) => { setUserData({ ...userData, gender: e.target.value }) }}
                                >
                                    <option value="">Choose</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </Col>

                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Date of Birth</label>
                                </div>
                                <DatePicker
                                    className="px-3"
                                    showIcon
                                    placeholderText="Select Date"
                                    selected={userData?.dateofbirth}
                                    onChange={(value) => { setUserData({ ...userData, dateofbirth: value }) }}
                                    monthsShown={1}
                                    maxDate={new Date()}
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </Col>
                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Birth Place</label>
                                </div>
                                <input
                                    type="text"
                                    name="birthplace"
                                    id="birthplace"
                                    value={userData?.birthplace}
                                    onChange={(e) => { setUserData({ ...userData, birthplace: e.target.value }) }}
                                />

                            </Col>
                            <Col lg={4} className="half-form birth-time d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Birth Time</label>
                                </div>
                                <input
                                    type="text"
                                    name="timeofbirth"
                                    id="timeofbirth"
                                    value={userData?.timeofbirth}
                                    onChange={(e) => { setUserData({ ...userData, timeofbirth: e.target.value }) }}
                                />

                            </Col>

                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Marital Status</label>
                                </div>
                                <select
                                    id='maritalstatus'
                                    name='maritalstatus'
                                    value={userData?.maritalstatus}
                                    onChange={(e) => { setUserData({ ...userData, maritalstatus: e.target.value }) }}
                                >
                                    <option value="">Choose</option>
                                    <option value="Single">Single</option>
                                    <option value="Married">Married</option>
                                    <option value="Divorced">Divorced</option>
                                </select>
                            </Col>
                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Contact Details</label>
                                </div>
                                <PhoneInput
                                    country={'in'}
                                    id='phone'
                                    name='phone'
                                    value={userData?.phone}
                                    onChange={(value) => { setUserData({ ...userData, phone: value }) }}
                                />
                            </Col>
                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Country</label>
                                </div>
                                <input
                                    type="text"
                                    name="country"
                                    id="country"
                                    value={userData?.country}
                                    onChange={(e) => { setUserData({ ...userData, country: e.target.value }) }}
                                />
                            </Col>
                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >State</label>
                                </div>
                                <input
                                    type="text"
                                    name="state"
                                    id="state"
                                    value={userData?.state}
                                    onChange={(e) => { setUserData({ ...userData, state: e.target.value }) }}
                                />
                            </Col>
                            <Col lg={6} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >City</label>
                                </div>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={userData?.city}
                                    onChange={(e) => { setUserData({ ...userData, city: e.target.value }) }}
                                />
                            </Col>
                            <Col lg={6} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Pincode</label>
                                </div>
                                <input
                                    type="text"
                                    name="pincode"
                                    id="pincode"
                                    value={userData?.pincode}
                                    onChange={(e) => { setUserData({ ...userData, pincode: e.target.value }) }}
                                />
                            </Col>
                        </Col>
                        <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                            <Button
                                className='otp-button'
                                onClick={handleUpdateProfile}
                            >
                                {Loading ?
                                    <div className='loader'></div>
                                    : <>Submit</>
                                }
                            </Button>
                            <Button
                                onClick={editClose}
                                className='otp-button bg-red'
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default EditProfile;
