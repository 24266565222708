import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';

import CardLeft from '../../Assets/HomeImages/card-left-img.svg'
import CardRight from '../../Assets/HomeImages/card-right-img.svg'
import SmileyImg from '../../Assets/HomeImages/funny-smiley-img.gif'

function HomeCta() {
    return (
        <>
            <Container fluid className="mt-5 mb-5">
                <div className="cta-left-img">
                    <img src={CardLeft} alt="" />
                </div>
                <Container>
                    <Row className="d-flex justify-content-center cta-row pt-3 pb-3">
                        <Col lg={11} className="cta-col">
                            <Col lg={2} className="d-flex justify-content-center align-items-center cta-btn">
                                <img src={SmileyImg} alt="" />
                            </Col>
                            <Col lg={9} className="d-flex flex-column justify-content-center text-white">
                                <h3 className="pb-2">Endorphin Mode</h3>
                                <p>My astrologer told me that I’m going to meet a girl with a moon in Virgo. That’s ridiculous, I’m a Scorpio and I don’t believe in astrology.</p>
                            </Col>

                        </Col>
                    </Row>
                </Container>
                <div className="cta-right-img">
                    <img src={CardRight} alt="" />
                </div>
            </Container>
        </>
    );
}

export default HomeCta;