import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Fragment, useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import EmptyImg from '../../../Assets/AstrologerImages/empty-profile.png';
import { FaRegStar } from "react-icons/fa6";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetChatSession, GetChatStatus, AddReviewForAstrologer, successMessage, errorMessage } from '../../../Redux/Slice';

function AddReview({ show, reviewClose, astrologer_name, astrologer_image, astrologerId, orderId }) {
    const dispatch = useDispatch();
    const { success, errors, Loading, User } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    const astrologerName = localStorage.getItem("roomName") || astrologer_name;
    const astrologerImage = localStorage.getItem("astrologerImage") || astrologer_image;

    const [ratings, setRatings] = useState(0);
    const [reviews, setReviews] = useState("");

    const ReviewClose = () => {
        reviewClose(false);
    }

    const handleUpdateRating = (newValue) => {
        setRatings(newValue);
    };

    const handleUpdateReviews = () => {
        let reviewData = {
            rating: ratings,
            comment: reviews,
            astrologerId: astrologerId,
            orderId: orderId
        }
        dispatch(AddReviewForAstrologer({ token, reviewData }));
    }

    useEffect(() => {
        if (success?.status == 200) {
            Swal.fire({
                title: success?.data?.message,
                confirmButtonColor: "#319F43",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                ReviewClose();
                dispatch(successMessage(''));
            });
        }
        if (errors?.status == 400) {
            Swal.fire({
                title: errors?.data?.message,
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then(() => {
                ReviewClose();
                dispatch(errorMessage(''));
            });
        }
    }, [success, errors])

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => ReviewClose()}
                className='review-modal'
            >
                <Modal.Header closeButton>
                    <div className='review-astrologer-profile'>
                        <div className='astro-review-img mb-2'>
                            <img src={astrologerImage ? astrologerImage : EmptyImg} alt="" className='img-fluid' />
                        </div>
                        <div>
                            <h5>{astrologerName ? astrologerName : "name"}</h5>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-items-center gap-3 mb-3'>
                        <div className='user-review-img'>
                            <img src={User?.data?.data?.profile ? User?.data?.data?.profile?.file : EmptyImg} alt="" className='img-fluid' />
                        </div>
                        <div>
                            <h6>{User?.data?.data?.user?.name}</h6>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center review-ratings mb-3'>
                        <p>Give Your Rating</p>
                        <div className=''>
                            {[1, 2, 3, 4, 5].map((ratingValue) => (
                                <span
                                    key={ratingValue}
                                    className={ratingValue <= ratings ? 'star filled' : 'star'}
                                    onClick={() => handleUpdateRating(ratingValue)}
                                >
                                    &#9733;
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className='add-reviews'>
                        <textarea name="" id="" rows="5" placeholder='Review and Feedback' value={reviews} onChange={(e) => setReviews(e.target.value)} />
                    </div>
                    <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                        <Button className={Loading ? 'otp-button disabled' : 'otp-button'} onClick={() => handleUpdateReviews()} disabled={ratings <= 0} >{Loading ? <div className='loader'></div> : "Submit"}</Button>
                        {/* <Button onClick={ChangeFileClose} className='otp-button bg-red'  >Cancel</Button> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddReview;