import '../../Styles/blogdetail.css'
import { Container, Row, Col } from 'react-bootstrap';

function BlogdetailForm() {

  return (
    <>
      <Container fluid className='blogdetailForm-background pt-5 pb-5'>
        <Container>
          <Row className='d-flex justify-content-center'>
            <Col lg={8} className='d-flex justify-content-between blogdetailFormCol-background p-5'>
              <Col lg={6} className='d-flex align-items-center blogdetailForm-content'>
                <div>
                  <div>
                    <h3>Leave a Reply</h3>
                    <p>Your email address will not be published. Required fields are marked *</p>
                  </div>
                  <Col lg={12} className='d-flex gap-3 blogdetailForm-requried'>
                    <div>
                       <input type="checkbox" />
                    </div>
                    <div>
                      <p>Save my name, email, and website in this browser for the next time I comment.</p>
                    </div>
                    
                  </Col>
                </div>
              </Col>
              <Col lg={5} className='blogdetailForm-form'>
                <div>
                  <form action="">
                    <div className='d-flex gap-2 flex-column mb-3'>
                      <label>Name</label>
                      <input type="text" />
                    </div>
                    <div className='d-flex gap-2 flex-column mb-3'>
                      <label>Email</label>
                      <input type="email" />
                    </div>
                    <div className='d-flex gap-2 flex-column mb-3'>
                      <label>Comment</label>
                      <textarea />
                    </div>
                    <div className='blogdetailForm-btn'>
                       <button className='bg-blue text-white'>Send</button>
                    </div>
                  </form>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default BlogdetailForm;