import { toZonedTime } from 'date-fns-tz';

const GetRemainingTime = (waitingTime, startTime, IpAddress) => {
    let RemainderWaitingTime = waitingTime % 60;
    let FixWaitingTime = waitingTime - RemainderWaitingTime;

    let CurrentDate = new Date();
    const ZonedDate = toZonedTime(CurrentDate, IpAddress?.timezone);
    let CurrentTime = new Date(ZonedDate.getTime());
    let starttime = new Date(startTime);
    let StartTime =  toZonedTime(starttime, IpAddress?.timezone);
    
    let DurationTime = FixWaitingTime * 1000;
    let EndTime = new Date(StartTime.getTime() + DurationTime);

    let timeDifference = EndTime - CurrentTime;
    let remainingWaitingTime = timeDifference - FixWaitingTime;

    let elapsedDurationTime = DurationTime - timeDifference;
    let elapsedSeconds = Math.floor(elapsedDurationTime / 1000);
    let remainingHours = Math.floor(remainingWaitingTime / (1000 * 60 * 60));
    let remainingMinutes = Math.ceil((remainingWaitingTime % (1000 * 60 * 60)) / (1000 * 60));
    let remainingSeconds = Math.ceil((remainingWaitingTime % (1000 * 60)) / 1000);
    // let remainingSeconds = 60;

    // setInterval(() => {
    //        remainingSeconds = remainingSeconds - 1;

    //     if (remainingSeconds < 0) {
    //         remainingSeconds = 59;
    //     }
    // }, 1000);

    let remainingTime = { hour: remainingHours, minutes: remainingMinutes, seconds: remainingSeconds, elapsedDuration: elapsedSeconds }
    return remainingTime;
}

export default GetRemainingTime;