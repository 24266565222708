import '../../Styles/about.css'
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cama from '../../Assets/AboutImages/cama.svg'

import Astro1 from '../../Assets/HomeImages/astrologer-img-1.png'
import Astro2 from '../../Assets/HomeImages/astrologer-img-2.png'
import Astro3 from '../../Assets/HomeImages/astrologer-img-3.png'
import Astro4 from '../../Assets/HomeImages/astrologer-founder-img.png'


function AboutTeam() {

    return (
        <>
            <Container fluid className='mb-5 ourTeam'>
                <Container>
                    <Row className='d-flex justify-content-center mb-4'>
                        <Col lg={10} className='d-flex justify-content-center'>
                            <h3 className='text-orange'>Meet Our Team</h3>
                        </Col>
                    </Row>
                </Container>
                
                <Tab.Container id="left-tabs-example" defaultActiveKey="sandhini" className="mt-5">
                    <Row className='d-flex justify-content-center meetOur-team' >
                        <Col lg={10} sm={12}>
                            <Nav variant="pills" className="d-flex justify-content-center">
                                <Nav.Item>
                                    <Nav.Link eventKey="parthiban">
                                        <img src={Astro4} alt="" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sandhini">
                                        <img src={Astro1} alt="" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="lavanya">
                                        <img src={Astro2} alt="" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="bala">
                                        <img src={Astro3} alt="" />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col lg={12} sm={12} className='mt-5'>
                            <Tab.Content>
                                <Tab.Pane eventKey="parthiban">
                                    <Container>
                                        <Row className='bg-white aboutBanner-main d-flex justify-content-center p-4'>
                                            <Col lg={12} className='d-flex justify-content-between aboutBanner-col'>
                                                <Col lg={2} className='bg-blue about-col text-white '>
                                                    <div className='about-astroImg'>
                                                        <img src={Astro4} alt="" />
                                                    </div>
                                                    <div className='about-astrodetail'>
                                                        <p className='title'>Ka.Parthiban</p>
                                                        <p className='description'>Founder of Raja Nadi, Naama Nadi (Nameology)</p>
                                                    </div>
                                                </Col>
                                                <Col lg={10} className='p-3'>
                                                    <div className='about-bannerContent d-flex flex-column  justify-content-center align-items-center'>
                                                        <div className='round-circle bg-blue d-flex  justify-content-center'>
                                                            <img src={Cama} alt="" />
                                                        </div>
                                                        <div className='pt-3'>
                                                            <h3 className='text-blue'> Astrology acts as an intrinsic tool in exploring one’s quest of life and the ability to identify the futuristic events </h3>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Col>
                                            <Col lg={12} className='pt-5'>
                                                <div className='about-para'>
                                                    <p><b>Ka.Parthiban</b> aka Ka. Pa has more than 25 years of experience in Astrology. He is the author of “Raja Nadi Jothida Sasthiram”, a very practical, magically simple and profoundly precise techniques for predicting. He hails from the family of astrological scholars and is been introduced to astrology at a very young age.</p>
                                                    <p>His maternal grandfather and uncle are well versed in Vedic astrology and Nadi astrology. His grandfather has devised the basic principles of Raja Nadi system from ancient palm leaf manuscripts, ancient scriptures and from the guidance of ancient seer’s observations. Discarding the outdated terminologies and incorporating modern terms, Ka. Pa co-founded the Raja Nadi system and is the authority in Raja Nadi Astrology (RNA).</p>
                                                    <ul>
                                                        <li><p>He has trained 1600 astrologers till date and continues to do so.</p></li>
                                                        <li><p>On a daily basis he conducts online course that trains an amateur into a professional astrologer as early as 3 months. He also conducts in-person classes at various locations around Tamil Nadu on a regular basis.</p></li>
                                                        <li><p>To become his student, one doesn’t need any exposure to astrology. Following his instructions and guidelines anyone can become a pro in predicting events based on the Raja Nadi system (an extended version of Nadi astrology that uses only planets and Rasi/signs for predicting the events).</p></li>

                                                    </ul>
                                                </div>
                                                {/* <div className='about-content'>
                                                    <p><b>Now this wisdom she has gathered in her life – like a honey bee from various flowers, she is ready to share. This is a mission to help others – change their lives, lead it completely and beautifully, usefully and meaningfully.
                                                    </b></p>
                                                </div> */}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sandhini">
                                    <Container>
                                        <Row className='bg-white aboutBanner-main d-flex justify-content-center p-4'>
                                            <Col lg={12} className='d-flex justify-content-between aboutBanner-col'>
                                                <Col lg={2} className='bg-blue about-col text-white '>
                                                    <div className='about-astroImg'>
                                                        <img src={Astro1} alt="" />
                                                    </div>
                                                    <div className='about-astrodetail'>
                                                        <p className='title'>Santhinidevi Ramsamy</p>
                                                        <p className='description'>Psychology and RajaNadi Astrology</p>
                                                    </div>
                                                </Col>
                                                <Col lg={10} className='p-3'>
                                                    <div className='about-bannerContent d-flex flex-column  justify-content-center align-items-center'>
                                                        <div className='round-circle bg-blue d-flex  justify-content-center'>
                                                            <img src={Cama} alt="" />
                                                        </div>
                                                        <div className='pt-3'>
                                                            <h3 className='text-blue'> My sincere Gratitude to my Traditional Astrology Guru AGP and RajaNadi Astrology Guru Ka.Pa. </h3>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Col>
                                            <Col lg={12} className='pt-5'>
                                                <div className='about-para'>
                                                    <p><b>Santhinidevi Ramsamy</b> was Born and raised in India. She was born the year Bangladesh won its freedom-1971. Always curious about the mysteries of life and existence she naturally got attracted to science and spirituality alike. Coming up in a village she got well acquainted with local spiritual traditions and also the joy of keen observation of nature. Then her life took her to the United States where she works in Healthcare for 25 years (Cardiac Sonographer in Pediatric field). Along with work also came travel which she loves. Through these travels she gathered wisdom from various people she met. A roller coaster life introduced her to the yin and yang of approaching life – both with passion and as disconcerted observer. In her need to approach the mysteries of human mind in a systematic manner, she studied psychology and obtained a masters in it.</p>
                                                    <p>Along with this she also studied astrology which came from a very ancient tradition – from a remote village of Tamil Nadu. It contains the best of the East and the West. With the scientific rigour of psychology and the holistic vision of this astrology she arrived at her own harmonious vision of human existence individual as well as collective. To her personal growth never stops. And in the quest for the meaning of life the astrology combined with psychology can be a catalyst of a fulfilling change. This is not the run of the mill predictive astrology that tells whether or not you are going to get a jackpot but this is the astrology that will help you live your life whether you get a jackpot or miss a fortune. Like a good scientist of renaissance vintage she has tested what she discovered in her own life. She lives what she counsels.</p>
                                                </div>
                                                <div className='about-content'>
                                                    <p><b>Now this wisdom she has gathered in her life – like a honey bee from various flowers, she is ready to share. This is a mission to help others – change their lives, lead it completely and beautifully, usefully and meaningfully.
                                                    </b></p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="lavanya">
                                    <Container>
                                        <Row className='bg-white aboutBanner-main d-flex justify-content-center p-4'>
                                            <Col lg={12} className='d-flex justify-content-between aboutBanner-col'>
                                                <Col lg={2} className='bg-blue about-col text-white '>
                                                    <div className='about-astroImg'>
                                                        <img src={Astro2} alt="" />
                                                    </div>
                                                    <div className='about-astrodetail'>
                                                        <p className='title'>Lavanya Subramanian</p>
                                                        <p className='description'>Rajanadi & Nakshatra Astrology</p>
                                                    </div>
                                                </Col>
                                                <Col lg={10} className='p-3'>
                                                    <div className='about-bannerContent d-flex flex-column  justify-content-center align-items-center'>
                                                        <div className='round-circle bg-blue d-flex  justify-content-center'>
                                                            <img src={Cama} alt="" />
                                                        </div>
                                                        <div className='pt-3'>
                                                            <h3 className='text-blue'>Knowing the real astrology makes you modest and teaches you to surrender</h3>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Col>
                                            <Col lg={12} className='pt-5'>
                                                <div className='about-para'>
                                                    <p><b>Lavanya Subramanian</b> has more than 10 years of experience in teaching and researching on various Astro techniques including but not limited to <b>Brighu Chakra Paddhatti (BCP), Raja Nadi Astrology, Nakshatra Astrology, Graha Friction Chart (GFC).</b></p>
                                                    <p>She was Born and raised in India. Always curious in exploring the reasons behind significant event in life, she naturally got attracted to Astrology. Coming up from a family of Numerology and surrounded by Astro aspirants she was keen in the area of Astro research. Then her life took her to the United States where she started working as a math teacher and later into finance. Working with numbers and calculations her mathematical quest eventually opened the magical door into the field of Astrology.</p>
                                                </div>
                                                <div className='about-content'>
                                                    <p><b>The bud of sharing the knowledge started to bloom and opened her the area of providing Astro guidance for family and friends. Overwhelmed by the magic this guidance does on people she realizes the need to share and how sharing means Caring for others.
                                                    </b></p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="bala">
                                    <Container>
                                        <Row className='bg-white aboutBanner-main d-flex justify-content-center p-4'>
                                            <Col lg={12} className='d-flex justify-content-between aboutBanner-col'>
                                                <Col lg={2} className='bg-blue about-col text-white '>
                                                    <div className='about-astroImg'>
                                                        <img src={Astro3} alt="" />
                                                    </div>
                                                    <div className='about-astrodetail'>
                                                        <p className='title'>K Bala Shanmugam</p>
                                                        <p className='description'>Rajanadi Astrologer</p>
                                                    </div>
                                                </Col>
                                                <Col lg={10} className='p-3'>
                                                    <div className='about-bannerContent d-flex flex-column  justify-content-center align-items-center'>
                                                        <div className='round-circle bg-blue d-flex  justify-content-center'>
                                                            <img src={Cama} alt="" />
                                                        </div>
                                                        <div className='pt-3'>
                                                            <h3 className='text-blue'> My sincere Gratitude to my RajaNadi Astrology Guru Ka.Pa. </h3>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Col>
                                            <Col lg={12} className='pt-5'>
                                                <div className='about-para'>
                                                    <p>From Chennai, Tamil Nadu and she <b>Specializes in detailed predictions.</b> Her sweet voice and well organized predictions are much admired by many. She is warm hearted and loved by her huge extended family. From childhood she was interested in <b>Zodiac signs , Numerology and predictions.</b> Soon that interest expanded to Vedic Astrology. For a long time she was keeping her expertise limited to friends and family. Now she is up for expanding that family circle globally.</p>
                                                </div>
                                                {/* <div className='about-content'>
                                                    <p><b>Now this wisdom she has gathered in her life – like a honey bee from various flowers, she is ready to share. This is a mission to help others – change their lives, lead it completely and beautifully, usefully and meaningfully.
                                                    </b></p>
                                                </div> */}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>


        </>


    );



}

export default AboutTeam;
