import '../../../Styles/login.css'
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LoginScreen, AddNewUser } from '../../../Redux/Slice';

function BasicDetails({ number, Message }) {
    const dispatch = useDispatch();
    const { Loading, DetailStatus, success } = useSelector((state) => state.Astromind);

    const[isChecked, setIsChecked] = useState(false);

    const detailClose = () => {
        dispatch(LoginScreen({ DetailStatus: false }))
    }

    useEffect(() => {
        if (success?.status === 200) {
            localStorage.setItem('user-auth-token', JSON.stringify(success?.data?.token));
            const token = JSON.parse(localStorage.getItem('user-auth-token'));
            if (token) {
                window.location.reload(false);
            }
            Message(success?.data?.message);
            dispatch(LoginScreen({ DetailStatus: false, ConfirmStatus: true }));
        }
    }, [success, dispatch]);

    useEffect(() => {
        if (number?.phone) {
            let phone = Number(number?.phone);
            console.log(phone);
            formik.setFieldValue('phone', number?.phone);
        }
        else if (number?.email) {
            console.log(number?.email);
            formik.setFieldValue('email', number?.email);
        }
    }, [number]);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            gender: '',
            dateofbirth: '',
            birthplace: '',
            Hour: '',
            Min: '',
            meridiem: '',
            maritalstatus: '',
            contactdetail: '',
            country: '',
            state: '',
            city: '',
            pincode: ''
        },
        onSubmit: async (values, { resetForm }) => {
            let date = new Date(values.dateofbirth).toString().slice(3, 16);
            let BirthTime = values.Hour && values.Min && values.meridiem ? `${values.Hour}:${values.Min} ${values.meridiem}` : '12:00 am';

            let data = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                gender: values.gender,
                dateofbirth: date,
                timeofbirth: BirthTime,
                placeofbirth: values.birthplace,
                maritalstatus: values.maritalstatus,
                city: values.city,
                state: values.state,
                country: values.country,
                pincode: values.pincode
            }

            dispatch(AddNewUser(data));
        },
        validate: (values) => {
            const errors = {};

            if (!values.name) {
                errors.name = "Required*";
            }
            if (!values.phone) {
                errors.phone = "Required*";
            }
            if (!values.email) {
                errors.email = "Required*";
            }
            else if (values.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address";
            }
            if (!values.dateofbirth) {
                errors.dateofbirth = "Required*";
            }
            if (!values.birthplace) {
                errors.birthplace = "Required*";
            }
            // if (!values.Hour) {
            //     errors.Hour = "Required*";
            // }
            // if (!values.Min) {
            //     errors.Min = "Required*";
            // }
            // if (!values.meridiem) {
            //     errors.meridiem = "Required*";
            // }
            // if (!values.gender) {
            //     errors.gender = "Required*";
            // }

            return errors;
        }
    })

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={DetailStatus}
                onHide={detailClose}
                className='login-modal'
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='detail-form'>
                        <div className='text-center pt-2 pb-3'>
                            <h1>Enter Your Basic Details</h1>
                        </div>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between '>
                            <Col lg={4} className='half-form signup-half-form mb-4'>
                                <div className='d-flex justify-content-between pb-2'>
                                    <label htmlFor="">Enter Your Name <span className='text-darkOrange'>*</span></label>
                                    <p className='error'>{formik.errors.name}</p>
                                </div>
                                <input
                                    type="text"
                                    id='name'
                                    name='name'
                                    placeholder='Enter Your Name'
                                    className={formik.errors.name ? "error-input" : null}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                            </Col>
                            <Col lg={4} className='half-form signup-half-form mb-4'>
                                <div className='d-flex justify-content-between pb-2'>
                                    <label htmlFor="">Enter Your Email ID<span className='text-darkOrange'>*</span></label>
                                    <p className='error'>{formik.errors.email}</p>
                                </div>
                                <input
                                    type="email"
                                    id='email'
                                    name='email'
                                    value={formik.values.email}
                                    placeholder='Enter Your Email Address'
                                    className={formik.errors.email ? "error-input" : null}
                                    onChange={formik.handleChange}
                                />
                            </Col>
                            {/* <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Gender<span className='text-darkOrange'>*</span></label>
                                    <p className='error'>{formik.errors.gender}</p>
                                </div>
                                <select
                                    id='gender'
                                    name='gender'
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                >
                                    <option value="">Choose</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </Col> */}

                            <Col lg={4} className="half-form signup-half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Date of Birth<span className='text-darkOrange'>*</span></label>
                                    <p className='error'>{formik.errors.dateofbirth}</p>
                                </div>

                                <DatePicker
                                    className="px-3"
                                    showIcon
                                    placeholderText="Select Date"
                                    selected={formik.values.dateofbirth}
                                    onChange={(value) => { formik.setFieldValue('dateofbirth', value) }}
                                    monthsShown={1}
                                    maxDate={new Date()}
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                {/* <input type="date" name='dateofbirth' id='dateofbirth' value={formik.values.dateofbirth} onChange={formik.handleChange} /> */}
                            </Col>
                            <Col lg={4} className="half-form signup-half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Birth Place<span className='text-darkOrange'>*</span></label>
                                    <p className='error'>{formik.errors.birthplace}</p>
                                </div>
                                <input
                                    type="text"
                                    id='birthplace'
                                    name='birthplace'
                                    value={formik.values.birthplace}
                                    onChange={formik.handleChange}
                                />

                            </Col>
                            <Col lg={4} className="half-form signup-half-form birth-time d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Birth Time (optional)<span className='text-darkOrange'></span></label>
                                    <p className='error'>{formik.errors.Hour || formik.errors.Min || formik.errors.meridiem}</p>
                                </div>
                                <div className="d-flex gap-1">
                                    {/* <input type="number" name='Hour' value={formik.values.Hour} onChange={formik.handleChange} className="col-lg-2" /> */}
                                    <select
                                        name='Hour'
                                        value={formik.values.Hour}
                                        onChange={formik.handleChange}
                                        className="col-lg-2"
                                    >
                                        <option value="">hr</option>
                                        {[...Array(12)].map((x, i) =>
                                            <option key={i} value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                                                {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                                            </option>
                                        )}
                                    </select>
                                    <span>:</span>
                                    <select
                                        name='Min'
                                        value={formik.values.Min}
                                        onChange={formik.handleChange}
                                        className="col-lg-2"
                                    >
                                        <option value="">min</option>
                                        {[...Array(60)].map((x, i) =>
                                            <option key={i} value={i + 1 <= 9 ? "0" + (i + 1) : i + 1}>
                                                {i + 1 <= 9 ? "0" + (i + 1) : i + 1}
                                            </option>
                                        )}
                                    </select>
                                    <span>:</span>
                                    <select
                                        name='meridiem'
                                        value={formik.values.meridiem}
                                        onChange={formik.handleChange}
                                        className="col-lg-2 col-sm-3"
                                    >
                                        <option value=""></option>
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </Col>

                            {/* <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Marital Status</label>
                                </div>
                                <select
                                    id='maritalstatus'
                                    name='maritalstatus'
                                    value={formik.values.maritalstatus}
                                    onChange={formik.handleChange}
                                >
                                    <option value="">Choose</option>
                                    <option value="Single">Single</option>
                                    <option value="Married">Married</option>
                                    <option value="Divorced">Divorced</option>
                                </select>
                            </Col> */}
                            <Col lg={4} className="half-form signup-half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Mobile Number<span className='text-darkOrange'>*</span></label>
                                    <p className='error'>{formik.errors.phone}</p>
                                </div>
                                <PhoneInput
                                    country={'in'}
                                    id='phone'
                                    name='phone'
                                    value={formik?.values?.phone}
                                    onChange={(value) => { formik.setFieldValue('phone', value) }}
                                />
                            </Col>
                            {/* <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Country</label>
                                </div>
                                <input
                                    type="text"
                                    id='country'
                                    name='country'
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                />
                            </Col>


                            <Col lg={4} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >State</label>
                                </div>
                                <input
                                    type="text"
                                    id='state'
                                    name='state'
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                />
                            </Col>
                            <Col lg={6} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >City</label>
                                </div>
                                <input
                                    type="text"
                                    id='city'
                                    name='city'
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                />
                            </Col>
                            <Col lg={6} className="half-form d-flex flex-column mb-4">
                                <div className='d-flex justify-content-between pb-2'>
                                    <label >Pincode</label>
                                </div>
                                <input
                                    type="text"
                                    id='pincode'
                                    name='pincode'
                                    value={formik.values.pincode}
                                    onChange={formik.handleChange}
                                />
                            </Col> */}
                        </Col>
                        <Col lg={12} className='d-flex gap-2 terms-check mb-3 user-signup'>
                            <input type="checkbox" value={isChecked} onChange={() => setIsChecked(!isChecked)} />
                            <p>By signing up, you agree to our <a href='/terms-of-use/' target='_blank' rel="noreferrer" >Terms of Use</a> and <a href='/privacy-policies/' target='_blank' rel="noreferrer">Privacy Policy.</a></p>
                        </Col>
                        <div className='d-flex justify-content-center pt-3 pb-3'>
                            <Button
                                className='otp-button bg-orange'
                                onClick={formik.handleSubmit}
                                disabled={!isChecked}
                            >
                                {Loading ? <div className='loader'></div> : "Submit"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BasicDetails;