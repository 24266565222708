import { Helmet } from 'react-helmet';

import CourseBanner from "./courseBanner";
import CourseContent from "./courseContent";

function InPerson() {
  return (
    <>
      <Helmet>
        <title>Join Astromind Counsel's Online Nadi Jothidam Training</title>
        <meta name="description" data-rh="true" content="Our Astromind Counsel conducts Nadi Jothidam online training to learn. We have more experienced astrologers to teach Rajanadi astrology classes online in Tamil." />
        <meta name="keywords" data-rh="true" content="jothidam class in tamil, rajanaadi training course, astrology online course in tamil, online astrology course, nadi jothidam, learn astrology online, astrology classes online, best astrology courses online" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/astromind-counsel-online-nadi-jothidam-training/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/astromind-counsel-online-nadi-jothidam-training/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Join Astromind Counsel's Online Nadi Jothidam Training" />
        <meta property="og:description" content="Our Astromind Counsel conducts Nadi Jothidam online training to learn. We have more experienced astrologers to teach Rajanadi astrology classes online in Tamil." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Join Astromind Counsel's Online Nadi Jothidam Training" />
        <meta name="twitter:description" content="Our Astromind Counsel conducts Nadi Jothidam online training to learn. We have more experienced astrologers to teach Rajanadi astrology classes online in Tamil." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>
      <CourseBanner />
      <CourseContent />
    </>
  );
}

export default InPerson;
