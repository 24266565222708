import { Helmet } from 'react-helmet';
import BestAstrologersInTrichyBanner from './BestAstrologerInTrichyBanner';
import BestAstrologersInTrichyContent from './BestAstrologerInTrichyContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";

function BestAstrologersInTrichy() {
    return (
        <>
            <Helmet>
                <title>AstroMind Counsel: Best Astrologer in Trichy for Insights</title>
                <meta name="description" content="Find the top astrologer in Trichy for reliable horoscope readings and life advice. Experience the benefits of professional astrology today." />
                <meta name="keywords" content="astromind counsel, top astrologers in Tiruchirappalli, best astrologer in Trichy, famous astrologer in Trichy, best vedic astrologer, best horoscopes near me,  best nadi astrology in Trichy." />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/best-astrologer-trichy/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/best-astrologer-trichy/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="AstroMind Counsel: Best Astrologer in Trichy for Insights" />
                <meta property="og:description" content="Find the top astrologer in Trichy for reliable horoscope readings and life advice. Experience the benefits of professional astrology today." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="AstroMind Counsel: Best Astrologer in Trichy for Insights" />
                <meta name="twitter:description" content="Find the top astrologer in Trichy for reliable horoscope readings and life advice. Experience the benefits of professional astrology today." />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <BestAstrologersInTrichyBanner />
            < BestAstrologersInTrichyContent />
        </>
    );
}

export default BestAstrologersInTrichy;
