import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';


const TermsOfUse = () => {
    return (
        <>
            <Helmet >
                <title>Terms of Use - Astromind Counsel</title>
                <meta name="description" data-rh="true" content="Please read the following terms and conditions carefully. By accessing or using this website, you acknowledge that you have read, understood and agreed to these terms and conditions." />
                <meta name="keywords" data-rh="true" content="Astromind counsel terms, astromind terms and conditions" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/terms-of-use/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Terms of Use - Astromind Counsel" data-rh="true" />
                <meta property="og:description" content="Please read the following terms and conditions carefully. By accessing or using this website, you acknowledge that you have read, understood and agreed to these terms and conditions." data-rh="true" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Terms of Use - Astromind Counsel" data-rh="true" />
                <meta name="twitter:description" content="Please read the following terms and conditions carefully. By accessing or using this website, you acknowledge that you have read, understood and agreed to these terms and conditions." data-rh="true" />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>
            <Container fluid  >
                <Container>
                    <Row className='privacy-policy'>
                        <div>
                            <h1>TERMS OF USE</h1>
                            <hr />
                        </div>
                        <div>
                            <p>
                                These terms and conditions of Use (hereinafter referred as “Terms of Usage”) describe and govern the User’s use of the content and services offered by Astromind Counsel  Services Private Limited through www.astromindcounsel.com (hereinafter referred as “We” “Astromind Counsel Private Limited” “us” “our” “Astromind Counsel  application” “Website”).
                            </p>

                        </div>
                        <div>
                            <h4>UPDATION</h4>
                            <p>
                                These terms of use may be updated, amended, or modified by the website at any time.  The user is responsible to check the terms of usage periodically to remain in compliance with these terms.
                            </p>
                        </div>
                        <div>
                            <h4>USER CONSENT</h4>
                            <p>
                                You (“Member”, “You”, “Your”) agree to and understand the terms of usage by using the Website. The user should read the terms of usage before using or registering on the website or accessing any material, information, or services. Your continued use of the website shows your agreement of the terms of use and consent to their legal binding.
                            </p>
                        </div>
                        <div>
                            <h4>GENERAL DESCRIPTION</h4>
                            <p>
                                The website provides astrological content, reports, data, telephone, and video consultations (hereinafter “Content”) on the Web, Application, and other electronic means. The Website offers “Paid Services” (collectively “Services”). Membership registration for Paid Services requires current, complete, and accurate information as asked by the Website.
                            </p>
                            <p>
                                Maintain and update the information you requested and submitted to maintain accuracy and completeness.
                            </p>
                        </div>
                        <div>
                            <h4>REGISTRATION AND ELIGIBILITY</h4>
                            <ul>
                                <li>
                                    <p>
                                        The Indian Contract Act, 1872 requires this website's users to be 18 or older to contract. This Website is not responsible for child abuse. This policy lets families ask simple questions.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Users must register on the Website and give current, accurate information in the sign-in form to use the services. These terms of usage designate all website data you supply and update "Registration Data".
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Register with your ID (Your Phone Number) and OTP. The User keeps accurate, comprehensive, and current account information. Can't share services. The website may suspend or terminate the user's account and restrict future use if their information is incorrect, incomplete, inaccurate, or outdated.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Only registered users can use this website. Protect passwords and registration info. The website user is liable for all account activities. Website isn't liable for privacy breaches. Any account misuse or security breach must be notified to the website immediately. Logout ends sessions.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Website or third-party disclosure. Websites cannot control third-party content.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The user agrees that the website or Payment Service Provider(s) have no control over online misuse, hacking, theft, or fraud of personal data, including debit/credit card information.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        If the user lives in a state/country with trade restrictions due to laws, rules, treaties, or religion.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Multi-mobile number accounts. Each user can have one user account.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>FEATURE “CALL WITH ASTROLOGER”</h4>
                            <p>
                                The website offers a specific service that may be accessed by phone with an enrolled astrologer mentioned on the website. If you accept the current terms of usage, you also give the website permission to call you on your mobile phone, even if your mobile service provider has placed your number on DND.
                            </p>
                        </div>

                        <div>
                            <h4>WEBSITE CONTENT</h4>
                            <ul>
                                <li>
                                    <p>
                                        The time-of-use covers all website interaction, including licensed provider help.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The user shall not publish or submit through this website any material that infringes others' rights, is unlawful, abusive, defamatory, invasive of privacy, vulgar, obscene, profane, or otherwise objectionable, or encourages criminal, civil, or other illegal
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Websites may suspend or cancel user registration or access and restrict reuse.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Website content, pictures, promotions, deals, and settings may change.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Except as instructed by the third-party service provider, site information is not medical advice.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The website does not guarantee medical advice from registered astrologers. Consult a doctor for diagnosis, treatment, and medication. This content does not guarantee that any drug or therapy is safe, suitable, or successful for you. Astromind Counsel does not recommend drugs, tests, or therapies.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        By using the Site, Application, or Services, you agree that you can only sue the party that caused the injury for other Members' actions or omissions, including the Website's service provider or other associated third parties. You agree not to sue the Website for such actions or omissions.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Multi-mobile number accounts. Each user can have one user account.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>USER ACCOUNT ACCESS</h4>
                            <p>
                                The website will access the user's account and information to provide high-quality services and meet customer needs. Users authorize websites, employees, agents, and others to access accounts without restriction. Based on its data, the website will investigate complaints and misuse. Users should review privacy policy for such records.
                            </p>
                        </div>
                        <div>
                            <h4>PRIVACY POLICY</h4>
                            <p>
                                The User hereby consents, expresses and agrees that the user has read and fully understands the privacy policy of the website. The user further consents that the terms and contents of such privacy policy is acceptable to the user inclusive of any update/alteration/change made and duly displayed on the Website.
                            </p>
                        </div>
                        <div>
                            <h4>BREACH AND TERMINATION</h4>
                            <ul>
                                <li>
                                    <p>
                                        The Website may change services or user accounts without notice. Site action may be advised.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Users who violate these terms of usage will lose their website registration immediately. If user registration data or other critical information cannot be verified, the website may close immediately.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Website considers the user's actions may blame it, other users, or service suppliers.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The Website believes the user submitted incorrect Registration Data, interfered with other Users or service administration, or violated its privacy policy.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Astrologers and other service providers agree that your website connection is limited to membership and that You operate alone. If a Service Provider violates these terms of usage and the service terms and conditions agreed upon during data registration, the Website may delete and deactivate their Profile.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>DELIVERY, CANCELLATION AND REFUND</h4>
                            <ul>
                                <li>
                                    <p>
                                        Astrologer-processed orders are nonrefundable. Site users must handle rushed or thoughtless orders without returns.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Final orders are nonrefundable. Contact customer care within an hour of payment to cancel successful orders. Optional website return policy.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Website processing errors, including Astrologer report generation, are nonrefundable. The User recognizes timescales are estimations and will try to fulfill them.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Astromind Counsel's audit team reviews refund-requested consultation chat/call recordings for case qualifying.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        All refunds go to the Astromind Counsel wallet.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Only specific situations are refunded.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The network caused chat/call pauses, weak signal, background noise, inaudible consultants, etc. during video/normal calls.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Inaccurate consultations are nonrefundable. Astromind Counsel cannot guarantee consultation accuracy.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>USER OBLIGATION</h4>
                            <ul>
                                <li>
                                    <p>
                                        Member Customers and Astrologers must observe Website privacy policies. User claims individuality, not the company. Private Web access.The website user terms and conditions prohibit posting or distributing false, misleading, defamatory, harmful, threatening, abusive, harassing, violating privacy, or promoting racism, hatred, or destruction. Content sharing requires legal or contractual consent.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Users must obey Indian and foreign laws. Personal use of the Website is allowed.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>DISCLAIMER / LIMITATION OF LIABILITY</h4>
                            <ul>
                                <li>
                                    <p>
                                        The User acknowledges that the website cannot legally provide full service. Interpretation affects website astrology consulting.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Astrologers online can only advise if you disclose mental, physical, and emotional state.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Unauthorized use of the user's account or account information on other services or materials is not the website's responsibility. Website data, services, are lacking. Website is not liable for delays, data, software, or graphic errors. Downloading and receiving Website content can harm computers and data. The Website is not liable for typographical errors that invalidate coupons. Website is not liable for errors.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        This Website and its suppliers disclaim all warranties, including merchantability, fitness for a particular purpose, title, non-infringement, results, accuracy, and reliability.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Advisors/consultants/astrologers are site members, not staff. This Website verifies advisors/consultants/astrologers' degrees, qualifications, credentials, and backgrounds but does not endorse, recommend, verify, evaluate, or guarantee their advice, information, or other services or the content's validity, accuracy, completeness, safety
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The Website is not responsible for data, information, or communication transmission or delivery errors, delays, omissions, non-performance, or interruptions. Website, payment service providers, employees, directors, and third-party agents processing, delivering, or managing services are not liable for punitive or exemplary damages. Actions make Astromind Counsel fully liable.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>INDEMNIFICATION</h4>
                            <p>
                                The Website and its parent, subsidiaries, affiliates, officers, directors, workers, suppliers, consultants, and agents shall be indemnified, defended, and held harmless by the User against any and all third-party claims, liability, damages, and/or costs (including attorney's fees) arising from Your use of the Services, Your violation of the Privacy Policy or these Terms of Service, or Your violation of any third party's rights, including these Terms of Service
                            </p>
                        </div>
                        <div>
                            <h4>PROPRIETARY RIGHTS TO CONTENT</h4>
                            <p>
                                Text, software, music, sound, photographs, video, graphics, and other material in sponsor advertisements or email, commercially produced information presented to Members by the Website, its suppliers, and Google photos, stories, graphs are protected by copyrights, trademarks, service marks, patents, and other proprietary rights and laws. Astromind Counsel Services Private Limited owns no data or material.
                            </p>
                        </div>
                        <div>
                            <h4>NOTICES</h4>
                            <p>
                                All notices to a party must be in writing and made via email or mail, unless otherwise stated in these Terms of Service. Notice is deemed given 24 hours after an email is delivered or 3 days after snail mail deposit to Member at the Registration Data address and to the Website at the address below:
                            </p>

                            <p>
                                <b>ASTROMIND COUNSEL PRIVATE LIMITED</b> <br />
                                RSF.NO.55/1, MAIN ROAD, OPP TO PARAMASIVAMPALAYAM, <br />
                                Pallapalayam, Tiruppur, Coimbatore- 641663, Tamil Nadu.
                            </p>
                        </div>
                        <div>
                            <h4>GOVERNING LAW AND JURISDICTION</h4>

                            <p>
                                Any dispute, claim, or controversy arising from these Terms of Usage or your use of the Application or its information will be resolved by a single Indian arbitrator designated by Members and Website. Arbitration follows the 1996 Act. Arbitration is in Coimbatore. Arbitration and awards will be in English.
                            </p>
                        </div>
                    </Row>
                </Container>

            </Container>
        </>
    )

}

export default TermsOfUse;