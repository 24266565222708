import React, { useContext } from "react";
import { useFormik } from 'formik';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from "antd";
import MultiStepFormContext from "./MultiStepFormContext";
import { Container, Row, Col } from 'react-bootstrap';
import IndianUser from '../../../Assets/PaymentImages/indian-user-scan-code-img.jpg'
import InternationalUser1 from '../../../Assets/PaymentImages/international-user-san-code-img1.jpg'
import InternationalUser2 from '../../../Assets/PaymentImages/international-user-san-code-img2.jpg'

const Details = () => {
  const { details, reportdetails, prev } = useContext(MultiStepFormContext);

  const [show, setShow] = useState(false);
  const [formShow, setFormShow] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isPayment, setIsPayment] = useState(false);


  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [window.location.search])

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const date = new Date(details.dateofbirth).toString().slice(3, 16);
      let BirthTime = `${details.birthhour} : ${details.birthminute} ${details.meridiem}`;
      console.log(BirthTime);
      let payment = isPayment ? "yes" : "none";
      console.log("detail", details, "report", reportdetails, "date", date, "birthtime", BirthTime);
      setLoader(true);

      const formData = new FormData()

      formData.append('name', details.name)
      formData.append('email', details.email)
      formData.append('phone', details.phone)
      formData.append('gender', details.gender)
      formData.append('dateofbirth', date)
      formData.append('placeofbirth', details.placeofbirth)
      formData.append('timeofbirth', BirthTime)
      formData.append('maritalstatus', details.maritalstatus)
      formData.append('preferedlanguage', details.preferredlanguage)
      formData.append('referral', details.reference)
      formData.append('messagefield', details.message)
      formData.append('report_name', reportdetails.report_name)
      formData.append('days', reportdetails.report_delivery)
      formData.append('payment', payment)

      formData.append('file', values.file)

      const Headers = {
        'Accept': 'application/json',
        "Content-type": "application/json; charset=UTF-8"
      }

      try {
        if (payment === "yes" || values.file) {
          await axios.post(`https://prod-astromind-api.astromindcounsel.com/astro-report`,
            formData, Headers)
            .then((res) => {
              toast.success("Thanks! Your report request has been submitted successfully.")
              setLoader(false)
              setFormShow(false)
            })
        }
        else {
          toast.error("please send the receipt of payment")
          setLoader(false)
        }

      } catch (err) {
        toast.error(err.response.data.error.message, {
          position: toast.POSITION.CENTER_CENTER
        })
        setLoader(false);

      }

    },
    validate: (values) => {
      const errors = {};

      // if (!values.file) {
      //   errors.file = "Required*";
      // }
      console.log("errors", errors);
      return errors;
    }

  })
  return (
    <>
      {formShow ?
        <>
          <Col lg={12} className='d-flex mt-5 mb-5 QrScanner'>
            <Col lg={6} className='IndianUser d-flex flex-column align-items-center'>
              <div>
                <p>Indian Customers Scan & Pay using the below code</p>
              </div>
              <div className='qrcodeImg'>
                <img src={IndianUser} alt="Qr-code" />
              </div>
              <div className="d-flex flex-column text-center">
                <p>(or)</p>
                <h5 style={{fontFamily:"sans-serif"}}>9944234567</h5>
              </div>
            </Col>
            <Col lg={6} className='InternationalUser d-flex flex-column align-items-center'>
              <div>
                <p>International Customers (Outside India) Scan & Pay using the below code</p>
              </div>
              <div className='d-flex gap-5 In-qrCode'>
                <div className='qrcodeImg'>
                  <img src={InternationalUser1} alt="Qr-code" />
                </div>
                <div className='qrcodeImg'>
                  <img src={InternationalUser2} alt="Qr-code" />
                </div>

              </div>
              
            </Col>
          </Col>
          <Col lg={12} className='d-flex flex-column justify-content-center align-items-center mt-5 mb-3'>
            <div>
              <p>Once the payment is completed, please send the receipt of payment.</p>
            </div>
            <div className='attach-btn'>
              <button onClick={() => { setShow(true) }} className='bg-lightBlue'>Attach Your Receipt</button>
            </div>
          </Col>
          <Row className='d-flex justify-content-center'>
            {show ?
              <Col lg={8} className=''>
                <div className='mb-4 text-center'>
                  <h4 className='text-orange'>Submit Your Receipt</h4>
                </div>
                <form onSubmit={formik.handleSubmit} action="">
                  <div className='d-flex justify-content-between mb-4 Form-name'>
                    <div className='d-flex flex-column align-items-center gap-3 Form-full'>
                      <div className='d-flex justify-content-between'>
                        {/* <label >Attach Your Receipt</label> */}
                        <p className='error'>{formik.errors.file}</p>
                      </div>
                      <div className="file-upload">
                        <input type="file" name='file' onChange={(e) => {
                          formik.setFieldValue('file', e.target.files[0])
                        }} />
                      </div>

                    </div>
                  </div>


                  {/* <div className='d-flex justify-content-center mt-4 submit-btn'>
            <button className='bg-blue text-white'>Send</button>
          </div> */}
                </form>
              </Col>
              : null}
          </Row>
          <Col lg={12} className="d-flex justify-content-center gap-2 mb-5">
            <input type="checkbox" value={isPayment} onChange={() => { setIsPayment(!isPayment) }} />
            <label>Have you done with payment?</label>
          </Col>
          <div className="d-flex justify-content-center gap-3">
            <Button type={"default"} onClick={prev}>
              Back
            </Button>
            <Button type={"primary"} onClick={formik.handleSubmit} >
              {loader ? <div className='report-loader'></div> : "Confirm"}
            </Button>
          </div>
        </>
        :
        <Container fluid className='mt-5 mb-5'>
          <Container>
            <Row className='d=flex justify-content-center'>
              <Col lg={10} className='p-5'>
                <p style={{ fontSize: '20px', textAlign: 'center' }}>Thank you for your submission. your report will sent to your Email within {reportdetails.report_delivery}. Our team will get back to you shortly. If you need any assistance please drop us an email to <a href='mailto: astromindcounsel@gmail.com' >astromindcounsel@gmail.com</a></p>
              </Col>
            </Row>
          </Container>
        </Container>}

    </>
  );
};
export default Details;
