import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function MidlifecrisisBanner() {
    return (
      <>
       <Container fluid className='children-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>Mid Life Crisis</h1>
                     <p>The combination of astrology and psychology can provide support and insights for individuals experiencing a mid-life crisis. Here's how this field can help with mid-life crisis</p>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default MidlifecrisisBanner;
  