import { useEffect } from 'react';

import AstroLogo from '../../Assets/HomeImages/Astro-logo.svg';

import { useSelector } from 'react-redux';

function BrowserNotification({ socket }) {
    const { User } = useSelector((state) => state.Astromind);

    function requestNotificationPermission() {
        if ("Notification" in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    console.log("Notification permission granted.");
                } else {
                    console.log("Notification permission denied.");
                }
            });
        } else {
            console.log("Browser does not support notifications.");
        }
    }

    useEffect(() => {
        requestNotificationPermission();
    }, []);

    useEffect(() => {
        // const showNotification = (title, description, isToNew, isToOld, redirectUrl) => {
        //     console.log("title : ", title);
        //     console.log("description : ", description);
        //     console.log("isToNew : ", isToNew);
        //     console.log("isToOld : ", isToOld);
        //     addNotification({
        //         title: title,
        //         message: description,
        //         theme: 'darkblue',
        //         native: true, // when using native, your OS will handle theming.
        //         duration: 5000,
        //         icon: AstroLogo,
        //         onClick: () => window.location = redirectUrl
        //     });
        // };
        const showNotification = (title, description, isToNew, isToOld, redirectUrl, userId) => {
            console.log(title);
            console.log(description);
            console.log(isToNew);
            console.log(isToOld);
            console.log(redirectUrl);
            console.log(userId);
            console.log(`${process.env.REACT_APP_URL}${redirectUrl}`);

            console.log(User);
            if (User?.data?.data?.user?.id === userId) {
                if ("serviceWorker" in navigator && Notification.permission === "granted") {
                    console.log("granted");
                    console.log("serviceWorker" in navigator);

                    console.log(navigator.serviceWorker.ready);

                    navigator.serviceWorker.ready
                        .then((registration) => {
                            console.log(registration);

                            registration.showNotification(title, {
                                // body: "This is a sample notification.",
                                // vibrate: [100, 50, 100],
                                // tag: "sample-notification",
                                title: title,
                                body: description,
                                theme: 'darkblue',
                                native: true, // when using native, your OS will handle theming.
                                duration: 5000,
                                icon: AstroLogo,
                                // onClick: () => window.location = redirectUrl,
                                data: {
                                    url: `${process.env.REACT_APP_URL}${redirectUrl}`  // Pass the redirect URL here
                                },
                                renotify: false,
                                // Other options
                            });
                        })
                        .catch(err => {
                            console.log(err);
                        })

                    // Optional: Handle click event
                } else {
                    console.log("Notifications are not allowed or supported.");
                }
            }
            else {
                console.log("User not found");

            }


        };
        socket.on('send-notify-to-user', showNotification);

        return () => {
            socket.off('send-notify-to-user', showNotification);
        };
    });

    return (
        <>
            <div>
            </div>
        </>
    )
}

export default BrowserNotification;