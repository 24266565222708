import { Container, Row, Col } from 'react-bootstrap';


import BlogContentImg1 from '../../../Assets/BlogdetailImages/jathagam-banner-img.png'
import BlogContentImg2 from '../../../Assets/BlogdetailImages/blog-detail-img-2.png'


function BlogdetailContent({ blog }) {

   console.log(blog?.blogdescription);
  const data =  blog?.blogdescription;
    return (
        <>
            <Container fluid className='blogContent-main'>
                <Row>
                    <Col lg={12}>
                        <div className='blog-description' dangerouslySetInnerHTML={{__html: data}} />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default BlogdetailContent;