import React, { useEffect } from 'react';
import Logo from '../../Assets/HomeImages/Astro-logo.svg';

function ChromeNotification() {
  const options = {
    body: "This is a sample notification.",
    vibrate: [100, 50, 100], // Vibration pattern (for mobile devices)
    tag: "sample-notification", // Tag to prevent multiple notifications of the same type
  };

  function requestNotificationPermission() {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Notification permission denied.");
        }
      });
    } else {
      console.log("Browser does not support notifications.");
    }
  }

  function showNotification() {
    console.log("test");

    if ("serviceWorker" in navigator && Notification.permission === "granted") {
      console.log("granted");
      console.log("serviceWorker" in navigator);

      // if (window.screen.width < 550) {
        console.log(navigator.serviceWorker.ready);

        navigator.serviceWorker.ready
          .then((registration) => {
            console.log(registration);

            registration.showNotification("Sample Notification", {
              body: "This is a sample notification.",
              vibrate: [100, 50, 100],
              tag: "sample-notification",
              renotify: false,
              // Other options
            });
            // registration.showNotification('Second Notification', {
            //   body: 'This is the second notification.',
            //   vibrate: [100, 50, 100],
            //   tag: 'second-notification', // Unique tag
            //   renotify: false, // Don't overwrite previous notifications
            // });
          })
          .catch(err => {
            console.log(err);
          })
      // }
      // else {
      //   const notification = new Notification('Sample Notification', options);
      //   // Optional: Handle click event
      //   notification.onclick = () => {
      //     window.open('https://google.com'); // Open a URL on click
      //   };

      // }


      // Optional: Handle click event
    } else {
      console.log("Notifications are not allowed or supported.");
    }
  }

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  const handleShowNotification = () => {
    showNotification();
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <div>
          <button onClick={handleShowNotification}>Show Notification</button>
        </div>
      </header>
    </div>
  );
}
export default ChromeNotification;