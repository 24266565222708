import '../../Styles/home.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import TimeIcon from '../../Assets/HomeImages/service-time-icon.svg'
import LoveIcon from '../../Assets/HomeImages/service-love-icon.svg'
import MarriageIcon from '../../Assets/HomeImages/service-marriage-icon.svg'
import AnalysisIcon from '../../Assets/HomeImages/service-analysis-icon.svg'
import DivorceIcon from '../../Assets/HomeImages/service-divorce-icon.svg'
import DepressionIcon from '../../Assets/HomeImages/service-depression-icon.svg'
import AddictionIcon from '../../Assets/HomeImages/service-addiction-icon.svg'
import SelfanalysisIcon from '../../Assets/HomeImages/service-self-analysis-icon.svg'
import IamIcon from '../../Assets/HomeImages/service-what-iam-icon.svg'
import EducationIcon from '../../Assets/HomeImages/service-education-icon.svg'
import CareerIcon from '../../Assets/HomeImages/service-career-icon.svg'
import BussinessIcon from '../../Assets/HomeImages/service-business-icon.svg'
import DebtIcon from '../../Assets/HomeImages/service-debt-icon.svg'
import HealthIcon from '../../Assets/HomeImages/service-health-icon.svg'


function HomeService() {
    const navigate = useNavigate();
    return (
      <>
       <Container fluid className="bg-blue pt-5 pb-5 ourService-main">
            <Container >
                <Row>
                    <Col lg={12} className="d-flex justify-content-center pb-4">
                        <h3 className="text-orange">Our Services</h3>
                    </Col>
                    <Col lg={12} className="d-flex gap-5 justify-content-center service-cards">
                        <Col lg={3} sm={6} className="bg-white text-blue service-card">
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}} >
                                <img src={TimeIcon} alt="" />
                                <p className="font-700">My future</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={LoveIcon} alt="" />
                                <p className="font-700">Love and Soulmate</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={MarriageIcon} alt="" />
                                <p className="font-700">Marriage</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={AnalysisIcon} alt="" />
                                <p className="font-700">Character Analysis</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={DivorceIcon} alt="" />
                                <p className="font-700">Divorce Issues</p>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} className="bg-white text-blue service-card">
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={DepressionIcon} alt="" />
                                <p className="font-700">Depression</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={AddictionIcon} alt="" />
                                <p className="font-700">Addiction</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={SelfanalysisIcon} alt="" />
                                <p className="font-700">Self Analysis</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={IamIcon} alt="" />
                                <p className="font-700">What I am Really ?</p>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} className="bg-white text-blue service-card">
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={EducationIcon} alt="" />
                                <p className="font-700">Education</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={CareerIcon} alt="" />
                                <p className="font-700">Career</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={BussinessIcon} alt="" />
                                <p className="font-700">Business</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={DebtIcon} alt="" />
                                <p className="font-700">Debt</p>
                            </div>
                            <div className="d-flex gap-3 card-list" onClick={() => { navigate('/talk-to-astrologer/')}}>
                                <img src={HealthIcon} alt="" />
                                <p className="font-700">Health</p>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </Container>
      </>
    );
  }
  
  export default HomeService;