

function BirthChart({ chart, user }) {

    const normalizeString = (str) => {
        return str.normalize("NFC");
    };

    const findExactName = (name) => {
        const isSun = normalizeString("சூரியன்");
        const isMoon = normalizeString("சந்திரன் ");
        const isTus = normalizeString("செவ்வாய் ");
        const isWed = normalizeString("புதன், ");
        const isThus = normalizeString("வியாழன் ");
        const isFri = normalizeString("வெள்ளி ");
        const isSat = normalizeString("சனி ");
        const isRagu = normalizeString("ராகு");
        const isKedu = normalizeString("கேட்டு");
        const isLak = normalizeString("களத்திரஸ்தானம்");

        const testPlanet = normalizeString(name);

        if (testPlanet === isMoon) {
            return "சந்";
        }
        else if (testPlanet === isSat) {
            return "சனி";
        }
        else if (testPlanet === isSun) {
            return "சூ";
        }
        else if (testPlanet === isTus) {
            return "செ";
        }
        else if (testPlanet === isWed) {
            return "பு";
        }
        else if (testPlanet === isThus) {
            return "கு";
        }
        else if (testPlanet === isFri) {
            return "சு";
        }
        else if (testPlanet === isRagu) {
            return "ரா";
        }
        else if (testPlanet === isKedu) {
            return "கே";
        }
        else if (testPlanet === isLak) {
            return "ல";
        }
    }
    return (
        <>
            <div className="box-chart">
                <div className="box1">
                  <h5>{user?.Dob}</h5>
                  <h6>{user?.Hour}:{user?.Min}:00</h6>
                  <h5>Rasi</h5>
                  <h6>{chart?.astro_details?.moon_sign}</h6>
                </div>
                <div className="box2 p-2">
                    <p className='box-number'>12</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 330 && planets.full_degree <= 360 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 330 && planets.full_degree <= 360 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                
                <div className="box3 p-2">
                    <p className='box-number'>1</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName' >
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree < 30 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree <= 30 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box4 p-2">
                    <p className='box-number'>2</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <>
                                        <p className={planets.full_degree >= 30 && planets.full_degree < 60 ?
                                            `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                                planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                    planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                        planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                            planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                        >
                                            {planets.full_degree >= 30 && planets.full_degree < 60 ?
                                                <div className='d-flex gap-1'>
                                                    <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                    <p>{planetShortName ? planetShortName : null}</p>
                                                    <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                                </div>
                                                :
                                                null}
                                        </p>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box5 p-2">
                    <p className='box-number'>3</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 60 && planets.full_degree < 90 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 60 && planets.full_degree < 90 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box6 p-2">
                    <p className='box-number'>4</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 90 && planets.full_degree < 120 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 90 && planets.full_degree < 120 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box7 p-2">
                    <p className='box-number'>5</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 120 && planets.full_degree < 150 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 120 && planets.full_degree < 150 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box8 p-2">
                    <p className='box-number'>6</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 150 && planets.full_degree < 180 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 150 && planets.full_degree < 180 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box9 p-2">
                    <p className='box-number'>7</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 180 && planets.full_degree < 210 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 180 && planets.full_degree < 210 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box10 p-2">
                    <p className='box-number'>8</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 210 && planets.full_degree < 240 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 210 && planets.full_degree < 240 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box11 p-2">
                    <p className='box-number'>9</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 240 && planets.full_degree < 270 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 240 && planets.full_degree < 270 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box12 p-2">
                    <p className='box-number'>10</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 270 && planets.full_degree < 300 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 270 && planets.full_degree < 300 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="box13 p-2">
                    <p className='box-number'>11</p>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='planetName'>
                            {chart?.planets_position?.map((planets, index) => {
                                let planetShortName = findExactName(planets.name);
                                return (
                                    <p className={planets.full_degree >= 300 && planets.full_degree < 330 ?
                                        `show ${planetShortName == 'சந்' || planetShortName == 'ல' ? 'chart-blue' :
                                            planetShortName == 'சனி' || planetShortName == 'கே' ? 'chart-darkBlue' :
                                                planetShortName == 'சூ' || planetShortName == 'ரா' ? 'chart-red' :
                                                    planetShortName == 'செ' || planetShortName == 'சு' ? 'chart-green' :
                                                        planetShortName == 'பு' || planetShortName == 'கு' ? 'chart-black' : null}` : 'hide'}
                                    >
                                        {planets.full_degree >= 300 && planets.full_degree < 330 ?
                                            <div className='d-flex gap-1'>
                                                <p className='degree'>{planets.sign_degree.split(":")[0]} <span>&#176;</span>{planets.sign_degree.split(":")[1]}<span>`</span></p>
                                                <p>{planetShortName ? planetShortName : null}</p>
                                                <p>{planets.is_retro === "true" ? "(வ)" : null}</p>
                                            </div>
                                            :
                                            null}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                </div>
              </div>
        </>
    )

}

export default BirthChart;