import { Helmet } from 'react-helmet';
import AboutBanner from "./AboutBaner";
import AboutWhychoose from "./AboutWhychoose";
import AboutTeam from "./AboutTeam";
import MissionVision from './MissionVision';

function About() {
  return (
    <>
      <Helmet>
        <title>Discover Your Destiny with Our Online Tamil Jathagam Service</title>
        <meta name="description" data-rh="true" content="Discover your destiny with precision and authenticity, integrating past, present, and future. Your own cosmic story awaits at Online Tamil Jathagam." />
        <meta name="keywords" data-rh="true" content="tamil jathagam online, tamil jathagam, tamil jathagam service" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/about/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/about/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/about/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/about/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Discover Your Destiny with Our Online Tamil Jathagam Service" />
        <meta property="og:description" content="Discover your destiny with precision and authenticity, integrating past, present, and future. Your own cosmic story awaits at Online Tamil Jathagam." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Discover Your Destiny with Our Online Tamil Jathagam Service" />
        <meta name="twitter:description" content="Discover your destiny with precision and authenticity, integrating past, present, and future. Your own cosmic story awaits at Online Tamil Jathagam." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

      <AboutBanner />
      <AboutTeam />
      <AboutWhychoose />
      <MissionVision />
    </>
  );
}

export default About;
