import { Helmet } from 'react-helmet';

import FaqsBanner from "./FaqsBanner";
import FaqsContent from "./FaqsContent";

function Faqs() {
  return (
    <>
      <Helmet>
        <title>Astromind Counsel's FAQs | Your Astrology Questions Answered</title>
        <meta name="description" data-rh="true" content="Find answers to your most common astrology questions with Astromind Counsel's FAQ section. Discover insights on astrological readings, services, and more." />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/faqs/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/faqs/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Astromind Counsel's FAQs | Your Astrology Questions Answered" />
        <meta property="og:description" content="Find answers to your most common astrology questions with Astromind Counsel's FAQ section. Discover insights on astrological readings, services, and more." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Astromind Counsel's FAQs | Your Astrology Questions Answered" />
        <meta name="twitter:description" content="Find answers to your most common astrology questions with Astromind Counsel's FAQ section. Discover insights on astrological readings, services, and more." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>
      <FaqsBanner />
      <FaqsContent />
    </>
  );
}

export default Faqs;
