import '../../../Styles/login.css';
import React, { useState } from 'react';
import PhoneInput from "react-phone-input-2";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { TiArrowRightThick } from "react-icons/ti";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LoginScreen, SendOtp } from '../../../Redux/Slice';

function PhoneNumber({ Number }) {
    const dispatch = useDispatch();
    const { OpenStatus, Loading } = useSelector((state) => state.Astromind);

    const [loginType, setLoginType] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setEmailAddress(e.target.value);
        setPhoneNumber('');
    }

    const handlePhoneSubmit = (e) => {
        e.preventDefault();
        if (!phoneNumber && !emailAddress) {
            setError('required*');
        } 
        else if (phoneNumber) {
            setError('');
            var phone = {
                phone: phoneNumber,
                role: "user"
            }
            Number(phone);
            dispatch(SendOtp(phone));
        }
        else if (emailAddress) {
            setError('');
            var email = {
                email: emailAddress,
                role: "user"
            }
            Number(email);
            dispatch(SendOtp(email));
        }
    };

    const loginClose = () => {
        dispatch(LoginScreen({ OpenStatus: false }));
    }

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={OpenStatus}
                onHide={loginClose}
                className='login-modal'
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='login-screen'>
                        <form action="">
                            <div className='text-center pt-2 pb-3'>
                                <h1>Login</h1>
                            </div>
                            {
                                loginType ?
                                    <>
                                        <p>Enter Your Email Id </p>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className='emailInput'
                                            placeholder='Enter your Emaill Address'
                                            value={emailAddress}
                                            onChange={handleChange}
                                        />
                                        <p className='error'>{error}</p>
                                        <p className='text-center pt-3 pb-3'>You will receive a 6 digit verification code to Registered Email ID</p>
                                    </>
                                    :
                                    <>
                                        <p>Enter Your Phone Number </p>
                                        <PhoneInput
                                            country={'in'}
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            value={phoneNumber}
                                            onChange={(value) => { setPhoneNumber(value); setError(''); }}
                                        />
                                        <p className='error'>{error}</p>
                                        <p className='text-center pt-3 pb-3'>You will receive a 6 digit verification code to Registered Mobile Number</p>

                                    </>
                            }    
                                        <div className='d-flex justify-content-center'>
                                            <Button 
                                            className={phoneNumber || emailAddress ? 'otp-button bg-orange' : 'otp-button bg-orange disabled'} 
                                            onClick={(e) => { handlePhoneSubmit(e) }} 
                                            type='submit'
                                            > 
                                            {Loading ? <div className='loader'></div> : <>Get OTP <TiArrowRightThick /></>}
                                            </Button>
                                        </div>
                                        <div className="login-another mt-3">
                                            <p>(OR)</p>
                                            <p 
                                            className='account' 
                                            onClick={() => { setLoginType(!loginType) }}
                                            >
                                                {loginType ? "Continue with Phone Number" : "Continue with Email ID "}
                                                </p>
                                        </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PhoneNumber;