import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';

import '../../Styles/blog.css'
import { Container, Row, Col } from 'react-bootstrap';

import newBlogLeftImg from '../../Assets/ServiceImages/service-card-left-img.svg'
import newBlogRightImg from '../../Assets/ServiceImages/service-card-right-img.svg'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetAllBlogs } from '../../Redux/Slice';

function NewBlog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Blogs, Loading, success, errors } = useSelector((state) => state.Astromind);

  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = window.innerWidth < 600 ? 10 : 15;

  useEffect(() => {
    dispatch(GetAllBlogs({ page: 1, pagelimit: pageLimit }));
  }, []);

  console.log(Blogs);

  const handleViewBlog = (slug) => {
    navigate(`/blogs/${slug}/`)
  }

  const changePage = (value) => {
    window.scrollTo(0, 0);
    dispatch(GetAllBlogs({ page: value, pagelimit: pageLimit }));
  }

  const nextPage = () => {
    const next = Number(currentPage) + 1;
    if (next <= Blogs?.data?.data?.pagination?.totalPage) {
      window.scrollTo(0, 0);
      dispatch(GetAllBlogs({ page: next, pagelimit: pageLimit }));
    }
  }

  const previousPage = () => {
    const previousPage = Number(currentPage) - 1;
    if (previousPage >= 1) {
      window.scrollTo(0, 0);
      dispatch(GetAllBlogs({ page: previousPage, pagelimit: pageLimit }));
    }
  }

  return (
    <>
      <Container fluid className='pt-5 pb-5'>
        <div className="newblog-left-img">
          <img src={newBlogLeftImg} alt="" />
        </div>
        <Container>
          <Row className='pb-3'>
            <Col lg={12}>
              <h3>New Blog </h3>
            </Col>
          </Row>
          <Row>
            {Loading ?
              <>
                <div class="wrapper">
                  <div class="page-loader">
                    <div class="loading one"></div>
                    <div class="loading two"></div>
                    <div class="loading three"></div>
                    <div class="loading four"></div>
                  </div>
                </div>
              </>
              :
              <>
                {
                  Blogs?.data?.data?.blogs?.length > 1 ?
                    <Col lg={12} className='d-flex flex-wrap gap-3 newBlog-cards'>
                      {
                        Blogs?.data?.data?.blogs?.map((blog, index) => {
                          let publishDate = new Date(blog?.publishedOn).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });

                          return (
                            <>
                              <Col lg={6} sm={12} className='newBlog-card' onClick={() => handleViewBlog(blog?.slug)}>
                                <Col lg={5} sm={5} className='newBlog-img' target='_blank'>
                                  {/* <a href={blog?.slug} target='_blank'> */}
                                  <img src={blog?.bannerimage} alt="" />
                                  {/* </a> */}
                                </Col>
                                <Col lg={7} sm={7} className='bg-white newBlog-content'>
                                  <div className='d-flex justify-content-end'>
                                    <p className='publish'>Published :{publishDate}</p>
                                  </div>
                                  <p className='title'>{blog?.bannertitle}</p>
                                  <p className='description'>{blog?.bannerdescription}</p>
                                  <p className='view' onClick={() => handleViewBlog(blog?.slug)}>view more</p>
                                </Col>
                              </Col>
                            </>
                          )
                        })
                      }
                    </Col>
                    :
                    <div className='no-data pt-5'>
                      <p>No sufficient data available!</p>
                    </div>
                }
              </>
            }
            {
              Blogs?.data?.data?.pagination?.totalPage > 1 ?
                <Col lg={12} className='d-flex justify-content-center mt-2 pagination'>
                  <Pagination className="px-4">
                    <Pagination.Prev onClick={() => previousPage()} />

                    {[...new Array(Blogs?.data?.data?.pagination?.totalPage)].map((arr, index) => {
                      return (
                        <Pagination.Item
                          onClick={() => changePage(index + 1)}
                          key={index + 1}
                          active={index + 1 === Number(currentPage)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      )
                    })}
                    <Pagination.Next onClick={() => nextPage()} />
                  </Pagination>
                </Col>
                :
                null
            }
          </Row>
        </Container>
        <div className="newblog-right-img">
          <img src={newBlogRightImg} alt="" />
        </div>
      </Container>
    </>
  );
}

export default NewBlog;
