import { Helmet } from 'react-helmet';

import HomeBanner from "./HomeBanner";
import HomeAstrologer from "./HomeAstrologer";
import HomeService from "./HomeService";
import HomeBlog from "./HomeBlog";
import HomeKundli from "./HomeKundli";
import HomeCard from "./HomeCard";
import HomeCta from "./HomeCta";
import HomeCard2 from "./HomeCard2";
import HomeFaqs from './HomeFaqs';
import HomeGuideVideo from './HomeGuideVideo';
import HomeTestimonial from './HomeTestimonial';

import AstrologerCta from '../CommonPages/AstrologerCta';

function Home() {
  return (
    <>
      <div>
        <Helmet >
          <title>Best Online Astrology & Counseling Services By Astromind Counsel</title>
          <meta name="description" data-rh="true" content="Astromind Counsel - One of the Best Online Tamil Jathagam Services by Expert Astrologers. Explore with our Astrologers to Know yourself and your Destiny!!" />
          <meta name="keywords" data-rh="true" content="online tamil jathagam, online tamil astrologer, jathagam online, jathagam tamil,  online jathagam, tamil jathagam, tamil jothidam, tamil josiyam, online astrologer, tamil horoscope, horoscope by date of birth, tamil jathagam online" />
          <meta name="language" content="en" />
          <meta name="revisit-after" content="7 days" />
          <meta name="Expires" content="never" />
          <meta name="Distribution" content="Global" />
          <meta name="Rating" content="general" />
          <meta name="search engines" content="ALL" />
          <meta name="copyright" content="https://www.astromindcounsel.com/" />
          <meta name="email" content="astromindcounsel@gmail.com" />
          <link rel="canonical" href="https://www.astromindcounsel.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.astromindcounsel.com/" />
          <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
          <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
          <meta property="og:title" content="Best Online Astrology & Counseling Services By Astromind Counsel" data-rh="true" />
          <meta property="og:description" content="Astromind Counsel - One of the Best Online Tamil Jathagam Services by Expert Astrologers. Explore with our Astrologers to Know yourself and your Destiny!!" data-rh="true" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Best Online Astrology & Counseling Services By Astromind Counsel" data-rh="true" />
          <meta name="twitter:description" content="Astromind Counsel - One of the Best Online Tamil Jathagam Services by Expert Astrologers. Explore with our Astrologers to Know yourself and your Destiny!!" data-rh="true" />
          <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        </Helmet>
        <HomeBanner />
        <HomeAstrologer />
        <HomeFaqs />
        <HomeGuideVideo />
        <HomeKundli />
        <HomeTestimonial />
        <HomeBlog />
        <HomeService />
        <HomeCard />
        <HomeCta />
        <HomeCard2 />

        <AstrologerCta />
      </div>
    </>
  );
}

export default Home;
