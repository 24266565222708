import { Container, Row, Col } from 'react-bootstrap';
// import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';

import OrderHistoryDetails from './OrderHistoryDetail';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetOrderHistory, LoginScreen } from '../../../Redux/Slice';

function OrderHistoryList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { OrderHistory, Loading } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const [type, setType] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const PreviewCall = (data) => {
        setPreviewData(data);
        handleShow();
    }

    useEffect(() => {
        if (!token) {
            navigate(`/`);
            dispatch(LoginScreen({ OpenStatus: true }))
        }

        let page = currentPage ? currentPage : 1;
        if (pathname === "/order-history/call-logs/") {
            setType("call");
            dispatch(GetOrderHistory({ token, page, type: "call" }))
        }

        else if (pathname === "/order-history/chat-logs/") {
            setType("chat");
            dispatch(GetOrderHistory({ token, page, type: "chat" }))
        }
    }, [dispatch, navigate, token, pathname, type]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(OrderHistory?.data?.pagination?.currentpage);
    }, [OrderHistory]);

    // const deleteData = () => {
    //     Swal.fire({
    //         title: "Are you sure want to Delete your payment data?",
    //         icon: "delete",
    //         iconColor: "#CA0505",
    //         showCancelButton: true,
    //         cancelButtonColor: "#025BFD",
    //         confirmButtonColor: "#CA0505",
    //         confirmButtonText: "Delete All"
    //     });
    // }

    const changePage = (value) => {
        let page = value;
        dispatch(GetOrderHistory({ token, page, type }));
    }

    const nextPage = () => {
        const page = Number(currentPage) + 1;
        if (page <= OrderHistory?.data?.pagination?.totalPage) {
            dispatch(GetOrderHistory({ token, page, type }));
        }
    }

    const previousPage = () => {
        const page = Number(currentPage) - 1;
        if (page >= 1) {
            dispatch(GetOrderHistory({ token, page, type }));
        }
    }

    return (
        <>
            <OrderHistoryDetails
                show={show}
                handleClose={handleClose}
                data={previewData}
            />

            <Container className='pt-100 order-history'>
                <Row>
                    <Col lg={12} className='d-flex justify-content-between mt-5 mb-3'>
                        <Col lg={4}></Col>
                        <Col lg={4} className='mb-3'>
                            <h3 className='text-center'>Order History</h3>
                        </Col>
                        <Col lg={4} className='list-type-btn'>
                            <button onClick={() => navigate('/order-history/call-logs/')}>Call</button>
                            <button onClick={() => navigate('/order-history/chat-logs/')}>Chat</button>
                        </Col>
                    </Col>
                    <Col lg={12} >
                        {Loading ?
                            <>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='transaction-table'>
                                    {OrderHistory?.data?.data?.length > 0 ?
                                        <>
                                            <table>
                                                <tr className='orderHistory-heading'>
                                                    <th>Date & Time</th>
                                                    <th>Order Id</th>
                                                    <th>Astrologer Id</th>
                                                    <th>Duration</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>

                                                {OrderHistory?.data?.data?.map((data, i) => {
                                                    let time = data?.order?.calls?.length > 0 ?
                                                        data?.order?.calls[0]?.EndTime
                                                        : data?.order?.createdAt;

                                                    let indianTime = new Date(time).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });

                                                    let Minute = data?.order?.type === "call" ?
                                                        Math.floor(data?.order?.calls[0]?.ConversationDuration / 60)
                                                        : data?.order?.type === "chat" ?
                                                            Math.floor(data?.chat[0]?.duration / 60)
                                                            : 0;

                                                    let seconds = data?.order?.type === "call" ?
                                                        data?.order?.calls[0]?.ConversationDuration % 60
                                                        : data?.order?.type === "chat" ?
                                                            Math.floor(data?.chat[0]?.duration % 60)
                                                            : 0;

                                                    return (
                                                        <>
                                                            <tr className='transaction-data'>
                                                                <td>{indianTime}</td>
                                                                <td>{data?.order?.id}</td>
                                                                <td > {data?.order?.astrologerId}</td>
                                                                <td >
                                                                    {
                                                                        data?.order?.calls?.length > 0 ?
                                                                            <>{`${Minute} min ${seconds} sec`}</>
                                                                            : (data?.chat?.length > 0 ? data?.chat[0]?.status === "completed" : false) ?
                                                                                <>{`${Minute} min ${seconds} sec`}</>
                                                                                : "none"
                                                                    }
                                                                </td>
                                                                {data?.order?.type === "call" ?
                                                                    <td className={
                                                                        data?.order?.calls[0]?.Status ?
                                                                            data?.order?.calls[0]?.Status === "no-answer" || data?.order?.calls[0]?.Status === "busy" ?
                                                                                "text-orange" :
                                                                                "payment-success" :
                                                                            "payment-failed"
                                                                    }>
                                                                        {data?.order?.calls[0]?.Status ? data?.order?.calls[0]?.Status : "Canceled"}
                                                                    </td>
                                                                    : data?.order?.type === "chat" ?
                                                                        <td className={
                                                                            data?.chat[0]?.status === "completed" ?
                                                                                "payment-success" :
                                                                                "payment-failed"
                                                                        }>
                                                                            {data?.chat[0]?.status === "completed" ? data?.chat[0]?.status : "Canceled"}
                                                                        </td>
                                                                        : null
                                                                }
                                                                <td className='view' onClick={() => PreviewCall(data)}>View Details</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </table>
                                        </>
                                        :
                                        <>
                                            <div className='no-data'>
                                                <p>No sufficient data available!</p>
                                            </div>
                                        </>
                                    }
                                </div>
                                {OrderHistory?.data?.pagination?.totalPage > 1 ?
                                    <Col lg={12} className='d-flex justify-content-center'>
                                        <Pagination className="px-4">
                                            <Pagination.Prev onClick={() => previousPage()} />

                                            {[...new Array(OrderHistory?.data?.pagination?.totalPage)].map((arr, index) => {
                                                return (
                                                    <Pagination.Item
                                                        onClick={() => changePage(index + 1)}
                                                        key={index + 1}
                                                        active={index + 1 === Number(currentPage)}
                                                    >
                                                        {index + 1}
                                                    </Pagination.Item>
                                                )
                                            })}
                                            <Pagination.Next onClick={() => nextPage()} />
                                        </Pagination>
                                    </Col>
                                    : null
                                }
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default OrderHistoryList;