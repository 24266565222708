import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UpiImg from '../../../Assets/Version2Images/upi-img.svg';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { LoginScreen } from '../../../Redux/Slice';

import PaymentLogs from './PaymentLogs';
import Transactions from './WalletTransactions';

function MyWallet() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { User } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    const [transactionhistory, setTransactionhistory] = useState(false);
    const [paymentlogshistory, setPaymentlogshistory] = useState(false);

    const transactionSelect = () => {
        setTransactionhistory(true)
        setPaymentlogshistory(false)
    }
    const paymentlogSelect = () => {
        setTransactionhistory(false)
        setPaymentlogshistory(true)
    }
    const walletSelect = () => {
        setTransactionhistory(false)
        setPaymentlogshistory(false)
    }

    useEffect(() => {
        if (!token) {
            navigate(`/`);
            dispatch(LoginScreen({ OpenStatus: true }))
        }
    }, [token, dispatch, navigate]);

    return (
        <>
            <Container fluid className='pt-150 mb-5'>
                <Container>
                {/* <MainPagetitle pageTitle="My Wallet" parentTitle="Home" /> */}

                    <Row>
                        <Col lg={12} className='transaction-nav'>
                            <div className='d-flex align-items-center mx-2' onClick={() => { walletSelect() }}>
                                <h3>Transactions</h3>
                                <img src={UpiImg} alt="" className='transaction-icon' />
                            </div>
                            <div className='wallet-btn d-flex gap-3 mx-2'>
                                <button className={transactionhistory ? 'active' : null} onClick={() => { transactionSelect() }}>Wallet Transactions</button>
                                <button className={paymentlogshistory ? 'active' : null} onClick={() => { paymentlogSelect() }}>Payment Logs</button>
                            </div>
                        </Col>
                        <Col lg={12} className='d-flex flex-column align-items-center justify-content-center'>
                            {
                                transactionhistory ? <Transactions navigate={navigate} /> :
                                    paymentlogshistory ? <PaymentLogs navigate={navigate} /> :
                                        <Col lg={7} className='wallet-balance d-flex flex-column align-items-center'>
                                            <div className='available-balance'>
                                                <p>Available Balance: <span>₹ {User?.data?.data?.user?.accountbalance}</span></p>
                                            </div>
                                            <div className='recharge-btn mt-5'>
                                                <button onClick={() => { navigate('/add-wallet-money/') }} >Recharge</button>
                                            </div>
                                        </Col>
                            }
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default MyWallet;