import '../../Styles/about.css'
import { Container, Row, Col } from 'react-bootstrap';

function AboutBanner() {
    return (
        <>
            <Container fluid className='mb-5'>
                <Row className='about-background pt-3 '>
                    <Col lg={12} className='about-title'>
                        <h1 className='text-darkOrange'>About Us</h1>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AboutBanner;
