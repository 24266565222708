import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import useInterval from 'use-interval';
import { Helmet } from 'react-helmet';

import ProfileDescription from "./ProfileDescription";
import ProfileReviews from "./ProfileReviews";
import SimilarAstrologer from "./SimilarAstrologer";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllAstrologers, GetSingleAstrologer, GetAstrologerStatus } from '../../../Redux/Slice';
import { Descriptions } from 'antd';

function Profile() {
  const dispatch = useDispatch();
  const { Astrologers, AstrologerProfile } = useSelector((state) => state.Astromind);
  let current_page = localStorage.getItem("currentPage");
  const pageLimit = window.innerWidth < 600 ? 15 : 24;

  const pageMetaTag= {
    title : `${AstrologerProfile?.data?.data?.astrologer?.name} - Best Raja Nadi & Vedic Astrologer | Astromind`,
    descriptions : `Discuss with our renowned raja nadi & vedic astrologer ${AstrologerProfile?.data?.data?.astrologer?.name}! Know your destiny by our expert's exceptional Astrology predictions. Unlock your path today!`,
    keyword : `Raja nadi & vedic astrologer ${AstrologerProfile?.data?.data?.astrologer?.name}, ${AstrologerProfile?.data?.data?.astrologer?.name} vedic astrologer, ${AstrologerProfile?.data?.data?.astrologer?.name} astrologer`
  }

  const { slug } = useParams();
  const currentUrl = new URL(window.location.href);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(GetSingleAstrologer(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(GetSingleAstrologer(slug));
    dispatch(GetAllAstrologers({ page: current_page, pagelimit: pageLimit }));
    dispatch(GetAstrologerStatus());
  }, [dispatch, slug, current_page, pageLimit]);

  useInterval(() => {
    dispatch(GetAstrologerStatus());
  }, 20000);

  return (
    <>
      <Helmet>
        <title>{pageMetaTag?.title}</title>
        <meta name="description" data-rh="true" content={pageMetaTag?.descriptions} />
        <meta name="keywords" data-rh="true" content={pageMetaTag?.keyword} />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href={currentUrl?.href} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/blogs/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content={pageMetaTag?.title} />
        <meta property="og:description" content={pageMetaTag?.descriptions} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageMetaTag?.title} />
        <meta name="twitter:description" content={pageMetaTag?.descriptions} />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>
      <ProfileDescription astrologer={AstrologerProfile?.data?.data} />
      <ProfileReviews astrologer={AstrologerProfile?.data?.data} />
      <SimilarAstrologer astrologer={AstrologerProfile?.data?.data} />
    </>
  );
}

export default Profile;
