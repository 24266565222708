import { Helmet } from 'react-helmet';
import BestAstrologerInMaduraiBanner from './BestAstrologerInMaduraiBanner';
import BestAstrologerInMaduraiContent from './BestAstrologerInMaduraiContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";

function BestAstrologerInMadurai() {
    return (
        <>
            <Helmet>
                <title>Best Astrologers In Madurai-Find Your Perfect Astrologer Today</title>
                <meta name="description" content="Meet the best astrologers in Madurai. Find your perfect astrologer today and get accurate, personalized astrological guidance." />
                <meta name="keywords" content="best astrologers in madurai, nadi astrology in madurai, famous astrologers in madurai, vedic astrologers in madurai, tamil astrologers in madurai" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/best-astrologers-in-madurai/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/best-astrologers-in-madurai/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Best Astrologers In Madurai-Find Your Perfect Astrologer Today" />
                <meta property="og:description" content="Meet the best astrologers in Madurai. Find your perfect astrologer today and get accurate, personalized astrological guidance." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Best Astrologers In Madurai-Find Your Perfect Astrologer Today" />
                <meta name="twitter:description" content="Meet the best astrologers in Madurai. Find your perfect astrologer today and get accurate, personalized astrological guidance." />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <BestAstrologerInMaduraiBanner />
            <BestAstrologerInMaduraiContent />
        </>
    );
}

export default BestAstrologerInMadurai;
