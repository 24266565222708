import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';

import blogLeft from '../../Assets/HomeImages/blog-left-img.svg'
import blogRight from '../../Assets/HomeImages/blog-right-img.svg'
import blogRightArrow from '../../Assets/HomeImages/blue-right-arrow.svg'
import orangeRightArrow from '../../Assets/HomeImages/orange-right-arrow.svg'

import blogImg from '../../Assets/HomeImages/blog-img.png'
import blogsmallImg1 from '../../Assets/HomeImages/blog-small-img1.png'
import blogsmallImg2 from '../../Assets/HomeImages/blog-small-img2.png'
import blogsmallImg3 from '../../Assets/HomeImages/blog-small-img3.png'




function HomeBlog() {
  return (
    <>
      <Container fluid className="d-flex mt-5 mb-5">
        <div className="blog-left-img">
          <img src={blogLeft} alt="" />
        </div>
        <Container>
          <Row>
            <Col lg={12} className="blog-res-title pb-3">
              <h3 className="text-orange">LATEST NEWS & BLOGS</h3>
            </Col>
          </Row>
          <Row className="d-flex blog-row">
            <Col lg={6} sm={10}>
              <div className="blog-img bg-black">
                <img src={blogImg} alt="" />
              </div>
              <div className="bg-blue d-flex blog-description">
                <div className="blog-content p-3">
                  <h5 className="text-orange">“Man will become better when you show him what he is like.” - Anton Chekov.</h5>
                  <p className="text-white">This quote by Anton Chekhov means that people can improve themselves when they are made aware of their true nature and behavior. When we see ourselves as others see us, we can identify our flaws and work to improve them. This is because self-awareness is the first step towards self-improvement. By recognizing our weaknesses, we can take steps to overcome them and become better versions of ourselves. </p>
                </div>
                <div className="blog-btn">
                  <button className="text-orange"><a href='/blogs/anton-chekov/' target='_blank'>Read More<img className="px-2" src={orangeRightArrow} alt="" /></a></button>

                </div>
              </div>
            </Col>
            <Col lg={6} className="d-flex flex-column justify-content-center blog-small">
              <div className="blog-title">
                <h3 className="text-orange">LATEST NEWS & BLOGS</h3>
              </div>

              <div className="d-flex gap-3">
                <div className="blog-small-img">
                  <img src={blogsmallImg1} alt="" />
                </div>
                <div className="blog-small-description">
                  <p className="title">Raja Nadi</p>
                  <p className="content">Nadi system is an astrological system that uses rasi/signs and the planets for predictions. Conjunction of planets forms the basis of this system. A planet is conjunct with another planet which is in trine (1,5,9) with it.</p>
                  <button className="text-blue font-700"><a href='/blogs/rajanadi-personal-horoscope-astrology-guide/' target='_blank'>Read More<img src={blogRightArrow} alt="" /></a></button>
                </div>
              </div>
              <div className="d-flex gap-3 ">
                <div className="blog-small-img">
                  <img src={blogsmallImg2} alt="" />
                </div>
                <div className="blog-small-description">
                  <p className="title">Raja Nadi Conjunctions – Part 1</p>
                  <p className="content">Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA).</p>
                  <button className="text-blue font-700"><a href='/blogs/tamil-jathagam-raja-nadi-conjunctions-part-one/' target='_blank'>Read More<img src={blogRightArrow} alt="" /></a></button>
                </div>
              </div>
              <div className="d-flex gap-3">
                <div className="blog-small-img">
                  <img src={blogsmallImg3} alt="" />
                </div>
                <div className="blog-small-description">
                  <p className="title">ஜாதகம் என்றால் என்ன ?</p>
                  <p className="content">ஜாதகம் என்றால் ஒரு மனிதன் பிறக்கும் போது வானில் இருக்கும் கிரக நிலைகளை குறிக்கும் வரைபடம்.</p>
                  <button className="text-blue font-700"><a href='/blogs/complete-guide-to-jadhagam/' target='_blank'>Read More<img src={blogRightArrow} alt="" /></a></button>
                </div>
              </div>
              
            </Col>
          </Row>
        </Container>
        <div className="blog-right-img">
          <img src={blogRight} alt="" />
        </div>
      </Container>
    </>
  );
}

export default HomeBlog;