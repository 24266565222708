import '../../../Styles/profile.css';
import { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

import { AiFillStar } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import Badge from '../../../Assets/AstrologerImages/astro-logo-badge.png';
import EmptyProfile from '../../../Assets/AstrologerImages/empty-profile.png';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetUserProfile, LoginScreen, FollowOrUnfollow, successMessage, errorMessage, ConsultationTypeStatus } from '../../../Redux/Slice';

import GetRemainingTime from '../AstrologerListPage/GetRemainingTime';
import CallConnect from '../AstrologerListPage/CallConnect';
import ChatWaiting from '../AstrologerListPage/ChatWaiting';

function ProfileDescription({ astrologer }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { IpAddress, User, AstrologerStatus, success, errors, ConsultationType } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    const waitingId = localStorage.getItem("waitingId");
    const chatSessionId = localStorage.getItem("messageSessionId");

    let description = astrologer?.astrologer?.description?.data?.map(obj => obj.name);
    let language = astrologer?.astrologer?.language?.data?.map(obj => obj.name);

    let OnlineStatus = AstrologerStatus?.data?.astrologer?.find((status) => status.id === astrologer?.astrologer?.id);
    let remainingWaitingTime = OnlineStatus?.call_waiting_time > 0 ? GetRemainingTime(OnlineStatus?.call_waiting_time, OnlineStatus?.call_start_time, IpAddress) : null;

    const [isFollow, setIsfollow] = useState(false);

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    useEffect(() => {
        if (token) {
            dispatch(GetUserProfile(token));
        }
    }, [dispatch, token]);

    useEffect(() => {

        if (pathname.includes("chat-with-astrologer")) {
            dispatch(ConsultationTypeStatus({ ConsultationType: "chat" }));
        }

        else if (pathname.includes("talk-to-astrologer")) {
            dispatch(ConsultationTypeStatus({ ConsultationType: "call" }));
        }

        let followId = User?.data?.data?.followings?.find((following) => following?.astrologerId === astrologer?.astrologer?.id);
        if (followId) {
            setIsfollow(true);
        }
        else {
            setIsfollow(false);
        }

        if (success?.status === 200) {
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK"
            }).then((result) => {
                if (token) {
                    dispatch(GetUserProfile(token));
                }
                dispatch(successMessage(''));
            })
        }

        else if (errors?.message) {
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then(() => {
                if (token) {
                    dispatch(GetUserProfile(token));
                }
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, token, success, errors, astrologer, User, pathname]);

    const handlePaid = (item) => {
        let astrologerAmount = Number(item?.profileDetail?.userpayspermin ? item?.profileDetail?.userpayspermin : item?.profileDetail?.myrate);
        let minimumAmount = 5 * astrologerAmount;
        if (!token) {
            dispatch(LoginScreen({ OpenStatus: true }));
        }
        else if (Number(User?.data?.data?.user?.accountbalance) < minimumAmount) {
            Swal.fire({
                text: `You have INR ${User?.data?.data?.user?.accountbalance} in your wallet. To initiate a call, a minimum balance of  INR ${minimumAmount} (5 minutes) must be paid to start call.`,
                showCancelButton: true,
                cancelButtonColor: "#025BFD",
                confirmButtonColor: "#319F43",
                confirmButtonText: "Recharge"
            }).then((result) => {
                if (result?.isConfirmed) {
                    navigate('/add-wallet-money/');
                }
            });
        }
        else {
            navigate(`/get-consultation/${item?.slug}/${item?.id}`);
        }
    }

    const handleFollow = (item) => {
        let id = item.id;
        console.log(isFollow);
        let followStatus = isFollow ? 0 : 1;
        if (!token) {
            dispatch(LoginScreen({ OpenStatus: true }));
        }
        else {
            dispatch(FollowOrUnfollow({ token, id, followStatus }));
            dispatch(GetUserProfile(token));
        }
    }

    return (
        <>
            {/* {
                ConsultationType == "call" ?
                    <CallConnect />
                    : ConsultationType == "chat" && chatSessionId ?
                        <ChatWaiting />
                        : null
            } */}
            <Container fluid className='mb-5 profileMain'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        {astrologer ?
                            <Col lg={10} className='astro-profileCard'>
                                <Fragment key={astrologer?.astrologer?.id}>
                                    <Col lg={2} className='d-flex flex-column align-items-center '>
                                        <div className='d-flex justify-content-center profileImg'>
                                            <img src={astrologer?.profile?.file ? astrologer?.profile?.file : EmptyProfile} alt="" />
                                            <div className='profileImg-badge'>
                                                <img src={Badge} alt="" />
                                            </div>
                                        </div>
                                        <div className='profileStars'>
                                            <div className="profileStar">
                                                <span>
                                                    {[...new Array(5)].map((arr, index) => {
                                                        return index < `${astrologer?.astrologer?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                    })}
                                                </span>

                                            </div>
                                        </div>
                                        <div className='profile-prize mt-2'>
                                            <p>
                                                <span>Rs. {astrologer?.astrologer?.userpayspermin ? astrologer?.astrologer?.userpayspermin : astrologer?.astrologer?.myrate} </span>
                                                {astrologer?.astrologer?.userpayspermin ? <del>Rs. {astrologer?.astrologer?.myrate}</del> : null} / min
                                            </p>
                                            <p>{astrologer?.totelsessions} sessions</p>
                                            {/* <p><span>(50% off)</span></p> */}
                                            {
                                                isFollow ?
                                                    <p className='text-success'>following</p>
                                                    : null
                                            }
                                        </div>
                                        <div className='mt-2'>

                                            {isFollow ?
                                                <button className='yellow-btn unfollow-btn' onClick={() => { handleFollow(astrologer?.astrologer) }}>UnFollow</button>
                                                : <button className='yellow-btn' onClick={() => { handleFollow(astrologer?.astrologer) }}>Follow</button>
                                            }
                                            {/* <div className='follow-alert'>
                                                <p>Report</p>
                                            </div> */}
                                        </div>
                                        {/* {
                                            astrologer?.astrologer?.discountpercentage ?
                                                <div className='offer-badge'>
                                                    <p className='mb-0'><span>{astrologer?.astrologer?.discountpercentage}% off</span></p>
                                                </div>
                                                : null
                                        } */}
                                    </Col>
                                    <Col lg={10} className='profile-details mt-4'>

                                        <div className='d-flex'>
                                            <p className='title'><b>Name</b></p>
                                            <p className='dot'>:</p>
                                            <p className='description'>{astrologer?.astrologer?.name} ({astrologer?.astrologer?.amc_id})</p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='title'><b>Expertise</b></p>
                                            <p className='dot'>:</p>
                                            <p className='description'>{description?.join(", ")}</p>
                                        </div>
                                        {astrologer?.astrologer?.experience ?
                                            <div className='d-flex'>
                                                <p className='title'><b>Experience</b></p>
                                                <p className='dot'>:</p>
                                                <p className='description'>{astrologer?.astrologer?.experience} years</p>
                                            </div>
                                            : null
                                        }
                                        {astrologer?.astrologer?.qualification ?
                                            <div className='d-flex'>
                                                <p className='title'><b>Qualification</b></p>
                                                <p className='dot'>:</p>
                                                <p className='description'>{astrologer?.astrologer?.qualification}</p>
                                            </div>
                                            : null
                                        }
                                        {
                                            language ?
                                                <div className='d-flex'>
                                                    <p className='title'><b>Languages</b></p>
                                                    <p className='dot'>:</p>
                                                    <p className='description'>{language?.join(", ")}</p>
                                                </div>
                                                : null
                                        }

                                        {astrologer?.astrologer?.profession ?
                                            <div className='d-flex'>
                                                <p className='title'><b>Profession</b></p>
                                                <p className='dot'>:</p>
                                                <p className='description'>{astrologer?.astrologer?.profession ? astrologer?.astrologer?.profession : "-"}</p>
                                            </div>
                                            : null
                                        }

                                        <Col lg={12} className={`profileDetail-btn ${(window.screen.width < 550) && ((ConsultationType == "call" && OnlineStatus?.call_next_activedate) || (ConsultationType == "chat" && OnlineStatus?.call_next_activetime)) && 'justify-content-between'} pt-3`}>

                                            {
                                                ConsultationType == "call" && (OnlineStatus?.call_next_activedate || OnlineStatus?.call_next_activetime) ?
                                                    <div className='next-active'>
                                                        <p> Next Online - {OnlineStatus?.call_next_activedate + " " + OnlineStatus?.call_next_activetime}</p>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                ConsultationType == "chat" && (OnlineStatus?.chat_next_activedate || OnlineStatus?.chat_next_activetime) ?
                                                    <div className='next-active'>
                                                        <p> Next Online - {OnlineStatus?.chat_next_activedate + " " + OnlineStatus?.chat_next_activetime}</p>
                                                    </div>
                                                    : null
                                            }
                                            <div>
                                            </div>
                                            <div className='d-flex gap-2 wait-time'>
                                                <p>{remainingWaitingTime?.minutes > 0 && OnlineStatus?.call_waiting_time > 0 ? "Wait " + remainingWaitingTime?.minutes + 'm' : null}</p>

                                                {
                                                    ConsultationType == "call" ?
                                                        <button onClick={() => { handlePaid(astrologer?.astrologer) }} className={OnlineStatus?.call_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null ? 'green-btn' : 'red-btn disabled'} disabled={!(OnlineStatus?.call_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null)}>Start Call</button>
                                                        : null
                                                }
                                                {
                                                    ConsultationType == "chat" ?
                                                        <button onClick={() => { handlePaid(astrologer?.astrologer) }} className={OnlineStatus?.chat_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null ? 'green-btn' : 'red-btn disabled'} disabled={!(OnlineStatus?.chat_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null)}>Start Chat</button>
                                                        : null
                                                }
                                            </div>
                                        </Col>

                                    </Col>

                                    <div className='three-dot'>
                                        <BsThreeDotsVertical className='dot-icon' />
                                        <div className='report-btn'>
                                            <p>Report</p>
                                        </div>
                                    </div>

                                </Fragment>
                            </Col>
                            :
                            <Fragment>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </Fragment>

                        }
                    </Row>
                </Container>
            </Container>

            <Container fluid className='bg-lightOrange py-4'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        {astrologer ?
                            <Col lg={10} className='profile-about'>
                                <h4>About Me</h4>

                                {astrologer?.astrologer?.about ?
                                    <p className='description'>{astrologer?.astrologer?.about}</p>
                                    : null
                                }
                            </Col>
                            : null
                        }
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default ProfileDescription;