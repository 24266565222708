import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { AiFillStar } from "react-icons/ai";

import EmptyProfile from '../../Assets/AstrologerImages/empty-profile.png'
import Layer1 from '../../Assets/HomeImages/talk-to-astrologer-Layer1.svg'
import Layer2 from '../../Assets/HomeImages/talk-to-astrologer-Layer2.svg'
import Layer3 from '../../Assets/HomeImages/talk-to-astrologer-Layer3.svg'

function HomeTestimonial() {
    var settings = {
        dots: false,
        arrows: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        // infinite: true,
        // autoplay: true,
        // autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,

                },
            },
           
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,

                },
            },
        ],

    };
    return (
        <>
            <Container fluid className="d-flex mt-5 mb-5">
                <div className="talk-left-img">
                    <img src={Layer1} alt="" />
                </div>
                <Container >
                    <Col lg={12}>
                        <Row className="d-flex justify-content-center">
                            <Col lg={8} className="d-flex flex-column text-center justify-content-center">
                                <h3 className="text-orange">Hear From Our Happy Clients</h3>
                                <p className="text-black">Read how Astromind Counsel has helped our clients with clear and helpful astrology advice.</p>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Col lg={12} className="d-flex justify-content-center gap-5 mt-3  home-testimonial">
                                <Slider {...settings}>
                                    <div>
                                        <video
                                            src="https://astromind-prod.s3.ap-south-1.amazonaws.com/testimonial-1.mp4"
                                            controls
                                        >
                                        </video>
                                    </div>
                                    <div>
                                        <video
                                            src="https://astromind-prod.s3.ap-south-1.amazonaws.com/testimonial-2.mp4"
                                            controls
                                        >
                                        </video>
                                    </div>
                                    <div>
                                        <video
                                            src="https://astromind-prod.s3.ap-south-1.amazonaws.com/testimonial-3.mp4"
                                            controls
                                        >
                                        </video>
                                    </div>
                                    <div>
                                        <video
                                            src="https://astromind-prod.s3.ap-south-1.amazonaws.com/testimonial-4.mp4"
                                            controls
                                        >
                                        </video>
                                    </div>
                                </Slider>

                            </Col>
                        </Row>
                    </Col>
                </Container>
                <div className="talk-right-img">
                    <img className="img-1" src={Layer2} alt="" />
                    <img className="img-2" src={Layer3} alt="" />
                </div>

            </Container>

        </>
    );
}

export default HomeTestimonial;