import { Helmet } from 'react-helmet';
import ContactBanner from "./ContactBanner";
import ContactForm from "./ContactForm";

function Contact() {
  return (
    <>
      <Helmet>
        <title>Connect with Our Online Tamil Astrologer for Smart Advice</title>
        <meta name="description" data-rh="true" content="Connect with our esteemed online Tamil astrologer for personalized insights and guidance. Discover Tamil astrology's ancient knowledge for a harmonious future." />
        <meta name="keywords" data-rh="true" content="online tamil jathagam, tamil jathagam, tamil horoscope, online tamil astrologer, tamil astrologer" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/contact-us/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/contact-us/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/contact-us/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/contact-us/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Connect with Our Online Tamil Astrologer for Smart Advice" />
        <meta property="og:description" content="Connect with our esteemed online Tamil astrologer for personalized insights and guidance. Discover Tamil astrology's ancient knowledge for a harmonious future." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Connect with Our Online Tamil Astrologer for Smart Advice" />
        <meta name="twitter:description" content="Connect with our esteemed online Tamil astrologer for personalized insights and guidance. Discover Tamil astrology's ancient knowledge for a harmonious future." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

     <ContactBanner />
     <ContactForm />
    </>
  );
}

export default Contact;
