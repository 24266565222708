import { Helmet } from 'react-helmet';
import BestAstrologersInChennaiBanner from './BestAstrologersInChennaiBanner';
import BestAstrologersInChennaiContent from './BestAstrologersInChennaiContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";

function BestAstrologersInChennai() {
    return (
        <>
            <Helmet>
                <title>Find the Best Astrologers in Chennai | Astromind Counsel</title>
                <meta name="description" content="Looking for the best astrologers in Chennai? Our trusted and experienced astrologers provide a detailed horoscope analysis and life guidance." />
                <meta name="keywords" content="best astrologers in chennai, tamil astrologers in chennai,nadi astrology in chennai, vedic astrologers in chennai" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/best-astrologers-chennai/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/best-astrologers-chennai/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Find the Best Astrologers in Chennai | Astromind Counsel" />
                <meta property="og:description" content="Looking for the best astrologers in Chennai? Our trusted and experienced astrologers provide a detailed horoscope analysis and life guidance." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Find the Best Astrologers in Chennai | Astromind Counsel" />
                <meta name="twitter:description" content="Looking for the best astrologers in Chennai? Our trusted and experienced astrologers provide a detailed horoscope analysis and life guidance." />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <BestAstrologersInChennaiBanner />
            < BestAstrologersInChennaiContent />
        </>
    );
}

export default BestAstrologersInChennai;
