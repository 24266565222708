import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import { useBeforeunload } from "react-beforeunload";

import { FaChevronDown, FaAngleUp } from "react-icons/fa";
import EmptyImg from '../../../Assets/AstrologerImages/empty-profile.png'

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetChatStatus, UpdateRechargeStatus, UpdateChatExtendOrEnd, successMessage, GetUserProfile, InprogressChatStatusMessage } from '../../../Redux/Slice';

import useBeforeUnload from "../../CommonPages/BrowserTapClose";

function ChatAstroProfile({ socket, chatEnd, setChatEnd, typing }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { IpAddress, User, ChatConnectStatus, InprogressChatStatus, AstrologerProfile } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    const orderId = localStorage.getItem("orderId");
    const chatSessionId = localStorage.getItem("messageSessionId");
    const astrologerName = localStorage.getItem("roomName");
    const astrologerImage = localStorage.getItem("astrologerImage");

    const astrologerAmount = Number(AstrologerProfile?.data?.data?.astrologer?.userpayspermin ? AstrologerProfile?.data?.data?.astrologer?.userpayspermin : AstrologerProfile?.data?.data?.astrologer?.myrate);

    const AstrologerRate = astrologerAmount ? astrologerAmount : localStorage.getItem("astrologerRate");

    let durationInSeconds = InprogressChatStatus?.data?.data?.totalDuration || 0;

    let durationMinutes = durationInSeconds / 60;

    let TotalRate = durationMinutes ? Number(durationMinutes) * AstrologerRate : null;

    let WalletAmount = (Number(User?.data?.data?.user?.accountbalance) - TotalRate).toFixed(2);
    console.log(User?.data?.data?.user?.accountbalance);
    console.log(TotalRate);
    console.log(WalletAmount);
    
    const [chatExtent, setChatExtent] = useState(false);
    const [extentView, setExtentView] = useState(false);
    const [showExtentOption, setShowExtentOption] = useState(false);
    const [time, setTime] = useState({ Hour: '', Minutes: '', Seconds: '', StartTime: '', EndTime: '' });

    useEffect(() => {
        if (InprogressChatStatus?.data?.data?.status === 'in-progress') {
            let remainingSeconds = InprogressChatStatus?.data?.data?.remainingTime;
            let Hour = Math.floor(remainingSeconds / 3600);
            let remainingSecondsAfterHours = remainingSeconds % 3600;
            let Minutes = Math.floor(remainingSecondsAfterHours / 60);
            let Seconds = remainingSecondsAfterHours % 60;
            let StartTime = new Date(InprogressChatStatus?.data?.data?.startTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            setTime({
                Hour: Hour > 0 ? Hour : 0,
                Minutes: Minutes,
                Seconds: Seconds,
                StartTime: StartTime,
                EndTime: ''
            })
        }
        else if (InprogressChatStatus?.data?.data?.status === 'completed') {

            let completedSeconds = InprogressChatStatus?.data?.data?.completedTime;
            let Hour = Math.floor(completedSeconds / 3600);
            let completedSecondsAfterHours = completedSeconds % 3600;
            let Minutes = Math.floor(completedSecondsAfterHours / 60);
            let Seconds = completedSecondsAfterHours % 60;
            let StartTime = new Date(InprogressChatStatus?.data?.data?.startTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let EndTime = new Date(InprogressChatStatus?.data?.data?.endTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

            setTime({
                Hour: Hour > 0 ? Hour : 0,
                Minutes: Minutes,
                Seconds: Seconds,
                StartTime: StartTime,
                EndTime: EndTime
            })
            setChatEnd(true);
            if (InprogressChatStatus?.data?.data?.isCompleted) {
                Swal.fire({
                    text: InprogressChatStatus?.data?.message,
                    confirmButtonColor: "#319F43",
                    confirmButtonText: "Okay",
                    timer: 5000,
                }).then(() => {
                });
            }
            dispatch(GetUserProfile(token));
        }
    }, [dispatch, token, InprogressChatStatus, setChatEnd])

    useEffect(() => {
        let Minutes = time?.Minutes;
        if ((Minutes === 1) && !(extentView) && !(chatEnd)) {
            setChatExtent(true);
            setExtentView(true)
        }
    }, [time?.Minutes, extentView, chatEnd])

    useEffect(() => {
        if (ChatConnectStatus) {
            Swal.fire({
                text: ChatConnectStatus?.data?.message,
                confirmButtonColor: "#319F43",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then(() => {
                // setChatEnd(true);
                // dispatch(UpdateChatExtendOrEnd(""));
                // dispatch(GetChatStatus({ token, orderId }));
            });
        }
    }, [ChatConnectStatus, dispatch]);

    const formatTime = (value) => {
        return value < 10 ? `0${value}` : value;
    };

    const UserEndChat = () => {
        Swal.fire({
            text: "Are you sure wants to end the chat",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Confirm"
        }).then((result) => {
            if (result?.isConfirmed) {
                EndChat();
            }
        });
    }
    const EndChat = () => {
        let chatUpdate = {
            orderId: orderId,
            isExtended: 0,
            extendedDuration: 0,
            isendedbyuser: 1,
            isendedbyastrologer: 0
        }
        dispatch(UpdateChatExtendOrEnd({ token, chatUpdate }));
        setChatEnd(true);
        let role = 'user'
        socket.emit('leaveRoom', astrologerName, role);
    }

    const ComplteChat = () => {
        Swal.fire({
            text: "Are you sure wants to exit from the chat",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Confirm"
        }).then((result) => {
            if (result?.isConfirmed) {
                localStorage.removeItem("roomName");
                localStorage.removeItem("messageSessionId");
                localStorage.removeItem("orderId");
                localStorage.removeItem("waitingId");
                localStorage.removeItem("astrologerId");
                localStorage.removeItem("astrologerImage");
                localStorage.removeItem("ElapsedTime");
                localStorage.removeItem("astrologerRate");
                localStorage.removeItem("StartTime");
                localStorage.removeItem("userData");
                setChatEnd(false);
                dispatch(GetChatStatus(''));
                dispatch(successMessage(''));
                dispatch(InprogressChatStatusMessage(''));
                dispatch(GetUserProfile(token));
                navigate(-1);
            }
        });
    }

    const handleExtent = (time) => {
        let CallTotalRate = time ? Number(time) * AstrologerRate : null;

        if (WalletAmount < CallTotalRate) {
            Swal.fire({
                text: `You have INR ${WalletAmount} in your wallet. To initiate a call, a minimum balance of  INR ${CallTotalRate} (${time} minutes) must be paid to start call.`,
                showCancelButton: true,
                cancelButtonColor: "#025BFD",
                confirmButtonColor: "#319F43",
                confirmButtonText: "Quick Recharge"
            }).then((result) => {
                if (result?.isConfirmed) {
                    handlePayment(CallTotalRate, time);
                }
            });
        }
        else if (WalletAmount >= CallTotalRate) {
            Swal.fire({
                text: `Do you want extent your chat`,
                showCancelButton: true,
                cancelButtonColor: "#025BFD",
                confirmButtonColor: "#319F43",
                confirmButtonText: "Yes"
            }).then((result) => {
                if (result?.isConfirmed) {
                    let extent_duration = time * 60;
                    let chatUpdate = {
                        orderId: orderId,
                        isExtended: 1,
                        extendedDuration: extent_duration,
                        isendedbyuser: 0,
                        isendedbyastrologer: 0
                    }
                    dispatch(UpdateChatExtendOrEnd({ token, chatUpdate }));
                    dispatch(GetChatStatus({ token, orderId }));
                    setChatExtent(false);
                }
            });
        }
    }

    const handlePayment = (amount, time) => {
        let gst_amount = (Number(amount) * 18) / 100;
        let total_amount = Number(amount) + gst_amount;

        var options = {
            key: "rzp_live_4sMB25br1OuK9Y",
            key_secret: "LfVxYjPxtJq3naKX1twpcSS3",
            amount: total_amount * 100,
            currency: "INR",
            name: "Astro Mind Counsel",
            // description: "for testing purpose",
            handler: function (response) {
                console.log(response);
                let paymentId = response.razorpay_payment_id;
                let cashback = "0";
                let status = 1;
                let address = IpAddress?.city;
                let state = IpAddress?.region;
                let country = IpAddress?.country;
                
                dispatch(UpdateRechargeStatus({ token, paymentId, cashback, status, address, state, country }));

                let extent_duration = time * 60;
                let chatUpdate = {
                    orderId: orderId,
                    chatSessionId: chatSessionId,
                    isExtended: 1,
                    extendedDuration: extent_duration,
                    duration: 0,
                    isendedbyuser: 0,
                }
                dispatch(UpdateChatExtendOrEnd({ token, chatUpdate }));
            },
            // prefill: {
            //     name: "Vinoth",
            //     email: "vinoth@akkenna.com",
            //     contact: "9361098632"
            // },
            notes: {
                address: "Razorpay Corporate office"
            },
            theme: {
                color: "#030E52"
            },
            image: "https://www.astromindcounsel.com/static/media/Astro-logo.75f31095b407fbea8d7e80d016fd2b77.svg"
        };

        var pay = new window.Razorpay(options);
        pay.open();

        pay.on('payment.failed', async function (response) {
            console.log(response.error.metadata);
            let paymentId = response.error.metadata.payment_id;
            let cashback = "0";
            let status = 0;
            dispatch(UpdateRechargeStatus({ token, paymentId, cashback, status }));
        });
    }

    // useBeforeunload((event) => {
    //     event.preventDefault();
    //     console.log("beforeunload happened!");
    //     const message = 'Are you sure you want to leave? Changes you made may not be saved.';
    //     const confirmed = window.confirm(message);
    //     console.log(confirmed);

    //     // return 'Are you sure you want to leave?';

    //     // const headers = {
    //     //     authorization: token,
    //     //     'Content-Type': 'application/json',
    //     // }
    //     // let CurrentDate = new Date();
    //     // const endTime = toZonedTime(CurrentDate, IpAddress?.timezone);
    //     // let chatUpdate = {
    //     //     orderId: orderId,
    //     //     chatSessionId: chatSessionId,
    //     //     isExtended: 0,
    //     //     extendedDuration: 0,
    //     //     duration: remainTime?.elapsedDuration,
    //     //     isendedbyuser: 1,
    //     //     endTime: endTime,
    //     // }

    //     // const success = navigator.sendBeacon(`${process.env.REACT_APP_SERVER_URL}/message/update-chat-status`, chatUpdate, { headers: headers });
    //     // const success = navigator.sendBeacon('/api/logLeave', JSON.stringify({ message: 'User clicked leave button' }));
    //     // if (success) {
    //     //     // EndChat();
    //     // } else {
    //     //     console.error('sendBeacon failed');
    //     // }

    // });

    // const apiEndpoint = 'https://api.example.com/leave'; // Replace with your API endpoint

    // Function to make the API call
    // const makeApiCall = async () => {
    //     // try {
    //     //     await axios.post(apiEndpoint, { data: 'your data' });
    //     //     console.log('API call successful');
    //     // } catch (error) {
    //     //     console.error('Error making API call', error);
    //     // }
    //     console.log("Callback");
    //     const confirmed = window.confirm('Are you sure you want to leave? Changes you made may not be saved.');
    //     console.log(confirmed);


    // };

    // // Hook to handle tab close
    // useBeforeUnload(() => {
    //     makeApiCall();
    // });

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         const confirmationMessage = 'Are you sure you want to leave? Changes you made may not be saved.';
    //         event.preventDefault();
    //         event.returnValue = confirmationMessage;

    //         const userConfirmed = window.confirm(confirmationMessage);
    //         console.log(userConfirmed);
    //         if (userConfirmed) {
    //             console.log(userConfirmed);
    //             const headers = {
    //                 authorization: token,
    //                 'Content-Type': 'application/json',
    //             }
    //             let CurrentDate = new Date();
    //             const endTime = toZonedTime(CurrentDate, IpAddress?.timezone);
    //             let chatUpdate = {
    //                 orderId: orderId,
    //                 isExtended: 0,
    //                 extendedDuration: 0,
    //                 isendedbyuser: 1,
    //                 isendedbyastrologer: 0
    //             }

    //             // // Use navigator.sendBeacon to send the data
    //             const url = `${process.env.REACT_APP_SERVER_URL}/message/extend-or-endchat/${chatUpdate?.orderId}?isExtended=${chatUpdate?.isExtended}&extendedDuration=${chatUpdate?.extendedDuration}&isendedbyuser=${chatUpdate?.isendedbyuser}&isendedbyastrologer=${chatUpdate?.isendedbyastrologer}`; // Replace with your server URL
    //             // navigator.sendBeacon(url, { headers: headers });

    //             fetch(url, {
    //                 method: 'GET',
    //                 headers: {
    //                     authorization: token,
    //                     'Content-Type': 'application/json',
    //                 },
    //             });

    //             navigate('/chat-with-astrologer/');
    //         }

    //         return confirmationMessage;
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);


    // useEffect(() => {
    //     const handleUnload = (event) => {
    //         // Prepare the data you want to send
    //         const headers = {
    //             authorization: token,
    //             'Content-Type': 'application/json',
    //         }
    //         let CurrentDate = new Date();
    //         const endTime = toZonedTime(CurrentDate, IpAddress?.timezone);
    //         let chatUpdate = {
    //             orderId: orderId,
    //             chatSessionId: chatSessionId,
    //             isExtended: 0,
    //             extendedDuration: 0,
    //             duration: remainTime?.elapsedDuration,
    //             isendedbyuser: 1,
    //             endTime: endTime,
    //         }

    //         // Use navigator.sendBeacon to send the data
    //         const url = `${process.env.REACT_APP_SERVER_URL}/message/update-chat-status`; // Replace with your server URL
    //         navigator.sendBeacon(url, chatUpdate, { headers: headers });
    //     };

    //     // Add event listener for unload
    //     window.addEventListener('beforeunload', handleUnload);

    //     // Cleanup function to remove the event listener
    //     return () => {
    //         window.removeEventListener('beforeunload', handleUnload);
    //     };
    // }, []);

    return (
        <>
            {/* <p style={{ textAlign: "center", backgroundColor: "red", color: "white" }}>
                Please do not leave the chat, close the tab, or refresh the page to avoid losing progress.
            </p> */}
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={chatExtent}
                onHide={() => setChatExtent(false)}
                className=''
            >
                <Modal.Body className="chat-extent">
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => { setChatExtent(false) }}></button>
                    <Col lg={12} className={`right-detail extent-time d-flex flex-column align-items-center gap-2 ${(chatEnd || time?.Minutes > 1) && "extent-time-disable"}`}>
                        <div>
                            <h3>Do yo want extent your chat</h3>
                        </div>
                        <div className="d-flex gap-3">
                            <button onClick={() => { handleExtent(5) }} disabled={(chatEnd || time?.Minutes > 1) && true} >5 min</button>
                            <button onClick={() => { handleExtent(10) }} disabled={(chatEnd || time?.Minutes > 1) && true} >10 min</button>
                            <button onClick={() => { handleExtent(15) }} disabled={(chatEnd || time?.Minutes > 1) && true} >15 min</button>
                        </div>
                    </Col>
                </Modal.Body>
            </Modal>

            <Col lg={12} className="bg-skyBlue chat-profiles">
                <Col
                    lg={12}
                    className="d-flex justify-content-between align-items-center"
                >
                    <div className="d-flex gap-3 align-items-center">
                        <div className="chat-profileImg">
                            <img src={astrologerImage ? astrologerImage : EmptyImg} alt="" className="img-fluid" />
                        </div>
                        <div className="chat-profile-detail">
                            <h5>{localStorage.getItem('roomName')}</h5>
                            <p className="status mt-2">{InprogressChatStatus?.data?.data?.status}</p>
                            {/* <p className="balance-time">Balance Time : <span className="time-count">{formatTime(time?.Hour)}:{formatTime(time?.Minutes)}:{formatTime(time?.Seconds)}</span> </p> */}
                            {
                                InprogressChatStatus?.data?.data?.status === 'completed' ?
                                    <p className="balance-time">Completed Duration : <span className="time-count text-success">{formatTime(time?.Minutes)} Min {formatTime(time?.Seconds)} Sec</span> </p>
                                    :
                                    <p className="balance-time">Balance Time : <span className="time-count text-red">
                                        {formatTime(time?.Hour)}:{formatTime(time?.Minutes)}:{formatTime(time?.Seconds)}
                                    </span> </p>
                            }
                            {
                                InprogressChatStatus?.data?.data?.status === 'completed' ?
                                    null
                                    :
                                    <p className={typing ? "typing-status" : "typing-status hide"}> Typing.....</p>
                            }


                        </div>
                    </div>
                    <div className="chat-profile-detail right-detail">
                        {
                            InprogressChatStatus?.data?.data?.status === 'completed' ?
                                <button
                                    onClick={() => {
                                        ComplteChat();
                                    }}
                                >
                                    Back
                                </button>
                                :
                                <button
                                    onClick={() => {
                                        UserEndChat();
                                    }}
                                >
                                    End Chat
                                </button>
                        }
                        <p className="balance-time pt-2">Start Time : {time?.StartTime} </p>
                        {time?.EndTime ?
                            <p className="balance-time pt-2">End Time : {time?.EndTime} </p>
                            : null
                        }
                    </div>
                </Col>
            </Col>
            {
                window.screen.width < 550 ?
                    <>
                        <Col
                            lg={12}
                            className="bg-blue text-white quick-recharge"
                        >
                            <Col lg={4} className="avail-balance">
                                <p>Available Balance : ₹
                                    {InprogressChatStatus?.data?.data?.status === 'completed' ?
                                        InprogressChatStatus?.data?.data?.UserBalance
                                        : WalletAmount
                                    }
                                </p>
                            </Col>

                            <Col lg={8} className={`right-detail extent-time d-flex align-items-center gap-1 ${(chatEnd || time?.Minutes > 1) && "extent-time-disable"}`}>
                                <div className="d-flex gap-1" onClick={() => setShowExtentOption(!showExtentOption)}>
                                    <p className="mb-0">Do yo want Extent</p>
                                    {
                                        showExtentOption ?
                                            <FaAngleUp />
                                            : <FaChevronDown />
                                    }
                                </div>
                            </Col>
                        </Col>
                        {
                            showExtentOption ?
                                <Col lg={12} className={`right-detail bg-blue text-white extent-time d-flex justify-content-center py-2 gap-3 ${(chatEnd || time?.Minutes > 1) && "extent-time-disable"}`}>
                                    <button onClick={() => { handleExtent(5) }} disabled={(chatEnd || time?.Minutes > 1) && true}>5 min</button>
                                    <button onClick={() => { handleExtent(10) }} disabled={(chatEnd || time?.Minutes > 1) && true}>10 min</button>
                                    <button onClick={() => { handleExtent(15) }} disabled={(chatEnd || time?.Minutes > 1) && true}>15 min</button>
                                </Col>
                                : null
                        }
                    </>
                    :
                    <Col
                        lg={12}
                        className="bg-blue text-white quick-recharge"
                    >
                        <Col lg={4} className="avail-balance">
                            <p>Available Balance : ₹
                                {InprogressChatStatus?.data?.data?.status === 'completed' ?
                                    InprogressChatStatus?.data?.data?.UserBalance
                                    : WalletAmount
                                }
                            </p>
                        </Col>

                        <Col lg={8} className={`right-detail extent-time d-flex align-items-center gap-3 ${(chatEnd || time?.Minutes > 1) && "extent-time-disable"}`}>
                            <p>Do yo want Extent</p>
                            <button onClick={() => { handleExtent(5) }} disabled={(chatEnd || time?.Minutes > 1) && true}>5 min</button>
                            <button onClick={() => { handleExtent(10) }} disabled={(chatEnd || time?.Minutes > 1) && true}>10 min</button>
                            <button onClick={() => { handleExtent(15) }} disabled={(chatEnd || time?.Minutes > 1) && true}>15 min</button>
                        </Col>
                    </Col>
            }
        </>
    )
}

export default ChatAstroProfile;