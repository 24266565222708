import { Helmet } from 'react-helmet';
import UnlockYourFutureBanner from './UnlockYourFutureBanner';
import UnlockYourFutureContent from './UnlockYourFutureContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";
import TopAstrologers from '../TopAstrologers';

function UnlockYourFuture() {
    return (
        <>
            <Helmet>
                <title>Get Personalized Nadi Astrology with Astromind Counsel</title>
                <meta name="description" content="Discover your destiny with online Nadi Astrology by Astromind Counsel. Unlock personalized insights & guidance for a fulfilling life." />
                <meta name="keywords" content="nadi astrologer, nadi astrology, nadi jothidam" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/services/get-personalized-nadi-astrology/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/services/get-personalized-nadi-astrology/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Navigate the Stars: Teen Horoscope Astrology Services" />
                <meta property="og:description" content="Discover your destiny with online Nadi Astrology by Astromind Counsel. Unlock personalized insights & guidance for a fulfilling life." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Navigate the Stars: Teen Horoscope Astrology Services" />
                <meta name="twitter:description" content="Discover your destiny with online Nadi Astrology by Astromind Counsel. Unlock personalized insights & guidance for a fulfilling life." />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <UnlockYourFutureBanner />
            < UnlockYourFutureContent />
            <ConnectAstrologers />
            <TopAstrologers />
        </>
    );
}

export default UnlockYourFuture;
