import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import BlogBanner from "./blogBanner";
import RecentBlog from "./recentBlog";
import NewBlog from "./newBlog";
import TopBlog from "./topBlog";

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetAllBlogs } from '../../Redux/Slice';

function Blog() {
    const dispatch = useDispatch();
    const { Blogs, success, errors } = useSelector((state) => state.Astromind);

    const [currentPage, setCurrentPage] = useState(1);
    const pageLimit = window.innerWidth < 600 ? 10 : 15;

    useEffect(() => {
      dispatch(GetAllBlogs({ page: 1, pagelimit: pageLimit }));
  }, []);

  console.log(Blogs);
  return (
    <>
      <Helmet>
        <title>Astromind-Rajanadi astrology services by Tamil astrologers</title>
        <meta name="description" data-rh="true" content="Astromind Counsel provides Rajanadi astrology services by Tamil astrologers. Our Expert astrologers can illuminate your life's predictable journey in the future." />
        <meta name="keywords" data-rh="true" content="jadhagam guide, Rajanadi jothidam, nadi astrologer, rajanadi astrology, tamil astrology, tamil astrologers" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/blogs/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/blogs/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Astromind-Rajanadi astrology services by Tamil astrologers" />
        <meta property="og:description" content="Astromind Counsel provides Rajanadi astrology services by Tamil astrologers. Our Expert astrologers can illuminate your life's predictable journey in the future." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Astromind-Rajanadi astrology services by Tamil astrologers" />
        <meta name="twitter:description" content="Astromind Counsel provides Rajanadi astrology services by Tamil astrologers. Our Expert astrologers can illuminate your life's predictable journey in the future." />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

      <BlogBanner />
      {/* <RecentBlog /> */}
      <NewBlog blogs={Blogs?.data?.data}/>
      {/* <PopularPost /> */}
      <TopBlog />
    </>
  );
}

export default Blog;
