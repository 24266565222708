import '../../Styles/blog.css'
import { Container, Row, Col } from 'react-bootstrap';

import BlogImg2 from '../../Assets/BlogImages/recent-blog-img-2.png'
import BlogImg4 from '../../Assets/BlogImages/recent-blog-img-4.png'

import NewYearImg from '../../Assets/BlogImages/new-year-blog-img.png'
import RajanadiImg from '../../Assets/BlogImages/rajanadi-blog-img.png'
function RecentBlog() {
  return (
    <>
      <Container fluid className='mt-5 mb-5'>
        <Container>
          <Row>
            <Col lg={12}>
              <h3>Recent Blogs </h3>
            </Col>
          </Row>
          <Row className='mt-3 recentBlog-row'>
            <Col lg={6} className='recentBlog-left'>
              <a href="/blogs/tamil-jathagam-consultation-new-year-2024/" target='_blank'>
                <div className='recentBlog-left-img'>
                  <img src={NewYearImg} alt="" />
                  <div className='overlay-content'>
                    <h6>New Year Predictions for 2024 – Part 1</h6>
                    <p>Transit predictions in Raja Nadi is based on the yearly planets triggering the natal planets in a chart. Saturn, Jupiter, Rahu and Ketu are the four planets that creates major changes in our life.</p>
                  </div>
                </div>
              </a>
            </Col>
            <Col lg={6} className='d-flex flex-column justify-content-between recentBlog-right'>
              <div className='d-flex justify-content-between recentBlog-right-card'>
                <div className='recentBlog-rightTop'>
                  <a href="/blogs/complete-guide-to-jadhagam/" target='_blank'>
                    <div className='recentBlog-rightTop-img'>
                      <img src={BlogImg2} alt="" />
                      <div className='overlay-content'>
                        <h6>ஜாதகம் என்றால் என்ன ?</h6>
                        <p>ஜாதகம் என்றால் ஒரு மனிதன் பிறக்கும் போது வானில் இருக்கும் கிரக நிலைகளை குறிக்கும் வரைபடம்.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='recentBlog-rightTop'>
                <a href="/blogs/rajanadi-personal-horoscope-astrology-guide/" target='_blank'>
                  <div className='recentBlog-rightTop-img'>
                    <img src={RajanadiImg} alt="" />
                    <div className='overlay-content'>
                      <h6>Raja Nadi</h6>
                      <p>Nadi system is an astrological system that uses rasi/signs and the planets for predictions. Conjunction of planets forms the basis of this system. A planet is conjunct with another planet which is in trine (1,5,9) with it.</p>
                    </div>
                  </div>
                </a>
                </div>
              </div>
              <div className='recentBlog-rightBottom'>
                <a href="/blogs/tamil-jathagam-raja-nadi-conjunctions-part-one/" target='_blank'>
                  <div className='recentBlog-rightBottom-img'>
                    <img src={BlogImg4} alt="" />
                    <div className='overlay-content'>
                      <h6>Raja Nadi Conjunctions – Part 1</h6>
                      <p>Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA).</p>
                    </div>
                  </div>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default RecentBlog;
