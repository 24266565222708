import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

import Card1 from '../../../Assets/ServiceImages/children-card-icon-1.svg'
import Card2 from '../../../Assets/ServiceImages/children-card-icon-2.svg'
import Card3 from '../../../Assets/ServiceImages/children-card-icon-3.svg'
import Card4 from '../../../Assets/ServiceImages/children-card-icon-4.svg'
import Card5 from '../../../Assets/ServiceImages/children-card-icon-5.svg'
import Card6 from '../../../Assets/ServiceImages/children-card-icon-6.svg'

import serviceLeftImg from '../../../Assets/ServiceImages/service-card-left-img.svg'
import serviceRightImg from '../../../Assets/ServiceImages/service-card-right-img.svg'

function ElderlyCard() {
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <div className="service-left-img">
                    <img src={serviceLeftImg} alt="" />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap gap-4 service-cards'>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card2} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Life Review and Integration</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist the elderly in reflecting on their life journey and integrating their experiences. Astrology provides a symbolic framework for exploring past events, patterns, and themes in one’s life. By examining astrological transits and progressions, the elderly can gain insights into the significance of different phases and milestones. Integrating psychological approaches, such as narrative therapy or reminiscence therapy, can support the process of life review and help in finding meaning and a sense of completion.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card3} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Personal and Spiritual Growth</h5>
                                    </div>
                                    <div>
                                        <p>The elderly often engage in deeper introspection and contemplation about the meaning of life and their personal growth. Astrology and psychology can provide tools for this exploration. Astrology can offer insights into the soul’s journey, karmic patterns, and life purpose. Psychological approaches, such as existential therapy or mindfulness practices, can facilitate personal and spiritual growth by exploring existential questions and cultivating a sense of presence and acceptance.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card4} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Emotional Well-being</h5>
                                    </div>
                                    <div>
                                        <p>Aging can bring emotional challenges, including loss, grief, and changes in identity and independence. Astrology and psychology can provide support for emotional well-being. Astrology can shed light on the planetary influences that affect emotional states, while psychological approaches can offer techniques for managing stress, cultivating resilience, and enhancing self-care. This integration can assist the elderly in navigating emotional changes and fostering emotional well-being.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Relationship and Family Dynamics:</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can help the elderly in understanding and navigating their relationships and family dynamics. Astrology can provide insights into compatibility, communication styles, and areas of growth within relationships. Psychological approaches can offer strategies for improving communication, resolving conflicts, and fostering meaningful connections with loved ones. This integration can support the elderly in maintaining fulfilling relationships and creating a sense of belonging.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card6} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Coping with Aging Challenges</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can provide tools and perspectives for coping with the challenges that come with aging. Astrology can offer insights into the energies and potentials present during different phases of life, allowing the elderly to align their expectations and make informed decisions. Psychological approaches can support the development of adaptive coping mechanisms, resilience, and the acceptance of physical and cognitive changes.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card1} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Legacy and Meaningful Contributions</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist the elderly in exploring their legacy and finding opportunities for meaningful contributions. Astrology can offer insights into potential areas of expertise, wisdom, and purpose that the elderly can share with others. Psychological approaches can support the development of mentoring relationships, engagement in community activities, or pursuing creative endeavors, leading to a sense of fulfillment and leaving a positive impact on others.</p>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <div className="service-right-img">
                    <img src={serviceRightImg} alt="" />
                </div>
            </Container>

        </>
    );
}

export default ElderlyCard;
