import '../../Styles/home.css'
import { Container, Row, Col } from 'react-bootstrap';
import bannerImg from '../../Assets/HomeImages/banner-img.png'
import AdCard from '../../Assets/HomeImages/ad-card-img.svg'
import AdCard2 from '../../Assets/HomeImages/ad-card-img-2.svg'

function HomeBanner() {
    return (
        <>
            <Container fluid className="banner-main">
            {/* <div className='ad-card-2'>
                    <a href='/get-your-astrology-reports/'><img src={AdCard2} alt="" /></a>
                </div> */}
                <Container>
                    <Row className="d-flex justify-content-between">
                        <Col lg={6} className="banner-content d-flex flex-column justify-content-center pt-5">
                            <div>
                                <h3 className="text-bannerOrange">Astromind Counsel</h3>
                                <h4 className="text-bannerOrange pb-2">Integrated Astrology and Counseling</h4>
                                <p className="text-black"><b>"Millionaires don't use Astrology, Billionaires do"</b> - JP.Morgan.</p>
                            </div>
                            <div className="banner-btn py-2">
                                <a href="/talk-to-astrologer/"><button className="bg-lightBlue radius-5 text-white p-2 mx-2">Talk to An Astrologer</button></a>
                                <a href="/chat-with-astrologer/"><button className="bg-lightBlue radius-5 text-white p-2 mx-2">Chat With Astrologer</button></a>
                            </div>
                            {/* <div className='mt-4'>
                                <h1 className="text-bannerOrange">Know Yourself and <br /> Your Destiny</h1>
                                <p className="text-black"><b>"Man will become better when you show him what he is like"</b> - Anton Chekhov.</p>
                            </div>
                            <div className="banner-btn">
                                <a href="/contact-us/"><button className="bg-lightBlue radius-5 text-white px-3 py-2">Talk to A Counselor</button></a>
                            </div> */}
                        </Col>
                        <Col lg={6} className="d-flex justify-content-center">
                            <div className='banner-img'>
                                <img src={bannerImg} alt="banner-img" />
                            </div>
                        </Col>

                    </Row>
                </Container>
                <div className='ad-card'>
                    <a href='/talk-to-astrologer/'><img src={AdCard} alt="" /></a>
                </div>
            </Container>
        </>
    );
}

export default HomeBanner;
