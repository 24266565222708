import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BirthChart from './BirthChart';
import PlanetDetails from './PlanetDetails'
import DashaPeriodDetails from './DashaPeriodDetails';

function ChartModal({ modalShow, closeModalShow, chartData, user }) {

    return (
        <>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered

                onHide={() => closeModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Birth Chart
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row className='d-flex justify-content-center'>
                        <Col lg={10}>
                            <Tabs
                                defaultActiveKey="birthchart"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="birthchart" title="Birth Chart">
                                    <BirthChart chart={chartData} user={user} />
                                </Tab>
                                <Tab eventKey="planet" title="Planet">
                                    <PlanetDetails chart={chartData} />
                                </Tab>
                                <Tab eventKey="dashaperiod" title="Dasha Period">
                                    <DashaPeriodDetails list={chartData} />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChartModal;