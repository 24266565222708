import '../../../Styles/login.css';
import React, { useState } from 'react';
import "react-phone-input-2/lib/style.css";

import PhoneNumber from './PhoneNumber';
import OtpVerify from './OtpVerify';
import BasicDetails from './BasicDetails';
import ConfirmMessage from './Confirm';

import { useSelector } from 'react-redux';

function Login() {
  const { OpenStatus, DetailStatus, OtpStatus, ConfirmStatus } = useSelector((state) => state.Astromind);

  const [userNumber, setUserNumber] = useState('');
  const [message, setMessage] = useState('');

  const AddPhoneNumber = (value) => {
    setUserNumber(value);
  }
  const ResponseMessage = (value) => {
    setMessage(value);
  }

  return (
    <>
      {
        OpenStatus ?
          <PhoneNumber Number={AddPhoneNumber} />
          : null
      }
      {
        OtpStatus ?
          <OtpVerify Number={userNumber} Message={ResponseMessage} />
          : null
      }

      {
        DetailStatus ?
          <BasicDetails number={userNumber} Message={ResponseMessage} />
          : null
      }
      {
        ConfirmStatus ?
          <ConfirmMessage Message={message} />
          : null
      }
    </>
  )
}

export default Login;