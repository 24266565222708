import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function EndoflifeCta() {
    return (
      <>
       <Container fluid>
          <Container>
              <Row>
                  <Col lg={12} className='bg-lightOrange p-5 text-center'>
                    <h6 className='text-blue'>It’s important to note that the end-of-life journey is deeply personal, and individual preferences and beliefs may vary. Seeking support from professionals experienced in end-of-life care, such as hospice or palliative care teams, can provide specialized guidance and comprehensive support during this phase. Additionally, maintaining open communication with loved ones, accessing appropriate medical and emotional care, and creating a supportive environment are essential components of navigating the end-of-life crisis with dignity and compassion.</h6>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default EndoflifeCta;
  