import '../../Styles/blog.css'
import { Container, Row, Col } from 'react-bootstrap';

function BlogBanner() {
    return (
      <>
       <Container fluid className='blog-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>Blog</h1>
                  </Col>
              </Row>
          </Container>
       </Container>
      </>
    );
  }
  
  export default BlogBanner;
  