import { Helmet } from 'react-helmet';
import BestAstrologerInThanjavurBanner from './BestAstrologerInThanjavurBanner';
import BestAstrologerInThanjavurContent from './BestAstrologerInThanjavurContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";

function BestAstrologerInThanjavur() {
    return (
        <>
            <Helmet>
                <title>Best Astrologers In Thanjavur-Find Your Perfect Astrologer Today</title>
                <meta name="description" content="Meet the best astrologers in Thanjavur. Find your perfect astrologer today and get accurate, personalized astrological guidance." />
                <meta name="keywords" content="best astrologers in Thanjavur, nadi astrology in Thanjavur, famous astrologers in Thanjavur, vedic astrologers in Thanjavur, tamil astrologers in Thanjavur" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/best-astrologers-in-madurai/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/best-astrologers-in-thanjavur/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Best Astrologers In Thanjavur-Find Your Perfect Astrologer Today" />
                <meta property="og:description" content="Meet the best astrologers in Thanjavur. Find your perfect astrologer today and get accurate, personalized astrological guidance." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Best Astrologers In Thanjavur-Find Your Perfect Astrologer Today" />
                <meta name="twitter:description" content="Meet the best astrologers in Thanjavur. Find your perfect astrologer today and get accurate, personalized astrological guidance." />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <BestAstrologerInThanjavurBanner />
            <BestAstrologerInThanjavurContent />
        </>
    );
}

export default BestAstrologerInThanjavur;
