import '../../Styles/blog.css'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import topBlogImg1 from '../../Assets/BlogImages/top-blog-img-1.png'
import topBlogImg2 from '../../Assets/BlogImages/top-blog-img-2.png'
import topBlogImg3 from '../../Assets/BlogImages/top-blog-img-3.png'
import topBlogImg4 from '../../Assets/BlogImages/top-blog-img-4.png'
import topBlogImg5 from '../../Assets/BlogImages/top-blog-img-5.png'
import topBlogImg6 from '../../Assets/BlogImages/top-blog-img-6.png'
import topBlogImg7 from '../../Assets/BlogImages/top-blog-img-7.png'
import topBlogImg8 from '../../Assets/BlogImages/top-blog-img-8.png'
import topBlogImg9 from '../../Assets/BlogImages/top-blog-img-9.png'


const TopBlogImage = [
  {
    image: topBlogImg1,
    url: "/blogs/tamil-jathagam-consultation-new-year-2024/",
    title: "New Year Predictions for 2024 – Part 1",
    description: "Transit predictions in Raja Nadi is based on the yearly planets triggering the natal planets in a chart. Saturn, Jupiter, Rahu and Ketu are the four planets that creates major changes in our life."
  },
  {
    image: topBlogImg6,
    url: "/blogs/rajanadi-personal-horoscope-astrology-guide/",
    title: "Raja Nadi",
    description: "Nadi system is an astrological system that uses rasi/signs and the planets for predictions. Conjunction of planets forms the basis of this system. A planet is conjunct with another planet which is in trine (1,5,9) with it."
  },
  {
    image: topBlogImg7,
    url: "/blogs/tamil-jathagam-raja-nadi-conjunctions-part-one/",
    title: "Raja Nadi Conjunctions – Part 1",
    description: "Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA)."
  },
  {
    image: topBlogImg2,
    url: "/blogs/tamil-jathagam-raja-nadi-conjunctions-part-two/",
    title: "Raja Nadi Conjunctions – Part 2",
    description: "Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA)."
  },
  {
    image: topBlogImg5,
    url: "/blogs/complete-guide-to-jadhagam/",
    title: "ஜாதகம் என்றால் என்ன ?",
    description: "ஜாதகம் என்றால் ஒரு மனிதன் பிறக்கும் போது வானில் இருக்கும் கிரக நிலைகளை குறிக்கும் வரைபடம்."
  },
  {
    image: topBlogImg9,
    url: "/blogs/tamil-jathagam-consultation-new-year-part2-2024/",
    title: "New Year Predictions for 2024 – Part 2",
    description: "Transit predictions in Raja Nadi is based on the yearly planets triggering the natal planets in a chart. Saturn, Jupiter, Rahu and Ketu are the four planets that creates major changes in our life."
  },
  {
    image: topBlogImg8,
    url: "/anton-chekov/",
    title: "“Man will become better when you show him what he is like.” -Anton Chekov.",
    description: "This quote by Anton Chekhov means that people can improve themselves when they are made aware of their true nature and behavior. When we see ourselves as others see us, we can identify our flaws and work to improve them. This is because self-awareness is the first step towards self-improvement."
  },
  {
    image: topBlogImg7,
    url: "/rajanadi-conjunctions-tamil-part-1/",
    title: "ராஜநாடி இணைவுகள் பகுதி -1",
    description: "ஜோதிடத்தில் ராஜநாடி முறை முக்கிய பங்கு வகிக்கிறது.  இந்த முறையில் பலன்களை மிக துல்லியமாகவும் எளிமையாகவும் நம்மால் புரிந்து கொண்டு பலன் கூற முடியும். ராஜநாடி முறையை நமக்கு அளித்தவர் திரு. கா. பார்த்திபன் அவர்கள். “ராஜநாடி ஜோதிட சாஸ்திரம்” என்ற நூலை எழுதியுள்ளார். இதன் அடிப்படையில் ராசிகள் மற்றும் கிரகங்களின் தன்மையை நாம் எளிதாக கணிக்க முடியும்."
  },
  {
    image: topBlogImg1,
    url: "/blogs/tamil-jathagam-consultation-new-year-2024/",
    title: "New Year Predictions for 2024 – Part 1",
    description: "Transit predictions in Raja Nadi is based on the yearly planets triggering the natal planets in a chart. Saturn, Jupiter, Rahu and Ketu are the four planets that creates major changes in our life."
  },
  {
    image: topBlogImg6,
    url: "/blogs/rajanadi-personal-horoscope-astrology-guide/",
    title: "Raja Nadi",
    description: "Nadi system is an astrological system that uses rasi/signs and the planets for predictions. Conjunction of planets forms the basis of this system. A planet is conjunct with another planet which is in trine (1,5,9) with it."
  },
  {
    image: topBlogImg7,
    url: "/blogs/tamil-jathagam-raja-nadi-conjunctions-part-one/",
    title: "Raja Nadi Conjunctions – Part 1",
    description: "Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA)."
  },
  {
    image: topBlogImg5,
    url: "/blogs/complete-guide-to-jadhagam/",
    title: "ஜாதகம் என்றால் என்ன ?",
    description: "ஜாதகம் என்றால் ஒரு மனிதன் பிறக்கும் போது வானில் இருக்கும் கிரக நிலைகளை குறிக்கும் வரைபடம்."
  },
  {
    image: topBlogImg2,
    url: "/blogs/tamil-jathagam-raja-nadi-conjunctions-part-two/",
    title: "Raja Nadi Conjunctions – Part 2",
    description: "Raja Nadi Astrology is a very practical, magically simple system that uses profoundly precise techniques for predictions which is followed in Tamil Astrology. Mr. Ka. Parthiban, author of “Raja Nadi Jothida Sashthiram” is the founder of Raja Nadi Astrology (RNA)."
  },
  {
    image: topBlogImg8,
    url: "/anton-chekov/",
    title: "Man will become better when you show him what he is like.” -Anton Chekov.",
    description: "This quote by Anton Chekhov means that people can improve themselves when they are made aware of their true nature and behavior. When we see ourselves as others see us, we can identify our flaws and work to improve them. This is because self-awareness is the first step towards self-improvement."
  },
  {
    image: topBlogImg7,
    url: "/rajanadi-conjunctions-tamil-part-1/",
    title: "ராஜநாடி இணைவுகள் பகுதி -1",
    description: "ஜோதிடத்தில் ராஜநாடி முறை முக்கிய பங்கு வகிக்கிறது.  இந்த முறையில் பலன்களை மிக துல்லியமாகவும் எளிமையாகவும் நம்மால் புரிந்து கொண்டு பலன் கூற முடியும். ராஜநாடி முறையை நமக்கு அளித்தவர் திரு. கா. பார்த்திபன் அவர்கள். “ராஜநாடி ஜோதிட சாஸ்திரம்” என்ற நூலை எழுதியுள்ளார். இதன் அடிப்படையில் ராசிகள் மற்றும் கிரகங்களின் தன்மையை நாம் எளிதாக கணிக்க முடியும்."
  },
  {
    image: topBlogImg9,
    url: "/blogs/tamil-jathagam-consultation-new-year-part2-2024/",
    title: "New Year Predictions for 2024 – Part 2",
    description: "Transit predictions in Raja Nadi is based on the yearly planets triggering the natal planets in a chart. Saturn, Jupiter, Rahu and Ketu are the four planets that creates major changes in our life."
  },
]

function TopBlog() {

  var settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,

        },
      },
    ],

  };
  return (
    <>
      <Container fluid className='mt-5 mb-5'>
        <Row className='topBlog-row pb-3'>
          <Col lg={12}>
            <h3>Top Blog </h3>
          </Col>
        </Row>
        <Row className='topBlog-row'>
          <Col lg={12}>
            <Slider {...settings}>
              {TopBlogImage.map((item) => (
                <div className='recentBlog-left-img'>
                  <Link to={item.url} target='_blank'>
                    <img src={item.image} alt="" />
                    <div className='overlay-xs'>
                      <div className='overlay-content'>
                        <h6>{item.title}</h6>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default TopBlog;
