import '../../../Styles/user.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { MdModeEdit, MdDelete } from "react-icons/md";
import EmptyProfile from '../../../Assets/AstrologerImages/empty-profile.png';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetUserProfile, DeleteUserProfile, DeleteUserProfileImg, LoginScreen, successMessage, errorMessage } from '../../../Redux/Slice';

import ProfileImageUpdate from './ProfileImageUpdate';
import EditProfile from './EditProfile';

function UserProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { success, errors, User } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    const [editShow, setEditShow] = useState(false)
    const [changeProfileShow, setChangeProfileShow] = useState(false)

    let BirthDate = new Date(User?.data?.data?.user?.dateofbirth).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
    const editClose = () => {
        setEditShow(false);
    }
    const ChangeProfileClose = () => {
        setChangeProfileShow(false);
    }

    const deleteAccount = () => {
        Swal.fire({
            title: "Are you sure want to Delete your account?",
            text: "After 14 days, the Account can be Deleted Permanently.",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete Account"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(DeleteUserProfile({ token }));
            }
        })
    }

    useEffect(() => {
        if (!token) {
            navigate(`/`);
            dispatch(LoginScreen({ OpenStatus: true }))
        }
        if (success) {
            if (success?.data?.data?.isDeletedRequested) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000,
                }).then(() => {
                    dispatch(successMessage(''));
                    localStorage.removeItem("user-auth-token");
                    navigate('/');
                    window.location.reload(false);
                })
            }
            else {
                setChangeProfileShow(false);
                setEditShow(false);
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000,
                }).then(() => {
                    if (token) {
                        dispatch(GetUserProfile(token));
                    }
                    dispatch(successMessage(''));
                })
            }
        }
        else if (errors) {
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then(() => {
                if (token) {
                    dispatch(GetUserProfile(token));
                }
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, navigate, token, success, errors]);

    const DeleteUserProfileImage = () => {
        const fileId = User?.data?.data?.profile?.id;

        Swal.fire({
            title: "Are you sure want to Delete your Profile Image?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete"
        }).then((result) => {
            console.log(fileId);
            if (result.isConfirmed) {
                dispatch(DeleteUserProfileImg({ token, fileId }))
            }
        })
    }

    return (
        <>
            <EditProfile
                editShow={editShow}
                editClose={editClose}
            />
            <ProfileImageUpdate
                profileShow={changeProfileShow}
                profileClose={ChangeProfileClose}
            />

            <Container fluid className='pt-150 pb-5'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10} className='user-profile radius-5 p-4'>
                            <Col lg={12} className='user-profile-col d-flex justify-content-center'>
                                <Col lg={3} className='user-profile-left'>
                                    <div className='user-profile-img'>
                                        <img src={User?.data?.data?.profile?.file ? User?.data?.data?.profile?.file : EmptyProfile} alt="" />
                                    </div>
                                    <div className='edit-btn d-flex gap-2'>
                                        <button
                                            onClick={() => { setChangeProfileShow(true) }}
                                        >
                                            <MdModeEdit className='icon' />
                                        </button>
                                        <button
                                            onClick={() => { DeleteUserProfileImage() }}
                                        >
                                            < MdDelete className='icon' />
                                        </button>
                                    </div>
                                </Col>
                                <Col lg={9} className='user-details'>
                                    <div className='py-3'>
                                        <h3 className='mb-4'>Profile Details</h3>
                                        <Col lg={12} className='d-flex flex-wrap'>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Name</h5>
                                                <p>{User?.data?.data?.user?.name}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Mail Id</h5>
                                                <p>{User?.data?.data?.user?.email}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Gender</h5>
                                                <p>{User?.data?.data?.user?.gender}</p>
                                            </Col>

                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Birth Time</h5>
                                                <p>{User?.data?.data?.user?.timeofbirth}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Date of Birth</h5>
                                                <p>{BirthDate}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Birth Place</h5>
                                                <p>{User?.data?.data?.user?.placeofbirth}</p>
                                            </Col>

                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Marital status</h5>
                                                <p>{User?.data?.data?.user?.maritalstatus}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Contact Details</h5>
                                                <p>+{User?.data?.data?.user?.phone}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Country</h5>
                                                <p>{User?.data?.data?.user?.country}</p>
                                            </Col>

                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>State</h5>
                                                <p>{User?.data?.data?.user?.state}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>City</h5>
                                                <p>{User?.data?.data?.user?.city}</p>
                                            </Col>
                                            <Col lg={4} className='half-form mb-4'>
                                                <h5>Pincode</h5>
                                                <p>{User?.data?.data?.user?.pincode}</p>
                                            </Col>
                                        </Col>
                                    </div>
                                </Col>
                            </Col>

                            <Col lg={12} className='delete-btn'>
                                <div >
                                    <button
                                        onClick={() => { setEditShow(true) }}
                                        className='text-blue'
                                    >
                                        Edit <MdModeEdit className='icon' />
                                    </button>
                                </div>
                                <div >
                                    <button
                                        onClick={() => { deleteAccount() }}
                                        className='text-blue'
                                    >
                                        Delete Account < MdDelete className='icon' />
                                    </button>
                                </div>
                            </Col>
                        </Col>

                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default UserProfile;