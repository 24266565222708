import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

import Card1 from '../../../Assets/ServiceImages/children-card-icon-1.svg'
import Card2 from '../../../Assets/ServiceImages/children-card-icon-2.svg'
import Card3 from '../../../Assets/ServiceImages/children-card-icon-3.svg'
import Card4 from '../../../Assets/ServiceImages/children-card-icon-4.svg'
import Card5 from '../../../Assets/ServiceImages/children-card-icon-5.svg'
import Card6 from '../../../Assets/ServiceImages/children-card-icon-6.svg'

import serviceLeftImg from '../../../Assets/ServiceImages/service-card-left-img.svg'
import serviceRightImg from '../../../Assets/ServiceImages/service-card-right-img.svg'

function ChildrenCard() {
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <div className="service-left-img">
                    <img src={serviceLeftImg} alt="" />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap gap-4 service-cards'>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card1} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Self-Understanding</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist children in developing self-understanding and self-awareness from an early age. By exploring their astrological birth charts, children can gain insights into their natural inclinations, strengths, and challenges. This knowledge can help them embrace their unique qualities and develop a positive self-image.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card2} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Identifying Strengths and Interests</h5>
                                    </div>
                                    <div>
                                        <p>Astrology can provide clues about a child’s potential strengths and interests. By understanding the planetary placements and aspects in their birth chart, parents and educators can gain insights into areas where the child may naturally excel or find particular enjoyment. This information can guide the child’s educational path and extracurricular activities, fostering a sense of fulfillment and achievement.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card3} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Parenting and Communication</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist parents in understanding their child’s unique temperament and needs. By exploring the child’s birth chart, parents can gain insights into their child’s personality, emotional patterns, and communication style. This understanding can enhance parent-child relationships by facilitating effective communication, tailored support, and appropriate guidance.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card4} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Emotional Intelligence</h5>
                                    </div>
                                    <div>
                                        <p>Integrating astrology and psychology can support the development of emotional intelligence in children. By exploring astrological placements associated with emotions and temperament, children can gain awareness of their own emotional landscape and learn to manage their feelings effectively. Additionally, psychological approaches can provide tools and strategies for emotional regulation, empathy, and healthy expression of emotions.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Coping with Transitions and Challenges</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can offer support to children during significant life transitions or challenges. Whether it’s starting school, dealing with peer relationships, or facing family changes, astrology can provide insights into the energies at play and potential areas of growth. Combined with psychological approaches, children can develop resilience, coping skills, and a sense of empowerment in navigating life’s ups and downs.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card6} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Cultivating Imagination and Creativity</h5>
                                    </div>
                                    <div>
                                        <p>Astrology’s rich symbolism and archetypes can inspire children’s imagination and creativity. Exploring the stories and mythologies associated with astrological symbols can foster a sense of wonder and curiosity. This creative engagement can support cognitive development, problem-solving skills, and the ability to think outside the box.</p>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <div className="service-right-img">
                    <img src={serviceRightImg} alt="" />
                </div>
            </Container>

        </>
    );
}

export default ChildrenCard;
