import '../../Styles/reports.css'
import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import MultiStepForm from './MultiStepForm/MultiStepForm';

import SaturnImg from '../../Assets/PaymentImages/Saturn-transit-report-img.png'
import JupiterImg from '../../Assets/PaymentImages/Jupiter-transit-report-img.png'
import RahuKetuImg from '../../Assets/PaymentImages/RahuKetu-transit-report.png'
import yearlyImg from '../../Assets/PaymentImages/2024-yearly-report.png'
import wholeChartImg from '../../Assets/PaymentImages/whole-chart-analysis-report.png'

function ReportsCards() {

    const [modalShow, setModalShow] = useState(false);
    const [data, setData] = useState({report:'', price:'', delivery:''})
    const GetReport = (value) =>{
        setData({report:value.title, price:value.price, delivery:value.delivery})
        setModalShow(true)
    }
    console.log(data);
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={12} className='d-flex flex-wrap'>
                            <div className="product-card">
                                <div className="badge">50% OFF</div>
                                <div className="product-tumb">
                                    <img src={SaturnImg} alt="" />
                                </div>
                                <div className='product-list'>
                                    <div className="product-details">
                                        {/* <span className="product-catagory">Women,bag</span> */}
                                        <h4>Personalized Saturn Transit Predictions <span>(சனிப் பெயர்ச்சி பலன்கள்)</span></h4>
                                        <ul className='mt-3'>
                                            <li><span>&#10003;</span> Saturn Transit</li>
                                            {/* <li><span>&#10003;</span> Personal Life</li>
                                        <li><span>&#10003;</span> Career</li>
                                        <li><span>&#10003;</span> Wealth & Finance</li>
                                        <li><span>&#10003;</span> Health</li> */}
                                            <li><span>&#10003;</span>Email Delivery 3 - 5 Days</li>

                                        </ul>
                                    </div>
                                    <div className="product-bottom-details">
                                        <div className="product-price"><small>Rs.500</small>Rs.250</div>
                                        <div className='button-links'>
                                            <button onClick={() => { GetReport({title:"Personalized Saturn transit report",price:"Rs.250", delivery:"3 - 5 Days"}) }}>Select this plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-card">
                                <div className="badge">50% OFF</div>
                                <div className="product-tumb">
                                    <img src={JupiterImg} alt="" />
                                </div>
                                <div className='product-list'>
                                    <div className="product-details">
                                        {/* <span className="product-catagory">Women,bag</span> */}
                                        <h4>Personalized Jupiter Transit Predictions <span>(குரு  பெயர்ச்சி பலன்கள்)</span></h4>
                                        <ul className='mt-3'>
                                            <li><span>&#10003;</span> Jupiter Transit</li>
                                            {/* <li><span>&#10003;</span> Personal Life</li>
                                        <li><span>&#10003;</span> Career</li>
                                        <li><span>&#10003;</span> Wealth & Finance</li>
                                        <li><span>&#10003;</span> Health</li> */}
                                            <li><span>&#10003;</span>Email Delivery 3 - 5 Days</li>

                                        </ul>
                                    </div>
                                    <div className="product-bottom-details">
                                        <div className="product-price"><small>Rs.500</small>Rs.250</div>
                                        <div className='button-links'>
                                            <button onClick={() => { GetReport({title:"Personalized Jupiter transit report",price:"Rs.250", delivery:"3 - 5 Days"}) }} >Select this plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-card">
                                <div className="badge">50% OFF</div>
                                <div className="product-tumb">
                                    <img src={RahuKetuImg} alt="" />
                                </div>
                                <div className='product-list'>
                                    <div className="product-details">
                                        {/* <span className="product-catagory">Women,bag</span> */}
                                        <h4>Personalized Rahu/Ketu Transit Predictions <span>(ராகு/கேது பெயர்ச்சி பலன்கள்)</span></h4>
                                        <ul className='mt-3'>
                                            <li><span>&#10003;</span> Rahu/Ketu transit</li>
                                            {/* <li><span>&#10003;</span> Personal Life</li>
                                        <li><span>&#10003;</span> Career</li>
                                        <li><span>&#10003;</span> Wealth & Finance</li>
                                        <li><span>&#10003;</span> Health</li> */}
                                            <li><span>&#10003;</span>Email Delivery 3 - 5 Days</li>

                                        </ul>
                                    </div>
                                    <div className="product-bottom-details">
                                        <div className="product-price"><small>Rs.500</small>Rs.250</div>
                                        <div className='button-links'>
                                            <button onClick={() => { GetReport({title:"Personalized Rahu/Ketu transit report",price:"Rs.250", delivery:"3 - 5 Days"}) }} >Select this plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-card">
                                <div className="badge">50% OFF</div>
                                <div className="product-tumb">
                                    <img src={yearlyImg} alt="" />
                                </div>
                                <div className='product-list'>
                                    <div className="product-details">
                                        {/* <span className="product-catagory">Women,bag</span> */}
                                        <h4>Personalized 2024 Yearly Predictions <span>(2024 ஆண்டு பலன்கள்)</span></h4>
                                        <ul className='mt-3'>
                                            <li><span>&#10003;</span> Personal Life</li>
                                            <li><span>&#10003;</span> Career</li>
                                            <li><span>&#10003;</span> Wealth & Finance</li>
                                            <li><span>&#10003;</span> Health</li>
                                            <li><span>&#10003;</span>Email Delivery 5 - 7 Days</li>

                                        </ul>
                                    </div>
                                    <div className="product-bottom-details">
                                        <div className="product-price"><small>Rs.1000</small>Rs.500</div>
                                        <div className='button-links'>
                                            <button onClick={() => { GetReport({title:"Personalized 2024 yearly report",price:"Rs.500", delivery:"5 - 7 Days"}) }} >Select this plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-card">
                                <div className="badge">50% OFF</div>
                                <div className="product-tumb">
                                    <img src={wholeChartImg} alt="" />
                                </div>
                                <div className='product-list'>
                                    <div className="product-details">
                                        {/* <span className="product-catagory">Women,bag</span> */}
                                        <h4>Personalized Whole Chart Analysis Predictions <span>(முழுமையான ஜாதக பலன்)</span></h4>
                                        <div className='whole-report mt-3'>
                                            <ul>
                                                <li><span>&#10003;</span> Personal Life</li>
                                                <li><span>&#10003;</span> Career</li>
                                                <li><span>&#10003;</span> Wealth & Finance</li>
                                                <li><span>&#10003;</span> Health</li>
                                            </ul>
                                            <ul>
                                                <li><span>&#10003;</span> Saturn Transit</li>
                                                <li><span>&#10003;</span> Jupiter Transit</li>
                                                <li><span>&#10003;</span> Rahu/Ketu transit</li>
                                                <li><span>&#10003;</span>Email Delivery 1 Week</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="product-bottom-details">
                                        <div className="product-price"><small>Rs.2000</small>Rs.1000</div>
                                        <div className='button-links'>
                                            <button onClick={() => { GetReport({title:"Personalized whole chart analysis report",price:"Rs.1000", delivery:"1 week"}) }} >Select this plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Report-model'
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>{data.report}</h4>
                        <h4 className='product-price'>{data.price} <span style={{color :'#000000', paddingLeft : '10px'}}>{data.delivery}</span></h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MultiStepForm report={data} />

                </Modal.Body>
            </Modal>
        </>
    );
}

export default ReportsCards;
