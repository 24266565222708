import '../../Styles/header.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import { Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { IoNotifications } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";

import Logo from '../../Assets/HomeImages/Astro-logo.svg';
import Logo3 from '../../Assets/HomeImages/ASTROMIND-LOGO-01.png';
import Menu from '../../Assets/HomeImages/menu-icon.svg';
import SupportIcon from '../../Assets/HomeImages/customer-support-icon.svg';

import { useDispatch } from 'react-redux';
import { LoginScreen } from '../../Redux/Slice';

const Header = ({ user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const LoginScreenOpen = () => {
        dispatch(LoginScreen({ OpenStatus: true, OtpStatus: false, DetailStatus: false, ConfirmStatus: false }))
    }

    const UserLogOut = () => {
        localStorage.removeItem("user-auth-token");
        window.location.reload(false);
    }
    const NavigatePage = (url) => {
        setShow(false)
        navigate(`${url}`);
    }

    useEffect(() => {
        if (pathname !== '/chat/') {
            if (user?.data?.data?.order?.id) {
                localStorage.setItem("orderId", user?.data?.data?.order?.id);
                localStorage.setItem("waitingId", user?.data?.data?.order?.waitingId);
                if (user?.data?.data?.order?.type === "chat") {
                    localStorage.setItem("messageSessionId", user?.data?.data?.order?.chatSession?.id);
                    localStorage.setItem("roomName", user?.data?.data?.order?.astrologer_name);
                    localStorage.setItem("astrologerId", user?.data?.data?.order?.astrologerId);
                    localStorage.setItem("astrologerslug", user?.data?.data?.order?.astrologer?.slug);
                    navigate('/chat-with-astrologer/');
                }
                else if (user?.data?.data?.order?.type === "call") {
                    navigate('/talk-to-astrologer/');
                }
            }
        }
    }, [user, navigate, pathname]);
    return (
        <>
            <Container fluid className='bg-blue header2'>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex'>
                            <Col lg={2} className='d-flex align-items-center'>
                                <div
                                    className="header-logo"
                                    onClick={() => navigate('/')}
                                >
                                    <img src={Logo3} alt="" />
                                </div>
                            </Col>
                            <Col lg={10}>
                                <Col lg={12} className='sub-nav1 d-flex align-items-center justify-content-end gap-3 pt-2'>
                                    {/* <Nav className='d-flex'>
                                        <Nav.Link href="/" >Home</Nav.Link>
                                        <Nav.Link href="/about/" >About Us</Nav.Link>
                                        <Nav.Link href="/contact-us/">Contact Us</Nav.Link>

                                    </Nav> */}
                                    {user?.data?.data?.user?.phone ?
                                        <>
                                            <div className="notification-icon text-white ">
                                                <Nav.Link href="/notifications/" className='d-flex'>
                                                    <IoNotifications />
                                                </Nav.Link>
                                            </div>
                                            <div className="notification-icon text-white ">
                                                <Nav.Link href="/customer-support/" className='d-flex'>
                                                    <img src={SupportIcon} alt="" />
                                                </Nav.Link>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                    <Nav.Link>
                                        {user?.data?.data?.user?.phone ?
                                            <>

                                                <Dropdown >
                                                    <Dropdown.Toggle className='login-profile login-icon text-white' id="dropdown-basic">
                                                        {
                                                            user?.data?.data?.profile ?
                                                                <img src={user?.data?.data?.profile?.file} className='profile-img' alt="" />
                                                                : <FaRegUserCircle className='person-icon' />
                                                        }
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='profile-menu'>
                                                        <div>
                                                            <div className='d-flex flex-column align-items-center text-center mt-2'>
                                                                <div className='popup-profile-img'>
                                                                    {
                                                                        user?.data?.data?.profile ?
                                                                            <img src={user?.data?.data?.profile?.file} className='profile-img' alt="" />
                                                                            : <FaRegUserCircle className='person-icon' />
                                                                    }
                                                                </div>

                                                                <Dropdown.Item
                                                                    onClick={() => { NavigatePage("/profile/") }}
                                                                    className='login-popup-details'
                                                                >
                                                                    <div className='mt-3' href=''>
                                                                        <h5>{user?.data?.data?.user?.name}</h5>
                                                                        <p>+{user?.data?.data?.user?.phone}</p>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </div>
                                                            <div>
                                                                <Dropdown.Item
                                                                    onClick={() => { NavigatePage("/my-wallet/") }}
                                                                    className='login-popup-nav'
                                                                >
                                                                    <div className='d-flex justify-content-between'>
                                                                        <p>Wallet</p>
                                                                        <p> <span>₹ {user?.data?.data?.user?.accountbalance}</span></p>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown className='login-popup-nav'>
                                                                    <Dropdown.Toggle id="dropdown-basic" className='order-details'>
                                                                        Order History
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='order-menu'>
                                                                        <Dropdown.Item onClick={() => { NavigatePage("/order-history/call-logs/") }}>Call Logs</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => { NavigatePage("/order-history/chat-logs/") }}>Chat Logs</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <Dropdown.Item
                                                                    onClick={() => { NavigatePage("/customer-support/") }}
                                                                    className='login-popup-nav'
                                                                >
                                                                    <div className='d-flex justify-content-between'>
                                                                        <p>Customer Support</p>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() => { NavigatePage("/notifications/") }}
                                                                    className='login-popup-nav'
                                                                >
                                                                    <div className='d-flex justify-content-between'>
                                                                        <p>Notifications</p>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={UserLogOut} className='login-popup-nav'>Logout <MdLogout /></Dropdown.Item>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                            :
                                            <>
                                                <div onClick={LoginScreenOpen} className="login-icon login-border text-white">
                                                    <p>LogIn</p>
                                                    <FaRegUserCircle className='icon' />
                                                </div>
                                            </>
                                        }

                                    </Nav.Link>

                                    <div className='d-flex gap-3'>
                                        <Nav.Link href="/chat-with-astrologer/" >
                                            <Button className="header-btn">
                                                <IoChatbubbleEllipsesSharp className='mx-1' />
                                                Chat with Astrologer
                                            </Button>
                                        </Nav.Link>
                                        <Nav.Link href="/talk-to-astrologer/" className='sub-nav2-link' >
                                            <Button className="bg-orange text-white header-btn">
                                                <IoCall /> Talk to An Astrologer
                                            </Button>
                                        </Nav.Link>
                                    </div>
                                </Col>
                                <Col lg={12} className='sub-nav2 d-flex align-items-center justify-content-end pt-1 gap-4'>
                                    <Nav className='d-flex'>
                                        <Nav.Link href="/" >Home</Nav.Link>
                                        <Nav.Link href="/about/" >About Us</Nav.Link>
                                        <Nav.Link href="/get-your-astrology-reports/" >Personalized Prediction</Nav.Link>
                                        <Nav.Link href="/faqs/">FAQs</Nav.Link>
                                        <Nav.Link href="/blogs/" >Blogs</Nav.Link>
                                        <Nav.Link href="/contact-us/">Contact Us</Nav.Link>
                                        {/* <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" className='sub-nav2-dropdown'>
                                                Courses
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-2">Online Course</Dropdown.Item>
                                                <Dropdown.Item href="/inperson-course/">In-person Course</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                        {/* <Nav.Link href="/chat-with-astrologer/" className='sub-nav2-btn'>
                                            <Button className="bg-orange text-white header-btn">Chat with Astrologer</Button>
                                        </Nav.Link>
                                        <Nav.Link href="/talk-to-astrologer/" className='sub-nav2-btn ml-3'>
                                            <Button className="bg-orange text-white header-btn">Talk to An Astrologer</Button>
                                        </Nav.Link> */}
                                        {/* <Nav.Link href="/chat-with-astrologer/" >
                                            <IoChatbubbleEllipsesSharp className='mx-1' />
                                            Chat with Astrologer
                                        </Nav.Link>
                                        <Nav.Link href="/talk-to-astrologer/" className='sub-nav2-link'>
                                            <IoCall /> Talk to An Astrologer
                                        </Nav.Link> */}
                                    </Nav>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="header-res bg-blue">
                <Container >
                    <Row >
                        <Col lg={12} className='d-flex justify-content-between'>
                            <Col lg={6}>
                                <div className="header-logo ">
                                    <img src={Logo3} alt="" />
                                </div>
                            </Col>
                            <Col lg={6} className='d-flex gap-3'>
                                {
                                    user?.data?.data?.user?.phone ?
                                        <Col>
                                            <div className="notification-icon text-white ">
                                                <Nav.Link href="/notifications/">
                                                    <IoNotifications />
                                                </Nav.Link>
                                            </div>
                                        </Col>
                                        : null
                                }
                                <Col className='d-flex align-items-center'>
                                    <Nav.Link>
                                        {user?.data?.data?.user?.phone ?
                                            <>
                                                <div className="notification-icon text-white ">
                                                    <Nav.Link href="/customer-support/" className='d-flex'>
                                                        <img src={SupportIcon} alt="" />
                                                    </Nav.Link>
                                                </div>

                                            </>
                                            :
                                            <>
                                                <div onClick={LoginScreenOpen} className="login-icon login-border text-white">
                                                    <p>LogIn</p>
                                                    <FaRegUserCircle className='icon' />
                                                </div>
                                            </>
                                        }

                                    </Nav.Link>
                                </Col>

                                <Col lg={1} className="offcanvas-end me-2" onClick={handleShow}  >
                                    <div className="menu">
                                        <img src={Menu} alt="" />
                                    </div>
                                </Col>
                            </Col>
                        </Col>

                        <Offcanvas
                            show={show}
                            onHide={handleClose}
                            placement='end'
                            name='end'
                        >
                            <Offcanvas.Header closeButton>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="side-nav">
                                    <Nav>
                                        <div className='side-nav-profile'>
                                            <div className='popup-profile-img'>
                                                {user?.data?.data?.user ?
                                                    <>
                                                        {user?.data?.data?.profile ?
                                                            <img src={user?.data?.data?.profile?.file} className='profile-img' alt="" />
                                                            :
                                                            <FaRegUserCircle className='person-icon' />
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <img src={Logo} alt="" className='profile-img' />
                                                    </>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    user?.data?.data?.user ?
                                                        <>
                                                            <Dropdown.Item
                                                                onClick={() => { NavigatePage("/profile/") }}
                                                                className='login-popup-details'
                                                            >
                                                                <div className='mt-3' href=''>
                                                                    <h5>{user?.data?.data?.user?.name}</h5>
                                                                    <p>+{user?.data?.data?.user?.phone}</p>
                                                                </div>
                                                            </Dropdown.Item>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                user?.data?.data?.user ?
                                                    <>
                                                        <Nav.Link
                                                            onClick={() => { NavigatePage("/my-wallet/") }}
                                                            className='login-popup-nav'
                                                        >
                                                            <div className='d-flex justify-content-between'>
                                                                <p>Wallet</p>
                                                                <p> <span>₹ {user?.data?.data?.user?.accountbalance}</span></p>
                                                            </div>
                                                        </Nav.Link>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            user?.data?.data?.user ?
                                                <>
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            Order History
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => { NavigatePage("/order-history/call-logs/") }}>Call Logs</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => { NavigatePage("/order-history/chat-logs/") }}>Chat Logs</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                                :
                                                null
                                        }

                                        <Nav.Link href="/">Home</Nav.Link>
                                        <Nav.Link href="/chat-with-astrologer/" >
                                            Chat with Astrologer
                                        </Nav.Link>
                                        <Nav.Link href="/talk-to-astrologer/">Talk to An Astrologers</Nav.Link>
                                        <Nav.Link href="/faqs/">FAQs</Nav.Link>
                                        <Nav.Link href="/about/" >About Us</Nav.Link>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                Services
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/services/tamil-jathagam-service-for-your-children/">Children</Dropdown.Item>
                                                <Dropdown.Item href="/services/teen-horoscope-astrology-services/">Teens</Dropdown.Item>
                                                <Dropdown.Item href="/services/couples-horoscope-astrology/">Couples</Dropdown.Item>
                                                <Dropdown.Item href="/services/tamil-jathagam-services-for-elder-age/">Elderly</Dropdown.Item>
                                                <Dropdown.Item href="/services/end-of-life-horoscope-astrology-services/">End Of Life</Dropdown.Item>
                                                <Dropdown.Item href="/services/tamil-jathagam-for-Midlife-crisis/">Mid Life Crisis</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Nav.Link href="/blogs/" >Blogs</Nav.Link>
                                        <Nav.Link href="/get-your-astrology-reports/" >Personalized Prediction</Nav.Link>
                                        {/* <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                Courses
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-2">Online Course</Dropdown.Item>
                                                <Dropdown.Item href="/inperson-course/">In-person Course</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                        <Nav.Link href="/contact-us/">Contact Us</Nav.Link>

                                        <Nav.Link onClick={() => { NavigatePage("/customer-support/") }} >
                                            Customer Support
                                        </Nav.Link>
                                        <Nav.Link onClick={() => { NavigatePage("/notifications/") }} >
                                            Notifications
                                        </Nav.Link>
                                        {
                                            user?.data?.data?.user ?
                                                <>
                                                    <Nav.Link className='nav-talk-btn' onClick={UserLogOut}>Logout <MdLogout /></Nav.Link>
                                                </>
                                                :
                                                null
                                        }
                                    </Nav>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>

                    </Row>
                </Container>
            </Container >
        </>
    )
}

export default Header