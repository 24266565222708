import '../../../Styles/astrologer.css';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState, useEffect } from 'react';
import useInterval from 'use-interval';
import { Container, Row, Col } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Swal from 'sweetalert2';

import { AiFillStar } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import { MdSort } from "react-icons/md";

import Search from '../../../Assets/HomeImages/search-icon.svg';
import EmptyProfile from '../../../Assets/AstrologerImages/empty-profile.png';

import CallConnect from './CallConnect';
import ChatWaiting from './ChatWaiting';
import GetRemainingTime from './GetRemainingTime';
import FilterAstrologer from './FilterAstrologer';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetAllAstrologers, GetAstrologerStatus, LoginScreen, ConsultationTypeStatus } from '../../../Redux/Slice';

function AstrologerList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { IpAddress, Astrologers, AstrologerStatus, Loading, User, ConsultationType } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    const waitingId = localStorage.getItem("waitingId");
    const chatSessionId = localStorage.getItem("messageSessionId");
    let sortBy = JSON.parse(localStorage.getItem("sortby"));
    let filter = JSON.parse(localStorage.getItem("filter"));

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const pageLimit = window.innerWidth < 600 ? 15 : 24;

    const [current_page, setCurrent_page] = useState(Astrologers?.data?.pagination?.currentpage);
    const [showFilter_or_sort, setShowFilter_or_sort] = useState({
        status: false,
        type: ''
    });
    const [searchValue, setSearchValue] = useState(null);

    const closeFilter = (value) => {
        setShowFilter_or_sort({ ...showFilter_or_sort, status: value });
    }

    useInterval(() => {
        dispatch(GetAstrologerStatus());
    }, 10000);

    useEffect(() => {
        if (pathname.includes("chat-with-astrologer")) {
            dispatch(ConsultationTypeStatus({ ConsultationType: "chat" }));
            window.scrollTo(0, 0);
        }

        else if (pathname.includes("talk-to-astrologer")) {
            dispatch(ConsultationTypeStatus({ ConsultationType: "call" }));
            window.scrollTo(0, 0);
        }
    }, [dispatch, pathname]);

    useEffect(() => {
        if (Astrologers?.data) {
            localStorage.setItem("currentPage", Astrologers?.data?.pagination?.currentpage);
            localStorage.setItem("pageLimit", pageLimit);
            setCurrent_page(Astrologers?.data?.pagination?.currentpage);
        }
    }, [Astrologers, current_page, pageLimit]);

    useEffect(() => {
        if (current_page) {
            dispatch(GetAllAstrologers({ page: current_page, pagelimit: pageLimit }));
            dispatch(GetAstrologerStatus());
        }
        else {
            dispatch(GetAllAstrologers({ page: 1, pagelimit: pageLimit }));
            dispatch(GetAstrologerStatus());
        }

        if (sortBy) {
            localStorage.removeItem("sortby");
        }
    }, [dispatch]);

    useEffect(() => {
        if (searchValue) {
            dispatch(GetAllAstrologers({ page: 1, pagelimit: pageLimit, search: searchValue }));
        }
        else if (searchValue == '') {
            dispatch(GetAllAstrologers({ page: 1, pagelimit: pageLimit }));
        }
    }, [dispatch, searchValue, pageLimit])


    const handleRecharge = () => {
        if (!token) {
            dispatch(LoginScreen({ OpenStatus: true }));
        }
        else {
            navigate('/add-wallet-money/');
        }
    }

    const handleClick = (item) => {
        if (ConsultationType == "call") {
            navigate(`/talk-to-astrologer/${item?.profileDetail?.slug}/${item?.profileDetail?.id}`);
        }
        else if (ConsultationType == "chat") {
            navigate(`/chat-with-astrologer/${item?.profileDetail?.slug}/${item?.profileDetail?.id}`);
        }
    }

    const handlePaid = (item) => {
        let astrologerAmount = Number(item?.profileDetail?.userpayspermin ? item?.profileDetail?.userpayspermin : item?.profileDetail?.myrate);
        let minimumAmount = 5 * astrologerAmount;
        if (!token) {
            dispatch(LoginScreen({ OpenStatus: true }));
        }
        else if (Number(User?.data?.data?.user?.accountbalance) < minimumAmount) {
            Swal.fire({
                text: `You have INR ${User?.data?.data?.user?.accountbalance} in your wallet. To initiate a call, a minimum balance of  INR ${minimumAmount} (5 minutes) must be paid to start call.`,
                showCancelButton: true,
                cancelButtonColor: "#025BFD",
                confirmButtonColor: "#319F43",
                confirmButtonText: "Recharge"
            }).then((result) => {
                if (result?.isConfirmed) {
                    navigate('/add-wallet-money/');
                }
            });
        }
        else {
            navigate(`/get-consultation/${item?.profileDetail?.slug}/${item?.profileDetail?.id}`);
        }
    }

    const changePage = (value) => {
        window.scrollTo(0, 0);
        dispatch(GetAllAstrologers({ page: value, pagelimit: pageLimit }));
    }

    const nextPage = () => {
        const next = Number(current_page) + 1;
        if (next <= Astrologers?.data?.pagination?.totalPage) {
            window.scrollTo(0, 0);
            dispatch(GetAllAstrologers({ page: next, pagelimit: pageLimit }));
        }
    }

    const previousPage = () => {
        const previousPage = Number(current_page) - 1;
        if (previousPage >= 1) {
            window.scrollTo(0, 0);
            dispatch(GetAllAstrologers({ page: previousPage, pagelimit: pageLimit }));
        }
    }

    return (
        <Fragment>
            {/* {
                ConsultationType == "call" ?
                    <CallConnect />
                    : ConsultationType == "chat" && chatSessionId ?
                        <ChatWaiting />
                        : null
            } */}
            {
                showFilter_or_sort ?
                    <FilterAstrologer
                        show={showFilter_or_sort}
                        close={closeFilter}
                        pageLimit={pageLimit}
                        sortBy={sortBy}
                        filter={filter}
                    />
                    : null
            }
            <Container fluid className='mt-5 mb-5'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={12} className='d-flex astrologer-listing-header' >
                            <Col lg={5} className='available-balance'>
                                <p>Available Balance : <span><b>₹ {User?.data?.data?.user?.accountbalance ? User?.data?.data?.user?.accountbalance : 0}</b></span></p>
                                <div className='recharge-btn'>
                                    <button onClick={() => { handleRecharge() }}>Recharge</button>
                                </div>
                            </Col>

                            {Astrologers?.data ?
                                <Fragment>
                                    <Col lg={7} sm={12} className="d-flex justify-content-end gap-3 astrologer-nav">

                                        <button
                                            className={`${(filter || showFilter_or_sort?.type == "filter") && "active"} filter-btn`}
                                            onClick={() => { setShowFilter_or_sort({ status: true, type: 'filter' }) }}
                                        >
                                            <FaFilter className='icon' />
                                            <p>Filter</p>
                                        </button>
                                        <button
                                            className={`${(sortBy || showFilter_or_sort?.type == "sort") && "active"} filter-btn`}
                                            onClick={() => { setShowFilter_or_sort({ status: true, type: 'sort' }) }}
                                        >
                                            <MdSort className='icon' />
                                            <p>Sort by</p>
                                        </button>
                                        <div className="d-flex text-white talk-btn">
                                            <input
                                                type="text"
                                                value={searchValue}
                                                onChange={(e) => { setSearchValue(e.target.value) }}
                                            />
                                            {
                                                searchValue ?
                                                    <div
                                                        className="d-flex align-items-center p-2 cancel-btn"
                                                        onClick={() => { setSearchValue('') }}
                                                    >
                                                        <IoClose />
                                                    </div>
                                                    :
                                                    <div className="d-flex justify-content-center p-2 search-btn">
                                                        <img src={Search} alt="" />
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                </Fragment>
                                : null}

                        </Col>
                        {Loading ?
                            <Fragment>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                {Astrologers?.data?.astrologer?.length ?
                                    <Fragment >
                                        <Col lg={12} className='d-flex flex-wrap gap-3 mt-4 astroList-main'>
                                            {
                                                Astrologers?.data?.astrologer?.map((item, index) => {
                                                    let description = item?.profileDetail?.description?.data?.map(obj => obj.name);
                                                    let language = item?.profileDetail?.language?.data?.map(obj => obj.name);

                                                    let OnlineStatus = AstrologerStatus?.data?.astrologer?.find((status) => status.id === item?.profileDetail?.id);
                                                    let Call_active_status = ConsultationType == "call" && OnlineStatus?.call_active_status;
                                                    let Chat_active_status = ConsultationType == "chat" && OnlineStatus?.chat_active_status;

                                                    let remainingWaitingTime = OnlineStatus?.call_waiting_time > 0 ? GetRemainingTime(OnlineStatus?.call_waiting_time, OnlineStatus?.call_start_time, IpAddress) : null;

                                                    return (
                                                        <>
                                                            <Col lg={4} sm={6} key={index} className='astrologer-Card'>
                                                                {
                                                                    item?.profileDetail?.isDedicated ?
                                                                        <div className='top-choice-badge'>
                                                                            <p>Best choice</p>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <Col lg={12}
                                                                    className={`d-flex 
                                                                ${(ConsultationType == "call" && OnlineStatus?.call_next_activedate) ||
                                                                            (ConsultationType == "chat" && OnlineStatus?.chat_next_activedate) ||
                                                                            item?.profileDetail?.discountpercentage ?
                                                                            "justify-content-between" : "justify-content-end"} 
                                                                gap-2 pb-1`}>
                                                                    {
                                                                        item?.profileDetail?.discountpercentage ?
                                                                            <div className='offer-badge'>
                                                                                <p className='mb-0'><span>{item?.profileDetail?.discountpercentage}% off</span></p>
                                                                            </div>
                                                                            : null
                                                                    }




                                                                    {
                                                                        ConsultationType == "call" && (OnlineStatus?.call_next_activedate || OnlineStatus?.call_next_activetime) ?
                                                                            <div className='next-active'>
                                                                                <p> Next Online - {OnlineStatus?.call_next_activedate + " " + OnlineStatus?.call_next_activetime}</p>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        ConsultationType == "chat" && (OnlineStatus?.chat_next_activedate || OnlineStatus?.chat_next_activetime) ?
                                                                            <div className='next-active'>
                                                                                <p> Next Online - {OnlineStatus?.chat_next_activedate + " " + OnlineStatus?.chat_next_activetime}</p>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    <div className={Call_active_status || Chat_active_status ? 'online-btn' : 'ofline-btn'}>
                                                                        {Call_active_status || Chat_active_status ? "Online" : "Offline"}
                                                                    </div>
                                                                </Col>
                                                                <Col
                                                                    lg={12}
                                                                    sm={12}
                                                                    className='d-flex gap-2 astrologer-CardCol'
                                                                    onClick={() => { handleClick(item) }}
                                                                >
                                                                    <Col lg={4} sm={4} className='astrologerCard-img'>
                                                                        <div className='d-flex justify-content-center astro-person-img'>
                                                                            <img src={item?.profileImage?.file ? item?.profileImage?.file : EmptyProfile} alt="" />
                                                                        </div>

                                                                        <div className='free-btn mt-2'>
                                                                            <p>
                                                                                <span>Rs. {item?.profileDetail?.userpayspermin ? item?.profileDetail?.userpayspermin : item?.profileDetail?.myrate} </span>
                                                                                {item?.profileDetail?.userpayspermin ? <del>Rs. {item?.profileDetail?.myrate}</del> : null} / min
                                                                            </p>
                                                                            <p>{item?.profileDetail?.orderCount} sessions</p>
                                                                        </div>
                                                                        <div className='profileStars'>
                                                                            <div className="profileStar">
                                                                                <span>
                                                                                    {[...new Array(5)].map((arr, index) => {
                                                                                        return index < `${item?.profileDetail?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                                                    })}
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    <Col lg={8} sm={8} className='astrologerCard-content'>
                                                                        <div >
                                                                            <h6 className='font-700'>{item?.profileDetail?.name}</h6>
                                                                            <p className='description'>{description?.join(", ")}</p>
                                                                        </div>
                                                                        <div className='d-flex gap-3 exprience'>
                                                                            {item?.profileDetail?.experience ? <p>Exp:<span className='text-black font-700 px-2'>{item?.profileDetail?.experience} years</span></p> : null}
                                                                            {/* {item.qualification ? <p className='qualification-para'>Qual:<span className='text-black font-700 px-2'>{item.qualification}</span></p> : null} */}
                                                                        </div>
                                                                        <div className='language'>
                                                                            {language ? <p className='lang-para'>Lang:<span className='text-black font-700 px-2'>
                                                                                {language.join(", ")}
                                                                            </span></p> : null}

                                                                        </div>
                                                                        <div className='more-details' onClick={() => { handleClick(item) }}>
                                                                            More Details
                                                                        </div>

                                                                    </Col>
                                                                </Col>
                                                                <Col lg={4} sm={4} className='btn-web'>
                                                                    <p>{
                                                                        remainingWaitingTime?.hour > 0 && OnlineStatus?.call_waiting_time > 0 ?
                                                                            "Wait " + remainingWaitingTime?.hour + 'hrs'
                                                                            : remainingWaitingTime?.minutes > 0 && OnlineStatus?.call_waiting_time > 0 ?
                                                                                "Wait " + remainingWaitingTime?.minutes + 'm'
                                                                                : null
                                                                    }</p>
                                                                    {
                                                                        ConsultationType == "call" ?
                                                                            <div>
                                                                                <button
                                                                                    onClick={() => { handlePaid(item) }}
                                                                                    className={OnlineStatus?.call_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null ? 'yellow-btn' : 'yellow-disable-btn'}
                                                                                    disabled={!(OnlineStatus?.call_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null)}
                                                                                >
                                                                                    Call
                                                                                </button>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        ConsultationType == "chat" ?
                                                                            <div>
                                                                                <button
                                                                                    onClick={() => { handlePaid(item) }}
                                                                                    className={OnlineStatus?.chat_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null ? 'yellow-btn' : 'yellow-disable-btn'}
                                                                                    disabled={!(OnlineStatus?.chat_active_status && OnlineStatus?.call_waiting_time === 0 && waitingId == null)}
                                                                                >
                                                                                    Chat
                                                                                </button>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </Col>
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Col>

                                        <Col lg={12} className='d-flex justify-content-center mt-2 pagination'>
                                            <Pagination className="px-4">
                                                <Pagination.Prev onClick={() => previousPage()} />

                                                {[...new Array(Astrologers?.data?.pagination?.totalPage)].map((arr, index) => {
                                                    return (
                                                        <Pagination.Item
                                                            onClick={() => changePage(index + 1)}
                                                            key={index + 1}
                                                            active={index + 1 === Number(current_page)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    )
                                                })}
                                                <Pagination.Next onClick={() => nextPage()} />
                                            </Pagination>
                                        </Col>
                                    </Fragment>
                                    :
                                    <>
                                        <div className='no-data pt-5'>
                                            <p>No sufficient data available!</p>
                                        </div>
                                    </>
                                }
                            </Fragment>
                        }
                    </Row>
                </Container>
            </Container>
        </Fragment>
    );
}

export default AstrologerList;
