import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function EndoflifeBanner() {
    return (
      <>
       <Container fluid className='children-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>End Of Life</h1>
                     <p>Combining astrology and psychology can provide support and guidance for individuals facing an end-of-life crisis. Here's how this field can help</p>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default EndoflifeBanner;
  