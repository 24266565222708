import '../../Styles/faqs.css'
import { Container, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Button } from 'react-bootstrap';

function HomeFaqs() {
    return (
        <>
            <Container fluid className='my-5 py-5 bg-lightOrange'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={8} className="d-flex flex-column text-center justify-content-center">
                            <h3 className="text-orange">Frequently Asked Questions: Astrology Services by Expert Astrologers</h3>
                            {/* <p className="text-black">We provide exceptional astrology online prediction services through its pool of highly qualified and experienced astrologers. Get your Consultation now.</p> */}
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-between faqs'>
                        <Col lg={6} >
                            <div className='d-flex flex-column gap-2 mt-3 faq'>
                                <Accordion className='d-flex flex-column gap-3'>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            I am new to this platform. How do I start?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    Select the tab ‘Chat/Talk with an Astrologer’. You will find the list of astrologers and a Recharge
                                                    option.
                                                </li>
                                                <li>
                                                    Select an astrologer
                                                </li>
                                                <li>
                                                    Recharge for the amount of your choice.
                                                </li>
                                                <li>
                                                    Fill in the required details.
                                                </li>
                                                <li>
                                                    Select the chat/call option.
                                                </li>
                                                <li>
                                                    You will be directed to the astrologer and the status of the call will be displayed on the screen.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            How can I choose an Astrologer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ol>
                                                <li>Please visit the Talk to An Astrologer button to explore the numerous astrologers on our team.</li>
                                                <li>If an astrologer is online, you'll see a green button on the right corner of the astrologer's picture.</li>
                                                <li>Clicking the call button will ask you to enter your phone number to send an OTP</li>
                                            </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Is Astromind Counsel Safe? Will my details be kept secured?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, your details on the website will be secure. We are secured by the AWS security feature. For more
                                            information about Terms and condition and privacy policy please visit : <br />
                                            <a href="https://www.astromindcounsel.com/privacy-policies/">https://www.astromindcounsel.com/privacy-policies/</a><br />
                                            <a href="https://www.astromindcounsel.com/terms-of-use/">https://www.astromindcounsel.com/terms-of-use//</a>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            Does Astromind Counsel prescribe remedies?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            No, since we use the Rajanadi method for predicting the events, we don’t prescribe any sorts of
                                            expensive remedies. Instead, we prescribe a change in lifestyle and help fellow humans.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            What is Rajanadi Astrology?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Rajanadi is a new method of Astrology that uses simply the planet position and Rasis. This has been
                                            derived from Vedic astrology by the renowned Astrologer Ka. Parthiban. This method neither uses
                                            Lagnam, Natchatiram or Bhava nor prescribes Remedies.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                        <Col lg={6} >
                            <div className='d-flex flex-column gap-2 mt-3 faq-tamil'>
                                <Accordion className='d-flex flex-column gap-3'>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            நான் இந்த ஜோதிடத்தளத்தில் புதிதாக இணைந்திருக்கின்றேன். நான் எங்கிருந்து தொடங்குவது?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    முதலில் ‘Chat/Talk with an Astrologer’ என்ற இணைப்பைச் சொடுக்கவும். அப்போது
                                                    ஜோதிடர்களின் பட்டியலும் கட்டணம் ரீசார்ஜ் செய்ய இணைப்பும் புலப்படும்.
                                                </li>
                                                <li>
                                                    ஜோதிடர்களின் பட்டியலில் இருந்து நீங்கள் விரும்பும் ஜோதிடரைத் தேர்ந்தெடுங்கள்.
                                                </li>
                                                <li>
                                                    உங்களுக்குத் தேவையான கட்டணத்தை செலுத்துங்கள்.
                                                </li>
                                                <li>
                                                    உங்கள் விவரங்களைப் பதிவு செய்யுங்கள். Call/Chat ஏதாவது ஒன்றைத் தேர்வு
                                                    செய்யுங்கள்.
                                                </li>
                                                <li>
                                                    இப்போது நீங்கள் ஜோதிடரோடு இணைக்கப் படுவீர்கள். இணைப்பு விவரம் திரையில்
                                                    தெரியும்.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            நான் எப்படி இந்த இணைய தளத்தில் ஜோதிடம் கேட்பது?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ol>
                                                <li>
                                                    Talk to an Astrologer என்ற சொடுக்கியை தொட்டு எங்கள் குழுவில் உள்ள ஏராளமான
                                                    ஜோதிடர்களை அலசி அறியலாம்.
                                                </li>
                                                <li>
                                                    ஒரு ஜோதிடர் பெயர் அருகே பச்சை நிறம் ஒளிர்ந்தால் அவர் ஆன்லைன் அதாவது
                                                    ஜோதிட சேவைக்குத் தயாராக இருக்கிறார் என்று பொருள்.
                                                </li>
                                                <li>
                                                    அந்த சொடுக்கியைச் சொடுக்கினால் அது உங்களிடம் ஃபோன் எண்ணைப் பெற்று ஒரு
                                                    ‘OTP’ (ஒரு முறை கடவுச்சொல்)யை அனுப்பும்.
                                                </li>
                                                <li>
                                                    அந்த எண்ணை நிரப்புவதன் மூலம் இணைய தளத்துக்குள் நுழையலாம்.
                                                </li>
                                            </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            அஸ்ட்ரோமைண்ட் கௌன்செல் பாதுகாப்பானதா? என்னுடைய விவரங்கள் இந்த இணையத்தில்
                                            பாதுகாப்பாக இருக்குமா?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            ஆமாம். உங்கள் விவரங்கள் இந்த இணைய தளத்தில் பாதுகாப்பாக இருக்கும். நாங்கள் AWS
                                            என்ற பாதுகாப்பு அமைப்பு மூலம் பாதுகாக்கப்படுகிறோம். பாதுகாப்பு பற்றிய மேலதிக
                                            விவரங்களுக்கு இங்கே தேடவும் : <br />
                                            <a href="https://www.astromindcounsel.com/privacy-policies/">https://www.astromindcounsel.com/privacy-policies/</a><br />
                                            <a href="https://www.astromindcounsel.com/terms-of-use/">https://www.astromindcounsel.com/terms-of-use//</a>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            அஸ்ட்ரோமைண்ட் கௌன்செல் பரிகாரங்களைப் பரிந்துரைக்கிறதா?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            இல்லை. நாங்கள் ராஜநாடி முறையில் பலன்களைக் கணிப்பதால் எந்த விதமான பண மதிப்புள்ள
                                            பரிகாரத்தையும் கூறுவதில்லை. மாறாக, வாழ்க்கை முறையில் சில மாற்றங்களையும் மற்ற
                                            மனிதர்களுக்கு உதவுவதையும் பரிந்துரைக்கிறோம்.
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            ராஜ நாடி ஜோதிடம் என்பது என்ன?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            இது ஒரு புதிய ஜோதிடமுறை. இதில் கிரகங்கள் அமர்ந்துள்ள இடத்தை யும்
                                            ராசிகளை யும் மட்டுமே பயன்படுத்துகிறோம். இது பாரம்பரிய ஜோதிடத்தில்
                                            இருந்து ஜோதிடர் கா.பார்த்திபன் அவர்களால் முன்னெடுக்கப்பட்ட ஜோதிட
                                            முறை. இதில் லக்னம், நட்சத்திரம், பாவம் மற்றும் பரிகாரங்களை
                                            பயன்படுத்துவது இல்லை .
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center pt-5'>
                        <Col lg={2} className='d-flex justify-content-center'>
                            <Button href='/faqs/'>View More</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default HomeFaqs;
