import { Container, Row, Col } from 'react-bootstrap';
import '../../../Styles/user.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { UpdateRechargeStatus, GetUserProfile, successMessage, LoginScreen } from '../../../Redux/Slice';

const data = [
    {
        plan_amount: "50",
        plan_cashback: "0"
    },
    {
        plan_amount: "100",
        plan_cashback: "0"
    },
    {
        plan_amount: "150",
        plan_cashback: "0"
    },
    {
        plan_amount: "200",
        plan_cashback: "0"
    },
    {
        plan_amount: "250",
        plan_cashback: "50"
    },
    {
        plan_amount: "300",
        plan_cashback: "0"
    },
    {
        plan_amount: "400",
        plan_cashback: "0"
    },
    {
        plan_amount: "500",
        plan_cashback: "75"
    },
    {
        plan_amount: "600",
        plan_cashback: "0"
    },
    {
        plan_amount: "800",
        plan_cashback: "0"
    },
    {
        plan_amount: "1000",
        plan_cashback: "100"
    },
    {
        plan_amount: "1200",
        plan_cashback: "0"
    },
    {
        plan_amount: "1400",
        plan_cashback: "120"
    },
    {
        plan_amount: "1500",
        plan_cashback: "150"
    },
];

function RechargeList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { success, IpAddress } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    const [rechargeAmount, setRechargeAmount] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!token) {
            navigate(`/`);
            dispatch(LoginScreen({ OpenStatus: true }))
        }
    }, [token, dispatch, navigate]);

    const handleRecharge = (plan) => {
        let gst_amount = (Number(plan?.plan_amount) * 18) / 100;
        let total_amount = Number(plan?.plan_amount) + gst_amount;
        setRechargeAmount({
            "plan_amount": Number(plan?.plan_amount),
            "plan_cashback": Number(plan?.plan_cashback),
            "gst_amount": gst_amount,
            "total_amount": total_amount
        })
    }

    const handlePayment = (amount) => {
        var options = {
            key: "rzp_live_4sMB25br1OuK9Y",
            key_secret: "LfVxYjPxtJq3naKX1twpcSS3",
            amount: amount?.total_amount * 100,
            currency: "INR",
            name: "Astro Mind Counsel",
            // description: "for testing purpose",
            handler: function (response) {
                console.log(response);
                let paymentId = response.razorpay_payment_id;
                let cashback = amount?.plan_cashback;
                let status = 1;
                let address = IpAddress?.city;
                let state = IpAddress?.region;
                let country = IpAddress?.country;

                dispatch(UpdateRechargeStatus({ token, paymentId, cashback, status, address, state, country }));
            },
            // prefill: {
            //     name: "Vinoth",
            //     email: "vinoth@akkenna.com",
            //     contact: "9361098632"
            // },
            notes: {
                address: "Razorpay Corporate office"
            },
            theme: {
                color: "#030E52"
            },
            image: "https://www.astromindcounsel.com/static/media/Astro-logo.75f31095b407fbea8d7e80d016fd2b77.svg"
        };

        var pay = new window.Razorpay(options);
        pay.open();

        pay.on('payment.failed', async function (response) {
            console.log(response.error.metadata);
            let paymentId = response.error.metadata.payment_id;
            let cashback = amount?.plan_cashback;
            let status = 0;
            dispatch(UpdateRechargeStatus({ token, paymentId, cashback, status }));
        });
    }

    useEffect(() => {
        if (success?.status === 200) {
            dispatch(GetUserProfile(token));
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK"
            }).then((result) => {
                if (result?.isConfirmed) {
                    dispatch(successMessage(''));
                    navigate('/my-wallet/');
                }
            })
        }
    }, [success, token, dispatch, navigate]);

    return (
        <>
            <Container fluid className='pt-250'>
                <Container>
                    {/* <Row>
                        {Loading ?
                            <>
                                <div class="spinner-box">
                                    <div class="pulse-container">
                                        <div class="pulse-bubble pulse-bubble-1"></div>
                                        <div class="pulse-bubble pulse-bubble-2"></div>
                                        <div class="pulse-bubble pulse-bubble-3"></div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </Row> */}
                    <Row className='recharge-list-main'>
                        <Col lg={12} className='text-center'>
                            <h3>Add Money to Wallet</h3>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <h4>Popular Plans:</h4>
                            </div>
                            <div className='recharge-list mt-4 mb-5'>
                                {data.map((plans, i) => (
                                    <>

                                        <Col lg={12} className={`${Number(plans?.plan_amount) === rechargeAmount?.plan_amount && "active"} recharge-plan `} onClick={() => { handleRecharge(plans) }}>
                                            {
                                                Number(plans?.plan_amount) === rechargeAmount?.plan_amount ?
                                                    <div className='select-plan'>
                                                        <div className='selected'></div>
                                                    </div>
                                                    : null
                                            }

                                            <div className='d-flex justify-content-center'>
                                                <div className='plan-amount'>
                                                    <p>₹ {plans.plan_amount}</p>
                                                </div>

                                            </div>
                                            {
                                                plans.plan_cashback > 0 ?
                                                    <>
                                                        <div className='plan-cashback'>
                                                            <p>₹ {plans.plan_cashback} bonus</p>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </Col>

                                    </>
                                ))}
                            </div>

                            <div className='plan-detail d-flex flex-column justify-content-center text-center mt-3 mb-5'>
                                {
                                    rechargeAmount ?
                                        <>
                                            <div>
                                                <p className='plan-descripe'>You are selecting a popular plan <span>₹ {rechargeAmount?.plan_amount} {rechargeAmount?.plan_cashback ? "with ₹ " + rechargeAmount?.plan_cashback + " Bonus" : null} </span></p>
                                                <p className='plan-descripe text-red'>{rechargeAmount?.plan_cashback ? "The Bonus amount will go to the wallet" : null}</p>
                                            </div>

                                            <div className='d-flex flex-column align-items-center mt-3'>
                                                <h3>Payment Details</h3>
                                                <Col lg={8} className='payment-details m-3'>
                                                    <Col lg={12} className='d-flex detail'>
                                                        <Col lg={8}>
                                                            <p>Recharge Amount</p>
                                                        </Col>
                                                        <Col lg={4} className='payment-amount'>
                                                            <p> ₹{rechargeAmount?.plan_amount}</p>
                                                        </Col>
                                                    </Col>
                                                    <div className='d-flex detail'>
                                                        <Col lg={8}>
                                                            <p>GST %</p>
                                                        </Col>
                                                        <Col lg={4} className='payment-amount'>
                                                            <p> ₹{rechargeAmount?.gst_amount}</p>

                                                        </Col>
                                                    </div>
                                                    <div className='d-flex detail'>
                                                        <Col lg={8}>
                                                            <p className='fw-bold'>Total Amount</p>
                                                        </Col>
                                                        <Col lg={4} className='payment-amount'>
                                                            <p className='fw-bold'> ₹{rechargeAmount?.total_amount}</p>

                                                        </Col>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className='detail py-2'>
                                                <p className='text-danger'><b>Note* : </b>Do not refresh page during transaction</p>
                                            </div>
                                            <div>
                                                <button onClick={() => handlePayment(rechargeAmount)}>Proceed</button>
                                            </div>

                                        </>
                                        : null
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default RechargeList