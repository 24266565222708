import { Container, Row, Col } from 'react-bootstrap';

import UnlockYourFutureIcon1 from '../../Assets/ServiceImages/Chat-call.png'
import UnlockYourFutureIcon2 from '../../Assets/ServiceImages/Unlock-Your-Future-step-icon-2.png'
import UnlockYourFutureIcon3 from '../../Assets/ServiceImages/Recharge.png'
import UnlockYourFutureIcon4 from '../../Assets/ServiceImages/Unlock-Your-Future-step-icon-4.png'

function ConnectAstrologers() {
    return (
        <>
            <Container fluid className='blogContent-main bg-lightOrange text-center mt-4 py-4'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10}>
                            <div>
                                <h4>Connect to Astrologers Effortlessly in Four Quick Steps</h4>
                            </div>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between py-4 Quick-steps'>
                            <Col lg={3} className='d-flex flex-column align-items-center gap-3 bg-blue text-white p-3 radius-5'>
                                <div className='service-step-card'>
                                    <p>Step-1</p>
                                </div>
                                <div>
                                    <img src={UnlockYourFutureIcon2} alt="" />
                                </div>
                                <div>
                                    <p>Enter your mobile number to sign up or Login your account.</p>
                                </div>
                            </Col>
                            <Col lg={3} className='d-flex flex-column align-items-center gap-3 bg-blue text-white p-3 radius-5'>
                                <div className='service-step-card'>
                                    <p>Step-2</p>
                                </div>
                                <div>
                                    <img src={UnlockYourFutureIcon3} alt="" />
                                </div>
                                <div>
                                    <p>Recharge the popular plan.</p>
                                </div>
                            </Col>
                            <Col lg={3} className='d-flex flex-column align-items-center gap-3 bg-blue text-white p-3 radius-5'>
                                <div className='service-step-card'>
                                    <p>Step-3</p>
                                </div>
                                <div>
                                    <img src={UnlockYourFutureIcon1} alt="" />
                                </div>
                                <div>
                                    <p>Chat or call your preferred astrologer.</p>
                                </div>
                            </Col>
                            <Col lg={3} className='d-flex flex-column align-items-center gap-3 bg-blue text-white p-3 radius-5'>
                                <div className='service-step-card'>
                                    <p>Step-4</p>
                                </div>
                                <div>
                                    <img src={UnlockYourFutureIcon4} alt="" />
                                </div>
                                <div>
                                    <p>Our astrologer will respond in a few minutes.</p>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default ConnectAstrologers;