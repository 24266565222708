import { Helmet } from 'react-helmet';
import PersonalizedHoroscopeBanner from './PersonalizedHoroscopeBanner';
import PersonalizedHoroscopeContent from './PersonalizedHoroscopeContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";
import TopAstrologers from '../TopAstrologers';

function PersonalizedHoroscope() {
    return (
        <>
            <Helmet>
                <title>Best Tamil Jothidam from our expert Tamil astrologers | AMC</title>
                <meta name="description" content="Discover your destiny with accurate Tamil Jathagam. Get personalized horoscope readings, astrological predictions & insights from our expert Tamil astrologers." />
                <meta name="keywords" content="Personalized Horoscope, Tamil Jathagam, Tamil Jothidam" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/services/best-tamil-jothidam-from-our-expert-tamil-astrologers/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/services/best-tamil-jothidam-from-our-expert-tamil-astrologers/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Best Tamil Jothidam from our expert Tamil astrologers | AMC" />
                <meta property="og:description" content="Discover your destiny with accurate Tamil Jathagam. Get personalized horoscope readings, astrological predictions & insights from our expert Tamil astrologers." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Best Tamil Jothidam from our expert Tamil astrologers | AMC" />
                <meta name="twitter:description" content="Discover your destiny with accurate Tamil Jathagam. Get personalized horoscope readings, astrological predictions & insights from our expert Tamil astrologers." />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <PersonalizedHoroscopeBanner />
            <PersonalizedHoroscopeContent />
            <ConnectAstrologers />
            <TopAstrologers />
        </>
    );
}

export default PersonalizedHoroscope;
