import '../../../Styles/astrologer.css'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AiFillStar } from "react-icons/ai";
import EmptyProfile from '../../../Assets/AstrologerImages/empty-profile.png';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ConsultationTypeStatus } from '../../../Redux/Slice';

function SimilarAstrologer({ astrologer }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Astrologers, ConsultationType } = useSelector((state) => state.Astromind);

    const [isFollow, setIsfollow] = useState(false);

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    useEffect(() => {
        if (pathname.includes("chat-with-astrologer")) {
            // setConsultation_type("chat");
            dispatch(ConsultationTypeStatus({ ConsultationType: "chat" }));
        }

        else if (pathname.includes("talk-to-astrologer")) {
            // setConsultation_type("call");
            dispatch(ConsultationTypeStatus({ ConsultationType: "call" }));

        }
    }, [pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleClick = (item) => {
        if(ConsultationType == "chat"){
            navigate(`/chat-with-astrologer/${item?.profileDetail?.slug}/${item?.profileDetail?.id}`);
        }
        else if(ConsultationType == "call"){
            navigate(`/talk-to-astrologer/${item?.profileDetail?.slug}/${item?.profileDetail?.id}`);
        }
    }

    // const handlePaid = (astro) => {
    //     const status = "paid";
    //     navigate(`/get-consultation/${astro.id}/${status}`);
    // }

    var settings = {
        dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        // autoplay: true,
        // autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2,

                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,

                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,

                },
            },
        ],

    };
    return (
        <>
            {astrologer ?
                <Container fluid className="d-flex mt-5 mb-5 similar-astrologer">
                    <Container >
                        <Col lg={12}>
                            <Row className="d-flex justify-content-center">
                                <Col lg={8} className="d-flex flex-column text-center justify-content-center">
                                    <h3 className="text-blue">View the Similar Astrologer </h3>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col lg={12} className="d-flex justify-content-center gap-5 mt-5 astrologer-list">
                                    <Slider {...settings}>
                                        {
                                            Astrologers?.data?.astrologer?.map((item, index) => {
                                                let description = item?.profileDetail?.description?.data.map(obj => obj.name);
                                                let language = item?.profileDetail?.language?.data.map(obj => obj.name);
                                                return (
                                                    <Col lg={12} sm={6} className='astrologer-Card p-3'>
                                                        <Col lg={12} sm={10} className='d-flex gap-2 astrologer-CardCol'>
                                                            <Col lg={4} sm={4} className='astrologerCard-img'>
                                                                <div className='d-flex justify-content-center astro-person-img'>
                                                                    <img src={item?.profileImage?.file ? item?.profileImage?.file : EmptyProfile} alt="" />
                                                                </div>

                                                                <div className='profileStars'>
                                                                    <div className="profileStar">
                                                                        <span>
                                                                            {[...new Array(5)].map((arr, index) => {
                                                                                return index < `${item?.profileDetail?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                                            })}
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={8} sm={8} className='astrologerCard-content'>
                                                                <div >
                                                                    <h6 className='font-700'>{item?.profileDetail?.name}</h6>
                                                                    <p className='description'>{description.join(", ")}</p>

                                                                </div>
                                                                <div className='d-flex gap-3 exprience'>
                                                                    {item?.profileDetail?.experience ? <p>Exp:<span className='text-black font-700 px-2'>{item?.profileDetail?.experience} years</span></p> : null}
                                                                    {/* {item.qualification ? <p>Qual:<span className='text-black font-700 px-2'>{item.qualification}</span></p> : null} */}
                                                                </div>
                                                                <div className='language'>
                                                                    {item?.profileDetail?.language ? <p className='lang-para'>Lang:<span className='text-black font-700 px-2'>
                                                                        {language.join(", ")}
                                                                        {/* {item?.profileDetail?.language} */}
                                                                    </span></p> : null}
                                                                    {/* {item.A_timings ? <p className='timing'>Timings:<span className='text-black font-700 px-2'>
                                                        {item['A_timings'].join(" / ")}</span></p> : null} */}
                                                                </div>
                                                            </Col>
                                                        </Col>
                                                        <Col lg={12} sm={2} className='btn-web'>
                                                            <div>
                                                                <button onClick={() => { handleClick(item) }} className='yellow-btn'>More</button>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Slider>

                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Container>

                : null
            }
        </>
    );
}

export default SimilarAstrologer;