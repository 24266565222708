import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

import Card1 from '../../../Assets/ServiceImages/children-card-icon-1.svg'
import Card2 from '../../../Assets/ServiceImages/children-card-icon-2.svg'
import Card3 from '../../../Assets/ServiceImages/children-card-icon-3.svg'
import Card4 from '../../../Assets/ServiceImages/children-card-icon-4.svg'
import Card5 from '../../../Assets/ServiceImages/children-card-icon-5.svg'
import Card6 from '../../../Assets/ServiceImages/children-card-icon-6.svg'

import serviceLeftImg from '../../../Assets/ServiceImages/service-card-left-img.svg'
import serviceRightImg from '../../../Assets/ServiceImages/service-card-right-img.svg'

function EndoflifeCard() {
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <div className="service-left-img">
                    <img src={serviceLeftImg} alt="" />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap gap-4 service-cards'>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card1} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Existential Exploration</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist individuals in exploring existential questions and finding meaning and purpose as they approach the end of life. Astrology offers a symbolic language to contemplate life’s bigger questions, such as the nature of existence, the soul’s journey, and the interconnectedness of all things. Psychological approaches, such as existential therapy or mindfulness practices, can facilitate the exploration of personal values, legacy, and the significance of one’s life.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card2} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Emotional Support</h5>
                                    </div>
                                    <div>
                                        <p>The end-of-life phase can be emotionally challenging, involving feelings of fear, sadness, or regret. Astrology and psychology can provide emotional support during this time. Astrology can offer insights into the planetary influences that affect emotional states, while psychological approaches can offer techniques for managing grief, anxiety, and emotional distress. This integration can assist individuals in processing their emotions, finding comfort, and fostering emotional well-being.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card3} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Life Review and Completion</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can facilitate the process of life review and finding a sense of completion. Astrology can provide a framework for reflecting on the patterns, themes, and lessons that have emerged throughout one’s life. Psychological approaches, such as narrative therapy or reminiscence therapy, can support individuals in integrating their life experiences, acknowledging their achievements, and finding closure. This process can contribute to a sense of peace and acceptance at the end of life.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card4} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Legacy and Meaningful Contributions</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist individuals in considering their legacy and exploring opportunities for meaningful contributions, even as they near the end of life. Astrology can offer insights into the unique gifts and wisdom individuals can share with others. Psychological approaches can support individuals in identifying ways to leave a positive impact, whether through sharing their stories, mentoring others, or engaging in acts of kindness and service. This exploration can provide a sense of purpose and leave a lasting legacy.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Spirituality and Transcendence</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can support individuals in exploring their spirituality and finding moments of transcendence as they approach the end of life. Astrology can offer insights into the spiritual dimensions of one’s life journey and the potential for growth and transformation. Psychological approaches can facilitate practices that promote a sense of connection to something greater than oneself, whether through meditation, prayer, or engaging in meaningful rituals. This exploration can bring comfort, solace, and a sense of peace during the end-of-life phase.</p>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <div className="service-right-img">
                    <img src={serviceRightImg} alt="" />
                </div>
            </Container>

        </>
    );
}

export default EndoflifeCard;
