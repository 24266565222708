import '../../Styles/about.css'
import { Container, Row, Col } from 'react-bootstrap';
import WhyChoose from '../../Assets/AboutImages/why-choose-img.png'
import checkBox from '../../Assets/AboutImages/check-box-icon.svg'


function AboutWhychoose() {
    return (
        <>
            <Container fluid className='aboutWhy-main pt-5 pb-5 mb-5'>
                
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex aboutwhyCol'>
                            <Col lg={6} className='d-flex flex-column justify-content-center'>
                                <div>
                                    <h3 className='text-orange pb-2'>Why Choose Us</h3>
                                    <p><b>We are here</b></p>
                                </div>
                                <div>
                                    <div className='d-flex gap-3 whychose-check mb-4'>
                                        <img src={checkBox} alt="" />
                                        <p>To give stunning and precise predictions.</p>
                                    </div>
                                    <div className='d-flex gap-3 whychose-check  mb-4'>
                                        <img src={checkBox} alt="" />
                                        <p>To Bring astrology as a science to the future generation.</p>
                                    </div>
                                    <div className='d-flex gap-3 whychose-check  mb-4'>
                                        <img src={checkBox} alt="" />
                                        <p>To identify spirituality through the elemental approach.</p>
                                    </div>
                                    <div className='d-flex gap-3 whychose-check  mb-4'>
                                        <img src={checkBox} alt="" />
                                        <p>To educate as many as possible, so one can identify the TRUE SELF.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className='whyChoose d-flex justify-content-center'>
                                <img src={WhyChoose} alt="" />
                            </Col>
                        </Col>
                    </Row>
                </Container>
               
            </Container>
        </>
    );
}

export default AboutWhychoose;
