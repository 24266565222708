import '../../../Styles/login.css';
import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

import { TiArrowRightThick } from "react-icons/ti";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LoginScreen, SendOtp, ReactiveUserProfile, ValitateOtp, errorMessage, successMessage } from '../../../Redux/Slice';

function OtpVerify({ Number, Message }) {
    const dispatch = useDispatch();
    const { Loading, success, errors, OtpStatus, User } = useSelector((state) => state.Astromind);
    
    const [otpValues, setOtpValues] = useState("");
    const [count, setCount] = useState(60);
    const otpId = localStorage.getItem("otpid");

    useEffect(() => {
        if (count > 0 && OtpStatus) {
            setTimeout(() => {
                setCount(count - 1);
            }, 1000)
        }
    }, [count, OtpStatus]);

    const handleVerifySubmit = (e) => {
        e.preventDefault();
        const OtpArray = otpValues.map(str => +str);
        const Otp = OtpArray.join('');
        if (Number?.phone) {
            let data = {
                phone: Number?.phone,
                otp: Otp,
                otpid: otpId
            }
            dispatch(ValitateOtp(data));
        }
        else if (Number?.email) {
            let data = {
                email: Number?.email,
                otp: Otp,
                otpid: otpId
            }
            dispatch(ValitateOtp(data));
        }
    };

    useEffect(() => {
        if(success?.data?.OTPID){
          localStorage.setItem("otpid", success?.data?.OTPID);
        }
        else if (User?.status === 200) {
            if (User?.data?.isExist === true) {
                Message(User?.data?.message);
                localStorage.removeItem("otpid");
                dispatch(LoginScreen({ OtpStatus: false, ConfirmStatus: true }));
            }
            else {
                localStorage.removeItem("otpid");
                dispatch(LoginScreen({ OtpStatus: false, DetailStatus: true }));
            }
        }

        else if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Ok",
                timer: 5000
            }).then(() => {
                dispatch(errorMessage({ errors: '' }))
            })
        }
    }, [success, errors, User, dispatch, Message]);

    const otpClose = () => {
        dispatch(LoginScreen({ OtpStatus: false }));
    }

    const inputRefs = useRef([]);

    const focusNextInput = (index) => {
        if (index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };
    const focusBackInput = (index) => {
        if (index < inputRefs.current.length && index !== 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleInputChange = (index, e) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = e.target.value;
        setOtpValues(newOtpValues);
        if (e.target.value.trim() !== '') {
            focusNextInput(index);
        }
        else if (e.target.value.trim() === '') {
            focusBackInput(index);
        }
    };

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    const resendOtp = () => {
        if (Number?.phone) {
            var phone = {
                phone: Number?.phone
            }
            dispatch(SendOtp(phone));
        }
        else if (Number?.email) {
            var email = {
                email: Number?.email
            }
            dispatch(SendOtp(email));
        }
        setCount(60);
    }

    const handleReactiveAccount = (id) => {
        if (id) {
            dispatch(ReactiveUserProfile({ id }));
        }
        else {
            dispatch(successMessage(' '));
            dispatch(LoginScreen({ OpenStatus: true }));
        }
    }

    const handleLogin = () => {
        dispatch(successMessage(' '));
        dispatch(LoginScreen({ OpenStatus: true }));
    }

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={OtpStatus}
                onHide={otpClose}
                className='login-modal'
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {
                        success?.data?.isDeleted ?
                            <div className='Otp-form'>
                                <div className='text-center pt-2 pb-3'>
                                    <h1>Reactive Your Account</h1>
                                </div>
                                <div className='text-center'>
                                    <p>{success?.message}</p>
                                </div>

                                <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                                    <Button
                                        className='otp-button bg-white text-black'
                                        onClick={(e) => { handleReactiveAccount(success?.data?.delreqId) }}
                                    >
                                        Continue
                                    </Button>
                                    <Button
                                        className='otp-button bg-white text-black'
                                        onClick={(e) => { handleReactiveAccount() }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            : success?.data?.data?.isRemoved ?
                                <div className='Otp-form'>
                                    <div className='text-center'>
                                        <p>{success?.data?.message}</p>
                                    </div>

                                    <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                                        <Button
                                            className='otp-button bg-white text-black'
                                            onClick={(e) => { handleLogin() }}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </div>
                                :
                                <div className='Otp-form'>
                                    <div className='text-center pt-2 pb-3'>
                                        <h1>Lets Verify!</h1>
                                    </div>
                                    <div className='text-center'>
                                        <p>Enter OTP sent on Moblie number or Email address  {Number?.phone ? `+${Number?.phone}` : Number?.email}. Your OTP is only valid for ten minutes.</p>
                                    </div>
                                    <div className='otp-input-fields'>
                                        {[1, 2, 3, 4, 5, 6].map((field, index) => (
                                            <input
                                                key={index}
                                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                                maxLength="1"
                                                size="1"
                                                className={`otp__digit otp__field__${field}`}
                                                ref={(el) => (inputRefs.current[index] = el)}
                                                onInput={(e) => handleInputChange(index, e)}
                                                value={otpValues[index]}
                                            />
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-center pt-3 pb-3'>
                                        <Button
                                            className={otpValues[5] ? 'otp-button bg-orange' : 'otp-button bg-orange disabled'}
                                            onClick={(e) => { handleVerifySubmit(e) }}
                                        >
                                            {Loading ? <div className='loader'></div> : <>Verify <TiArrowRightThick /></>}
                                        </Button>
                                    </div>
                                    <div className='resend-otp text-center'>
                                        <p>Please Click here to Resend OTP {count > 0 ? <span className='mx-2 text-danger'> {count}s</span> : <a onClick={() => resendOtp()} className='mx-2'><b>click</b></a>}  </p>
                                    </div>
                                </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OtpVerify;