import { Helmet } from 'react-helmet';

import ChildrenBanner from "./ChildrenBanner";
import ChildrenCard from "./ChildrenCard";
import ChildrenCta from "./ChildrenCta";
import TalkAstrologer from "../TalkAstrologer";

function Children() {
  return (
    <>
    <Helmet>
        <title>Personalized Online Tamil Jathagam Service for Your Children</title>
        <meta name="description" data-rh="true" content="Unlock the future for your children with our Online Tamil Jathagam Service. Accurate horoscope predictions tailored to guide them on their life journey" />
        <meta name="keywords" data-rh="true" content="tamil jathagam, jathagam for kids, online jathagam for school kids, online astrology for children, jathagam service" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/services/tamil-jathagam-service-for-your-children/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/services/tamil-jathagam-service-for-your-children/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Personalized Online Tamil Jathagam Service for Your Children" />
        <meta property="og:description" content="Unlock the future for your children with our Online Tamil Jathagam Service. Accurate horoscope predictions tailored to guide them on their life journey" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Personalized Online Tamil Jathagam Service for Your Children" />
        <meta name="twitter:description" content="Unlock the future for your children with our Online Tamil Jathagam Service. Accurate horoscope predictions tailored to guide them on their life journey" />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

     <ChildrenBanner />
     <ChildrenCard />
     <ChildrenCta />
     <TalkAstrologer />
    </>
  );
}

export default Children;
