import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios';

const initialState = {
    Loading: '',
    success: '',
    errors: '',
    IpAddress: null,
    OpenStatus: false,
    OtpStatus: false,
    DetailStatus: false,
    ConfirmStatus: false,
    ConsultationMessage: '',
    OtpMessage: '',
    ConsultationType: null,
    User: {},
    UserProfile: {},
    SubUserProfile: {},
    Astrologers: [],
    DedicatedAstrologers: [],
    AstrologerProfile: null,
    AstrologerStatus: null,
    PaymentHistory: null,
    WalletHistory: null,
    OrderHistory: null,
    CallStatus: null,
    ChatStatus: null,
    InprogressChatStatus: null,
    ChatHistory: null,
    ChatConnectStatus: null,
    ReviewsForAstrologer: null,
    Blogs: null,
    Blog: null,
    Tickets: null,
    TicketMessages: null,
    Notifications: null,
};

export const GetIPAddress = createAsyncThunk(
    'page/IPAddress',
    async (_, { rejectWithValue }) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            }
            const response = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_API_IPINFO}`, { headers: headers })
            if (response?.status == 200 || response.status == 201) {
                const { data } = response
                return data;
            }
        } catch (error) {
            return rejectWithValue('');
        }
    }
)

// Signup and Login Module

export const CheckLoginStatus = createAsyncThunk(
    'page/CheckLoginStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/check-session`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const SendOtp = createAsyncThunk(
    'page/sendOtp',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/send-otp`, val);
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error?.message);
        }
    },
);

export const ValitateOtp = createAsyncThunk(
    'page/otpValidate',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/user/otp-validate`, val);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const AddNewUser = createAsyncThunk(
    'page/addNewUser',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/user/new-user`, val);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

// User Profile Module

export const GetUserProfile = createAsyncThunk(
    'page/UserProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-profile`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateUserProfile = createAsyncThunk(
    'page/UpdateUserProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/user/edit-profile`, val.userData,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const UpdateUserProfileImg = createAsyncThunk(
    'page/UpdateUserProfileImg',
    async (val, { rejectWithValue }) => {
        const headers = {
            "authorization": val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            if (val?.ExistFile === null) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/upload-file?first=1`, val.formData,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/update-file/${val?.fileId}`, val.formData,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteUserProfile = createAsyncThunk(
    'page/DeleteUserProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/delete-account`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const ReactiveUserProfile = createAsyncThunk(
    'page/ReactiveUserProfile',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/remove-delete-request/${val?.id}`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteUserProfileImg = createAsyncThunk(
    'page/DeleteUserProfileImg',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/delete-file/${val?.fileId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

// Payment Module

export const UpdateRechargeStatus = createAsyncThunk(
    'page/UpdateRechargeStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/recharge?success=${val.status}&rzrId=${val?.paymentId}&bonus=${val?.cashback}&address=${val?.address}&state=${val?.state}&country=${val?.country}`
                , { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetPaymentLogs = createAsyncThunk(
    'page/GetPaymentLogs',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/my-transactions/${val?.page}`
                , { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetWalletTransactions = createAsyncThunk(
    'page/GetWalletTransactions',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/my-wallet-transactions/${val?.page}`
                , { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

// Order Module

export const GetOrderHistory = createAsyncThunk(
    'page/GetOrderHistory',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/get-orderhistroy/${val?.page}?type=${val?.type}`
                , { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

// Astrologers Module

export const GetAllAstrologers = createAsyncThunk(
    'page/GetAllAstrologers',
    async (val, { rejectWithValue }) => {
        try {
            if (val?.search) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/user/search/${val?.page}?name=${val?.search}&pagelimit=${val?.pagelimit}`);
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.sort) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/user/sort/${val?.page}?sort=${val?.sort}&sortby=${val?.sortby}&pagelimit=${val?.pagelimit}`);
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.filter) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/user/filter`, val?.filter);
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/user/getall-astrologers/${val?.page}?pagelimit=${val?.pagelimit}`);
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetDedicatedAstrologers = createAsyncThunk(
    'page/GetDedicatedAstrologer',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-dedicated-astrologers/${1}`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetSingleAstrologer = createAsyncThunk(
    'page/GetSingleAstrologer',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-astrologer/${val}`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologerStatus = createAsyncThunk(
    'page/GetAstrologerStatus',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-astrologer-status`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetSubUsers = createAsyncThunk(
    'page/GetSubUsers',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-my-subusers`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const AstrologerRegister = createAsyncThunk(
    'page/AstrologerRegister',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/new-astrologer`, val);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

// Call and Chat Module

export const MakeCallWithAstrologer = createAsyncThunk(
    'page/MakeCallWithAstrologer',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/user/call`, val.CounsultationData, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetCallStatus = createAsyncThunk(
    'page/GetCallStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-mycall-status?orderId=${val?.orderId}&waitingId=${val?.waitingId}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetChatStatus = createAsyncThunk(
    'page/GetChatStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-chat-status/${val?.orderId}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetInprogressChatStatus = createAsyncThunk(
    'page/GetInprogressChatStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/get-chat-status-on-chatting/${val?.orderId}?isUserIn=1`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetChatSession = createAsyncThunk(
    'page/GetChatSession',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-session-messages/${val?.messageSessionId}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const UpdateChatExtendOrEnd = createAsyncThunk(
    'page/UpdateChatExtendOrEnd',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/extend-or-endchat/${val?.chatUpdate?.orderId}?isExtended=${val?.chatUpdate?.isExtended}&extendedDuration=${val?.chatUpdate?.extendedDuration}&isendedbyuser=${val?.chatUpdate?.isendedbyuser}&isendedbyastrologer=${val?.chatUpdate?.isendedbyastrologer}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const AddReviewForAstrologer = createAsyncThunk(
    'page/AddReviewForAstrologer',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/user/review-astrologer`, val?.reviewData, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetReviews = createAsyncThunk(
    'page/GetReviews',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-reviews/${val?.astrologerId}`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const FollowOrUnfollow = createAsyncThunk(
    'page/FollowOrUnfollow',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/follow-astrologer/${val.id}?followOrUnfollow=${val.followStatus}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

// Blogs Module

export const GetAllBlogs = createAsyncThunk(
    'page/GetAllBlogs',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-all-blogs/${val?.page}?pagelinit=${val?.pagelimit}`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetBlog = createAsyncThunk(
    'page/GetBlog',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-blog/${val?.slug}/`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

// Support Chat Module

export const CreateTicket = createAsyncThunk(
    'page/CreateTicket',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/create-support-ticket`, val?.data,
                { headers: headers }
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetAllTickets = createAsyncThunk(
    'page/GetAllTickets',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-my-tickets`,
                { headers: headers }
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetTicketMessages = createAsyncThunk(
    'page/GetTicketMessages',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-support-messages/${val?.ticketId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

// Notifications Module

export const GetAllNotifications = createAsyncThunk(
    'page/GetAllNotifications',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/get-notifications`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateNotificationSeenStatus = createAsyncThunk(
    'page/UpdateNotificationSeenStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/set-seen-notification/${val?.id}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const ReduxSlice = createSlice({
    name: 'astromind',
    initialState: initialState,
    reducers: {
        errorMessage: (state, action) => {
            state.errors = action.payload.errors;
        },
        successMessage: (state, action) => {
            state.success = action.payload.successess;
        },
        loadingStatus: (state, action) => {
            state.Loading = action.payload.loadingStatus;
        },
        LoginScreen: (state, action) => {
            state.OpenStatus = action.payload.OpenStatus;
            state.OtpStatus = action.payload.OtpStatus;
            state.DetailStatus = action.payload.DetailStatus;
            state.ConfirmStatus = action.payload.ConfirmStatus;
        },
        OtpSendStatus: (state, action) => {
            state.Loading = action.payload.OtpMessage;
        },
        UserData: (state, action) => {
            state.User = action.payload.User;
        },
        CallData: (state, action) => {
            state.CallStatus = action.payload.CallData;
        },
        ConsultationTypeStatus: (state, action) => {
            state.ConsultationType = action.payload.ConsultationType;
        },
        InprogressChatStatusMessage: (state, action) => {
            state.InprogressChatStatus = action.payload.InprogressChatStatusMessage;
        },
    },
    extraReducers: (builder) => {
        builder
            // Get Ip Address
            .addCase(GetIPAddress.pending, (state) => {
                state.IpAddress = ''
                state.Loading = true
            })
            .addCase(GetIPAddress.fulfilled, (state, action) => {
                state.Loading = false
                state.IpAddress = action.payload
            })
            .addCase(GetIPAddress.rejected, (state, action) => {
                state.Loading = false
                state.IpAddress = ''
            })

            //Add New User
            .addCase(CheckLoginStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CheckLoginStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CheckLoginStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Send Otp
            .addCase(SendOtp.pending, (state) => {
                state.Loading = true;
            })
            .addCase(SendOtp.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
                state.OtpStatus = true;
                state.OpenStatus = false;
            })
            .addCase(SendOtp.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Valitate Otp
            .addCase(ValitateOtp.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ValitateOtp.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.User = action.payload;
            })
            .addCase(ValitateOtp.rejected, (state, action) => {
                state.errors = action.payload;
                state.User = {};
                state.Loading = false;
            })

            //Add New User
            .addCase(AddNewUser.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AddNewUser.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AddNewUser.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Get User Profile
            .addCase(GetUserProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetUserProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.User = action.payload;
            })
            .addCase(GetUserProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.User = {};
            })

            //Update User Profile
            .addCase(UpdateUserProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateUserProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateUserProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.User = {};
            })

            //Update User Profile Image
            .addCase(UpdateUserProfileImg.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateUserProfileImg.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateUserProfileImg.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Delete User Profile 
            .addCase(DeleteUserProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteUserProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteUserProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Reactive User Profile 
            .addCase(ReactiveUserProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ReactiveUserProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(ReactiveUserProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Delete User Profile Image
            .addCase(DeleteUserProfileImg.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteUserProfileImg.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteUserProfileImg.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Update Recharge Status
            .addCase(UpdateRechargeStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateRechargeStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateRechargeStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })


            //Get Payment Tranactions
            .addCase(GetPaymentLogs.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetPaymentLogs.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.PaymentHistory = action.payload;
            })
            .addCase(GetPaymentLogs.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.PaymentHistory = null;
            })

            //Get Wallet Tranactions
            .addCase(GetWalletTransactions.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetWalletTransactions.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.WalletHistory = action.payload;
            })
            .addCase(GetWalletTransactions.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.WalletHistory = null;
            })

            //Get Order History
            .addCase(GetOrderHistory.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetOrderHistory.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.OrderHistory = action.payload;
            })
            .addCase(GetOrderHistory.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.OrderHistory = null;
            })



            //Get All Astrologers Profile
            .addCase(GetAllAstrologers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllAstrologers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Astrologers = action.payload;
            })
            .addCase(GetAllAstrologers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Astrologers = [];
            })

            //Get All Dedicated Astrologers Profile
            .addCase(GetDedicatedAstrologers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetDedicatedAstrologers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.DedicatedAstrologers = action.payload;
            })
            .addCase(GetDedicatedAstrologers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.DedicatedAstrologers = [];
            })

            //Get Single Astrologer Profile
            .addCase(GetSingleAstrologer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetSingleAstrologer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologerProfile = action.payload;
            })
            .addCase(GetSingleAstrologer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologerProfile = [];
            })

            //Get Astrologer Status
            .addCase(GetAstrologerStatus.pending, (state) => {
                state.Loading = false;
            })
            .addCase(GetAstrologerStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologerStatus = action.payload;
            })
            .addCase(GetAstrologerStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologerStatus = [];
            })

            //Get Sub User Profile
            .addCase(GetSubUsers.pending, (state) => {
                state.Loading = false;
            })
            .addCase(GetSubUsers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.SubUserProfile = action.payload;
            })
            .addCase(GetSubUsers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.SubUserProfile = null;
            })

            //Make Call With Astrologer 
            .addCase(MakeCallWithAstrologer.pending, (state) => {
                state.Loading = false;
            })
            .addCase(MakeCallWithAstrologer.fulfilled, (state, action) => {
                state.Loading = true;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(MakeCallWithAstrologer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Call Status 
            .addCase(GetCallStatus.pending, (state) => {
                state.Loading = false;
            })
            .addCase(GetCallStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.CallStatus = action.payload;
            })
            .addCase(GetCallStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.CallStatus = null;
            })

            //Get Chat Status 
            .addCase(GetChatStatus.pending, (state) => {
                state.Loading = false;
            })
            .addCase(GetChatStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ChatStatus = action.payload;
            })
            .addCase(GetChatStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ChatStatus = null;
            })

            //Get Inprogress Chat Status 
            .addCase(GetInprogressChatStatus.pending, (state) => {
                state.Loading = false;
            })
            .addCase(GetInprogressChatStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.InprogressChatStatus = action.payload;
            })
            .addCase(GetInprogressChatStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.InprogressChatStatus = null;
            })

            //chat history
            .addCase(GetChatSession.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetChatSession.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ChatHistory = action.payload;
            })
            .addCase(GetChatSession.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ChatHistory = null;
            })

            //Chat Extent or End
            .addCase(UpdateChatExtendOrEnd.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateChatExtendOrEnd.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ChatConnectStatus = action.payload;
            })
            .addCase(UpdateChatExtendOrEnd.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ChatConnectStatus = null;
            })

            //Add Review For Astrologer
            .addCase(AddReviewForAstrologer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AddReviewForAstrologer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AddReviewForAstrologer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Astrologers Reviews
            .addCase(GetReviews.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetReviews.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ReviewsForAstrologer = action.payload;
            })
            .addCase(GetReviews.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ReviewsForAstrologer = null;
            })

            //Get Follow Status 
            .addCase(FollowOrUnfollow.pending, (state) => {
                state.Loading = false;
            })
            .addCase(FollowOrUnfollow.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(FollowOrUnfollow.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = null;
            })

            //Register New Astrologers Profile
            .addCase(AstrologerRegister.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AstrologerRegister.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(AstrologerRegister.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get All Blogs
            .addCase(GetAllBlogs.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllBlogs.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Blogs = action.payload;
            })
            .addCase(GetAllBlogs.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Blogs = null;
            })

            //Get Blog
            .addCase(GetBlog.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetBlog.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Blog = action.payload;
            })
            .addCase(GetBlog.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Blog = null;
            })

            //Create a Support Ticket
            .addCase(CreateTicket.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CreateTicket.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CreateTicket.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Support Tickets
            .addCase(GetAllTickets.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllTickets.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Tickets = action.payload;
            })
            .addCase(GetAllTickets.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Tickets = null;
            })

            //Get Tickets Messages
            .addCase(GetTicketMessages.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetTicketMessages.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.TicketMessages = action.payload;
            })
            .addCase(GetTicketMessages.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.TicketMessages = null;
            })

            //Get All Notifications
            .addCase(GetAllNotifications.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllNotifications.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Notifications = action.payload;
            })
            .addCase(GetAllNotifications.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Notifications = null;
            })

            //Update Notification Seen Status 
            .addCase(UpdateNotificationSeenStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateNotificationSeenStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateNotificationSeenStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })
    },
})

export const { errorMessage, successMessage, loadingStatus, LoginScreen, OtpSendStatus, UserData, CallData, ConsultationTypeStatus, InprogressChatStatusMessage } = ReduxSlice.actions;
export default ReduxSlice.reducer;