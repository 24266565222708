import '../../../Styles/login.css';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LoginScreen, GetSingleAstrologer, GetSubUsers, MakeCallWithAstrologer, successMessage, errorMessage } from '../../../Redux/Slice';

import { FaCaretRight } from "react-icons/fa";
import NewUserForm from './NewUserForm';

function ConsultationDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Loading, SubUserProfile, User, AstrologerProfile, success, ConsultationType, errors } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    const { id } = useParams();
    const { slug } = useParams();

    const [isNewUser, setIsNewUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [astrologer, setAstrologer] = useState(null);
    const astrologerAmount = Number(astrologer?.astrologer?.userpayspermin ? astrologer?.astrologer?.userpayspermin : astrologer?.astrologer?.myrate);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(GetSingleAstrologer(slug));
    }, [dispatch, slug]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(GetSubUsers(token));
    }, [dispatch, token]);

    // useEffect(() => {
    //     if (id) {
    //         // const data = AstrologerProfile?.data?.data?.astrologer?.find((astro) => astro.profileDetail.id == id);
    //         // console.log(data);
    //         setAstrologer(AstrologerProfile?.data?.data);
    //     }
    // }, [AstrologerProfile, id]);

    // useEffect(() => {
    //     if (!token) {
    //         navigate(`/`);
    //         dispatch(LoginScreen({ OpenStatus: true }));
    //     }
    //     if (ConsultationType == null) {
    //         navigate(-1);
    //     }
    // }, [dispatch, navigate, token, ConsultationType]);

    useEffect(() => {

        if (id) {
            setAstrologer(AstrologerProfile?.data?.data);
        }

        if (!token) {
            navigate(`/`);
            dispatch(LoginScreen({ OpenStatus: true }));
        }
        if (ConsultationType == null) {
            navigate(-1);
        }

        if (success?.status === 200) {
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then(() => {
                if (success?.data?.data?.WaitingId) {
                    localStorage.setItem("orderId", success?.data?.data?.orderId);
                    localStorage.setItem("waitingId", success?.data?.data?.WaitingId);
                    if (ConsultationType == "chat") {
                        localStorage.setItem("messageSessionId", success?.data?.data?.chatSessionId);
                        localStorage.setItem("roomName", success?.data?.data?.roomName);
                        localStorage.setItem("astrologerId", success?.data?.data?.astrologerId);
                        localStorage.setItem("astrologerImage", astrologer?.profile?.file);
                    }
                }
                dispatch(successMessage(''));
                navigate(-1);
            })
        }
        else if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then(() => {
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, navigate, success, errors, ConsultationType, astrologer, token, AstrologerProfile, id]);

    const backNewUser = () => {
        setIsNewUser(false);
    }

    const handleSelectUser = (e) => {
        let value = e.target.value;
        let selectedValue = SubUserProfile?.data?.data?.find((item) => item.name == value);
        setSelectedUser(selectedValue);
    }

    const handleMakeCall = () => {
        let DurationLimit;
        let isFixedDuration;
        let WalletBalance = Number(User?.data?.data?.user?.accountbalance);

        if (selectedDuration === "Unlimited" || selectedDuration === null) {
            DurationLimit = WalletBalance / astrologerAmount;
            isFixedDuration = 0;
        }
        else {
            DurationLimit = selectedDuration;
            isFixedDuration = 1;
        }

        let CallDuration = DurationLimit ? Number(DurationLimit) * 60 : 5 * 60;
        let CallTotalRate = DurationLimit ? Number(DurationLimit) * astrologerAmount : 5 * astrologerAmount;

        if (Number(WalletBalance < CallTotalRate) || WalletBalance < (5 * astrologerAmount)) {
            Swal.fire({
                text: `You have INR ${WalletBalance} in your wallet. To initiate a call, a minimum balance of  INR ${ DurationLimit ?  DurationLimit * astrologerAmount : 5 * astrologerAmount } (${DurationLimit ? DurationLimit : 5 } minutes) must be paid to start call.`,
                showCancelButton: true,
                cancelButtonColor: "#025BFD",
                confirmButtonColor: "#319F43",
                confirmButtonText: "Recharge"
            }).then((result) => {
                if (result?.isConfirmed) {
                    navigate('/add-wallet-money/');
                }
            });
        }

        else {
            let CalculationRate = (CallDuration / 60) * astrologerAmount;
            if (Number(WalletBalance < CalculationRate)) {
                Swal.fire({
                    text: `Oops! Something's not right. Please refresh and try again.`,
                    confirmButtonColor: "#025BFD",
                    confirmButtonText: "Refresh"
                }).then((result) => {
                    if (result?.isConfirmed) {
                        window.location.reload(false);
                    }
                });
            }
            else {
                let CounsultationData = {
                    astrologerId: Number(id),
                    name: selectedUser?.name,
                    gender: selectedUser?.gender,
                    dateofbirth: selectedUser?.dateofbirth,
                    timeofbirth: selectedUser?.timeofbirth,
                    placeofbirth: selectedUser.placeofbirth,
                    maritalstatus: selectedUser?.maritalstatus,
                    type: ConsultationType == "call" ? "call" : "chat",
                    totalrate: CallTotalRate,
                    duration: Math.floor(CallDuration),
                    duration_isfixed: isFixedDuration,
                    subuserId: selectedUser?.id,
                }
                localStorage.setItem("userData", JSON.stringify(CounsultationData));
                localStorage.setItem("astrologerRate", astrologerAmount);
                dispatch(MakeCallWithAstrologer({ token, CounsultationData }));
            }
        }
    }

    return (
        <>
            {
                isNewUser ?
                    <NewUserForm
                        backNewUser={backNewUser}
                        id={id}
                        astrologerRate={astrologerAmount}
                    />
                    :
                    <Container fluid className='pt-250 mb-5'>
                        <Container>
                            <Row className='d-flex justify-content-center consultation-details'>
                                <Col lg={8} className='available-balance mb-4'>
                                    <p>Available Balance : <span><b>₹ {User?.data?.data?.user?.accountbalance}</b></span></p>
                                    <div className='recharge-btn'>
                                        <button
                                            onClick={() => { navigate('/add-wallet-money/') }}
                                        >
                                            Recharge
                                        </button>
                                    </div>
                                </Col>
                                <Col lg={8} className='bg-blue p-5 radius-5'>
                                    <Col lg={12} className='detail-form'>
                                        <div className='text-white text-center pt-2 pb-3'>
                                            <h3>Select User Details</h3>
                                        </div>
                                        <Col lg={12} className='d-flex flex-wrap justify-content-between '>
                                            <Col lg={6} className="half-form d-flex flex-column mb-4">
                                                <label >User name<span className='text-darkOrange'>*</span></label>
                                                {
                                                    SubUserProfile ?
                                                        <select
                                                            id='selectedUser'
                                                            name='selectedUser'
                                                            value={selectedUser?.name}
                                                            onChange={(e) => { handleSelectUser(e) }}
                                                        >
                                                            <option value="">Choose</option>
                                                            {SubUserProfile?.data?.data?.map((item, i) => (
                                                                <>
                                                                    <option value={item?.name} >{item?.name}</option>
                                                                </>
                                                            ))}
                                                        </select> :
                                                        <select
                                                            id='selectedUser'
                                                            name='selectedUser'
                                                            value={selectedUser}
                                                            onChange={(e) => { handleSelectUser(e) }}
                                                        >
                                                            <option value={User?.data?.data?.user?.name} >{User?.data?.data?.user?.name}</option>
                                                        </select>
                                                }
                                            </Col>
                                            <Col lg={6} className="half-form duration-form d-flex flex-column mb-4">
                                                <label >Duration<span className='text-darkOrange'>*</span></label>
                                                <select
                                                    id='selectedDuration'
                                                    name='selectedDuration'
                                                    value={selectedDuration}
                                                    onChange={(e) => { setSelectedDuration(e.target.value) }}
                                                >
                                                    <option value="5" >05 min</option>
                                                    <option value="10">10 min</option>
                                                    <option value="15">15 min</option>
                                                    <option value="20">20 min</option>
                                                    <option value="25">25 min</option>
                                                    <option value="30">30 min</option>
                                                    <option value="45">45 min</option>
                                                    <option value="60">60 min</option>
                                                    <option value="Unlimited" selected>Limitless</option>
                                                </select>
                                            </Col>
                                        </Col>
                                    </Col>
                                    <Col lg={12} className='new-user-form'>
                                        <div className='detail-form py-3'>
                                            <Col lg={12} className='d-flex flex-wrap justify-content-center  text-white'>
                                                <Col lg={6} className='half-form mb-4'>
                                                    <h5>Name</h5>
                                                    <p>{selectedUser?.name ? selectedUser?.name : "none"}</p>
                                                </Col>

                                                <Col lg={6} className='half-form mb-4'>
                                                    <h5>Gender</h5>
                                                    <p>{selectedUser?.gender ? selectedUser?.gender : "none"}</p>
                                                </Col>

                                                <Col lg={6} className='half-form mb-4'>
                                                    <h5>Birth Time</h5>
                                                    <p>{selectedUser?.timeofbirth ? selectedUser?.timeofbirth : "none"}</p>
                                                </Col>
                                                <Col lg={6} className='half-form mb-4'>
                                                    <h5>Date of Birth</h5>
                                                    <p>{selectedUser?.dateofbirth ? selectedUser?.dateofbirth : "none"}</p>
                                                </Col>
                                                <Col lg={6} className='half-form mb-4'>
                                                    <h5>Birth Place</h5>
                                                    <p>{selectedUser?.placeofbirth ? selectedUser?.placeofbirth : "none"}</p>
                                                </Col>

                                                <Col lg={6} className='half-form mb-4'>
                                                    <h5>Marital status</h5>
                                                    <p>{selectedUser?.maritalstatus ? selectedUser?.maritalstatus : "none"}</p>
                                                </Col>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col lg={12} className='new-user-form'>
                                        <hr className='text-white'></hr>
                                        <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                                            <Button
                                                className='otp-button addNew-button bg-white'
                                                onClick={() => { setIsNewUser(true) }}
                                            >
                                                Add Another User Details <FaCaretRight className='icon' />
                                            </Button>
                                            <Button
                                                className='otp-button bg-orange'
                                                onClick={() => handleMakeCall()}
                                                disabled={selectedUser === null}
                                            >
                                                {Loading ?
                                                    <div className='loader'></div>
                                                    : ConsultationType == "call" ? "Call"
                                                        : ConsultationType == "chat" ? "Chat"
                                                            : null}
                                            </Button>
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
            }
        </>
    )
}

export default ConsultationDetails;