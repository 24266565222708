import React, { useContext, useEffect } from "react";
import { Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from "antd";
import MultiStepFormContext from "./MultiStepFormContext";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from 'multiselect-react-dropdown';
import { IoImages } from "react-icons/io5";
import NextArrow from '../../../../Assets/Version2Images/next-arrow-img.png';
import PhoneInput from "react-phone-input-2";
import Swal from 'sweetalert2';

const PersonalDetails = () => {
  const { details, setDetails, next } = useContext(MultiStepFormContext);

  const expertiseOption = [{ "name": 'Vedic Astrology' }, { "name": 'Face Reading' }, { "name": 'KP' }, { "name": 'Life Coach' }, { "name": 'Nadi' }, { "name": 'Numerology' }, { "name": 'Palmistry' }, { "name": 'Prashana' }, { "name": 'Psychic' }, { "name": 'Psychologist' }, { "name": 'Tarot' }];
  const languageOption = [{ "name": 'English' }, { "name": 'Tamil' }, { "name": 'Hindi' }, { "name": 'Telugu' }, { "name": 'Malayalam' }, { "name": 'Kannada' }];

  const calculateAge = (dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }


  const formik = useFormik({
    initialValues: details,
    onSubmit: async (values) => {
      setDetails(values);
      next();
    },
    validate: (values) => {
      const errors = {};
      const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes

      if (!values.name) {
        errors.name = "Requried*";
      }
      if (!values.email) {
        errors.email = "Required*";
      }
      else if (values.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address";
      }
      if (!values.phone) {
        errors.phone = "Required*";
      }
      if (values.expertise == null) {
        errors.expertise = "Required*";
      }
      if (values.language == null) {
        errors.language = "Required*";
      }
      if (!values.exprience) {
        errors.exprience = "Required*";
      }
      if (values.dateofbirth) {
        const age = calculateAge(values.dateofbirth);
        formik.setFieldValue('age', age);
      }
      if (values.profile) {
        const fileType = values.profile.type;
        const fileSize = values.profile.size;

        if (!supportedFormats.includes(fileType)) {
          errors.profile = 'Unsupported image format. Please upload a JPG, JPEG, PNG, WEBP, or GIF file.';
        }
        else if (fileSize > maxFileSize) {
          errors.profile = 'File size should be less than 2 MB.';
        }
        else {
          // Create a FileReader to read the image file
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              const { width, height } = img;
              if (width > 150 || height > 150) {
                errors.profile = 'Image dimensions should be less than 150x150 pixels.';
                formik.setErrors(errors); // Set errors in Formik state
              }
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(values.profile);
        }
      }
      return errors;
    }
  })

  const nextSubmit = () => {
    if (formik.errors.name || formik.errors.expertise || formik.errors.exprience || formik.errors.language || formik.errors.phone) {
      console.log(formik.errors);
      Swal.fire({
        title: `Some data are missing. Kindly add them.`,
        icon: "error",
        iconColor: "#CA0505",
        confirmButtonColor: "#CA0505",
        confirmButtonText: "Okay",
        timer: 5000,
      })
    }
    else {
      formik.handleSubmit()
    }
  }
  return (
    <>
      <form action="" onSubmit={formik.handleSubmit}>
        <div className={"details__wrapper register-form mb-5"}>
          <Col lg={6} className="p-3">
            <div className="mb-4">
              <h3>Personal Details</h3>
            </div>

            <div className={`form__item ${formik.errors.name && "input__error"} mb-4`}>
              <label>Enter your Name <span className="text-red">*</span></label>
              <input
                name="name"
                className="px-3"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <p className={"error__feedback"}>{formik.errors.name}</p>
            </div>

            <div className={`form__item ${formik.errors.email && "input__error"} mb-4`}>
              <label>Enter your Email<span className="text-red">*</span> </label>
              <input
                name="email"
                className="px-3"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <p className={"error__feedback"}>{formik.errors.email}</p>
            </div>

            <div className={`form__item ${formik.errors.phone && "input__error"} mb-4`}>
              <label>Phone Number <span className="text-red">*</span></label>
              <PhoneInput
                country={'in'}
                id='phone'
                name='phone'
                value={formik.values.phone}
                onChange={(value) => { formik.setFieldValue('phone', value) }}
              />
              <p className={"error__feedback"}>{formik.errors.phone}</p>
            </div>

            <div className="d-flex gap-3">
              <div className={`form__item ${formik.errors.dateofbirth && "input__error"} half-form `}>
                <label>Date Of Birth </label>
                <DatePicker
                  className="px-3"
                  showIcon
                  placeholderText="Select Date"
                  selected={formik.values.dateofbirth}
                  onChange={(value) => { formik.setFieldValue('dateofbirth', value) }}
                  monthsShown={1}
                  maxDate={new Date()}
                  showYearDropdown
                  dropdownMode="select"
                />
                <p className={"error__feedback"}>{formik.errors.dateofbirth}</p>
              </div>
              <div className={`form__item ${formik.errors.age && "input__error"} half-form `}>
                <label>Age</label>
                <input
                  type="number"
                  name="age"
                  className="px-3"
                  value={formik.values.age}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="d-flex gap-3 ">

              <div
                className={`form__item ${formik.errors.gender && "input__error"} half-form `}
              >
                <label>Gender </label>
                <select
                  name="gender"
                  className="px-2"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                >
                  <option value="">Choose</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <p className={"error__feedback"}>{formik.errors.gender}</p>
              </div>

              <div className={`form__item ${formik.errors.maritalstatus && "input__error"} half-form`}>
                <label>Marital Status </label>
                <select
                  name="maritalstatus"
                  className="px-3"
                  value={formik.values.maritalstatus}
                  onChange={formik.handleChange}
                >
                  <option value="">Choose</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                </select>
                <p className={"error__feedback"}>{formik.errors.maritalstatus}</p>
              </div>
            </div>

            <div className="dz-default dlab-message upload-img bg-lightOrange p-2 mb-3">
              <form action="#" className="dropzone astro-file-upload">
                <IoImages className="file-icon" />

                <div className="fallback">
                  <input
                    type="file"
                    name="profile"
                    onChange={(e) => {
                      formik.setFieldValue('profile', e.target.files[0])
                    }}
                    multiple
                  />
                </div>
                {
                  formik?.values?.profile && !formik.errors.profile ?
                    <div className="upload-img-text">
                      <p className="file-name">{formik?.values?.profile?.name}</p>
                    </div>
                    :
                    <div className="upload-img-text">
                      <p>Upload Your Profile Image</p>
                      <p className="sub-note"><span className="text-red">Note:</span> Passport size photo with Plain background (jpg, png, jpeg, webp only) and less than 150x150 pixels</p>
                    </div>
                }

              </form>
            </div>
            <p className={"error__feedback"}>{formik.errors.profile}</p>

          </Col>

          <Col lg={6} className="p-3">
            <div className="mb-4">
              <h3>
                Professional Details
              </h3>
            </div>

            <div className="form__item mb-4" >
              <label>Areas of Expertise <span className="text-red">*</span></label>
              <Multiselect
                options={expertiseOption}
                selectedValues={formik.values.expertise}
                onSelect={(value) => { formik.setFieldValue('expertise', value) }}
                displayValue="name"
              />
              <p className={"error__feedback"}>{formik.errors.expertise}</p>
            </div>

            <div className="form__item mb-4" >
              <label>Languages <span className="text-red">*</span></label>
              <Multiselect
                options={languageOption}
                selectedValues={formik.values.language}
                onSelect={(value) => { formik.setFieldValue('language', value) }}
                displayValue="name"
              />
              <p className={"error__feedback"}>{formik.errors.language}</p>
            </div>

            <div className="d-flex gap-3">

              <div className={`form__item ${formik.errors.exprience && "input__error"} half-form`}>
                <label>Experience <span className="text-red">*</span></label>
                <input
                  type="number"
                  name="exprience"
                  className="px-3"
                  value={formik.values.exprience}
                  onChange={formik.handleChange}
                />
                <p className={"error__feedback"}>{formik.errors.exprience}</p>
              </div>

              <div className={`form__item ${formik.errors.qualification && "input__error"} half-form`}>
                <label>Qualification </label>
                <input
                  type="text"
                  name="qualification"
                  className="px-3"
                  value={formik.values.qualification}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className={`form__item ${formik.errors.profession && "input__error"} mb-4`}>
              <label>Profession </label>
              <input
                type="text"
                name="profession"
                className="px-3"
                value={formik.values.profession}
                onChange={formik.handleChange}
              />
            </div>

            <div className={`form__item ${formik.errors.about && "input__error"}`}>
              <label>Who I Am</label>
              <textarea
                type="text"
                name="about"
                className="px-3"
                value={formik.values.about}
                onChange={formik.handleChange}
              />
            </div>

          </Col>

          <div
            className={"form__item2 button__items d-flex justify-content-center mx-4"}
          >
            <Button
              type={"primary"}
              className="register-btn"
              onClick={() => nextSubmit()}
            >
              Next
              <img src={NextArrow} alt="" />
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default PersonalDetails;
