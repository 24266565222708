import '../../Styles/faqs.css'
import { Container, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

import Step1 from '../../Assets/HowitworksImages/free-step1-icon.svg'
import Step2 from '../../Assets/HowitworksImages/free-step2-icon.svg'
import Step3 from '../../Assets/HowitworksImages/free-step3-icon.svg'
import Step4 from '../../Assets/HowitworksImages/free-step4-icon.svg'
import Step5 from '../../Assets/HowitworksImages/free-step5-icon.svg'
import Step6 from '../../Assets/HowitworksImages/free-step6-icon.svg'
import PaidStep3 from '../../Assets/HowitworksImages/paid-step3-icon.svg'
import PaidStep5 from '../../Assets/HowitworksImages/paid-step5-icon.svg'
import PaidStep6 from '../../Assets/HowitworksImages/paid-step6-icon.svg'

function FaqsContent() {
  return (
    <>
      <Container fluid className='mt-5 mb-5'>
        <Container>
          <Row className='d-flex justify-content-between faqs'>
            <Col lg={6} className='p-4  bg-lightOrange' >
              <div className='d-flex'>
                <div className='bg-orange text-white call-btn'>
                  <p>English</p>
                </div>

              </div>
              <div className='d-flex flex-column gap-2 mt-3 faq'>
                <Accordion className='d-flex flex-column gap-3'>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What is Rajanadi Astrology?
                    </Accordion.Header>
                    <Accordion.Body>
                      Rajanadi is a new method of Astrology that uses simply the planet position and Rasis. This has been
                      derived from Vedic astrology by the renowned Astrologer Ka. Parthiban. This method neither uses
                      Lagnam, Natchatiram or Bhava nor prescribes Remedies.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Is Astromind Counsel Safe? Will my details be kept secured?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, your details on the website will be secure. We are secured by the AWS security feature. For more
                      information about Terms and condition and privacy policy please visit : <br />
                      <a href="https://www.astromindcounsel.com/privacy-policies/">https://www.astromindcounsel.com/privacy-policies/</a><br />
                      <a href="https://www.astromindcounsel.com/terms-of-use/">https://www.astromindcounsel.com/terms-of-use//</a>

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Does Astromind Counsel prescribe remedies?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, since we use the Rajanadi method for predicting the events, we don’t prescribe any sorts of
                      expensive remedies. Instead, we prescribe a change in lifestyle and help fellow humans.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How can I choose an Astrologer?
                    </Accordion.Header>
                    <Accordion.Body>
                      <ol>
                        <li>Please visit the Talk to An Astrologer button to explore the numerous astrologers on our team.</li>
                        <li>If an astrologer is online, you'll see a green button on the right corner of the astrologer's picture.</li>
                        <li>Clicking the call button will ask you to enter your phone number to send an OTP</li>
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      I am new to this platform. How do I start?
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          Select the tab ‘Chat/Talk with an Astrologer’. You will find the list of astrologers and a Recharge
                          option.
                        </li>
                        <li>
                          Select an astrologer
                        </li>
                        <li>
                          Recharge for the amount of your choice.
                        </li>
                        <li>
                          Fill in the required details.
                        </li>
                        <li>
                          Select the chat/call option.
                        </li>
                        <li>
                          You will be directed to the astrologer and the status of the call will be displayed on the screen.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How do I select an astrologer?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can use the Filter option to choose an astrologer based on language, skills, etc. you can use the Sort
                      option to identify the astrologers with various experience levels. You can also use the search option to
                      search by name.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      How do I select my comfortable language?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can use the language filter option to choose your comfortable language
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Is Chatting and talking cost me different?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, the cost is based on the minutes you interact with the astrologer. Both calling and chatting cost you
                      the same price.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      How do I get predictions for one or more of my family members?
                    </Accordion.Header>
                    <Accordion.Body>
                      You get add additional member details option to add the details of your family members/friends.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      How do I extend my call if the recharge amount gets over during the consultation?
                    </Accordion.Header>
                    <Accordion.Body>
                      You will get a notification and an option to recharge. You can continue after recharging. To avoid such
                      trouble, you can top up for a comparatively higher amount. That amount will stay safe in your account
                      and you can use it when you visit the portal the next time.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      Should I share my horoscope copy created by my family astrologer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Not required. Sharing your date of birth along with the required details will do.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header>
                      Are there any restrictions in your predictions?
                    </Accordion.Header>
                    <Accordion.Body>
                      There are a few. Questions related to the sex of an unborn baby, life span of ailing family members,
                      winning possibilities of lottery/race/share market, winning court cases including criminal proceedings,
                      survival chances of patients undergoing surgical procedure, etc. are not predicted.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      Are you providing service for marriage matching?
                    </Accordion.Header>
                    <Accordion.Body>
                      Not exactly. We analyze the overall character of the persons involved and their possible compatibility.
                      The decision is ultimately your choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      Are you providing only Rajanadi based predictions?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. However, some traditional services are also available without contradicting the basic principles of
                      Rajanadi.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="15">
                    <Accordion.Header>
                      What other major service do you provide other than future prediction?
                    </Accordion.Header>
                    <Accordion.Body>
                      We provide psychological consultation to those who need it. This service is provided based on the birth
                      chart of the customer, after analyzing their need to get support/motivation/guidance to get through the
                      struggle indicated in the chart.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="16">
                    <Accordion.Header>
                      I have seen a call for donation. Is there a purpose behind this call?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. A major part of the income from this website is used to support a hospice centre called “Motcham”
                      a facility that provides palliative care and emotional support to the terminally ill.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>
            </Col>
            <Col lg={6} className='p-4  bg-lightOrange' >
              <div className='d-flex'>
                <div className='bg-orange text-white call-btn'>
                  <p>தமிழ்</p>
                </div>

              </div>
              <div className='d-flex flex-column gap-2 mt-3 faq-tamil'>
                <Accordion className='d-flex flex-column gap-3'>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      ராஜ நாடி ஜோதிடம் என்பது என்ன?
                    </Accordion.Header>
                    <Accordion.Body>
                      இது ஒரு புதிய ஜோதிடமுறை. இதில் கிரகங்கள் அமர்ந்துள்ள இடத்தை யும்
                      ராசிகளை யும் மட்டுமே பயன்படுத்துகிறோம். இது பாரம்பரிய ஜோதிடத்தில்
                      இருந்து ஜோதிடர் கா.பார்த்திபன் அவர்களால் முன்னெடுக்கப்பட்ட ஜோதிட
                      முறை. இதில் லக்னம், நட்சத்திரம், பாவம் மற்றும் பரிகாரங்களை
                      பயன்படுத்துவது இல்லை .
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      அஸ்ட்ரோமைண்ட் கௌன்செல் பாதுகாப்பானதா? என்னுடைய விவரங்கள் இந்த இணையத்தில்
                      பாதுகாப்பாக இருக்குமா?
                    </Accordion.Header>
                    <Accordion.Body>
                      ஆமாம். உங்கள் விவரங்கள் இந்த இணைய தளத்தில் பாதுகாப்பாக இருக்கும். நாங்கள் AWS
                      என்ற பாதுகாப்பு அமைப்பு மூலம் பாதுகாக்கப்படுகிறோம். பாதுகாப்பு பற்றிய மேலதிக
                      விவரங்களுக்கு இங்கே தேடவும் : <br />
                      <a href="https://www.astromindcounsel.com/privacy-policies/">https://www.astromindcounsel.com/privacy-policies/</a><br />
                      <a href="https://www.astromindcounsel.com/terms-of-use/">https://www.astromindcounsel.com/terms-of-use//</a>

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      அஸ்ட்ரோமைண்ட் கௌன்செல் பரிகாரங்களைப் பரிந்துரைக்கிறதா?
                    </Accordion.Header>
                    <Accordion.Body>
                      இல்லை. நாங்கள் ராஜநாடி முறையில் பலன்களைக் கணிப்பதால் எந்த விதமான பண மதிப்புள்ள
                      பரிகாரத்தையும் கூறுவதில்லை. மாறாக, வாழ்க்கை முறையில் சில மாற்றங்களையும் மற்ற
                      மனிதர்களுக்கு உதவுவதையும் பரிந்துரைக்கிறோம்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      நான் எப்படி இந்த இணைய தளத்தில் ஜோதிடம் கேட்பது?
                    </Accordion.Header>
                    <Accordion.Body>
                      <ol>
                        <li>
                          Talk to an Astrologer என்ற சொடுக்கியை தொட்டு எங்கள் குழுவில் உள்ள ஏராளமான
                          ஜோதிடர்களை அலசி அறியலாம்.
                        </li>
                        <li>
                          ஒரு ஜோதிடர் பெயர் அருகே பச்சை நிறம் ஒளிர்ந்தால் அவர் ஆன்லைன் அதாவது
                          ஜோதிட சேவைக்குத் தயாராக இருக்கிறார் என்று பொருள்.
                        </li>
                        <li>
                          அந்த சொடுக்கியைச் சொடுக்கினால் அது உங்களிடம் ஃபோன் எண்ணைப் பெற்று ஒரு
                          ‘OTP’ (ஒரு முறை கடவுச்சொல்)யை அனுப்பும்.
                        </li>
                        <li>
                          அந்த எண்ணை நிரப்புவதன் மூலம் இணைய தளத்துக்குள் நுழையலாம்.
                        </li>
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      நான் இந்த ஜோதிடத்தளத்தில் புதிதாக இணைந்திருக்கின்றேன். நான் எங்கிருந்து தொடங்குவது?
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          முதலில் ‘Chat/Talk with an Astrologer’ என்ற இணைப்பைச் சொடுக்கவும். அப்போது
                          ஜோதிடர்களின் பட்டியலும் கட்டணம் ரீசார்ஜ் செய்ய இணைப்பும் புலப்படும்.
                        </li>
                        <li>
                          ஜோதிடர்களின் பட்டியலில் இருந்து நீங்கள் விரும்பும் ஜோதிடரைத் தேர்ந்தெடுங்கள்.
                        </li>
                        <li>
                          உங்களுக்குத் தேவையான கட்டணத்தை செலுத்துங்கள்.
                        </li>
                        <li>
                          உங்கள் விவரங்களைப் பதிவு செய்யுங்கள். Call/Chat ஏதாவது ஒன்றைத் தேர்வு
                          செய்யுங்கள்.
                        </li>
                        <li>
                          இப்போது நீங்கள் ஜோதிடரோடு இணைக்கப் படுவீர்கள். இணைப்பு விவரம் திரையில்
                          தெரியும்.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      நான் எப்படி ஒரு குறிப்பிட்ட ஜோதிடரை தேர்வு செய்வது?
                    </Accordion.Header>
                    <Accordion.Body>
                      நீங்கள் மொழி அல்லது திறமை அடிப்படையில் உங்களுக்கு ஏற்ற ஜோதிடரைத் தேர்ந்தெடுக்க
                      வடிகட்டி/பில்டர் பயன்படுத்தலாம். ஜோதிடர்களின் அனுபவம் மற்றும் அவர்களின் கட்டண
                      அடிப்படையில் வகைப்படுத்திப் பார்க்க (Sort by) என்ற அமைப்பைப் பயன்படுத்தலாம். இவை
                      தவிர குறிப்பிட்ட ஜோதிடரின் பெயரையும் நீங்கள் தேடி எடுக்கலாம்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      எனக்கு வசதியான மொழியை எப்படி தேர்வு செய்வது?
                    </Accordion.Header>
                    <Accordion.Body>
                      மொழி வடிகட்டும் வசதியைப் பயன்படுத்தி உங்களுக்கு வசதியான மொழியைத் தேர்வு
                      செய்யலாம்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      ஜோதிடரோடு பேசுவதற்கும் எழுத்து பூர்வமாக கேள்விகள் கேட்பதற்கும் வேறு வேறு கட்டணம்
                      வசூலிக்கப் படுகிறதா?
                    </Accordion.Header>
                    <Accordion.Body>
                      இல்லை. இரண்டுக்கும் ஒரே கட்டணம்தான். நீங்கள் எத்தனை நிமிடம் ஜோதிடரோடு தொடர்பில்
                      இருக்கிறீர்கள் என்பதைப் பொறுத்து கட்டணம் மாறும்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      நான் என் குடும்பத்தில் உள்ள மற்ற உறுப்பினர்களுக்கு எப்படி பலன் கேட்பது?
                    </Accordion.Header>
                    <Accordion.Body>
                      நீங்கள் ‘Additional Member Details’ பட்டனை சொடுக்கி ஒன்றுக்கு மேற்பட்ட உறுப்பினர்
                      விவரத்தை பதிவிடலாம்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      நான் பலன் கேட்டுக்கொண்டிருக்கும்போது நான் செலுத்திய கட்டணம் தீர்ந்து விட்டால் எப்படி
                      தொடர்வது?
                    </Accordion.Header>
                    <Accordion.Body>
                      உங்களுக்கு மேற்கொண்டு பணம் கட்ட (ரீசார்ஜ்) அறிவிப்பு வரும். பணம் செலுத்திய பிறகு
                      நீங்கள் மீண்டும் தொடரலாம். ஒருவேளை இப்படி இடையூறு ஏற்படக்கூடாது என்று நினைத்தால்
                      நீங்கள் தொடக்கத்திலேயே தேவையான அளவுக்கு கட்டணம் செலுத்திக்கொள்ளலாம்.
                      அதிகப்படி தொகை இருக்குமானால் அது உங்கள் கணக்கிலேயே பத்திரமாக இருக்கும். நீங்கள்
                      மறுமுறை பயன்படுத்திக் கொள்ளலாம்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      நான் என் குடும்ப ஜோதிடர் கணித்த நோட்டுப் புத்தகத்தைப் பகிர வேண்டுமா? பலர் அப்படிக்
                      கேட்கிறார்களே?
                    </Accordion.Header>
                    <Accordion.Body>
                      தேவையில்லை. உங்களுடைய பிறந்த தேதியும் தொடர்புடைய சில தகவல்களும் அளித்தால்
                      போதும்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header>
                      நீங்கள் பலன் கூறுவதில் ஏதாவது எல்லைகளை வகுத்து வைத்திருக்கிறீர்களா?
                    </Accordion.Header>
                    <Accordion.Body>
                      ஒரு சில எல்லைகள் உண்டு. பிறக்கப்போகும் குழந்தை ஆனா, பெண்ணா என்று கணிப்பது,
                      குடும்ப உறுப்பினரின் ஆயுள் காலம் பற்றிய கேள்வி, லாட்டரி, குதிரைப்பந்தயம், ஷேர்
                      மார்க்கெட், குற்ற வழக்குகள் இவற்றில் வெல்வதற்கான சாத்தியக் கூறுகள், அறுவை சிகிச்சைக்கு
                      காத்திருக்கும் நோயாளியின் வெற்றி வாய்ப்புகள், இன்னும் இவை போன்ற சிலவற்றை நாங்கள்
                      கணிப்பதில்லை.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      திருமணப் பொருத்தம் பார்ப்பீர்களா?
                    </Accordion.Header>
                    <Accordion.Body>
                      இல்லை. சம்பந்தப்பட்ட இரண்டு பேரின் தனிப்பட்ட குணங்களை ஆராய்ந்து அவர்களுக்குள்
                      ஒத்துப் போக எந்தளவு வாய்ப்பு உண்டு என்று மட்டுமே கணிப்போம். திருமணம் செய்வது பற்றிய
                      இறுதி முடிவு உங்களுடையது தான்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      நீங்கள் ராஜநாடி முறையில் மட்டுமே பலன்கள் கூறுகிறீர்களா?
                    </Accordion.Header>
                    <Accordion.Body>
                      ஆமாம். என்றாலும், ராஜநாடி விதிகளுக்குட்பட்டு வேறு சில அடிப்படை சேவைகளும்
                      வழங்குகிறோம்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="15">
                    <Accordion.Header>
                      வருங்காலம் கணிப்பது தவிர வேறு என்ன சேவைகள் வழங்குகிறீர்கள்?
                    </Accordion.Header>
                    <Accordion.Body>
                      தேவைப்படுபவர்களுக்கு உளவியல் ஆலோசனை வழங்குகிறோம். ஒரு வாடிக்கையாளரின்
                      ஜாதகத்தை ஆராய்ந்த பின் அவர்கள் சிக்கலில்/போராட்டத்தில் இருப்பது தெரிந்தால்
                      அவர்களுக்குத் தேவையான ஆதரவு/ஊக்கம் அளிப்பதோடு, தேவையான வழி காட்டலும்
                      செய்கிறோம்.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="16">
                    <Accordion.Header>
                      இந்த இணையத்தில் நன்கொடை அளிக்குபடி கோரி இருந்ததை ஒரு இடத்தில் பார்த்தேன். இது
                      என்ன காரணத்துக்காக?
                    </Accordion.Header>
                    <Accordion.Body>
                      இந்த ஜோதிட தளத்தின் வருமானத்தின் ஒரு குறிப்பிட்ட பகுதி “மோட்சம்” என்ற பெயரில் உள்ள
                      ஒரு ஹோஸ்பிஸ் (இறுதிக்கட்ட) மையத்தை ஆதரிக்க பயன்படுத்தப் படுகிறது. இந்த மையம்
                      தங்கள் வாழ்வின் இறுதிக்கட்ட நிலையில் உள்ள நோயாளிகளையும் முதியோர்களையும்
                      பராமரித்து உணர்வு பூர்வமான ஆதரவு வழங்கும் ஒரு அமைப்பு ஆகும்.
                      உங்களுக்கு விருப்பம் இருக்குமானால் இந்த மையத்துக்கு நன்கொடை வழங்கலாம்.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default FaqsContent;
