import '../../Styles/contact.css'
import { Container, Row, Col } from 'react-bootstrap';


function ReportsBanner() {
    return (
        <>
            <Container fluid className='astrologer-background'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                            <h1 className='text-darkOrange'>Personalized Prediction Reports</h1>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default ReportsBanner;
