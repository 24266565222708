import React, { useState, useEffect, useRef } from "react";
import useInterval from 'use-interval';
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import { IoSend } from "react-icons/io5";
import { TiAttachment } from "react-icons/ti";
import { FaFileDownload } from "react-icons/fa";
import { MdDownload } from "react-icons/md";

import Chart from '../../../Assets/Version2Images/horoscope-chart.png';

import FileUpload from "./FileUpload";
import AddReview from "./AddReview";
import ChatAstroProfile from "./ChatAstroProfile";
import ChartModal from "../../BirthChart/ChartModal";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetChatSession, GetChatStatus, GetInprogressChatStatus, GetSingleAstrologer } from '../../../Redux/Slice';

import io from "socket.io-client";

// const socket = io.connect("http://13.201.217.89:80");
// const socket = io.connect("http://localhost:8080");
// const socket = io.connect("https://prod-chat.astromindcounsel.com");

// const socket = io('https://prod-astromind-api.astromindcounsel.com', {
//     withCredentials: true,
//   });
const socket = io('https://prod-astromind-api.astromindcounsel.com', {
    withCredentials: true,
    transports: ['websocket', 'polling'],
    query: {
        who: 'user-chat'
    }
});

function ChatConnect() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { success, ChatHistory, InprogressChatStatus, ChatStatus } = useSelector((state) => state.Astromind);

    const textareaRef = useRef(null);
    const chatfeedRef = useRef(null);

    const [message, setMessage] = useState("");
    const [fileStatus, setFileStatus] = useState(false);
    const [receivedMessage, setReceivedMessage] = useState([]);
    const [viewChart, setViewChart] = useState(false);
    const [chart, setChart] = useState();
    const [user, setUser] = useState();

    const [chatEnd, setChatEnd] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [trackTypingMessage, setTrackMessage] = useState("");
    const [reviewShow, setReviewShow] = useState(false);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    let orderId = localStorage.getItem("orderId");
    let chatSessionId = localStorage.getItem("messageSessionId");
    const astrologerName = localStorage.getItem("roomName");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const slug = localStorage.getItem("astrologerslug");

    const closeViewChart = (value) => {
        setViewChart(value)
    }
    useEffect(() => {
        if (!chatSessionId) {
            navigate(-1);
        }
    }, [chatSessionId, navigate]);

    useEffect(() => {
        scrollToBottom();
    }, [receivedMessage]);

    useEffect(() => {
        if (orderId) {
            dispatch(GetInprogressChatStatus({ token, orderId }));
        }
    }, [dispatch, token, orderId]);

    useInterval(() => {
        if (InprogressChatStatus?.data?.data?.status === 'in-progress' || InprogressChatStatus?.data?.data?.status === 'pending') {
            dispatch(GetInprogressChatStatus({ token, orderId }));
        }
    }, 1000);
    // console.log(InprogressChatStatus);

    useEffect(() => {
        dispatch(GetSingleAstrologer(slug));
    }, [dispatch, slug]);

    const scrollToBottom = () => {
        if (chatfeedRef.current) {
            chatfeedRef.current.scrollTop = chatfeedRef.current.scrollHeight;
        }
    };

    const handleChangeMessage = (e) => {
        let input = e.target.value;
        // const isNumber = /^\d+$/.test(input);
        // const hasDigit = /\d/.test(input);
        // if (isNumber || hasDigit) {
        //     input = maskPhoneNumber(input);
        // }
        setMessage(input);
        e.target.style.height = '35px';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    // const maskPhoneNumber = (input) => {
    //     console.log("maskPhoneNumber", input);
    //     const maskedInput = input.replace(/\d/g, '*');
    //     return maskedInput;
    // };
    // const handleTyping = () => {
    //     setTrackMessage(message);
    //     socket.emit('typing',{room: astrologerName});
    // };

    const handleNotTyping = () => {
        if (trackTypingMessage?.length === message?.length || message?.length === 0) {
            socket.emit('not typing', { room: astrologerName });
        }
        else {
            setTrackMessage(message);
            socket.emit('typing', { astrologerName });
        }
    };

    useInterval(() => {
        handleNotTyping();
    }, 2000)


    useEffect(() => {
        socket.emit("join", { room: astrologerName, role: 'user' });
    }, []);

    useEffect(() => {
        socket.on('typing', (data) => {
            setIsTyping(true);
            // Handle typing status UI update here
        });
    }, []);

    useEffect(() => {
        const handleNotTyping = () => {
            setIsTyping(false);
        }
        socket.on('not typing', handleNotTyping);
        return () => {
            socket.off("not typing", handleNotTyping);
        };

    });

    useEffect(() => {
        if (ChatStatus?.data?.data?.subUserDetails) {
            setUser(ChatStatus?.data?.data?.subUserDetails);
            if (ChatStatus?.data?.data?.subUserDetails?.birthtable) {
                setChart(ChatStatus?.data?.data?.subUserDetails?.birthtable);
            }
        }

    }, [ChatStatus]);

    const fileClose = (value) => {
        setFileStatus(value);
    }

    const reviewClose = (value) => {
        console.log("close", value);
        setReviewShow(value);
    }

    const sendMessage = () => {
        if (message !== '') {
            let string = message;
            const regex = /\b\d{10}\b/;
            const match = string.match(regex);

            if (match) {
                string = string.replace(/\d/g, '*');
            }

            socket.emit("sendMessage", {
                room: astrologerName,
                message: string,
                role: 'user',
                token: token,
                to: localStorage.getItem('astrologerId'),
                messageSessionId: chatSessionId
            });

            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

            setReceivedMessage(prevMessages => [...prevMessages, { message: string, role: 'user', time: MessageTime }]);
            setMessage(""); // Clear the input field
        }
    };

    useEffect(() => {
        if (success) {
            socket.emit("sendMessage", {
                room: astrologerName,
                message: success?.data?.file,
                role: 'user',
                token: token,
                to: localStorage.getItem('astrologerId'),
                messageSessionId: chatSessionId
            });
            let message = success?.data?.file;
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            setReceivedMessage(prevMessages => [...prevMessages, { message, role: 'user', time: MessageTime }]);
            setFileStatus(false);
        }

        if (chatEnd) {
            setReviewShow(true);
        }

        // let messageSessionId = chatSessionId;
        // if (messageSessionId) {
        //     dispatch(GetChatSession({ token, messageSessionId }));
        // }

        if (orderId) {
            dispatch(GetChatStatus({ token, orderId }));
        }

    }, [dispatch, token, success, chatEnd, chatSessionId, orderId, astrologerName])

    useEffect(() =>{
        if (ChatHistory) {
            for (let i = 0; i < ChatHistory?.data?.data?.length; i++) {
                let MessageTime = new Date(ChatHistory?.data?.data[i]?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                let obj = {
                    message: ChatHistory?.data?.data[i]?.message,
                    role: ChatHistory?.data?.data[i]?.role,
                    time: MessageTime,
                };
                setReceivedMessage(prevMessages => [...prevMessages, obj]);
            }
        }
    },[ChatHistory])

    useEffect(() => {
        let messageSessionId = chatSessionId;
        if (messageSessionId) {
            dispatch(GetChatSession({ token, messageSessionId }));
        }
    }, [dispatch, token, chatSessionId]);

    useEffect(() => {
        const handleReceiveMessage = (message, role, type) => {
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let obj = {
                message: message,
                role: role,
                time: MessageTime,
            };
            setReceivedMessage(prevMessages => [...prevMessages, obj]);
        };

        socket.on("receiveMessage", handleReceiveMessage);

        return () => {
            socket.off("receiveMessage", handleReceiveMessage);
        };

    }, []);

    useEffect(() => {
        const handleLeft = (role) => {
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let obj = {
                message: `${role} left the chat`,
                role: role,
                time: MessageTime,
                isleft: true,
            };
            // setLeftMessage(obj);
            // setChatEnd(true);
        };

        socket.on("onleft", handleLeft);
        return () => {
            socket.off("onleft", handleLeft);
        };
    }, []);


    useEffect(() => {
        const handleJoin = (role) => {
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let obj = {
                message: `Your Chat has been connected`,
                role: role?.role,
                time: MessageTime,
            };
            setReceivedMessage(prevMessages => [...prevMessages, obj]);
            // setChatEnd(true);
        };
        socket.on("onjoin", handleJoin);
        return () => {
            socket.off("onjoin", handleJoin);
        };
    }, []);

    const handleKeyPress = (e) => {
        let newLine = '\n';
        if (e.ctrlKey && e.key === 'Enter') {
            setMessage((prevValue) => prevValue + '\n');
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }
        if (e.key === 'Enter' && !(message.includes(newLine)) && message != " ") {
            e.preventDefault();
            sendMessage();
            textareaRef.current.style.height = '35px';
        }
    };

    const handleFocusIn = () => {
        console.log("open");
        setIsKeyboardOpen(true);
    };

    const handleFocusOut = () => {
        console.log("close");
        setIsKeyboardOpen(false);
    };

    // useEffect(() => {
    //     const initialViewportHeight = window.innerHeight;

    //     const handleResize = () => {
    //         const currentViewportHeight = window.innerHeight;
    //         if (currentViewportHeight < initialViewportHeight) {
    //             setIsKeyboardOpen(true);
    //         } else {
    //             setIsKeyboardOpen(false);
    //         }
    //     };

    //    

    //     // Add event listeners
    //     // window.addEventListener('resize', handleResize);
    //     window.addEventListener('focusin', handleFocusIn);
    //     window.addEventListener('focusout', handleFocusOut);

    //     // Cleanup event listeners on component unmount
    //     return () => {
    //         // window.removeEventListener('resize', handleResize);
    //         window.removeEventListener('focusin', handleFocusIn);
    //         window.removeEventListener('focusout', handleFocusOut);
    //     };
    // }, []);

    useEffect(() => {
        const updateVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        updateVh(); // Set initial value

        window.addEventListener('resize', updateVh);

        return () => window.removeEventListener('resize', updateVh);
    }, []);

    return (
        <>
            {
                fileStatus ?
                    <FileUpload
                        fileShow={fileStatus}
                        fileClose={fileClose}
                    />
                    : null
            }
            {
                reviewShow ?
                    <AddReview
                        show={reviewShow}
                        reviewClose={reviewClose}
                        astrologerId={userData?.astrologerId}
                        orderId={orderId}
                    />
                    : null
            }
            {
                viewChart ?
                    <ChartModal
                        modalShow={viewChart}
                        closeModalShow={closeViewChart}
                        chartData={chart}
                        user={user}
                    />
                    : null
            }

            <Container fluid className="pt-130 chat-top">
                <Container className="chat-container">
                    <Row className={`${isKeyboardOpen && "chat-view-screen-keyboard"} chat-row`}>
                        <ChatAstroProfile socket={socket} chatEnd={chatEnd} setChatEnd={setChatEnd} typing={isTyping} />
                        <Col lg={12} id="chat-feed" ref={chatfeedRef} className={`chat-view-screen bg-skyBlue p-3`}>
                            {/* {
                                joinedMessage.map((item, indes) => {
                                    return (
                                        <>
                                            {item?.role == "user" ?
                                                <Col lg={12} className="d-flex justify-content-center chat-connect">
                                                    <p>Your Chat has been connected</p>
                                                </Col>
                                                : null
                                            }
                                        </>
                                    )
                                })
                            } */}
                            <Col lg={12} className="d-flex justify-content-center chat-connect">
                                <p>Your Chat has been connected</p>
                            </Col>
                            <Col lg={12} className="d-flex justify-content-end">
                                <div className="chat-message">
                                    <p>Hi {localStorage.getItem('roomName')}</p>
                                    <p>My Details are given below</p>
                                    <p>{userData?.name}</p>
                                    <p>{userData?.dateofbirth}</p>
                                    <p>{userData?.gender}</p>
                                    <p>{userData?.timeofbirth}</p>
                                </div>
                            </Col>


                            {
                                receivedMessage.map((item, index) => {
                                    const url = item.message;
                                    const imageExtension = url?.split('.').pop().toLowerCase();
                                    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'].includes(imageExtension);
                                    const isFile = ['pdf', 'csv', 'xlsx', 'txt', 'pptx', 'docx', 'doc', 'zip'].includes(imageExtension);

                                    const urlFile = isFile ? new URL(url) : null;
                                    const pathname = isFile ? urlFile.pathname : null;

                                    return (
                                        <Col lg={12} className={`d-flex ${item.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`} key={index}>
                                            {isImage ?
                                                <div className="chat-image" >
                                                    <img src={item.message} alt="" className="img-fluid" />
                                                    <div className="image-download">
                                                        <p><a href={item.message}>Download <MdDownload className="icon" /></a></p>
                                                        <span>{item.time}</span>
                                                    </div>
                                                </div>
                                                : isFile ?
                                                    <div className="chat-file">
                                                        <div className="file">
                                                            <a href={item.message}>
                                                                <FaFileDownload className="icon" />
                                                            </a>
                                                        </div>
                                                        <div className="file-name">
                                                            <p>{pathname?.slice(15)}</p>
                                                            <span>{item.time}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="chat-message">
                                                        <p>{item.message}</p>
                                                        <span>{item.time}</span>
                                                    </div>
                                            }

                                        </Col>
                                    );
                                })
                            }
                            {/* {
                                leftMessage?.isleft ?
                                    <Col lg={12}
                                        className="d-flex justify-content-center chat-connect"
                                    >
                                        <p>{leftMessage?.role == 'astrologer' ? "Astrologer" : null} left the chat</p>
                                    </Col>
                                    : null
                            }
                            {
                                chatEnd && !(leftMessage?.isleft) ?
                                    <Col lg={12} className="d-flex justify-content-center chat-connect">
                                        <p>You left the chat</p>
                                    </Col>
                                    : null
                            } */}

                            {
                                InprogressChatStatus?.data?.data?.isEndedBy === 'User' ?
                                    <Col lg={12} className="d-flex justify-content-center chat-connect">
                                        <p>You left the chat</p>
                                    </Col>
                                    : InprogressChatStatus?.data?.data?.isEndedBy === 'Astrologer' ?
                                        <Col lg={12} className="d-flex justify-content-center chat-connect">
                                            <p>Astrologer left the chat</p>
                                        </Col>
                                        : InprogressChatStatus?.data?.data?.isEndedBy === 'Automatically' ?
                                            <Col lg={12} className="d-flex justify-content-center chat-connect">
                                                <p>Chat order is completed</p>
                                            </Col>
                                            : null
                            }
                        </Col>
                        {chatEnd ?
                            <Col lg={12} className="bg-blue type-section py-3 d-flex justify-content-center align-items-center gap-3">
                                <h5 className="text-white">Please Add Your Review</h5>
                                <Button className="otp-button add-review-btn" onClick={() => setReviewShow(true)}>Add Here</Button>
                            </Col>
                            :
                            <Col lg={12} className={`bg-blue type-section ${window.screen.width < 550 ? 'py-2' : 'py-3'}`}>
                                <div className="text-input">
                                    <textarea
                                        type="text"
                                        name=""
                                        id=""
                                        placeholder="Type Messages Here........."
                                        ref={textareaRef}
                                        value={message}
                                        onChange={(e) => {
                                            setIsKeyboardOpen(true);
                                            handleChangeMessage(e);
                                            // handleTyping()
                                        }}
                                        onKeyDown={handleKeyPress}
                                        onFocus={handleFocusIn}
                                        onBlur={handleFocusOut}
                                        required
                                    ></textarea>
                                    <button >
                                        <TiAttachment className="icon" onClick={() => setFileStatus(true)} />
                                    </button>
                                </div>
                                <div>
                                    <button>
                                        <IoSend className="icon" onClick={() => {
                                            sendMessage()
                                        }} />
                                    </button>
                                </div>
                                <div >
                                    <button
                                        className="birth-chart-img"
                                        onClick={() => {
                                            setViewChart(true);
                                        }}
                                    >
                                        <img src={Chart} alt="chart" />
                                    </button>
                                </div>
                            </Col>
                        }

                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default ChatConnect;
