import AstrologerBanner from "./AstrologerBanner";
import AstrologerList from "./AstrologerList";

function Astrologer() {
  return (
    <>
     <AstrologerBanner />
     <AstrologerList />
    </>
  );
}

export default Astrologer;
