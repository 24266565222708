import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function CouplesBanner() {
    return (
      <>
       <Container fluid className='children-background'>
          <Container>
              <Row className='d-flex justify-content-center align-items-center'>
                  <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                     <h1 className='text-darkOrange'>Couples</h1>
                     <p>Combining astrology and psychology can provide several benefits for couples in their relationships. Here's how this field can help couples</p>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default CouplesBanner;
  