import { Helmet } from 'react-helmet';
import EmpowerYourLifeBanner from './EmpowerYourLifeBanner';
import EmpowerYourLifeContent from './EmpowerYourLifeContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";
import TopAstrologers from '../TopAstrologers';

function EmpowerYourLife() {
    return (
        <>
            <Helmet>
                <title>Best Online Vedic Astrology- Astromind Counsel</title>
                <meta name="description" content="Explore the depths of Vedic astrology for profound insights into your life's journey. Discover personalized guidance and ancient wisdom with Astromind counsel." />
                <meta name="keywords" content="vedic astrology, astrology prediction, personalized horoscope" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/services/get-personalized-nadi-astrology/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/services/get-personalized-nadi-astrology/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Best Online Vedic Astrology- Astromind Counsel" />
                <meta property="og:description" content="Explore the depths of Vedic astrology for profound insights into your life's journey. Discover personalized guidance and ancient wisdom with Astromind counsel." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Best Online Vedic Astrology- Astromind Counsel" />
                <meta name="twitter:description" content="Explore the depths of Vedic astrology for profound insights into your life's journey. Discover personalized guidance and ancient wisdom with Astromind counsel." />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <EmpowerYourLifeBanner />
            <EmpowerYourLifeContent />
            <ConnectAstrologers />
            <TopAstrologers />
        </>
    );
}

export default EmpowerYourLife;
