import '../../Styles/about.css';
import { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Mission from '../../Assets/AboutImages/mission.png';
import Vision from '../../Assets/AboutImages/vision.png';

import { TiTick } from "react-icons/ti";

function MissionVision() {
    const [isTamil, setIsTamil] = useState(false);
    const scrollToPosition = window.innerWidth < 600 ? document.documentElement.scrollHeight * 0.47 : document.documentElement.scrollHeight * 0.45;
    return (
        <>
            <Container fluid id='mission' className='mb-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='text-center'>
                            <h3 className='text-orange pb-2'>{isTamil ? "இலக்கு" : "Our Mission"}</h3>
                        </Col>
                        <Col lg={12} className='d-flex aboutwhyCol mission'>
                            <Col lg={12} className='d-flex flex-column justify-content-center'>
                                <div>
                                    <p><b>{isTamil ? "அஸ்ட்ரோமைண்ட் கௌன்செல்-ன் இலக்கு" : "The mission of Astromind Counsel is"}</b></p>
                                </div>
                                <div>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>வாடிக்கையாளர்களுக்கு துல்லியமான, அறிவியல் அடிப்படையிலான ஜோதிட கணிப்புகளை வழங்குவதன் மூலம் அவர்கள் பயம், கவலை, மூடநம்பிக்கை மற்றும் நிச்சயமற்ற தன்மையிலிருந்து விடுபட்டு சிறந்த வாழ்க்கையை வாழ உதவுதல்.</p>
                                                :
                                                <p>To provide accurate, scientific astrological predictions to our customers so that they can live a better life free from fear, anxiety, superstitious beliefs, and uncertainty.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>ஆரோக்கியமான, நேர்மையான, அறிவியல் அடிப்படையிலான ஜோதிடக் கணிப்புகளை வழங்கும் கலாச்சாரத்தை சமூகத்தில் உருவாக்குதல்.</p>
                                                :
                                                <p>To create a culture of providing healthy, honest, and scientifically proven predictions.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>வாடிக்கையாளர்கள் மகிழ்ச்சியாகவும் வசதியாகவும் வாழத் தேவையான உளவியல் ஆலோசனையை வழங்கி அவர்களுக்கு ஆதரவு அளித்தல்.</p>
                                                :
                                                <p>To support customers with the necessary psychological counselling that empowers them to live a happy and comfortable life.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>மக்களுக்குத் தங்களைத் தாங்களே புரிந்துகொள்ளவும், பிரச்சினைகளை சரியான மனப்பாங்குடன் அணுகவும், தங்கள் முழுத்திறனை உணர்ந்து, பிரச்சினைகளை சமாளித்து அவற்றிலிருந்து மேம்படவும் உதவுதல்.</p>
                                                :
                                                <p>To help people understand themselves, perceive problems with the right mindset, realize their own potential, cope with issues, and evolve from them.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>பழமையான மூடநம்பிக்கைகள், மூடப் பழக்க வழக்கங்கள், ஜோதிடம் என்ற பெயரில் முன்னெடுக்கப்படும் பாலின பேதம் ஆகியவை பற்றிய விழிப்புணர்வை ஏற்படுத்தி, ஜோதிடத்தைப் பற்றிய பிற்போக்கான சமூகப் பார்வையை மாற்றுதல்.</p>
                                                :
                                                <p>To revolutionize the societal perception of Jyotish by creating awareness of age-old superstitious beliefs, formalities, gender discrimination, etc.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>அடிப்படையில் இல்லத்தரசிகளாகவும், பொருளாதார சுதந்திரம் தேவைப்படுபவர்களாகவும் இருக்கும் பெண்களுக்கு வேலை வாய்ப்புகளை வழங்குதல்.</p>
                                                :
                                                <p>To provide employment opportunities for women, who are primarily homemakers and in need of economic freedom.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>அவரவர் வாழ்க்கையை உள்ளபடியே ஏற்றுக்கொள்ளவும் மேம்படுத்திக்கொள்ளவும் உதவும் வகையில் சமூக நலனுக்கான ஒரு கருவியாக அன்றாட வாழ்வில் ஜோதிடத்தைப் பயன்படுத்துதல்.</p>
                                                :
                                                <p>To use Astrology as a tool for the well-being of the society that promotes self-acceptance and self-improvement.</p>
                                            }
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                            {/* <Col lg={5} className='whyChoose d-flex justify-content-center align-items-center'>
                                <div className='mission'>
                                    <img src={Mission} alt="" />
                                </div>
                            </Col> */}
                        </Col>
                    </Row>
                </Container>

            </Container>

            <Container fluid className='mb-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='text-center'>
                            <h3 className='text-orange pb-2'>{isTamil ? "தொலை நோக்கு" : "Our Vision"}</h3>
                        </Col>
                        <Col lg={12} className='d-flex aboutwhyCol mission'>
                            {/* <Col lg={6} className='whyChoose d-flex justify-content-center align-items-center'>
                                <div className='mission'>
                                    <img src={Vision} alt="" />
                                </div>
                            </Col> */}
                            <Col lg={12} className='d-flex flex-column justify-content-center'>
                                <div>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>நாங்கள் அறிவியல் பூர்வமான தகவல்களைக் கொண்டு எதிர்காலத்தை கணிப்பதோடு வாடிக்கையாளர்களுக்கு நிம்மதி அளிக்க உளவியல் முறைகளையும் பயன்படுத்துகிறோம். அறிவியல் பூர்வமாகவும், ஆன்மீக ரீதியாகவும், நடைமுறை/சமூக அணுகுமுறைகள் மூலமாகவும் வாழ்க்கையைப் புரிந்துகொள்ள வாடிக்கையாளர்களுக்கு உதவுகிறோம்.</p>
                                                :
                                                <p>We engage scientific findings to predict the future and use psychological methods to comfort our customers. We help customers understand life through various approaches: scientific, practical, social, and spiritual.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>நாங்கள் புராணங்கள் மற்றும் காவியங்களின் உட்கருத்துகளை மக்களுக்குப் புரியும்படி வெளியிடுவதன் மூலம் பாரம்பரிய ஜோதிடத்தின் மீது படிந்த மூடநம்பிக்கைகள் மற்றும் மூடப் பழக்க வழக்கங்களை அகற்ற முற்படுகிறோம்.</p>
                                                :
                                                <p>We challenge the superstitious beliefs and methods of traditional astrology by unmasking the Epics and Mythology.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>தங்கள் விதியை அறிந்து கொண்டாலும் அதை ஏற்றுக்கொள்ள முடியாமல் தடுமாறும் வாடிக்கையாளர்களுக்கு நாங்கள் உளவியல் ஆலோசனை வழங்கி, நம்பிக்கையோடு வாழ்க்கையைத் தொடர உதவுகிறோம். நம்மைப் போன்ற இன்னொரு மனிதரை உளவியல் ரீதியாகக் குணப்படுத்த வேண்டும் என்ற இதயபூர்வமான அக்கறையும் ஆர்வமும் கொண்டு நாங்கள் இந்த உளவியல் ஆலோசனையைக் கூடுதல் சேவையாக வழங்குகிறோம். இதற்காக நாங்கள் கட்டணம் பெறுவதில்லை.</p>
                                                :
                                                <p>We provide psychological counselling to those customers who find it difficult to accept their destiny and move on. This is an add-on service that flows from the bottom of our hearts as a token of love and a desire to heal a fellow member of our society. We do not charge for it.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>வாடிக்கையாளர்கள் தங்கள் பிரச்சினைகளைச் சரியான முறையில் புரிந்து கொண்டு அணுக உதவுகிறோம். தங்களுக்குள் பொதிந்து கிடக்கும் திறனை உணர்ந்து, பிரச்சினைகளை சமாளித்து, அவற்றிலிருந்து மேம்பட்டு வாழ அவர்களை நாங்கள் ஊக்குவிக்கிறோம்.</p>
                                                :
                                                <p>We help customers understand and approach their problems with the right mindset. We motivate them to realize their own potential, cope with issues, and evolve from them.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>ஜோதிடத் துறையில் நிலவும் தோஷம், சாபம், பரிகாரம் போன்றவை குறித்து மக்களிடையே விழிப்புணர்வு ஏற்படுத்த நாங்கள் பாடுபடுகிறோம். இந்த விழிப்புணர்வு ஒரு இயக்கமாகப் பரவி, ஜோதிட உலகில் நிலவும் பலவிதமான பயமுறுத்தல்கள், அவை ஏற்படுத்தும் ஆறாத்துயர், நேர்மையற்ற ஜோதிடர்கள் மற்றும் ஏமாற்றும் நோக்கத்தோடு உலா வரும் ஜோதிடர்கள் ஆகிய தீமைகளில் இருந்து நமது சமூகம் விடுபட வேண்டும் என்று விரும்புகிறோம்.
                                                </p>
                                                :
                                                <p>We strive to create awareness of false beliefs like Dosh, Shap, Parikaram, etc. We want this awareness to spread as a movement and free our society from fear, pain, corruption, and deceitful intentions prevailing in the Jyotish field.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>பெண்களுக்கு சக்தியும் ஆற்றலும் அளிக்கும் வகையில் அவர்களுக்கு வேலைவாய்ப்புகளை உருவாக்கி வருகிறோம். குறிப்பாக, ஒற்றைப் பெற்றோர் என்னும் வகையில் தனியே இருந்து குடும்பத்தைப் பராமரிக்கும் பெண்களுக்கு முன்னுரிமை அளிக்கிறோம். இதன் மூலம், ஒரு தனி நபரை மட்டுமல்லாது ஒரு குடும்பத்தையே உயர்த்த முற்படுகிறோம்.</p>
                                                :
                                                <p>We create employment opportunities for women to empower them. We also prioritize single mothers to uplift entire families.</p>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex gap-3 whychose-check mb-3'>
                                        <div>
                                            <TiTick className='tick-icon' />
                                        </div>
                                        <div>
                                            {isTamil ?
                                                <p>இந்த தளத்திலிருந்து பெறப்படும் வருமானத்தின் ஒரு முக்கியமான பகுதியை “மோட்சம்” என்ற பெயரில் உள்ள ஒரு ஹோஸ்பிஸ் (இறுதிக்கட்ட) மையத்தை ஆதரிக்க பயன்படுத்துகிறோம். இந்த மையம் தங்கள் வாழ்வின் இறுதிக்கட்ட நிலையில் உள்ள நோயாளிகளையும் முதியோர்களையும் பராமரித்து உணர்வு பூர்வமான ஆதரவு வழங்கும் ஒரு அமைப்பு ஆகும். </p>
                                                :
                                                <p>We use a significant portion of the income generated from this platform to support a hospice center named “Motcham,” a facility that provides palliative care and emotional support to the terminally ill.</p>
                                            }
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>

            </Container>

            <Container fluid className='mb-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex justify-content-center'>
                            <Button
                                onClick={() => {
                                    setIsTamil(!isTamil)
                                    window.scrollTo(0, scrollToPosition)
                                }}>
                                Translate to {isTamil ? "English" : "Tamil"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default MissionVision;
