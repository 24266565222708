import '../../../Styles/blogdetail.css'
import { Container, Row, Col } from 'react-bootstrap';

import EmpowerYourLifeImg1 from '../../../Assets/ServiceImages/Empower-Your-Life-img-1.png';
import EmpowerYourLifeImg2 from '../../../Assets/ServiceImages/Empower-Your-Life-img-2.png';
import EmpowerYourLifeImg3 from '../../../Assets/ServiceImages/Empower-Your-Life-img-3.png';


function EmpowerYourLifeContent() {
    return (
        <>
            <Container fluid className='blogContent-main mt-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between'>

                            <Col lg={5} className='d-flex flex-column justify-content-center'>
                                <div>
                                    <h4 className='text-blue'>What is Vedic Astrology</h4>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <p className='text-black pt-2'><a href='https://www.astromindcounsel.com/'>Vedic astrology</a> prediction has its roots in the Atharva Veda and other ancient <a href='https://www.westernunion.com/blog/en/unique-cultural-traditions-of-india/'>Indian traditions</a>. Ayurveda and VedangJyotisha, an early text about astrology in Vedas, has all the knowledge about Vedic astrology. It is called Jyotisha in ordinary parlance. Vedic astrology holds that fate and karma are intimately related. Indian astrology places a great deal of detail on the birth chart, or Kundli. The future events in your life are determined by the position of your planets, which are based on your exact birth date, place, and timing. It uses the fixed zodiac to predict the future and determine what will happen.</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='blogContent-img pt-3'>
                                    <img src={EmpowerYourLifeImg1} alt="" />
                                </div>
                            </Col>
                        </Col>
                        <Col lg={12} className='pt-2' >
                            <div>
                                <p className='text-black pt-2'>The principles of the Vedas form the foundation of Vedic astrology. Using the concept of Karma, the planets are interpreted in this case in terms of their influence on the previous life and their concurrent impact on the current one. Hindu astrology uses only the planets up to Saturn. The lunar nakshatra, dasha, prashna, muhurta, varshaphal, and harmonic charts are the additional instruments used for forecasting.</p>
                                <p className='text-black '>Jyotisha shashtra uses a system of computations based on twelve constellations in addition to 28 nakshatra, or moon constellations, in contrast to Western astrology's equinoxes. When it comes to predicting and analyzing the fate of the native, it is thought to be more accurate than Western astrology.</p>
                                <p className='text-black '>The study of dashas, or the time a planet is in its natal orbit, contributes to the remarkable accuracy of Vedic astrology predictions. Future events are detected by evaluating your planets' dasha. This approach broadens the scope of Indian astrology beyond the individual to include more significant events that will occur shortly. After then, the yearly result, or varshaphal, is determined by looking at the <a href='https://www.pveducation.org/pvcdrom/properties-of-sunlight/the-suns-position'>Sun's position</a> at the time of your birth. Varshaphal predicts the year that follows your birthday in a certain year, which sets it apart from natal chart. Another application of Vedic astrology is to determine the shubhmuhurat, or lucky times.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='blogContent-main bg-blue text-white text-center mt-4 py-4'>
                <Row className='d-flex justify-content-center'>
                    <Col lg={10}>
                        <div>
                            <h4>Why Vedic Astrology</h4>
                            <p>Respected among the Vedanga Sastras, Vedic Astrology is symbolically compared to the regal crest of a peacock or the brilliant jewel perched above the hood of a cobra. Our route to a more promising future and a more contented existence is illuminated by this age-old "Science of Light."</p>
                            <p>Fundamentally speaking, personalized horoscope <a href='https://www.astromindcounsel.com/about/'>astrology</a> prediction is a science that foretells future occurrences and helps us make wise judgments so that we can more easily overcome obstacles in life. Based on an intriguing idea, it suggests that our life path can be influenced by the placements of the planets at the moment of our birth. Earthly events and human experiences are significantly influenced by the movements of planets and stars, as our predecessors realized. Astrology provides a unique perspective on a variety of occurrences, from major life events to <a href='https://en.wikipedia.org/wiki/List_of_natural_phenomena#:~:text=Examples%20include%3A%20sunrise%2C%20weather%2C,volcanic%20eruptions%2C%20hurricanes%20and%20earthquakes.'>natural phenomena</a> like earthquakes.</p>
                        </div>
                        <div className='pt-3'>
                            <img src={EmpowerYourLifeImg2} alt="" className='img-fluid' />
                        </div>
                        <div className='pt-3'>
                            <p>The main tool in astrology is a birth chart, which shows where the planets were at the time of your birth. Astrologers can provide light on a number of areas of your life, including relationships, profession, health, and finances, by examining this chart. It is comparable to having an individual life path map.</p>
                            <p>Numerous positive and difficult facets of our lives are also illuminated by astrology prediction. From which way to face for good luck to which colors and gemstones may provide great energy, it offers advice on everything. It serves as a thorough manual for daily life.</p>
                            <p>Additionally, Vedic astrology provides treatments such as Yagyas (sacrificial offerings) and pujas (rituals) to balance planetary influences for problems that could arise from past-life acts. With its enlightening advice, personalized horoscopes, and corrective actions, this special system created by <a href='https://www.quora.com/Who-are-Ancient-Sages-and-how-powerful-are-they'>ancient sages</a>—has assisted many people in leading more fulfilling lives.</p>
                            <p>To put it simply, Jyotish is about giving us the tools to navigate life with insight and intuition, not just about knowing our fate.</p>
                        </div>
                    </Col>

                </Row>
            </Container>
            <Container fluid className='blogContent-main mt-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between'>

                            <Col lg={6} className='d-flex flex-column justify-content-center align-items-center'>
                                <div>
                                    <h4 className='text-blue'>Why Choose us</h4>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <ul>
                                        <li><b>Expertise in Vedic Astrology :</b> Our team consists of seasoned astrologers who bring deep knowledge and understanding of Vedic astrology prediction. They blend traditional techniques with contemporary life scenarios to offer relevant advice.</li>
                                        <li><b>Personalized Attention :</b> We believe every individual's cosmic blueprint is unique. Our <a href='https://www.astromindcounsel.com/personalized-future-predictions-dob/'>personalized horoscope</a> readings delve into your specific circumstances, offering tailored advice that speaks directly to your life's questions and challenges.</li>
                                        <li><b>Accuracy in Predictions :</b> Our commitment to accuracy is unwavering. Utilizing precise calculations and thorough analysis, we ensure our personalized horoscope predictions and insights are both reliable and enlightening.</li>
                                        <li><b>Confidential and Secure :</b> Your privacy is paramount. All consultations and personal data are handled with the utmost confidentiality and security, ensuring your peace of mind.</li>
                                        <li><a href='https://www.astromindcounsel.com/contact-us/'>Contact</a> Astro Mind Counsel for expert insights into your future. Our seasoned astrologers offer personalized readings and guidance tailored to your unique life journey. Unlock the mysteries of the cosmos and gain clarity on love, career, <a href='https://www.who.int/'>health</a>, and more. Your destiny awaits—reach out to us today.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='pt-3'>
                                    <img src={EmpowerYourLifeImg3} alt="" className='img-fluid' />
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    )
}

export default EmpowerYourLifeContent;