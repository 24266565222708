import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

import Card1 from '../../../Assets/ServiceImages/children-card-icon-1.svg'
import Card2 from '../../../Assets/ServiceImages/children-card-icon-2.svg'
import Card3 from '../../../Assets/ServiceImages/children-card-icon-3.svg'
import Card4 from '../../../Assets/ServiceImages/children-card-icon-4.svg'
import Card5 from '../../../Assets/ServiceImages/children-card-icon-5.svg'
import Card6 from '../../../Assets/ServiceImages/children-card-icon-6.svg'

import serviceLeftImg from '../../../Assets/ServiceImages/service-card-left-img.svg'
import serviceRightImg from '../../../Assets/ServiceImages/service-card-right-img.svg'

function CouplesCard() {
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <div className="service-left-img">
                    <img src={serviceLeftImg} alt="" />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap gap-4 service-cards'>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card1} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Understanding Relationship Dynamics</h5>
                                    </div>
                                    <div>
                                        <p>Astrology can offer insights into the dynamics and compatibility between partners. By examining the birth charts of both individuals, astrologers can identify areas of synergy, potential conflicts, and complementary qualities. This understanding can help couples gain insights into their strengths and challenges, enhancing their understanding of each other and fostering healthier communication and interactions.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card2} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Enhancing Communication</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can provide a common language for couples to communicate and understand each other better. By exploring the astrological placements associated with communication styles and emotional needs, couples can gain insights into each other’s preferred modes of expression and ways of processing information. This knowledge can improve communication and reduce misunderstandings in the relationship.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card3} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Relationship Growth and Development</h5>
                                    </div>
                                    <div>
                                        <p>Integrating astrology and psychology can support couples in their growth and development as individuals and as a partnership. Astrology can provide insights into the life themes and growth opportunities that couples may encounter together. By combining this with psychological approaches, couples can explore their strengths, vulnerabilities, and areas for personal and relational growth, fostering a sense of shared purpose and evolution.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Conflict Resolution</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can provide tools for conflict resolution within a relationship. Astrological insights can help couples understand the underlying dynamics and triggers of conflicts, while psychological approaches can offer techniques for effective communication, active listening, and empathy. This integration can support couples in finding constructive resolutions and nurturing a more harmonious and understanding partnership.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card4} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Intimacy and Emotional Connection</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can contribute to fostering intimacy and emotional connection between partners. Astrological insights can shed light on each partner’s emotional needs, attachment styles, and preferences for affection and connection. Combined with psychological understanding, couples can cultivate emotional intimacy, express vulnerability, and meet each other’s needs more effectively.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card6} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Timing and Relationship Milestones</h5>
                                    </div>
                                    <div>
                                        <p>Astrology can provide insights into timing and relationship milestones. By examining astrological transits and progressions, couples can gain awareness of the energies and potentials present during different phases of their relationship. This understanding can assist in making informed decisions regarding important milestones such as marriage, starting a family, or career changes, and can facilitate shared growth and alignment.</p>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <div className="service-right-img">
                    <img src={serviceRightImg} alt="" />
                </div>
            </Container>

        </>
    );
}

export default CouplesCard;
