import React, { useState,useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { Steps } from "antd";
import { Provider } from "./MultiStepFormContext";
import Details from "./Details";
import Address from "./Address";
import Review from "./Review";

const { Step } = Steps;

const reportInitialState = {
  report_name: "",
  report_cost: "",
  report_delivery: ""

};
const detailsInitialState = {
  name: "",
  email: "",
  phone: "",
  gender: "",
  dateofbirth: "",
  birthhour: '',
  birthminute: '',
  meridiem: '',
  placeofbirth: "",
  maritalstatus: "",
  preferredlanguage: "",
  reference: "",
  message: ""
};

const paymentInitialState = {

};

const renderStep = (step) => {
  switch (step) {
    case 0:
      return <Details />;
    case 1:
      return <Review />;
    case 2:
      return <Address />;

    default:
      return null;
  }
};

const MultiStepForm = ({report}) => {
  const [details, setDetails] = useState(detailsInitialState);
  const [payment, setPayment] = useState(paymentInitialState);
  const [reportdetails, setReportdetails] = useState(reportInitialState);
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    if (currentStep === 2) {
      setCurrentStep(0);
      setDetails(detailsInitialState);
      setPayment(paymentInitialState);
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const prev = () => setCurrentStep(currentStep - 1);
  useEffect(() => {
    setReportdetails({report_name: report.report, report_cost: report.price, report_delivery: report.delivery})
  }, []);
  console.log(reportdetails);
  return (
    <Provider value={{ details, setDetails, next, prev, payment, setPayment, reportdetails, setReportdetails }}>
      <Container fluid>
        <Container>
          <Row>
            <Col lg={12}>
              <Steps current={currentStep}>
                <Step title={"Fill in your details"} />
                <Step title={"Review and Make Payment"} />
                <Step title={"Payment details "} />
              </Steps>
              <main>{renderStep(currentStep)}</main>
            </Col>
          </Row>
        </Container>
      </Container>

    </Provider>
  );
};
export default MultiStepForm;
