import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

import Card1 from '../../../Assets/ServiceImages/children-card-icon-1.svg'
import Card2 from '../../../Assets/ServiceImages/children-card-icon-2.svg'
import Card3 from '../../../Assets/ServiceImages/children-card-icon-3.svg'
import Card4 from '../../../Assets/ServiceImages/children-card-icon-4.svg'
import Card5 from '../../../Assets/ServiceImages/children-card-icon-5.svg'
import Card6 from '../../../Assets/ServiceImages/children-card-icon-6.svg'

import serviceLeftImg from '../../../Assets/ServiceImages/service-card-left-img.svg'
import serviceRightImg from '../../../Assets/ServiceImages/service-card-right-img.svg'

function MidlifecrisisCard() {
    return (
        <>
            <Container fluid className='mt-5 mb-5'>
                <div className="service-left-img">
                    <img src={serviceLeftImg} alt="" />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap gap-4 service-cards'>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card1} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Self-Reflection and Self-Discovery</h5>
                                    </div>
                                    <div>
                                        <p>Astrology and psychology can assist individuals in engaging in self-reflection and self-discovery during a mid-life crisis. Astrology offers a unique perspective on life transitions by examining astrological transits and progressions that occur around mid-life. By understanding these cosmic influences and integrating psychological approaches, individuals can gain insights into their changing priorities, desires, and the potential for personal growth during this phase.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card2} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Life Review and Meaning-Making</h5>
                                    </div>
                                    <div>
                                        <p>A mid-life crisis often involves questioning the meaning and purpose of one’s life. Astrology and psychology can provide frameworks for exploring and understanding one’s life journey. Astrology offers a symbolic language to examine past experiences, recurring themes, and potential paths for the future. Psychological approaches, such as narrative therapy or existential psychology, can support individuals in reviewing their life story, identifying values, and creating new meanings that align with their evolving self.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card3} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Reconnecting with Authentic Self</h5>
                                    </div>
                                    <div>
                                        <p>Mid-life crises can arise when individuals feel disconnected from their authentic selves or buried under societal expectations. Astrology and psychology can assist in rediscovering and reconnecting with one’s true essence. Astrological insights can reveal innate qualities, strengths, and potentials that may have been overlooked or suppressed. Psychological tools, such as self-exploration exercises or therapy, can support individuals in understanding their values, desires, and redefining their identity and purpose.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card4} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Navigating Transitions and Reinvention</h5>
                                    </div>
                                    <div>
                                        <p>A mid-life crisis often involves major life transitions, such as career changes, relationship shifts, or personal transformations. Astrology and psychology can provide guidance during these transitions. Astrology can offer insights into the cosmic energies at play and potential areas for growth and reinvention. Psychological approaches can assist in managing fears, exploring new possibilities, and developing resilience as individuals navigate these changes.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Embracing Personal Growth</h5>
                                    </div>
                                    <div>
                                        <p>Mid-life crises can be transformative periods that lead to personal growth and self-realization. Astrology and psychology can facilitate this growth by providing a framework for self-reflection, understanding unconscious patterns, and embracing new perspectives. Psychological approaches, such as mindfulness or cognitive-behavioral techniques, can support individuals in developing new coping strategies, managing stress, and fostering a positive mindset during this phase.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className='serviceOuter-card'>
                                <div className='serviceInner-card'>
                                    <div className='card-img mb-3'>
                                        <img src={Card5} alt="" />
                                    </div>
                                    <div className='mb-3'>
                                        <h5>Support and Guidance</h5>
                                    </div>
                                    <div>
                                        <p>Engaging with astrology and psychology can provide individuals with support and guidance during a mid-life crisis. Seeking assistance from knowledgeable professionals, such as astrologers or therapists, can offer a safe space to explore emotions, gain insights, and receive personalized guidance. The combination of astrology and psychology can provide a holistic approach to navigating the complexities of a mid-life crisis and fostering personal growth and fulfillment.</p>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <div className="service-right-img">
                    <img src={serviceRightImg} alt="" />
                </div>
            </Container>

        </>
    );
}

export default MidlifecrisisCard;
