import { Helmet } from 'react-helmet';
import ElderlyBanner from "./ElderlyBanner";
import ElderlyCard from "./ElderlyCard"
import ElderlyCta from "./ElderlyCta"
import TalkAstrologer from "../TalkAstrologer";

function Elderly() {
  return (
    <>
    <Helmet>
        <title>Best Online Tamil Jathagam Services for Elder Age People</title>
        <meta name="description" data-rh="true" content="Online Tamil Jathagam Services for seniors unlock the future's mysteries. Use ancient Tamil astrology to make wise life decisions and get personalised advice" />
        <meta name="keywords" data-rh="true" content="online jathagam, jathagam online, tamil jathagam, online tamil jathagam" />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href="https://www.astromindcounsel.com/services/tamil-jathagam-services-for-elder-age/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/services/tamil-jathagam-services-for-elder-age/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content="Best Online Tamil Jathagam Services for Elder Age People" />
        <meta property="og:description" content="Online Tamil Jathagam Services for seniors unlock the future's mysteries. Use ancient Tamil astrology to make wise life decisions and get personalised advice" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Best Online Tamil Jathagam Services for Elder Age People" />
        <meta name="twitter:description" content="Online Tamil Jathagam Services for seniors unlock the future's mysteries. Use ancient Tamil astrology to make wise life decisions and get personalised advice" />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

     <ElderlyBanner />
     <ElderlyCard />
     <ElderlyCta />
     <TalkAstrologer />
    </>
  );
}

export default Elderly;
