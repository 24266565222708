import { Helmet } from 'react-helmet';
import TopAstrologersInCoimbatoreBanner from './TopAstrologersInCoimbatoreBanner';
import TopAstrologersInCoimbatoreContent from './TopAstrologersInCoimbatoreContent';
import ConnectAstrologers from '../ConnectAstrologers';
import TalkAstrologer from "../TalkAstrologer";

function TopAstrologersInCoimbatore() {
    return (
        <>
            <Helmet>
                <title>Top Astrologers in Coimbatore | Expert Astrologer's Guidance</title>
                <meta name="description" content="Discover insights with the top astrologer in Coimbatore. Get expert astrology readings, guidance & predictions to navigate your life's journey. Consult us soon!" />
                <meta name="keywords" content="nadi astrology in coimbatore, best astrologers in coimbatore, good astrologer in coimbatore, top astrologer in coimbatore, famous astrologer in coimbatore" />
                <meta name="language" content="en" />
                <meta name="revisit-after" content="7 days" />
                <meta name="Expires" content="never" />
                <meta name="Distribution" content="Global" />
                <meta name="Rating" content="general" />
                <meta name="search engines" content="ALL" />
                <meta name="copyright" content="https://www.astromindcounsel.com/" />
                <meta name="email" content="astromindcounsel@gmail.com" />
                <link rel="canonical" href="https://www.astromindcounsel.com/top-astrologers-in-coimbatore/" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.astromindcounsel.com/top-astrologers-in-coimbatore/" />
                <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
                <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
                <meta property="og:title" content="Top Astrologers in Coimbatore | Expert Astrologer's Guidance" />
                <meta property="og:description" content="Discover insights with the top astrologer in Coimbatore. Get expert astrology readings, guidance & predictions to navigate your life's journey. Consult us soon!" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Find the Best Astrologers in Chennai | Astromind Counsel" />
                <meta name="twitter:description" content="Discover insights with the top astrologer in Coimbatore. Get expert astrology readings, guidance & predictions to navigate your life's journey. Consult us soon!" />
                <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
            </Helmet>

            <TopAstrologersInCoimbatoreBanner />
            < TopAstrologersInCoimbatoreContent />
        </>
    );
}

export default TopAstrologersInCoimbatore;
