import '../../../Styles/profile.css'
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { AiFillStar } from "react-icons/ai";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetReviews } from '../../../Redux/Slice';

function ProfileReviews({ astrologer }) {
    const dispatch = useDispatch();
    const { ReviewsForAstrologer } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    useEffect(() => {
        if (astrologer) {
            dispatch(GetReviews({ astrologerId: astrologer?.astrologer?.id }))
        }
    }, [dispatch, astrologer])

    return (
        <>
            <Container fluid className='RatingwMain my-5'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={6} className='RatingCard'>
                            <h4>Rating & Reviews</h4>

                            <Col lg={12} className='d-flex'>
                                <Col lg={3}>
                                    <p className='RatingAverage'>{ReviewsForAstrologer?.data?.data?.count?.averageRating}</p>
                                    <p className='mb-0'>({ReviewsForAstrologer?.data?.data?.count?.totalReviews})</p>
                                    <div className='profileStars'>
                                        <div className="profileStar">
                                            <span>
                                                {[...new Array(5)].map((arr, index) => {
                                                    return index < `${ReviewsForAstrologer?.data?.data?.count?.averageRating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                })}
                                            </span>

                                        </div>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>5</h5>
                                        </div>
                                        <div className="progress progress1">
                                            <ProgressBar now={ReviewsForAstrologer?.data?.data?.count?.five} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>4</h5>
                                        </div>
                                        <div className="progress progress2">
                                            <ProgressBar now={ReviewsForAstrologer?.data?.data?.count?.four} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>3</h5>
                                        </div>
                                        <div className="progress progress3">
                                            <ProgressBar now={ReviewsForAstrologer?.data?.data?.count?.three} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>2</h5>
                                        </div>
                                        <div className="progress progress4">
                                            <ProgressBar now={ReviewsForAstrologer?.data?.data?.count?.two} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>1</h5>
                                        </div>
                                        <div className="progress progress5">
                                            <ProgressBar now={ReviewsForAstrologer?.data?.data?.count?.one} />
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='ReviewMain'>
                <Container>
                    <Row>
                        <Col lg={12} className='bg-blue text-white p-2 px-4'>
                            <h3>User Review</h3>
                        </Col>
                        {
                            ReviewsForAstrologer?.data?.data?.reviews?.length > 0 ?
                                <Col lg={12} className='user-reviews'>
                                    {ReviewsForAstrologer?.data?.data?.reviews?.map((review, i) => {
                                        let indianTime = new Date(review?.order?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                        return (
                                            <>
                                                <Col lg={12} sm={12} className='review-card mt-3'>
                                                    <Col lg={4} sm={3} className='reviewer_profile'>
                                                        <Col lg={4} className='d-flex justify-content-center profileImg'>
                                                            <img src={review?.profile?.file} alt="" />
                                                        </Col>
                                                        <Col lg={8}>
                                                            <div>
                                                                <h5>{review?.order?.user?.name}</h5>
                                                            </div>
                                                            <div className='profileStars'>
                                                                <div className="profileStar">
                                                                    <span>
                                                                        {[...new Array(5)].map((arr, index) => {
                                                                            return index < `${review?.order?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                                        })}
                                                                    </span>

                                                                </div>
                                                            </div>
                                                            <div className='review_date'>
                                                                <p>{indianTime}</p>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                    <Col lg={8} sm={9} className='px-2 review_command'>

                                                        <div className='review'>
                                                            <p>{review?.order?.comment}</p>
                                                        </div>
                                                        {/* <div className='bg-lightOrange astrologer-command px-3 py-2'>
                                                            <div>
                                                                <p>{astro.name}</p>
                                                                <p>{review.astrologer_command}</p>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div> */}

                                                    </Col>
                                                </Col >
                                            </>
                                        )
                                    })}
                                </Col>
                                :
                                <div className='no-data pt-5'>
                                    <p>No sufficient data available!</p>
                                </div>
                        }
                    </Row >
                </Container>
            </Container>

        </>
    );
}

export default ProfileReviews;
