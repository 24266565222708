import '../../../Styles/blogdetail.css'
import { Container, Row, Col } from 'react-bootstrap';

import EmpowerYourLifeImg1 from '../../../Assets/ServiceImages/Personalized-Horoscope.png';
import EmpowerYourLifeImg2 from '../../../Assets/ServiceImages/Horoscope.png';
import EmpowerYourLifeImg3 from '../../../Assets/ServiceImages/Raja-Nadi.png';


function PersonalizedHoroscopeContent() {
    return (
        <>
            <Container fluid className='blogContent-main mt-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between'>

                            <Col lg={5} className='d-flex flex-column justify-content-center'>
                                <div>
                                    <h4 className='text-blue'>Personalized Horoscope</h4>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <p className='text-black pt-2'>
                                        A personalized horoscope is a <a href='https://www.astromindcounsel.com/'>tailored astrological reading</a> based on your unique birth details, including the date, time, and place of your birth. Unlike general horoscopes that provide broad predictions for sun signs, a <a href='https://www.astromindcounsel.com/services/get-personalized-nadi-astrology/'>personalized horoscope</a> delves deeper into your individual astrological profile, offering more precise insights and guidance.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='blogContent-img pt-3'>
                                    <img src={EmpowerYourLifeImg1} alt="" />
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='blogContent-main bg-blue text-white text-center mt-4 py-4'>
                <Row className='d-flex justify-content-center'>
                    <Col lg={10}>
                        <div>
                            <h4>Horoscope</h4>
                            <p>A horoscope is a diagram or chart that shows the positions of the planets, Sun, Moon, astrological aspects, and sensitive angles at specific times, like the moment of a person's birth. It is used to provide insight into various aspects of an individual's life, including personality, relationships, career, and future events.</p>
                        </div>
                        <div className='pt-3'>
                            <img src={EmpowerYourLifeImg2} alt="" className='img-fluid' />
                        </div>
                        <div className='pt-3'>
                            <p>Horoscopes are also known as Birth chart, <a href='https://www.astromindcounsel.com/blogs/best-tamil-jathagam-readings-by-our-expert-online-astrologer/'>Tamil Jathagam</a> and Kundli .</p>
                            <p>The analysis of Birth chart, Jathagam, or Kundli is called Jothidam, Jothisham, Jothish or Astrology.</p>
                            <p>A <a href='https://www.astromindcounsel.com/'>detailed Horoscope</a> can be projected by using the Birth chart calculator or Kundli calculator by providing the Birth details.</p>
                            <p>The Birth detail form requires the birth date primarily. The birth time is Optional. Traditional Vedic Astrology methods require exact birth time for the Calculation of Lagna, Rasi and Dasa.</p>
                            <p>But our Primary Jothidam Method uses Raja Nadi Astrology. In Raja Nadi Astrology Birth time is only optional. Birth date should be enough to project the Birth chart.</p>
                        </div>
                    </Col>

                </Row>
            </Container>
            <Container fluid className='blogContent-main mt-5'>
                <Container>
                    <Row>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between'>

                            <Col lg={6} className='d-flex flex-column justify-content-center align-items-center'>
                                <div>
                                    <h4 className='text-blue'>Raja Nadi</h4>
                                </div>
                                <div className='pt-3'>
                                    <p>
                                        Raja Nadi, sometimes referred to as "Nadi Astrology" or "Nadi Jyotish," is an ancient form of Hindu astrology that originated in Tamil Nadu, India. It is believed to have been composed by ancient sages, particularly the revered Sage Agastya, who is said to have recorded the life destinies of individuals on palm leaves thousands of years ago.
                                    </p>
                                    <p>
                                        Raja Nadi Jothidam, also known as Nadi Astrology, is a revered ancient form of astrology originating from Tamil Nadu, India. It is believed that thousands of years ago, great sages like Sage Agastya, Sage Bhrigu, and others received divine insights about individuals' destinies and recorded these revelations on palm leaves. Raja Nadi Jothidam translates to <a href='https://www.astromindcounsel.com/'>"Royal Channel Astrology,"</a> highlighting its esteemed status in the realm of predictive sciences.
                                    </p>
                                    <p>
                                        The origins of Nadi Astrology date back thousands of years, with the palm leaf manuscripts reportedly written by ancient sages who had the ability to foresee the lives of individuals. These manuscripts were later rediscovered and preserved by dedicated custodians and are now primarily found in Tamil Nadu. The texts are written in ancient Tamil script and are organized into various chapters that cover different aspects of life.
                                    </p>

                                    {/* <ul>
                                        <li><b>Expertise in Vedic Astrology :</b> Our team consists of seasoned astrologers who bring deep knowledge and understanding of Vedic astrology prediction. They blend traditional techniques with contemporary life scenarios to offer relevant advice.</li>
                                        <li><b>Personalized Attention :</b> We believe every individual's cosmic blueprint is unique. Our <a href='https://www.astromindcounsel.com/personalized-future-predictions-dob/'>personalized horoscope</a> readings delve into your specific circumstances, offering tailored advice that speaks directly to your life's questions and challenges.</li>
                                        <li><b>Accuracy in Predictions :</b> Our commitment to accuracy is unwavering. Utilizing precise calculations and thorough analysis, we ensure our personalized horoscope predictions and insights are both reliable and enlightening.</li>
                                        <li><b>Confidential and Secure :</b> Your privacy is paramount. All consultations and personal data are handled with the utmost confidentiality and security, ensuring your peace of mind.</li>
                                        <li><a href='https://www.astromindcounsel.com/contact-us/'>Contact</a> Astro Mind Counsel for expert insights into your future. Our seasoned astrologers offer personalized readings and guidance tailored to your unique life journey. Unlock the mysteries of the cosmos and gain clarity on love, career, <a href='https://www.who.int/'>health</a>, and more. Your destiny awaits—reach out to us today.</li>
                                    </ul> */}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='pt-3'>
                                    <img src={EmpowerYourLifeImg3} alt="" className='img-fluid' />
                                </div>
                            </Col>
                            <Col lg={12} className='mt-3'>
                                <ul>
                                    <li>
                                        <p>
                                            Raja Nadi Jothidam/ Astrology method uses Planets and their position for the prediction of Jathagam.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Within the Tamil speaking region Raja Nadi Jothidam Astrology is famous for its scientific, spiritual and quick approach.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tamil Jothidam methods utilize Rasi centric format and house formats.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tamil Jothidam also uses Kalapurusha thatthuvam. In this approach Mesha Rasi is considered as 1st house.                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tamil Jothidam Consists of Multiple layers of arrangements about the Universe from the Earth centric / Human centric perspective.                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tamil Jothidam uses these multiple layers to explain about how the universe works.                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tamil Jathagam is analysed using all the above approaches and strategies.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tamil Jathagam analysis helps oneself to understand the self, to understand the circumstances, to understand the past, present and future.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Analysis of Tamil Jathagam thus predicts the Future and prepares the person to face it well.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            A <a href='https://www.astromindcounsel.com/services/get-personalized-nadi-astrology/'>personalized horoscope</a> or Tamil Jathagam, thus helps for self-improvement.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            A personalized Horoscope of Tamil Jathagam contains practical, emotional, psychological, philosophical and Spiritual guidelines.
                                        </p>
                                    </li>
                                </ul>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    )
}

export default PersonalizedHoroscopeContent;