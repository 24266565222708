import '../../Styles/contact.css'
import { Container, Row, Col } from 'react-bootstrap';

function FaqsBanner() {
    return (
        <>
            <Container fluid className='astrologer-background'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={8} className='d-flex flex-column justify-content-center align-items-center text-center'>
                            <h2 className='text-darkOrange'>Frequently Asked Questions: Astrology Services by Expert Astrologers</h2>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default FaqsBanner;
