import '../../../Styles/login.css';
import { Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import "react-phone-input-2/lib/style.css";
import Modal from 'react-bootstrap/Modal';

import ConfirmImg from '../../../Assets/Version2Images/confirm-image.png';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LoginScreen, errorMessage, successMessage, loadingStatus } from '../../../Redux/Slice';

function ConfirmMessage({ Message }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {  ConfirmStatus, User } = useSelector((state) => state.Astromind)

    const comfirmClose = () => {
        navigate('/talk-to-astrologer/');
        dispatch(LoginScreen({ ConfirmStatus: false }));
    }

    useEffect(() => {
        if (Message != '') {
            setTimeout(() => {
                navigate('/talk-to-astrologer/');
                dispatch(LoginScreen({ ConfirmStatus: '', OpenStatus: '', OtpStatus: '', DetailStatus: '' }));
                dispatch(errorMessage({ errors: '' }));
                dispatch(successMessage({ success: '' }));
                dispatch(loadingStatus({ Loading: false }));
                if (User?.data?.isExist) {
                    localStorage.setItem('user-auth-token', JSON.stringify(User?.data?.token));
                }
            }, 3000);
        }
    }, [dispatch, Message, User]);

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ConfirmStatus}
                onHide={comfirmClose}
                className='confirm-modal'
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Col>
                        <div className='d-flex justify-content-center '>
                            <img src={ConfirmImg} alt="" />
                        </div>
                        <div className='text-center mt-4'>
                            <h2>{Message}</h2>
                        </div>
                        <div className='text-center mt-4 thank-text'>
                            <p>Thank You!!</p>
                        </div>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmMessage;