import { Col, Button } from 'react-bootstrap';
import { FaPhone } from "react-icons/fa6";
import useInterval from 'use-interval';
import { useEffect, useState } from 'react';

import { MdCancel } from "react-icons/md";

import AddReview from '../ChatPage/AddReview';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetCallStatus, CallData } from '../../../Redux/Slice';

function CallConnect() {
    const dispatch = useDispatch();
    const { CallStatus, ConsultationType } = useSelector((state) => state.Astromind);

    const token = JSON.parse(localStorage.getItem('user-auth-token'));
    let orderId = localStorage.getItem("orderId");
    let waitingId = localStorage.getItem("waitingId");
    const userData = JSON.parse(localStorage.getItem("userData"));

    const [reviewShow, setReviewShow] = useState(false);

    const reviewShowClose = (value) => {
        setReviewShow(value)
    }
    useEffect(() => {
        if (waitingId != null && orderId != null && ConsultationType == "call") {
            dispatch(GetCallStatus({ token, orderId, waitingId }));
        }
    }, []);

    useInterval(() => {
        if (waitingId != null && orderId != null && ConsultationType == "call") {
            dispatch(GetCallStatus({ token, orderId, waitingId }));

            if (CallStatus?.data?.data?.rejected || CallStatus?.data?.data?.ended) {
                setTimeout(() => {
                    callExit();
                }, 10000)
            }
        }
    }, 5000)

    const callExit = () => {
        localStorage.removeItem("orderId");
        localStorage.removeItem("waitingId");
        if (waitingId && orderId) {
            dispatch(CallData({ CallStatus: '' }));
        }
    }

    return (
        <>
            {
                reviewShow ?
                    <AddReview
                        show={reviewShow}
                        reviewClose={reviewShowClose}
                        astrologerId={userData?.astrologerId}
                        orderId={orderId}
                    />
                    : null
            }
            {CallStatus?.data?.data?.waiting ?
                <div className='call-waiting-list'>
                    <Col lg={12} className="call-buton">
                        <Col lg={4} className="cc-calto-action-ripple">
                            <FaPhone className='icon' />
                        </Col>
                        <Col lg={8} className='call-text'>
                            {CallStatus?.data?.message}
                        </Col>
                    </Col>
                </div>
                : null
            }
            {
                CallStatus?.data?.data?.accepted || CallStatus?.data?.data?.rejected || CallStatus?.data?.data?.ended ?
                    <div className='call-accept-list'>
                        {CallStatus?.data?.data?.rejected || CallStatus?.data?.data?.ended ?
                            <div
                                className='call-cancel'
                                onClick={() => { callExit() }}
                            >
                                <MdCancel className='icon' />
                            </div>
                            : null
                        }

                        <Col lg={12} className="call-buton">
                            <Col lg={12} className="cc-calto-accept-action-ripple"><FaPhone className='icon' /></Col>
                            <Col lg={12} className='astro-name'>{CallStatus?.data?.data?.astrologer_name}</Col>
                            <Col lg={12} className='call-text'>{CallStatus?.data?.message}</Col>
                            {
                                CallStatus?.data?.data?.ended ?
                                    <Col lg={12} className='call-text'><Button onClick={() => setReviewShow(true)}>Give your Review Here</Button></Col>
                                    : null
                            }
                        </Col>
                    </div>
                    : null
            }
        </>
    )
}

export default CallConnect;