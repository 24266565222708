import '../../../Styles/service.css'
import { Container, Row, Col } from 'react-bootstrap';

function CouplesCta() {
    return (
      <>
       <Container fluid>
          <Container>
              <Row>
                  <Col lg={12} className='bg-lightOrange p-5 text-center'>
                    <h6 className='text-blue'>It’s important to note that the integration of astrology and psychology in relationships should be approached with an open mind and mutual agreement between partners. It’s advisable to work with knowledgeable and ethical professionals who can provide guidance and support tailored to the couple’s specific needs. Ultimately, the field of astrology and psychology can serve as a tool to deepen understanding, enhance communication, and foster growth within the relationship.</h6>
                  </Col>
              </Row>
          </Container>
       </Container>
  
      </>
    );
  }
  
  export default CouplesCta;
  