import { Col } from 'react-bootstrap';


function PlanetDetails({ chart }) {
    return (
        <>
            {chart?.planets_position?.length > 0 ?
                <Col lg={12} className='birth-planet mb-3'>
                    <table >
                        <tr>
                            <th>கிரகம்</th>
                            <th>ராசி</th>
                            <th >கிரக நிலை</th>
                            <th>நட்சத்திரம்</th>
                        </tr>
                        {
                            chart?.planets_position?.map((data, index) => {

                                return (
                                    <>
                                        <tr>
                                            <td>
                                                {data?.name == "வியாழன் " ? "குரு" :
                                                    data?.name == "வெள்ளி " ? "சுக்கிரன்" :
                                                        data?.name == "கேட்டு" ? "கேது" :
                                                            data?.name == "களத்திரஸ்தானம்" ? "லக்னம்" :
                                                                data?.name?.slice(0, 8)}
                                                {data?.is_retro === "true" ? "(வ)" : null}
                                            </td>
                                            <td>{data?.sign}</td>
                                            <td>{data?.sign_degree?.split(":")[0]} <span>&#176;</span>{data?.sign_degree.split(":")[1]}<span>`</span></td>
                                            <td>{data?.nakshatra}</td>
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </table>
                </Col>
                :
                <>
                    <div className='no-data'>
                        <p>No sufficient data available!</p>
                    </div>
                </>
            }
        </>
    )
}

export default PlanetDetails;