import React, { useContext } from "react";
import { Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from "antd";
import CreatableSelect from 'react-select/creatable';
import MultiStepFormContext from "./MultiStepFormContext";

import { FaFile } from "react-icons/fa";

const options = [
  { value: 'Axis Bank Ltd', label: 'Axis Bank Ltd' },
  { value: 'Bank of Baroda Ltd', label: 'Bank of Baroda Ltd' },
  { value: 'City Union Bank Ltd', label: 'City Union Bank Ltd' },
  { value: 'Canara Bank', label: 'Canara Bank' },
  { value: 'Central Bank of India', label: 'Central Bank of India' },
  { value: 'HDFC Bank Ltd', label: 'HDFC Bank Ltd' },
  { value: 'ICICI Bank Ltd', label: 'ICICI Bank Ltd' },
  { value: 'IDFC First Bank Ltd', label: 'IDFC First Bank Ltd' },
  { value: 'Indian Bank', label: 'Indian Bank' },
  { value: 'Indian Overseas Bank', label: 'Indian Overseas Bank' },
  { value: 'IDFC First Bank Ltd', label: 'IDFC First Bank Ltd' },
  { value: 'Karur Vysya Bank Ltd', label: 'Karur Vysya Bank Ltd' },
  { value: 'Kotak Mahindra Bank Ltd', label: 'Kotak Mahindra Bank Ltd' },
  { value: 'Punjab & Sind Bank', label: 'Punjab & Sind Bank' },
  { value: 'Punjab National Bank', label: 'Punjab National Bank' },
  { value: 'State Bank of India', label: 'State Bank of India' },
  { value: 'South Indian Bank Ltd', label: 'South Indian Bank Ltd' },
  { value: 'Union Bank of India', label: 'Union Bank of India' },
  { value: 'YES Bank Ltd', label: 'YES Bank Ltd' },
];

const BankDetails = () => {
  const { bankdetails, setBankdetails, next, prev } = useContext(MultiStepFormContext);

  const formik = useFormik({
    initialValues: bankdetails,
    onSubmit: async (values) => {
      setBankdetails(values);
      next();
    },
    validate: (values) => {
      const errors = {};
      const supportedFormats = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes

      if (!values.id_proof_image) {
        errors.id_proof_image = "Requried*";
      }
      else if (values.id_proof_image) {
        const fileType = values.id_proof_image.type;
        const fileSize = values.id_proof_image.size;

        if (!supportedFormats.includes(fileType)) {
          errors.id_proof_image = 'Unsupported file format. Please upload a PDF file.';
        } else if (fileSize > maxFileSize) {
          errors.id_proof_image = 'File size should be less than 2 MB.';
        }
      }
      if(values.passbook_image){
        const fileType = values.passbook_image.type;
        const fileSize = values.passbook_image.size;

        if (!supportedFormats.includes(fileType)) {
          errors.passbook_image = 'Unsupported file format. Please upload a PDF file.';
        } else if (fileSize > maxFileSize) {
          errors.passbook_image = 'File size should be less than 2 MB.';
        }
      }
      return errors;
    }
  })
  return (
    <>
      <form action="" onSubmit={formik.handleSubmit}>
        <div className={"details__wrapper register-form mb-5"}>
          <Col lg={12} className="p-3">
            <div className="mb-4">
              <h3>Bank Details</h3>
            </div>
            <div className="d-flex gap-3">
              <div className={`form__item mb-4`}>
                <label>Bank account holder name</label>
                <input
                  name="holder_name"
                  className="px-3"
                  value={formik.values.holder_name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={`form__item mb-4`}>
                <label>Bank name</label>
                {/* <input name="bank_name" className="px-3" value={formik.values.bank_name} onChange={formik.handleChange} /> */}
                <CreatableSelect
                  isClearable
                  options={options}
                  placeholder="Select or Type your Bank"
                  onChange={(newValue) => formik.setFieldValue('bank_name', newValue)}
                  value={formik.values.bank_name}
                />
              </div>
            </div>
            <div className="d-flex gap-3">
              <div className={`form__item  mb-4`}>
                <label>Bank account number</label>
                <input
                  name="account_number"
                  className="px-3"
                  value={formik.values.account_number}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={`form__item  mb-4`}>
                <label>Branch Name</label>
                <input
                  name="branch_name"
                  className="px-3"
                  value={formik.values.branch_name}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="d-flex gap-3">
              <div className={`form__item  mb-4`}>
                <label>IFSC Code</label>
                <input
                  name="ifsc_code"
                  className="px-3"
                  value={formik.values.ifsc_code}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={`form__item  mb-4`}>
                <label>Pan number</label>
                <input
                  name="pan_number"
                  className="px-3"
                  value={formik.values.pan_number}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="d-flex gap-3 bank-file-upload">
              <div className={`form__item  mb-4`}>
                <label>ID Proof <span className="text-red">*</span></label>
                <div className="d-flex">
                  <input
                    name="name"
                    className="px-3"
                    placeholder="Driving licence/ Aadhar/Passport"
                    value={formik.values.id_proof_image.name}
                    disabled
                  />
                  <div className="d-flex file-upload">
                    <input
                      type="file"
                      name="id_proof_image"
                      onChange={(e) => {
                        formik.setFieldValue('id_proof_image', e.target.files[0])
                      }}
                      multiple
                    />
                    <div className="upload-btn d-flex justify-content-center align-items-center gap-2">
                      <FaFile  className="file-icon" />
                      Upload
                    </div>
                  </div>
                </div>
                <p className={"error__feedback"}>{formik.errors.id_proof_image}</p>
              </div>
              <div className={`form__item ${formik.errors.passbook_image && "input__error"} mb-4`}>
                <label>Bank passbook</label>
                <div className="d-flex">
                  <input
                    name="name"
                    className="px-3"
                    placeholder="Pass book/Bank statement"
                    value={formik.values.passbook_image.name}
                    disabled
                  />
                  <div className="d-flex file-upload">
                    <input
                      type="file"
                      name="passbook_image"
                      onChange={(e) => {
                        formik.setFieldValue('passbook_image', e.target.files[0])
                      }}
                      multiple
                    />
                    <div className="upload-btn d-flex justify-content-center align-items-center gap-2">
                      <FaFile  className="file-icon" />
                      Upload
                    </div>
                  </div>
                </div>
                <p className={"error__feedback"}>{formik.errors.passbook_image}</p>
              </div>
            </div>
          </Col>

          <div className={"form__item2 button__items d-flex justify-content-center gap-3 mx-4"}>
            <Button
              type={"primary"}
              className="verify-btn"
              onClick={prev}
            >
              Back
            </Button>
            <Button
              type={"primary"}
              className="verify-btn bg-orange"
              onClick={formik.handleSubmit}
            >
              Preview
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default BankDetails;
