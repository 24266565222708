import { Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetPaymentLogs } from '../../../Redux/Slice';

function PaymentLogs({ navigate }) {
    const dispatch = useDispatch();
    const { PaymentHistory, Loading } = useSelector((state) => state.Astromind);
    const token = JSON.parse(localStorage.getItem('user-auth-token'));

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(PaymentHistory?.data?.pagination?.currentpage);
    }, [PaymentHistory]);

    useEffect(() => {
        let page = currentPage;
        dispatch(GetPaymentLogs({ token, page }))
    }, []);

    const deleteData = () => {
        Swal.fire({
            title: "Are you sure want to Delete your payment data?",
            icon: "delete",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete All"
        });
    }

    const changePage = (value) => {
        let page = value;
        dispatch(GetPaymentLogs({ token, page }));
    }

    const nextPage = () => {
        const page = Number(currentPage) + 1;
        if (page <= PaymentHistory?.data?.pagination?.totalPage) {
            dispatch(GetPaymentLogs({ token, page }));
        }
    }

    const previousPage = () => {
        const page = Number(currentPage) - 1;
        if (page >= 1) {
            dispatch(GetPaymentLogs({ token, page }));
        }
    }

    return (
        <>
            <Col lg={12} sm={12} className='d-flex flex-column justify-content-center mt-5 wallet-transaction'>
                <div className='mb-3'>
                    <h3 className='text-center'>Payment Logs</h3>
                </div>
                {Loading ?
                    <>
                        <div class="wrapper">
                            <div class="page-loader">
                                <div class="loading one"></div>
                                <div class="loading two"></div>
                                <div class="loading three"></div>
                                <div class="loading four"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <Col lg={11} className='transaction-btn d-flex justify-content-between mb-3'>
                            <div className='recharge-btn'>
                                <button onClick={() => { navigate('/add-wallet-money/') }}>Recharge</button>
                            </div>
                            {
                                PaymentHistory?.data?.data.length > 0 ?
                                    <>
                                        <div className='recharge-btn delete-btn'>
                                            <button onClick={() => { deleteData() }}>Delete All</button>
                                        </div>
                                    </>
                                    :
                                    null
                            }

                        </Col>
                        <div className='transaction-table payment-table'>
                            {PaymentHistory?.data?.data.length > 0 ?
                                <>
                                    <table>
                                        <tr className='transaction-heading'>
                                            <th>Date & Time</th>
                                            <th>Payment Id</th>
                                            <th>Deposit Amount</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Total Amount (Gst 18%)</th>
                                            <th>Invoice Id</th>
                                            <th>Invoice Url</th>
                                        </tr>

                                        {PaymentHistory?.data?.data?.map((data, i) => {
                                            let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });

                                            return (
                                                <>
                                                    <tr className='transaction-data'>
                                                        <td>{indianTime}</td>
                                                        <td>{data.payment_id}</td>
                                                        <td className={data.status === 'authorized' || data.status === 'captured' ? null : 'payment-failed'}>₹ {data.deposit_amount}
                                                            <span className={data?.bonus > 0 ? 'payment-success' : null} > {data?.bonus > 0 ? <>(+{data?.bonus})</> : null}</span>
                                                        </td>
                                                        <td>{data.Payment_method}</td>
                                                        <td className={data.status === 'authorized' || data.status === 'captured' ? 'payment-success' : 'payment-failed'}>
                                                            {data.status === 'authorized' || data.status === 'captured' ? 'success' : data?.status}
                                                        </td>
                                                        <td className={data.status === 'authorized' || data.status === 'captured' ? null : 'payment-failed'}>
                                                            ₹ {data.total_amount}
                                                        </td>
                                                        <td>{data.invoice_id ? data.invoice_id : "none"}</td>
                                                        <td>{data.invoice_url ? <a href={data.invoice_url} >download</a> : "none"}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </table>
                                </>
                                :
                                <>
                                    <div className='no-data'>
                                        <p>No sufficient data available!</p>
                                    </div>
                                </>
                            }
                        </div>
                        {PaymentHistory?.data?.pagination?.totalPage > 1 ?
                            <Col lg={12} className='d-flex justify-content-center'>
                                <Pagination className="px-4">
                                    <Pagination.Prev onClick={() => previousPage()} />

                                    {[...new Array(PaymentHistory?.data?.pagination?.totalPage)].map((arr, index) => {
                                        return (
                                            <Pagination.Item
                                                onClick={() => changePage(index + 1)}
                                                key={index + 1}
                                                active={index + 1 === Number(currentPage)}
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        )
                                    })}
                                    <Pagination.Next onClick={() => nextPage()} />
                                </Pagination>
                            </Col>
                            : null
                        }
                    </>
                }
            </Col>
        </>
    )
};

export default PaymentLogs;